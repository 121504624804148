<template>
  <ad-icon-base iconName="copy" v-bind="$attrs">
    <path
      d="M33.7,0H12.1c-1.2,0-2.1,1-2.2,2.1v3.6H6.3c-1.2,0-2.1,1-2.2,2.1v30c0,1.2,1,2.1,2.2,2.1h21.6
	c1.2,0,2.1-1,2.2-2.1v-3.6h3.6c1.2,0,2.1-1,2.2-2.1v-30C35.8,1,34.8,0,33.7,0z M28.6,31.5v6.3c0,0.4-0.3,0.7-0.6,0.7
	c0,0-0.1,0-0.1,0H6.3c-0.4,0-0.7-0.3-0.7-0.6c0,0,0-0.1,0-0.1v-30c0-0.4,0.3-0.7,0.6-0.7c0,0,0.1,0,0.1,0h21.6
	c0.4,0,0.7,0.3,0.7,0.6c0,0,0,0.1,0,0.1V31.5z M34.4,25.8v6.4c0,0.4-0.3,0.7-0.6,0.7c0,0-0.1,0-0.1,0h-3.6v-25c0-1.2-1-2.1-2.2-2.1
	H11.4V2.1c0-0.4,0.3-0.7,0.6-0.7c0,0,0.1,0,0.1,0h21.6c0.4,0,0.7,0.3,0.7,0.6c0,0,0,0.1,0,0.1L34.4,25.8z"
    />
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconCopy extends Vue {}
</script>
