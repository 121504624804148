<template>
  <div class="product-search-filter-numericrange">
    <label class="form-group bg-transparent border-0 mb-0 p-0 fs-18"><slot name="filter-label">[Filter]</slot></label>
    <b-row no-gutters>
      <b-col>
        <ad-filter-numeric-input
          :value="selectedValueFrom"
          :borderValue="borderValueMin"
          @input="onChangeFromValue"
          :fieldFormat="fieldFormat"
          :loading="loading"
        >
          <template #filter-label-text> <slot name="filter-label-from">[TO]</slot></template>
        </ad-filter-numeric-input>
      </b-col>
      <b-col>
        <div class="form-range-divider">-</div>
        <ad-filter-numeric-input
          :value="selectedValueTo"
          :borderValue="borderValueMax"
          @input="onChangeToValue"
          :fieldFormat="fieldFormat"
          :loading="loading"
        >
          <template #filter-label-text> <slot name="filter-label-to">[TO]</slot></template>
        </ad-filter-numeric-input>
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ValueRangeModel, ValueRangeParameterModel } from '@/src/types/the-q-api';

@Component({
  inheritAttrs: false,
})
export default class AdFilterNumericRangeHolder extends Vue {
  @Prop() availableValues!: ValueRangeModel<number>;
  @Prop() value!: ValueRangeParameterModel | null;
  @Prop({ required: true }) fieldFormat!: string;
  @Prop({ required: false }) loading!: boolean;

  private readonly changeValueEventName = 'selected-value-changed';

  get borderValueMin(): number | null {
    return this.availableValues?.min;
  }

  get borderValueMax(): number | null {
    return this.availableValues?.max;
  }

  get selectedValueFrom() {
    return this.value?.min ?? null;
  }

  get selectedValueTo() {
    return this.value?.max ?? null;
  }

  isValueWithinRange(val: number | null): boolean {
    if (val === null || isNaN(val)) {
      return false;
    }

    return this.isInRange(val, this.availableValues);
  }

  onChangeFromValue(newValue: number | null): void {
    if (!this.checkFromValueAndResetIfNeeded(newValue))
      this.$emit('input', this.createNumericRange(newValue, this.selectedValueTo));

    this.$emit(this.changeValueEventName);
  }

  onChangeToValue(newValue: number | null): void {
    if (!this.checkToValueAndResetIfNeeded(newValue))
      this.$emit('input', this.createNumericRange(this.selectedValueFrom, newValue));

    this.$emit(this.changeValueEventName);
  }

  @Watch('value.min')
  checkFromValueAndResetIfNeeded(newValue: number | null): boolean {
    if (this.availableValues === null) {
      return false;
    }

    let selectedValueTo = this.selectedValueTo;
    let isValueReset = false;

    if (newValue != null) {
      if (newValue < this.availableValues.min) {
        newValue = this.availableValues.min;
        isValueReset = true;
      } else if (newValue > this.availableValues.max) {
        newValue = this.availableValues.max;
        isValueReset = true;
      }

      if (selectedValueTo != null && newValue > selectedValueTo) {
        selectedValueTo = newValue;
        isValueReset = true;
      }
    }

    if (isValueReset) this.$emit('input', this.createNumericRange(newValue, selectedValueTo));

    return isValueReset;
  }

  @Watch('value.max')
  checkToValueAndResetIfNeeded(newValue: number | null): boolean {
    if (this.availableValues === null) {
      return false;
    }
    let selectedValueFrom = this.selectedValueFrom;
    let isValueReset = false;

    if (newValue !== null) {
      if (newValue < this.availableValues.min) {
        newValue = this.availableValues.min;
        isValueReset = true;
      } else if (newValue > this.availableValues.max) {
        newValue = this.availableValues.max;
        isValueReset = true;
      }

      if (selectedValueFrom !== null && newValue < selectedValueFrom) {
        selectedValueFrom = newValue;
        isValueReset = true;
      }
    }

    if (isValueReset) this.$emit('input', this.createNumericRange(selectedValueFrom, newValue));

    return isValueReset;
  }

  @Watch('availableValues', { deep: false })
  changeAvailableItems(): void {
    if (this.availableValues === null) {
      return;
    }

    if (!this.isValueWithinRange(this.selectedValueFrom)) {
      this.$emit('input', this.createNumericRange(null, this.selectedValueTo));
    }
    if (!this.isValueWithinRange(this.selectedValueTo)) {
      this.$emit('input', this.createNumericRange(this.selectedValueFrom, null));
    }
  }

  isInRange(val: number | null, range: ValueRangeModel<number> | null) {
    if (val == null || range == null) {
      return false;
    }

    return val >= range.min && val <= range.max;
  }

  createNumericRange(minValue: number | null, maxValue: number | null): ValueRangeParameterModel | null {
    if (minValue === null && maxValue == null) return null;

    return { min: minValue, max: maxValue } as ValueRangeParameterModel;
  }
}
</script>
