<script lang="ts">
import { VNode } from 'vue';
import { Component, Provide, Prop, Vue } from 'vue-property-decorator';
import {} from '@/src/types/episerver-api';

@Component({})
export default class AdIndex extends Vue {
  @Provide()
  @Prop({ required: true, type: Number })
  index!: number;

  render(): VNode[] | VNode | undefined {
    return this.$scopedSlots.default?.({});
  }
}
</script>
