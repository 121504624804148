<template>
  <div class="product-search-filter-daterange">
    <label class="form-group bg-transparent border-0 mb-0 p-0 fs-18"><slot name="filter-label">[Filter]</slot></label>
    <b-row no-gutters>
      <b-col cols="6">
        <b-form-group id="product-search-filter-daterange-from" class="focus-white" label-for="date-from-list-filter">
          <template #label> <slot name="filter-label-from">[DATE FROM]</slot></template>

          <ad-drop-down-list
            :loading="loading"
            v-bind="$attrs"
            :options="dropdownItems"
            :is-selectable="isSelectableDateFrom"
            :value="currentSelectedValueFrom"
            @input="onChangeDateFromValue"
            form-element-state-id="product-search-filter-daterange-from"
            id="date-from-list-filter"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <div class="form-range-divider">-</div>
        <b-form-group id="product-search-filter-daterange-to" class="focus-white" label-for="date-to-list-filter">
          <template #label> <slot name="filter-label-to">[DATE TO]</slot></template>

          <ad-drop-down-list
            :loading="loading"
            v-bind="$attrs"
            :options="dropdownItems"
            :is-selectable="isSelectableDateTo"
            :value="currentSelectedValueTo"
            @input="onChangeDateToValue"
            form-element-state-id="product-search-filter-daterange-to"
            id="date-to-list-filter"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DropDownItem } from '@/src/types/vue-api';
import { convertToDropdownItems } from '@/src/utils/dropdown-option-provider';
import { DateRangeParameterModel } from '@/src/types/the-q-api';
import { formatField } from '@/src/utils/value-formatter/formatting-service';

@Component({
  inheritAttrs: false,
})
export default class AdFilterDaterange extends Vue {
  @Prop() availableValues!: string[];
  @Prop({ required: true }) value!: DateRangeParameterModel | null;
  @Prop({ required: false }) loading!: boolean;

  private selectedValueIndexFrom = 0;
  private selectedValueIndexTo = 0;

  private selectedValueFrom: DropDownItem | null = null;
  private selectedValueTo: DropDownItem | null = null;

  private dropdownItems: Array<DropDownItem> = [];

  setDefaultValues(): void {
    if (this.dropdownItems.length) {
      const fromIndex = this.getItemIndexByValueOrDefault(this.value?.min);
      this.selectedValueFrom = this.dropdownItems[fromIndex];
      this.selectedValueIndexFrom = fromIndex;

      const lastIndex = this.getItemIndexByValueOrDefault(this.value?.max, this.dropdownItems.length - 1);
      this.selectedValueTo = this.dropdownItems[lastIndex];
      this.selectedValueIndexTo = lastIndex;
    }
  }

  @Watch('availableValues')
  changeAvailableItems(): void {
    this.dropdownItems = convertToDropdownItems(null, this.availableValues, (x) => formatField(x, 'date'));
    this.setDefaultValues();
  }

  get currentSelectedValueFrom(): DropDownItem | null {
    if (this.dropdownItems.length === 0) {
      return null;
    }
    let matchingItem = this.value?.min ? this.findItemByValue(this.value.min) : this.dropdownItems[0];
    matchingItem = matchingItem ?? this.dropdownItems[0];

    return matchingItem;
  }

  get currentSelectedValueTo(): DropDownItem | null {
    if (this.dropdownItems.length === 0) {
      return null;
    }

    const lastIndex = this.dropdownItems.length - 1;
    let matchingItem = this.value?.max ? this.findItemByValue(this.value.max) : this.dropdownItems[lastIndex];
    matchingItem = matchingItem ?? this.dropdownItems[lastIndex];

    return matchingItem;
  }

  isSelectableDateFrom(item: DropDownItem): boolean {
    const selectedIndex = this.findItemIndex(item);
    this.$log.debug('index', this.selectedValueIndexTo, selectedIndex);
    return this.selectedValueIndexTo < 0 || selectedIndex <= this.selectedValueIndexTo;
  }

  isSelectableDateTo(item: DropDownItem): boolean {
    const selectedIndex = this.findItemIndex(item);
    return this.selectedValueIndexFrom < 0 || selectedIndex >= this.selectedValueIndexFrom;
  }

  findItemIndex(item: DropDownItem): number {
    return this.getItemIndexByValueOrDefault(item.value);
  }

  getItemIndexByValueOrDefault(val: string | number | null | undefined, defaultVal = 0) {
    let index = defaultVal;

    if (val) index = this.dropdownItems.findIndex((x) => x.value == val);

    return index;
  }

  findItemByValue(val: string | undefined): DropDownItem | null {
    return this.dropdownItems.find((x) => x.value == val) ?? null;
  }

  createDateRange(
    minValue: string | number | null | undefined,
    maxValue: string | number | null | undefined
  ): DateRangeParameterModel {
    return { min: minValue ?? null, max: maxValue ?? null } as DateRangeParameterModel;
  }

  onChangeDateFromValue(newValue: string | undefined): void {
    this.selectedValueIndexFrom = this.getItemIndexByValueOrDefault(newValue);
    this.selectedValueFrom = this.findItemByValue(newValue);

    const emittedValue = this.createDateRange(newValue, this.selectedValueTo?.value);
    this.$emit('input', emittedValue);
    this.$emit('selected-value-changed');
  }

  onChangeDateToValue(newValue: string | undefined): void {
    this.selectedValueIndexTo = this.getItemIndexByValueOrDefault(newValue);
    this.selectedValueTo = this.findItemByValue(newValue);

    const emittedValue = this.createDateRange(this.selectedValueFrom?.value, newValue);
    this.$emit('input', emittedValue);
    this.$emit('selected-value-changed');
  }
}
</script>
