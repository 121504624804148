/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const observersMap: Map<string, MutationObserver[]> = new Map();
const mutationObserverConfigs = { attributes: true, childList: true, subtree: true };

/**
 * Component to hide an element if parent element previous sibling is same component
 * component is identified by class
 * The element with this directive must have the id attribute, since it is used to store mutations in a map
 */
Vue.directive('hide-element-by-same-parent-previous-sibling', {
  // When the bound element is inserted into the DOM...
  inserted: function (el: HTMLElement, binding: DirectiveBinding) {
    const elementId = el.getAttribute('id');
    if (elementId === null) {
      Vue.$log.error(
        'v-hide-element-by-same-parent-previous-sibling directive does not work without an id attribute on the element'
      );
      return;
    }

    const mutationCallback: MutationCallback = function (mutationList: MutationRecord[], observer) {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList' && mutation.target.childNodes.length > 0) {
          // add element back again to DOM
          el.classList.remove('same-sibling-element');
        }
      }
    };

    const observers: MutationObserver[] = [];
    const classToCheck = binding.value;
    let currentSibling: Element | null = el.previousElementSibling ?? null;

    while (currentSibling != null) {
      // get all rendered styles
      const styles = window.getComputedStyle(currentSibling);
      if (styles.display !== 'none') {
        if (currentSibling.classList.contains(classToCheck)) {
          Vue.$log.debug('hide-element-by-same-previous-sibling remove element', el);
          // remove element from DOM
          el.classList.add('same-sibling-element');
        }

        break;
      }
      /**
       * MutationObservers are set on the currentSibling elements, because there might be the case
       * where elements are rendered asynchronously after data is loaded from backend with in the currentSibling elements
       */
      const observer = new MutationObserver(mutationCallback);
      observer.observe(currentSibling, mutationObserverConfigs);
      observers.push(observer);
      currentSibling = currentSibling.previousElementSibling;
    }

    observersMap.set(elementId!, observers);
  },
  unbind(el: HTMLElement) {
    // This if check is required because unbind is called at the beginning, when the element is not even rendered
    if (!el.getAttribute) return;
    const id = el.getAttribute('id');
    const elementObservers = observersMap.get(id!);

    if (elementObservers === undefined) return;

    for (const observer of elementObservers) {
      // Disconnect all observers on the element, if the element is removed from DOM with v-if or display:none
      observer?.disconnect();
    }

    observersMap.delete(id!);
  },
});
