<template>
  <div class="ce-newsletter-subscription py-7 mb-7">
    <h2 class="text-center">
      <b-collapse :visible="subscribed">
        <h2 class="text-center"><slot name="confirmation-first-headline"></slot></h2>
        <span class="ce-newsletter-subscription--thank-you"><slot name="confirmation-second-headline"></slot></span>
      </b-collapse>
      <b-collapse :visible="confirmOptInPanel">
        <h2 v-if="confirmOptInSuccess" class="text-center"><slot name="confirmation-optin-success-headline"></slot></h2>
        <h2 v-else class="text-center"><slot name="confirmation-optin-error-headline"></slot></h2>
      </b-collapse>
    </h2>

    <div class="blog-container container-fluid">
      <b-collapse :visible="!open && !subscribed && !confirmOptInPanel">
        <h2 v-if="!subscribed" class="text-center"><slot name="hidden-headline"></slot></h2>
        <p
          v-if="!subscribed && $slots['hidden-description-text']"
          class="ce-newsletter-subscription--collapsed-subtext fs-26 text-center pb-5 m-0"
        >
          <slot name="hidden-description-text"></slot>
        </p>
      </b-collapse>
    </div>
    <b-collapse v-model="open">
      <b-collapse :visible="subscribed && !confirmOptInPanel">
        <div class="blog-container container-fluid">
          <p class="mb-5">
            <span v-if="subscribeSuccess"><slot name="confirmation-description-text"></slot></span>
            <span v-else><slot name="confirmation-error-description-text"></slot></span>
          </p>
        </div>
      </b-collapse>
      <b-collapse :visible="confirmOptInPanel">
        <div class="blog-container container-fluid">
          <p class="mb-5">
            <span v-if="subscribeSuccess"><slot name="confirmOptIn-description-text"></slot></span>
            <span v-else><slot name="confirmOptIn-error-description-text"></slot></span>
          </p>
        </div>
      </b-collapse>
      <b-collapse :visible="!subscribed && !confirmOptInPanel">
        <div class="ce-newsletter-subscription--collapsible-content pt-3">
          <div class="blog-container container-fluid">
            <h2 class="text-center"><slot name="unhidden-headline"></slot></h2>
            <p class="mb-5" v-if="$slots['unhidden-description-text']">
              <slot name="unhidden-description-text"></slot>
            </p>

            <b-form ref="form" @submit="handleSubmit($event)" novalidate>
              <b-form-radio-group name="salutation" class="mb-5" v-model="salutation">
                <span v-for="(salutationItem, i) in settingsProperty.newsletterSalutations" v-bind:key="i">
                  <b-form-radio :value="salutationItem">
                    <span class="ce-newsletter-subscription--checkbox-and-radio-label fs-16">{{ salutationItem }}</span>
                  </b-form-radio>
                </span>
              </b-form-radio-group>

              <!-- Text fields -->
              <div class="ce-newsletter-subscription--input-fields mb-5">
                <div class="d-flex flex-column flex-md-row justify-content-between">
                  <div
                    class="ce-newsletter-subscription--form-field-container"
                    :data-show-error="!!errors.firstname && firstnameTouched"
                  >
                    <b-form-group
                      :label="settingsProperty.nameLabel"
                      label-for="newsletter-subscription-form--firstname"
                    >
                      <b-form-input
                        id="newsletter-subscription-form--firstname"
                        name="firstname"
                        v-model="firstname"
                      ></b-form-input>
                    </b-form-group>
                    <span class="ce-newsletter-subscription--form-error">
                      <ad-icon-warnings></ad-icon-warnings>
                      {{ errors.firstname }}
                    </span>
                  </div>
                  <div
                    class="ce-newsletter-subscription--form-field-container"
                    :data-show-error="!!errors.surname && surnameTouched"
                  >
                    <b-form-group
                      :label="settingsProperty.surnameLabel"
                      label-for="newsletter-subscription-form--surname"
                    >
                      <b-form-input
                        id="newsletter-subscription-form--surname"
                        name="surname"
                        v-model="surname"
                      ></b-form-input>
                    </b-form-group>
                    <span class="ce-newsletter-subscription--form-error">
                      <ad-icon-warnings></ad-icon-warnings>
                      {{ errors.surname }}
                    </span>
                  </div>
                </div>
                <div
                  class="ce-newsletter-subscription--form-field-container"
                  :data-show-error="!!errors.email && emailTouched"
                >
                  <b-form-group :label="settingsProperty.emailLabel" label-for="newsletter-subscription-form--email">
                    <b-form-input type="email" id="newsletter-subscription-form--email" name="email" v-model="email">
                    </b-form-input>
                  </b-form-group>
                  <span class="ce-newsletter-subscription--form-error">
                    <ad-icon-warnings></ad-icon-warnings>
                    {{ errors.email }}
                  </span>
                </div>
                <div
                  v-if="settingsProperty.showEmailConfirmationField"
                  class="ce-newsletter-subscription--form-field-container"
                  :data-show-error="!!errors.confirmemail && confirmemailTouched"
                >
                  <b-form-group
                    :label="settingsProperty.confirmEmailLabel"
                    label-for="newsletter-subscription-form--confirmemail"
                  >
                    <b-form-input
                      type="email"
                      id="newsletter-subscription-form--confirmemail"
                      name="confirmemail"
                      v-model="confirmemail"
                    >
                    </b-form-input>
                  </b-form-group>
                  <span class="ce-newsletter-subscription--form-error">
                    <ad-icon-warnings></ad-icon-warnings>
                    {{ errors.confirmemail }}
                  </span>
                </div>
              </div>

              <!-- Topics -->
              <div
                class="ce-newsletter-subscription--form-field-container mb-5"
                :data-show-error="!!errors.topics && topicsTouched"
              >
                <b-form-checkbox-group
                  id="newsletter-subscription-form--topic"
                  name="newsletter-topic"
                  v-model="topics"
                >
                  <p class="fs-16 m-0 mb-2"><slot name="newsletter-topic-label"></slot></p>
                  <div class="d-flex flex-column flex-md-row">
                    <b-form-checkbox
                      v-for="(description, i) in topicDescriptions"
                      v-bind:key="i"
                      :value="description"
                      :class="['mb-md-0', { 'mb-3': i !== topicDescriptions.length - 1 }]"
                    >
                      <span class="ce-newsletter-subscription--checkbox-and-radio-label fs-16">
                        {{ description.title }}

                        <button
                          v-b-modal="`newsletter-topic--${description.title}--${randomId}`"
                          type="button"
                          class="ce-newsletter-subscription--topic-info-icon"
                        >
                          <ad-icon-info></ad-icon-info><span class="sr-only">Info</span>
                        </button>
                        <b-modal
                          :id="`newsletter-topic--${description.title}--${randomId}`"
                          dialog-class="ce-newsletter-subscription--topic-modal"
                          hide-footer
                          centered
                        >
                          <template #modal-header></template>
                          <template #modal-header-close>
                            <ad-icon-close></ad-icon-close>
                          </template>
                          <h3>{{ description.title }}</h3>
                          <p>{{ description.description }}</p>
                        </b-modal>
                      </span>
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox-group>
                <span class="ce-newsletter-subscription--form-error">
                  <ad-icon-warnings></ad-icon-warnings>
                  {{ errors.topics }}
                </span>
              </div>

              <div
                class="ce-newsletter-subscription--form-field-container mb-5"
                :data-show-error="errors.termsAndConditionsAccepted && termsAndConiditionsTouched"
                v-if="settingsProperty.displayTermsAndConditions"
              >
                <b-form-checkbox
                  name="terms-and-conditions"
                  value="terms-and-conditions-accepted"
                  v-model="termsAndConditionsAccepted"
                >
                  <span class="ce-newsletter-subscription--checkbox-and-radio-label fs-16 w-100">
                    <slot name="terms-and-conditions-checkbox-text"></slot>
                  </span>
                </b-form-checkbox>
                <span class="ce-newsletter-subscription--form-error">
                  <ad-icon-warnings></ad-icon-warnings>
                  {{ errors.termsAndConditionsAccepted }}
                </span>
              </div>

              <button type="submit" class="btn btn-primary px-6 mb-6 mb-md-7 w-100 w-md-auto"
                ><slot name="sign-up-button-text"></slot
              ></button>
            </b-form>
          </div>

          <div
            class="content-container content-container-t container-fluid justify-content-center d-none d-lg-flex mb-6"
          >
            <div
              v-for="(description, i) in topicDescriptions"
              v-bind:key="i"
              :class="`ce-tile ce-tile__gradient-${topicColors[i]} no-hover p-3 p-lg-5`"
            >
              <div>
                <h3>{{ description.title }}</h3>
                <p>{{ description.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </b-collapse>

    <div class="text-center ce-newsletter-subscription--subscribe-button-container blog-container container-fluid">
      <button
        class="btn btn-secondary px-6 w-100 w-md-auto"
        @click="open = !open"
        v-if="!subscribed && !confirmOptInPanel"
      >
        <span v-if="open"><slot name="close-view-button-text"></slot></span>
        <span v-else><slot name="subscribe-button-text"></slot></span>
        <br />
        <span class="ce-newsletter-subscription--subscribe-button-chevron">
          <ad-icon-chevron v-if="open" direction="up"> </ad-icon-chevron>
          <ad-icon-chevron v-else direction="down"> </ad-icon-chevron>
        </span>
      </button>
    </div>
    <div class="text-center ce-newsletter-subscription--home-button-container blog-container container-fluid">
      <button class="btn btn-secondary px-6 w-100 w-md-auto" @click="redirectToHome()" v-if="confirmOptInPanel">
        <slot name="homepage-button-text"></slot>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { NewsletterSignupData, NewsletterTopicDescription } from '@/src/types/ce-component-types';
import { Controller, HttpStatusCodes } from '@/src/types/enumerations';
import { NewsletterSubscriptionSettings, SubscriptionRequest } from '@/src/types/episerver-api';
import { getEpiserverApiUrl } from '@/src/utils/url-helper';
import httpClient from '@/src/utils/http-service';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Inject } from 'vue-property-decorator';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';

@Component({})
export default class CeNewsletterSubscription extends Vue {
  @Inject() settingsProperty!: NewsletterSubscriptionSettings;

  get topicDescriptions() {
    return this.settingsProperty?.newsletterTopicDescriptions ?? [];
  }

  get homepageUrl() {
    return this.settingsProperty?.homepageUrl;
  }

  open = false;
  subscribed = false;
  subscribeSuccess = false;
  confirmOptInPanel = false;
  confirmOptInSuccess = false;

  salutation = '';
  firstname = '';
  firstnameTouched = false;
  surname = '';
  surnameTouched = false;
  email = '';
  emailTouched = false;
  confirmemail = '';
  confirmemailTouched = false;
  topics: NewsletterTopicDescription[] = [];
  topicsTouched = false;
  topicColors = ['mulberry', 'grape', 'peacock-blue'];
  termsAndConditionsAccepted = false;
  termsAndConiditionsTouched = false;

  /**
   * Needed so that multiple newsletter subscriptions do not get conflicting modals and collapses
   */
  randomId = Math.random().toString();

  mounted(): void {
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    let code = params.get('code');
    let guid = params.get('guid');
    if (code != null && guid != null) {
      // code and quid might include some special characters that might need to be encoded before being sent to server
      code = encodeURIComponent(code);
      guid = encodeURIComponent(guid);
      this.confirmOptIn(code, guid);
    }
  }

  get errors(): {
    firstname: null | string;
    surname: null | string;
    email: null | string;
    confirmemail: null | string;
    topics: null | string;
    termsAndConditionsAccepted: null | string;
  } {
    const errorByField = {
      firstname: null as null | string,
      surname: null as null | string,
      email: null as null | string,
      confirmemail: null as null | string,
      topics: null as null | string,
      termsAndConditionsAccepted: null as null | string,
    };

    if (this.settingsProperty.nameIsRequired) {
      if (this.firstname.trim().length === 0) {
        errorByField.firstname = this.settingsProperty.nameErrorMessage;
      }
    }
    if (this.settingsProperty.surnameIsRequired) {
      if (this.surname.trim().length === 0) {
        errorByField.surname = this.settingsProperty.surnameErrorMessage;
      }
    }
    if (this.email.trim().length === 0) {
      errorByField.email = this.settingsProperty.requiredEmailErrorMessage;
    } else if (!this.email.match(/[^@]+@[^.@]/)) {
      errorByField.email = this.settingsProperty.invalidEmailErrorMessage;
    }
    if (this.settingsProperty.showEmailConfirmationField) {
      if (this.email.trim().toLowerCase() !== this.confirmemail.trim().toLowerCase()) {
        errorByField.confirmemail = this.settingsProperty.emailMatchErrorMessage;
      }
    }
    if (this.topics.length === 0) {
      errorByField.topics = this.settingsProperty.newsletterTopicErrorMessage;
    }
    if (!this.termsAndConditionsAccepted && this.settingsProperty.displayTermsAndConditions) {
      errorByField.termsAndConditionsAccepted = this.settingsProperty.termsAndConditionsErrorMessage;
    }

    return errorByField;
  }

  get getSubscriptionCodes(): string {
    let subscriptionCodes = '';
    this.topics.map((topic) => {
      subscriptionCodes += `${topic.subscriptionCode};`;
    });
    return subscriptionCodes;
  }

  get getSubscriptionTitles(): string {
    return this.topics.map((topic) => topic.title).join(';');
  }

  handleSubmit(e: Event) {
    e.preventDefault();

    const hasErrors = Object.values(this.errors).some((e) => e !== null);
    if (hasErrors) {
      this.firstnameTouched = true;
      this.surnameTouched = true;
      this.emailTouched = true;
      this.confirmemailTouched = true;
      this.topicsTouched = true;
      this.termsAndConiditionsTouched = true;
      return;
    }

    this.trackSubscription();
    this.signup();
  }

  async subscribe(): Promise<void> {
    const subscriptionRequest: SubscriptionRequest = {
      salutation: this.salutation,
      firstName: this.firstname,
      lastName: this.surname,
      email: this.email,
      subscriptionCodes: this.getSubscriptionCodes,
    };

    try {
      const response = await httpClient.post(getEpiserverApiUrl(Controller.NewsletterSubscription, 'subscribe'), {
        subscription: subscriptionRequest,
      });

      this.open = true;
      this.subscribed = true;
      this.subscribeSuccess = response.status == HttpStatusCodes.Created;
    } catch (ex) {
      this.open = true;
      this.subscribed = true;
      this.subscribeSuccess = false;
    }
  }

  async confirmOptIn(code: string, guid: string): Promise<void> {
    try {
      const response = await httpClient.get<string>(
        getEpiserverApiUrl(Controller.NewsletterSubscription, 'confirmOptIn'),
        {
          params: { code: code, guid: guid },
        }
      );
      this.confirmOptInPanel = true;
      this.confirmOptInSuccess = response.status == HttpStatusCodes.NoContent;
    } catch (ex) {
      this.confirmOptInPanel = true;
      this.confirmOptInSuccess = false;
    }
  }
  redirectToHome(): void {
    if (this.settingsProperty.homepageUrl != null) {
      window.location.href = this.settingsProperty.homepageUrl;
    }
  }

  trackSubscription(): void {
    trackEvent(GaEvent.JoinGroup, {
      group_id: 'newsletter_' + this.getSubscriptionTitles,
    });
  }
  @Emit('signup')
  signup(): NewsletterSignupData {
    this.subscribe();
    return {
      salutation: this.salutation,
      firstname: this.firstname,
      surname: this.surname,
      email: this.email,
      topics: this.topics,
      termsAndConditionsAccepted: !!this.termsAndConditionsAccepted,
    };
  }
}
</script>

<style lang="scss">
.ce-newsletter-subscription {
  background: $footer-color;
  color: $white;

  .collapsing {
    transition: 0.5s ease-in-out height;
  }

  h2 {
    color: $white;
  }

  &--thank-you {
    color: #84bd00;
  }

  &--collapsed-subtext {
    transition: opacity 0.4s;
    opacity: 1;

    &.hidden {
      opacity: 0;
    }
  }

  &--collapsible-content {
    .custom-radio .custom-control-label {
      padding-top: 0.1rem;
    }

    .custom-checkbox .custom-control-label {
      padding-top: 0.1rem;
    }

    .ce-tile {
      flex: 0 1 425px;
    }

    .ce-tile + .ce-tile {
      margin-left: map-get($spacers, 3);
    }
  }

  & .custom-radio {
    width: calc(50% - 1rem);

    @include media-breakpoint-up('md') {
      width: 12rem;
    }
  }

  &--checkbox-and-radio-label {
    color: $white;
    font-weight: normal;

    @include media-breakpoint-up('md') {
      display: inline-block;
      min-width: 11rem;
    }
  }

  &--form-field-container {
    transition:
      padding 0.2s,
      background 0.2s,
      color 0.2s;
    margin-bottom: map-get($spacers, 3);
    border-radius: 0.4rem;
    padding: 0;

    .form-group {
      margin: 0;
    }

    &[data-show-error='true'] {
      border-radius: 0.4rem;
      background: #e7cedd;
      padding: map-get($spacers, 2);
      color: $mulberry;

      .form-group {
        border: 2px solid $mulberry;
      }

      label {
        color: $mulberry;
      }

      .custom-checkbox .custom-control-label::before {
        border: 2px solid $mulberry;
        background: $white;
      }

      .ce-newsletter-subscription--checkbox-and-radio-label {
        color: $mulberry;
      }

      .ce-newsletter-subscription--checkbox-and-radio-label a {
        text-decoration: underline;
        color: $mulberry;
      }

      .ce-newsletter-subscription--topic-info-icon {
        color: $mulberry;
      }
    }
  }

  &--form-error {
    display: none;
    padding-top: map-get($spacers, 1);
    font-size: 16px;
    font-weight: bold;

    [data-show-error='true'] & {
      display: inline-block;
    }
  }

  &--input-fields {
    .ce-newsletter-subscription--form-field-container {
      width: 100%;

      @include media-breakpoint-up('md') {
        width: calc(50% - #{map-get($spacers, 4) / 2});
      }
    }
  }

  &--topic-info-icon {
    display: inline-block;
    border: 0;
    background: transparent;
    color: $white;

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }

  &--topic-modal {
    .close {
      font-size: 1rem;
    }

    .modal-header {
      border: 0;
      padding: map-get($spacers, 3) map-get($spacers, 3) 0 0;
      font-size: 12px;
    }

    .modal-body {
      padding: 0 map-get($spacers, 4) 2rem;
    }
  }

  &--subscribe-button-container .btn {
    line-height: 1rem;
  }

  &--subscribe-button-chevron {
    line-height: 0.5rem;
    font-size: 0.5rem;
  }
}
</style>
