<template>
  <b-form v-if="productType == settingsProperty.productType" id="best-match-search-teaser" novalidate>
    <ad-best-match-search-filter-container
      v-model="filter"
      :best-match-metadata-result-model="bestMatchMetadataResultModelItem"
      @best-match-button-click="onBestMatchValueClick"
      teaser
      @product-type-changed="productFilterChanged"
    >
      <template #filter-product-type-dropdown-label-text>
        <slot name="filter-product-type-dropdown-label-text">Placeholder product type label</slot>
      </template>

      <template #filter-subtype-label-both>
        <slot name="filter-subtype-label-both"></slot>
      </template>

      <template #filter-underlying-dropdown-label-text>
        <slot name="filter-underlying-dropdown-label-text">Dropdown label</slot>
      </template>

      <template #filter-show-best-match-button-text>
        <slot name="filter-show-best-match-button-text">[filter show best match button text placeholder]</slot>
      </template>

      <template #maturity-date-filter="{ onFilterValueChanged, availableValues }">
        <slot
          name="maturity-date-filter"
          :availableValues="availableValues"
          :currentSearchFilter="filter"
          :onFilterValueChanged="onFilterValueChanged"
        ></slot>
      </template>

      <template
        #additional-numeric-range-filter-elements="{
          onFilterValueChanged,
          isNumericFilterVisible,
          availableValues,
          isLoading,
        }"
      >
        <slot
          name="additional-numeric-range-filter-elements"
          :availableValues="availableValues"
          :currentSearchFilter="filter"
          :onFilterValueChanged="onFilterValueChanged"
          :required="true"
          :isVisibleFunc="isNumericFilterVisible"
          :isLoading="isLoading"
        ></slot>
      </template>

      <template #go-to-full-seach-button-text>
        <slot name="go-to-full-seach-button-text">[go to full seach button text]</slot>
      </template>
    </ad-best-match-search-filter-container>
  </b-form>
</template>
<script lang="ts">
import { BestMatchSearchRequestModel, BestMatchMetadataResultModel } from '@/src/types/the-q-api';
import { BestMatchSearchBlockSetting, PageRoutes } from '@/src/types/episerver-api';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { BestMatchSearchRequestFilter } from '@/src/types/vue-api';
import { ProductTypeBo } from '@/src/types/enumerations';
import { Action, Getter } from 'vuex-class';
import { tryCreateBestMatchSearchLink } from '@/src/utils/url-helper';

@Component({})
export default class AdBestMatchSearchTeaser extends Vue {
  @Inject() settingsProperty!: BestMatchSearchBlockSetting;
  @Inject() pageRoutes!: PageRoutes;
  @Prop({ required: true }) pageLink!: string;
  @Prop({ required: true }) productType!: ProductTypeBo;

  @Getter('bestMatchMetadataResultModel', { namespace: 'bestMatch' })
  bestMatchMetadataResultModel!: (filter: BestMatchSearchRequestModel) => BestMatchMetadataResultModel | null;

  @Action('loadBestMatchMetadataResultModelAsync', { namespace: 'bestMatch' })
  loadBestMatchMetadataResultModelAsync!: (filter: BestMatchSearchRequestModel) => Promise<void>;

  private filter = new BestMatchSearchRequestFilter();

  get bestMatchMetadataResultModelItem() {
    return this.bestMatchMetadataResultModel(this.filter);
  }

  onBestMatchValueClick(): void {
    window.location.href = tryCreateBestMatchSearchLink(this.pageLink, this.filter);
  }

  async created(): Promise<void> {
    this.setFilterDefaults();
    await this.loadBestMatchMetadataResultModelAsync(this.filter);
  }

  productFilterChanged(value: ProductTypeBo): void {
    this.$emit('update-product-type', value);
  }

  private async setFilterDefaults(): Promise<void> {
    this.filter.productType = this.settingsProperty.productType;
    this.filter.filterProperty = this.settingsProperty.numericFilters[0].filterProperty;
    this.filter.numberOfItems = this.settingsProperty.loadMaxItems;

    //check if item is available and if not request had loaded all items,
    // so therefore reset default underlying, because it is not in the request
    if (this.settingsProperty.underlyingDefaultIsin) {
      this.filter.underlyingIsin = this.settingsProperty.underlyingDefaultIsin;
    }
  }
}
</script>
