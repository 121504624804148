<template>
  <b-container class="px-3 px-md-4 pb-5 cookie-consent">
    <b-row>
      <b-col>
        <h3 class="mb-2"><slot name="cookie-consent-header"></slot></h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col><slot name="cookie-consent"></slot></b-col>
    </b-row>
    <b-row class="d-lg-none d-md-block mt-4">
      <b-col>
        <b-button block variant="secondary" @click="acceptAll" :disabled="!isAcceptButtonsEnabled"
          ><slot name="cookie-consent-acceptall"></slot
        ></b-button>
      </b-col>
    </b-row>
    <b-row class="flex-sm-row bg-light my-5 mb-lg-3 mt-lg-0 mx-0 rounded">
      <b-col lg class="pb-lg-3 pt-3">
        <b-form-checkbox v-model="technical" switch size="lg" @change="onTechnicalChange">
          <slot name="cookie-technical"></slot>
        </b-form-checkbox>
      </b-col>
      <b-col lg class="pb-lg-3 pt-3">
        <b-form-checkbox v-model="marketing" name="cookie-marketing" switch size="lg">
          <slot name="cookie-marketing"></slot>
        </b-form-checkbox>
      </b-col>
      <b-col lg :class="['pb-lg-3', { 'py-3': canHideThirdPartyMedia, 'pt-3': !canHideThirdPartyMedia }]">
        <b-form-checkbox v-model="comfort" name="cookie-comfort" switch size="lg">
          <slot name="cookie-comfort"></slot>
        </b-form-checkbox>
      </b-col>
      <b-col lg class="py-3" v-if="!canHideThirdPartyMedia">
        <b-form-checkbox v-model="thirdPartyMedia" name="cookie-third" switch size="lg">
          <slot name="cookie-third"></slot>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mt-lg-5">
      <b-col lg="4" class="pb-3" v-if="canReject">
        <b-button block text-wrap variant="light" @click="reject">
          <slot name="cookie-consent-reject"></slot>
        </b-button>
      </b-col>
      <b-col lg="4" class="pb-3">
        <b-button block text-wrap variant="light" @click="accept" :disabled="!isAcceptButtonsEnabled">
          <slot name="cookie-consent-acceptselected"></slot>
        </b-button>
      </b-col>
      <b-col lg="4" class="d-none d-lg-block">
        <b-button block text-wrap variant="secondary" @click="acceptAll" :disabled="!isAcceptButtonsEnabled">
          <slot name="cookie-consent-acceptall"></slot>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-lg-3">
        <b-form-checkbox v-model="remember" :disabled="!comfort" size="lg">
          <slot name="cookie-remember-selection"></slot>
        </b-form-checkbox>
      </b-col>
    </b-row>
  </b-container>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import CookieManager, { CookieSettings } from '@/src/utils/cookie';

@Component
export default class AdCookieCConsent extends Vue {
  private cookieManager!: CookieManager;
  private technical = true;
  private marketing = false;
  private comfort = false;
  private thirdPartyMedia = false;
  private remember = false;

  @Prop()
  cookieconsentversion: string | undefined;

  @Prop({ type: Boolean, default: false })
  canReject;

  @Prop({ type: Boolean, default: false })
  canHideThirdPartyMedia;

  @Watch('comfort')
  onComfortChanged(): void {
    if (!this.comfort) this.remember = false;
  }

  @Prop({ type: Boolean, default: true })
  isAcceptButtonsEnabled;

  private async onTechnicalChange() {
    await this.$nextTick();
    this.technical = true;
  }

  private created(): void {
    this.cookieManager = new CookieManager(this.$cookies);
    this.marketing = this.cookieManager.marketing;
    this.comfort = this.cookieManager.comfort;
    this.thirdPartyMedia = this.cookieManager.thirdPartyMedia;
  }

  private mounted(): void {
    this.applyCanHideThirdPartyMedia();
  }
  private applyCanHideThirdPartyMedia() {
    this.thirdPartyMedia &&= !this.canHideThirdPartyMedia;
  }

  private acceptAll(): void {
    this.marketing = this.comfort = this.thirdPartyMedia = this.remember = true;
    this.applyCanHideThirdPartyMedia();
    this.accept();
  }

  private accept(): void {
    const cookieVersion = this.cookieconsentversion;
    this.cookieManager.saveSettings(
      new CookieSettings(cookieVersion || '', this.technical, this.marketing, this.comfort, this.thirdPartyMedia),
      this.remember
    );
    this.consent(true);
  }

  private reject(): void {
    this.consent(false);
  }

  @Emit()
  consent(state: boolean): boolean {
    return state;
  }
}
</script>
