<template>
  <div v-if="newTeaserType === newTeaserTypeEnum.ImageTop" class="d-flex flex-column col-12 col-md-6 mb-5 mb-md-0">
    <a :href="pageUrl" class="aspect-ratio-16-9 d-block">
      <picture>
        <source media="(min-width:650px)" :srcset="getImageUrl(631, 370)" />
        <source media="(min-width:465px)" :srcset="getImageUrl(316, 185)" />
        <img class="aspect-ratio-filler zoom-animated-image" :src="getImageUrl(500, 500)" />
      </picture>
    </a>
    <div class="ce-short-news-teaser__text mt-3 mt-md-5">
      <div class="ce-short-news-teaser__date fs-16"
        ><slot name="new-teaser-date">{{ settingsProperty.publishedDate | formatField(dateFormatKey) }}</slot></div
      >
      <div class="row">
        <div class="col-md-10">
          <h3>
            <a :href="pageUrl">
              <slot name="news-teaser-headline">[news-teaser-headline]</slot>
            </a>
          </h3>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center">
      <div class="ce-short-news-teaser__author fs-16"><slot name="news-teaser-author"></slot></div>
      <div class="ce-short-news-teaser__likes fs-14 ml-3 d-flex align-items-center">
        <ad-like-count :content-id="settingsProperty.contentId" />
      </div>
    </div>
  </div>
  <div
    v-else-if="newTeaserType === newTeaserTypeEnum.ImageBottom"
    class="d-flex flex-column-reverse flex-md-column col-12 col-md-5 mb-5 mb-md-0"
  >
    <div class="ce-short-news-teaser__container d-flex flex-column mb-md-5">
      <div class="ce-short-news-teaser__text fs-26 mt-3 mt-md-0">
        <div class="ce-short-news-teaser__date fs-16"
          ><slot name="new-teaser-date">{{ settingsProperty.publishedDate | formatField(dateFormatKey) }}</slot></div
        >
        <div>
          <h3
            ><a :href="pageUrl"><slot name="news-teaser-headline">[news-teaser-headline]</slot></a></h3
          >
        </div>
      </div>
      <div class="d-flex flex-row align-items-center">
        <div class="ce-short-news-teaser__author fs-16"><slot name="news-teaser-author"></slot></div>
        <div class="ce-short-news-teaser__likes fs-14 ml-3 d-flex align-items-center">
          <ad-like-count :content-id="settingsProperty.contentId" />
        </div>
      </div>
    </div>
    <a :href="pageUrl" class="aspect-ratio-16-9 d-block">
      <picture>
        <source media="(min-width:650px)" :srcset="getImageUrl(549, 306)" />
        <source media="(min-width:465px)" :srcset="getImageUrl(275, 153)" />
        <img class="aspect-ratio-filler zoom-animated-image" :src="getImageUrl(500, 500)" />
      </picture>
    </a>
  </div>
  <div v-else-if="newTeaserType === newTeaserTypeEnum.ImageRight" class="col-12">
    <div class="row">
      <div class="col-12 col-lg-7">
        <a :href="pageUrl" class="aspect-ratio-16-9 d-block">
          <picture>
            <source media="(min-width:650px)" :srcset="getImageUrl(444, 247)" />
            <source media="(min-width:465px)" :srcset="getImageUrl(444, 247)" />
            <img class="aspect-ratio-filler zoom-animated-image" :src="getImageUrl(500, 500)" />
          </picture>
        </a>
      </div>
      <div class="d-flex flex-column pl-lg-3 col-12 col-lg-5">
        <div class="ce-short-news-teaser__text mt-3 mt-lg-0">
          <div class="ce-short-news-teaser__date fs-16">{{
            settingsProperty.publishedDate | formatField(dateFormatKey)
          }}</div>
          <div>
            <h3>
              <a :href="pageUrl"><slot name="news-teaser-headline">[news-teaser-headline]</slot></a>
            </h3>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <div class="ce-short-news-teaser__author fs-16"><slot name="news-teaser-author"></slot></div>
          <div class="ce-short-news-teaser__likes fs-14 ml-3 d-flex align-items-center">
            <ad-like-count :content-id="settingsProperty.contentId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DefaultFormat, NewTeaserType } from '@/src/types/enumerations';
import { BlogPageSettings } from '@/src/types/episerver-api';
import { getImageResizerUrl } from '@/src/utils/url-helper';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';

@Component
export default class CeAdNewsTeaser extends Vue {
  @Prop({ required: true }) newTeaserType!: NewTeaserType;
  @Inject() settingsProperty!: BlogPageSettings;

  private newTeaserTypeEnum = NewTeaserType;

  get pageUrl() {
    return this.settingsProperty?.url;
  }

  private getImageUrl(width: number, height: number) {
    if (this.settingsProperty.imageUrlWithSizeParameter)
      return getImageResizerUrl(this.settingsProperty?.imageUrlWithSizeParameter, width, height);

    return;
  }

  get dateFormatKey(): string {
    return DefaultFormat.BlogDate;
  }
}
</script>

<style lang="scss">
.ce-short-news-teaser {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px dashed $peacock-blue;
    padding: map-get($spacers, 2);
    padding-left: 0;
    color: $marine-blue;
  }
  &__date {
    color: $peacock-blue;
  }
  &__icon {
    transform: rotate(270deg);
    color: $clarinet;
  }

  .btn {
    margin-top: map-get($spacers, 3);
  }

  &__text {
    color: $marine-blue;
  }

  &__author {
    color: $gunmetal;
  }

  &__container {
    @include media-breakpoint-up('md') {
      padding-left: map-get($spacers, 5);
    }
  }

  &__likes {
    color: $peacock-blue;

    svg {
      color: $clarinet;
    }
  }
}
</style>
