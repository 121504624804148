import httpClient from '@/src/utils/http-service';
import {
  BestMatchMetadataResultModel,
  BestMatchSearchRequestModel,
  BestMatchSearchResultModel,
  ProductModel,
  ProductPricePush,
} from '@src/types/the-q-api';
import { getTheQApiUrl } from '@/src/utils/url-helper';
import { Controller } from '@/src/types/enumerations';
import { Commit } from 'vuex';
import { MutationPushPayloadHolder } from '@/src/types/vue-api';
import { isMutationPushPayloadHolder } from '@/src/types/type-guards';
import {
  createPushPayload,
  LightStreamerProductSubscriptionMutationTypes,
  PUSH_UPDATE_PRODUCT,
  tryUpdateProductValue,
} from '@/src/state/plugins/light-streamer/pushing-helper';

export const state = {
  bestMatchSearchResultModel: null as MutationPushPayloadHolder<BestMatchSearchResultModel, ProductModel> | null,
  bestMatchMetadataResultModel: {} as { [key: string]: BestMatchMetadataResultModel },
};

export enum MutationTypes {
  BEST_MATCH_META_DATA_VALUE = 'BEST_MATCH_META_DATA_VALUE',
}

type State = typeof state;

function getBestMatchMetaDataKey(filter: BestMatchSearchRequestModel) {
  return filter.productType + filter.subType + filter.underlyingIsin;
}

export const getters = {
  bestMatchSearchResultModel: (state: State) => {
    return state.bestMatchSearchResultModel?.holder;
  },
  bestMatchMetadataResultModel: (state: State) => {
    return (filter: BestMatchSearchRequestModel) => state.bestMatchMetadataResultModel[getBestMatchMetaDataKey(filter)];
  },
};

export const mutations = {
  [LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCTS_BEST_MATCHES](
    state: State,
    bestMatchSearchResultModel: MutationPushPayloadHolder<BestMatchSearchResultModel, ProductModel>
  ) {
    state.bestMatchSearchResultModel = bestMatchSearchResultModel;
  },
  [MutationTypes.BEST_MATCH_META_DATA_VALUE](
    state: State,
    bestMatchSearchResultModel: { [key: string]: BestMatchMetadataResultModel }
  ) {
    state.bestMatchMetadataResultModel = bestMatchSearchResultModel;
  },
  [PUSH_UPDATE_PRODUCT](
    state: State,
    pushItem: {
      productPricePush: ProductPricePush;
      pushSubscriptions: { [key: string]: boolean };
    }
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allProductsInState = Object.values(state).reduce((prevVal: Array<ProductModel>, statePropVal: any) => {
      if (isMutationPushPayloadHolder(statePropVal)) {
        return prevVal.concat(statePropVal.getSubscriptionPushItems(statePropVal.holder));
      } else {
        return prevVal;
      }
    }, new Array<ProductModel>());

    allProductsInState.forEach((p) => tryUpdateProductValue(p, pushItem.productPricePush, pushItem.pushSubscriptions));
  },
};

const cache = {
  metaKeys: {} as { [key: string]: boolean },
};

export const actions = {
  async loadBestMatchSearchResultModelAsync(
    { commit }: { commit: Commit },
    filter: BestMatchSearchRequestModel
  ): Promise<void> {
    const response = await httpClient.get<BestMatchSearchResultModel>(getTheQApiUrl(Controller.BestMatch, 'Search'), {
      params: filter,
    });
    const bestMatchSearchPushPayload = createPushPayload<BestMatchSearchResultModel, ProductModel>(
      response.data,
      (holder: BestMatchSearchResultModel): Array<ProductModel> => [
        ...holder.aboveMatches.map((bm) => bm.product),
        ...holder.belowMatches.map((bm) => bm.product),
        ...holder.bestMatches.map((bm) => bm.product),
      ]
    );

    commit(LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCTS_BEST_MATCHES, bestMatchSearchPushPayload);
  },

  async loadBestMatchMetadataResultModelAsync(
    { commit, state }: { commit: Commit; state: State },
    filter: BestMatchSearchRequestModel
  ): Promise<void> {
    const key = getBestMatchMetaDataKey(filter);
    if (
      cache.metaKeys[key] === undefined &&
      (state.bestMatchMetadataResultModel === null || state.bestMatchMetadataResultModel[key] === undefined)
    ) {
      cache.metaKeys[key] = true;
      const response = await httpClient.get<BestMatchMetadataResultModel>(
        getTheQApiUrl(Controller.BestMatch, 'Metadata'),
        {
          params: filter,
        }
      );

      commit(
        MutationTypes.BEST_MATCH_META_DATA_VALUE,
        Object.assign({}, state.bestMatchMetadataResultModel, { [getBestMatchMetaDataKey(filter)]: response.data })
      );
    }
  },

  async bestMatchTableClear({ commit }: { commit: Commit }): Promise<void> {
    commit(LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCTS_BEST_MATCHES, null);
  },
};
