import { getCurrentWebsiteKey } from '@/src/utils/culture-helper';
import VuexPersistence from 'vuex-persist';
import {
  State as RealtimeState,
  isPersistMutation as isPersistRealtimeMutation,
  reducePersistState as reduceRealtimeState,
} from '../../modules/realtime';
import {
  State as WatchlistState,
  isPersistMutation as isPersistWatchlistMutation,
  reducePersistState as reduceWatchlistState,
} from '../../modules/watchlist';

const vuexLocalStorage = new VuexPersistence<{ realtime: RealtimeState; watchlist: WatchlistState }>({
  storage: window.localStorage,
  key: 'citi_state_' + getCurrentWebsiteKey(),
  reducer: (store) => ({
    realtime: reduceRealtimeState(store.realtime),
    watchlist: reduceWatchlistState(store.watchlist),
  }),
  filter: (mutation) => {
    return isPersistRealtimeMutation(mutation.type) || isPersistWatchlistMutation(mutation.type);
  },
});

export default vuexLocalStorage.plugin;
