import httpClient from './http-service';
import qs from 'qs';
import { GroupedDownloadGroup } from '@src/types/ce-component-types';
import { DownloadDocumentType, Download, DownloadLink } from '@src/types/episerver-api';
import {
  DocumentServiceConfiguration,
  DownloadGroupItem,
  GroupedDownloadGroupItem,
  LayeredDownloadItem,
} from '../types/vue-api';
import { Controller, DefaultFormat, DocumentType } from '@src/types/enumerations';
import {
  CustomOrdinalFormatting,
  CulturesWithCustomOrdinal,
} from '@src/utils/value-formatter/ordinal-custom-formatter';
import { DocumentModel, DocumentFilterData, DigitalAssetDocument } from '@src/types/the-q-api';
import { getTheQApiUrl } from '@src/utils/url-helper';
import { formatField } from '@src/utils/value-formatter/formatting-service';
import { getCurrentCulture } from '@src/utils/culture-helper';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';

export async function getAllDocumentsForProduct(productIsin: string): Promise<DigitalAssetDocument[]> {
  const response = await httpClient.get<DigitalAssetDocument[]>(
    getTheQApiUrl(Controller.Document, 'GetAllDocumentsForProduct'),
    {
      params: {
        isin: productIsin,
      },
    }
  );
  return response.data;
}

async function requestProductDocuments(productIsin: string, types: DocumentType[]): Promise<DocumentModel[]> {
  const response = await httpClient.get<DocumentModel[]>(getTheQApiUrl(Controller.Document, 'GetProductDocuments'), {
    params: {
      isin: productIsin,
      docs: types,
    },
  });
  return response.data;
}

async function requestFolderDocuments(parentFolderIds: string[], relatedFolderIds: string[]): Promise<DocumentModel[]> {
  const response = await httpClient.get<DocumentModel[]>(getTheQApiUrl(Controller.Document, 'GetFolderDocuments'), {
    params: {
      parentFolderIds: parentFolderIds,
      relatedFolderIds: relatedFolderIds,
    },
  });
  return response.data;
}

function formatDownloadName(
  document: DocumentModel,
  types: { [type: string]: DownloadDocumentType },
  index: number,
  totalCount: number,
  config: DocumentServiceConfiguration
): string {
  const type = types[document.documentType];
  let name = type?.name || document.name;

  const date = formatField(document.asOfDate, config.dateFormat, null);
  name = name.replace(config.datePlaceholder, date);

  if (type?.numberItems && totalCount > 1) {
    const culture = getCurrentCulture();
    const formatting = Object.values(CulturesWithCustomOrdinal).includes(culture)
      ? CustomOrdinalFormatting
      : DefaultFormat.Ordinal;

    name = `${formatField(index, formatting, null)} ${name}`;
  }

  if (document.namePattern) {
    name = document.namePattern.replace(config.namePlaceholder, name);
  }

  return name;
}

function mapDownload(
  document: DocumentModel,
  children: GroupedDownloadGroupItem[] | undefined,
  types: { [type: string]: DownloadDocumentType },
  listIndex: number,
  listCount: number,
  config: DocumentServiceConfiguration
): GroupedDownloadGroupItem {
  return {
    title: formatDownloadName(document, types, listIndex, listCount, config),
    date: document.asOfDate ? Date.parse(document.asOfDate) : 0,
    year: document.filterData?.year ?? null,
    link: document.downloadLink,
    format: document.fileExtension,
    rank: document.rank,
    childDownloads: children,
  };
}

function mapDownloads(
  typeDocuments: DocumentModel[],
  allTypes: { [type: string]: DownloadDocumentType },
  config: DocumentServiceConfiguration
): GroupedDownloadGroup[] {
  const files = typeDocuments
    .map((document, index, array) => {
      const children =
        document.childDocuments?.length != 0
          ? document.childDocuments.map((d, i) =>
              mapDownload(d, undefined, allTypes, i, document.childDocuments.length, config)
            )
          : undefined;
      return mapDownload(document, children, allTypes, index + 1, array.length, config) as GroupedDownloadGroup;
    })
    .sort((a, b) => (a.date < b.date ? 1 : -1));

  return files;
}

function groupDocuments(
  documents: DocumentModel[],
  types: DownloadDocumentType[],
  config: DocumentServiceConfiguration
): DownloadGroupItem[] {
  const typeInfos = types.reduce((dict, type) => ({ ...dict, [type.type]: type }), {});

  const result: DownloadGroupItem[] = [];
  for (const type of types.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))) {
    const typeDocuments = documents.filter((g) => g.documentType == type.type);
    if (typeDocuments.length == 0) continue;
    if (type.groupItems) {
      result.push({
        type: type.type,
        childListName: type.childListName,
        files: mapDownloads(typeDocuments, typeInfos, config),
      });
    } else {
      result.push(
        ...typeDocuments.map((document, index) => {
          return {
            type: type.type,
            childListName: null,
            files: [mapDownload(document, undefined, typeInfos, index + 1, typeDocuments.length, config)],
          } as DownloadGroupItem;
        })
      );
    }
  }
  return result;
}

function setGroupFilterData(documents: DocumentModel[], documentGroup: string): void {
  documents.forEach((_) => {
    if (_.filterData) {
      _.filterData.group = documentGroup;
      return;
    }
    _.filterData = { group: documentGroup } as DocumentFilterData;
  });
}

function setYearFilterData(documents: DocumentModel[]): void {
  documents.forEach((_) => {
    if (!_.asOfDate) {
      return;
    }

    const date = new Date(_.asOfDate);
    const year = date.getFullYear();
    if (_.filterData) {
      _.filterData.year = year;
      return;
    }
    _.filterData = { year: year } as DocumentFilterData;
  });
}

export async function getProductDocuments(
  productIsin: string,
  types: DownloadDocumentType[],
  config: DocumentServiceConfiguration
): Promise<DownloadGroupItem[]> {
  if (productIsin == null) return [];

  const requestTypes = types.map((t) => t.type);
  const documents: DocumentModel[] = await requestProductDocuments(productIsin, requestTypes);

  if (documents.length === 0) return [];

  const groupedItems = groupDocuments(documents, types, config);

  return groupedItems;
}

export async function getFolderDocuments(
  parentFolderIds: string[],
  types: DownloadDocumentType[],
  relatedFolderIds: string[],
  config: DocumentServiceConfiguration
): Promise<DownloadGroupItem[]> {
  const documents: DocumentModel[] = await requestFolderDocuments(parentFolderIds, relatedFolderIds);
  if (documents.length === 0) return [];
  setGroupFilterData(documents, parentFolderIds[0]);
  setYearFilterData(documents);
  const groupedItems = groupDocuments(documents, types, config);
  return groupedItems;
}

function collectLayeredDownloadItemRecursive(
  depth: number,
  download: DownloadLink | Download | DownloadGroupItem | GroupedDownloadGroupItem,
  accumulator: LayeredDownloadItem[]
) {
  const downloadGroup = download as unknown as DownloadGroupItem;
  if (isDownloadGroup(downloadGroup)) {
    //download group is not a download
    // depth unchanged
    if (downloadGroup.files) {
      downloadGroup.files.forEach((f) => {
        collectLayeredDownloadItemRecursive(depth, f, accumulator);
      });
    }
    return;
  }

  const groupedDownload = download as unknown as GroupedDownloadGroupItem;
  if (isGroupedDownloadGroup(groupedDownload)) {
    const groupItem = { file: groupedDownload, depth: depth } as LayeredDownloadItem;
    accumulator.push(groupItem);

    if (groupedDownload.childDownloads) {
      groupedDownload.childDownloads?.forEach((f) => {
        collectLayeredDownloadItemRecursive(depth + 1, f, accumulator);
      });
    }
    return;
  }
  const fileDownload = download as Download;
  if (isDownload(fileDownload)) {
    const linkItem = { file: fileDownload, depth: depth } as LayeredDownloadItem;
    accumulator.push(linkItem);
    return;
  }
  const downloadLink = download as unknown as DownloadLink;
  if (isDownloadLink(downloadLink)) {
    const linkItem = { file: downloadLink, depth: depth } as LayeredDownloadItem;
    accumulator.push(linkItem);
    return;
  }
}

/**
 * Flatten linked documents to array of documents with depth
 * @param downloads
 * @returns
 */
export function flattenToLayeredDownloadItem(
  downloads: (DownloadLink | Download | DownloadGroupItem)[]
): LayeredDownloadItem[] {
  const depth = 1;
  const items = [];
  downloads.forEach((d) => {
    collectLayeredDownloadItemRecursive(depth, d, items);
  });
  return items;
}

export function isDownload(document: DownloadLink | Download | DownloadGroupItem): document is Download {
  const download = document as Download;
  return download && !!download.title && !!download.link && !!download.fileFormat;
}

export function isDownloadLink(document: DownloadLink | Download | DownloadGroupItem): document is DownloadLink {
  const link = document as DownloadLink;
  return link !== null && !!link.title && !!link.link;
}

export function isDownloadGroup(document: DownloadLink | Download | DownloadGroupItem): document is DownloadGroupItem {
  const group = document as DownloadGroupItem;
  return group && group.childListName?.length != 0 && Array.isArray(group.files) && group.files.length >= 1;
}

export function isGroupedDownloadGroup(
  document: DownloadGroupItem | GroupedDownloadGroupItem
): document is GroupedDownloadGroupItem {
  const group = document as GroupedDownloadGroupItem;
  return group && group.title.length != 0 && group.link.length != 0 && !!group.format;
}

export function isSingleDownload(document: DownloadLink | Download | DownloadGroupItem): document is DownloadGroupItem {
  const group = document as DownloadGroupItem;
  return group && Array.isArray(group.files) && group.files.length == 1;
}

export function downloadClick(url: string, title: string, format?: string | null | undefined): void {
  trackEvent(GaEvent.ClickDownload, {
    displayName: title,
    extension: format,
    url: url,
  });
}

export function getDownloadFileFormat(file: Download): string | undefined {
  return file.fileFormat?.toUpperCase();
}
