import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options';

/**
 * Use this directive to mark elements that should fade in when they are scrolled into viewport.
 * Use a delay number to stagger appearance of elements.
 * Use this directive only on or below elements with the v-appear-trigger directive.
 *
 * Example:
 *
 * <div v-appear-trigger v-appear-delay="1">
 *   I appear when scrolled into view.
 * </div>
 *
 * or:
 *
 * <ul v-appear-trigger>
 *   <li v-appear-delay="0">First</li>
 *   <li v-appear-delay="1">Second</li>
 *   <li v-appear-delay="2">Third</li>
 * </ul>
 */
Vue.directive('appear-delay', {
  inserted: (el: HTMLElement, binding: DirectiveBinding) => {
    el.classList.add(`show-animation-delay-${binding.value}`);
  },
});
