import { isNumber, isPriceModel } from '@/src/types/type-guards';
import { ApiModel, ApiTypeValue, FormatDataField } from '@/src/types/vue-api';

import {
  InstrumentFormat,
  PushFormat,
  SmallValueFormat,
  UnderlyingCategoryFormat,
} from '@/src/utils/value-formatter/formats-selectors';
import evaluateExpressionByKey from './format-helper';

function createFormatterService(...formats: InstrumentFormat[]) {
  return {
    selectFormat(value: ApiTypeValue, field: FormatDataField, model: ApiModel | null, isPush = false): string {
      let result: string | boolean = false;
      for (let i = 0; i < formats.length; i++) {
        result = formats[i].trySelect(field, value, model, isPush);
        if (result !== false) return result as string;
      }
      return field?.format || '';
    },
  };
}

function formatCurrency(format: string, value: ApiTypeValue, model: ApiModel | null): string {
  if (format.includes('c:')) {
    if (isNumber(value) || isPriceModel(value)) {
      const formatArr = format.split(':');
      const currency = evaluateExpressionByKey(formatArr[1], model);
      return formatArr[0] + currency;
    }
  } else if (format.includes('c')) {
    if (isPriceModel(value)) {
      return format.replace('c', `c${value.currencyCode}`);
    }
  }

  return format;
}

const service = createFormatterService(new UnderlyingCategoryFormat(), new SmallValueFormat(), new PushFormat());

export default function selectFormat(
  value: ApiTypeValue,
  field: FormatDataField,
  model: ApiModel | null,
  isPush = false
): string {
  return formatCurrency(service.selectFormat(value, field, model, isPush), value, model);
}
