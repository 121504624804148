<template>
  <div class="ce-sub-nav-item-info">
    <h2 class="ce-sub-nav-item-info__headline m-0 mb-1 fs-16">{{ headline }}</h2>
    <p class="m-0">
      <slot></slot>
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CeSubNavItemInfo extends Vue {
  @Prop() private headline!: string;
}
</script>

<style lang="scss">
$ce-sub-nav-item-spacer-x: $grid-gutter-width;
$ce-sub-nav-item-spacer-y: 20px;

/** @define ce-sub-nav-item-info */
.ce-sub-nav-item-info {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 0;
  box-shadow: $header-sub-menu-box-shadow;
  background-color: $light-grey;
  padding: $ce-sub-nav-item-spacer-y $ce-sub-nav-item-spacer-x;
  width: 410px;
  min-height: 100%;
  font-size: $h6-font-size;

  &::before {
    position: absolute;
    top: 0;
    left: -$ce-sub-nav-item-spacer-x;
    background-color: $white;
    width: $ce-sub-nav-item-spacer-x;
    height: 100%;
    content: '';
  }

  &__headline {
    color: $header-highlight-color;
    font-size: $h6-font-size;
    font-weight: $font-weight-bold;
  }
}
</style>
