import { HttpStatusCodes, TextAlign } from '../types/enumerations';

export function mapTextAlign(textAlign: TextAlign): string {
  switch (textAlign) {
    case TextAlign.Left:
      return 'text-left';
    case TextAlign.Right:
      return 'text-right';
    case TextAlign.Center:
      return 'text-center';
    default:
      return '';
  }
}

export function mapErrorCodes(code: number): HttpStatusCodes {
  switch (code) {
    case 200:
      return HttpStatusCodes.OK;
    case 201:
      return HttpStatusCodes.Created;
    case 204:
      return HttpStatusCodes.NoContent;
    case 400:
      return HttpStatusCodes.BadRequest;
    case 404:
      return HttpStatusCodes.NotFound;
    case 500:
      return HttpStatusCodes.InternalServerError;
    default:
      return HttpStatusCodes.UnknownError;
  }
}
