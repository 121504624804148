<template>
  <div class="ad-quick-search">
    <b-row no-gutters>
      <b-col class="px-0">
        <b-form-group :id="'input-group-1' + _uid" :label-for="'input-1' + _uid" class="focus-white">
          <template #label><slot name="underlying-list-placeholder"></slot></template>
          <ad-underlying-list
            :input-id="'input-1' + _uid"
            :form-element-state-id="'input-group-1' + _uid"
            v-model="selectedUnderlying"
            :group-items="true"
            @input="onUnderlyingChanged"
          >
          </ad-underlying-list>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col class="pl-2 fs-16 mb-2">
        <slot name="product-types-header"></slot>
      </b-col>
    </b-row>
    <b-row no-gutters class="flex-nowrap">
      <b-col class="pl-0 pr-2">
        <ad-product-type-button
          v-for="type in productTypesCol(false)"
          :key="type.id"
          @click="doSearch(type)"
          :type="type"
          :disabled="!isTypeAvailable(type)"
        />
      </b-col>
      <b-col class="pl-0 pr-0">
        <ad-product-type-button
          v-for="type in productTypesCol(true)"
          :key="type.id"
          @click="doSearch(type)"
          :type="type"
          :disabled="!isTypeAvailable(type)"
        />
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col class="pt-3 px-0">
        <a @click="doSearch()" href="#" class="fs-12"
          ><slot name="full-search-text"></slot>
          <ad-icon-chevron size="xxs" direction="right" aria-hidden="true"></ad-icon-chevron>
        </a>
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { Prop, Component, Watch, Inject } from 'vue-property-decorator';
import {
  PageRoutes,
  ProductType,
  ProductUnderlyingFilter,
  ProductTypeFilterModel,
  ProductTypeModel,
  BlockSetting,
} from '@/src/types/episerver-api';
import { Action, Getter } from 'vuex-class';
import { redirectToSearchProducts } from '@/src/utils/url-helper';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';

@Component
export default class AdQuickSearch extends Vue {
  private selectedUnderlying: string | null = null;
  private filter = {
    underlyingIsin: '',
    types: [],
  } as ProductUnderlyingFilter;

  @Prop()
  initialUnderlying: string | undefined;

  @Inject()
  settingsProperty: BlockSetting | undefined;

  @Inject() pageRoutes!: PageRoutes;

  @Getter('productUnderlyingTypes', { namespace: 'product' })
  productUnderlyingTypes!: (filter: ProductUnderlyingFilter) => ProductType[];

  @Action('getUnderlyingTypesAsync', { namespace: 'product' })
  getUnderlyingTypesAsync!: (filter: ProductUnderlyingFilter) => Promise<void>;

  get isSearchPageToOpenInParent(): boolean {
    return this.settingsProperty!.isEmbeddedInExternPage;
  }

  private get availableTypes() {
    return this.productUnderlyingTypes(this.filter);
  }

  private isTypeAvailable(typ: ProductType): boolean {
    const isAvailable =
      !!this.availableTypes &&
      this.availableTypes.some((at) => at.typeCode === typ.typeCode && at.subTypeCode === typ.subTypeCode);
    return isAvailable;
  }

  private get displayedTypes(): ProductTypeFilterModel[] | undefined {
    return this.settingsProperty?.productTypes?.map(
      (t) =>
        ({
          typeCode: t.typeCode,
          subTypeCode: t.subTypeCode,
        }) as ProductTypeFilterModel
    );
  }

  @Watch('availableTypes', { immediate: true, deep: true })
  private productTypesCol(odd: boolean): ProductTypeModel[] {
    const types = [] as ProductTypeModel[];
    if (!!this.settingsProperty?.productTypes && this.settingsProperty?.productTypes.length !== 0) {
      for (let i = odd ? 1 : 0; i < this.settingsProperty?.productTypes.length; i += 2) {
        const typ = this.settingsProperty?.productTypes[i];
        types.push(typ);
      }
    }
    return types;
  }

  @Watch('initialUnderlying', { immediate: true })
  private selectUnderlying() {
    if (this.initialUnderlying) {
      this.selectedUnderlying = this.initialUnderlying;
    } else {
      this.selectedUnderlying = this.settingsProperty?.defaultUnderlyingIsin || null;
    }
    this.updateProductTypesForUnderlying();
  }

  private async onUnderlyingChanged() {
    this.updateProductTypesForUnderlying();
  }

  public doSearch(type: ProductTypeModel | undefined): void {
    if (this.selectedUnderlying) {
      this.trackQuickSearch(type);
      redirectToSearchProducts(
        this.selectedUnderlying,
        this.pageRoutes,
        this.isSearchPageToOpenInParent,
        type?.typeCode,
        type?.subTypeCode
      );
    }
  }

  private async updateProductTypesForUnderlying(): Promise<void> {
    if (!this.selectedUnderlying || !this.displayedTypes || this.displayedTypes.length === 0) {
      return;
    }
    try {
      this.filter = {
        underlyingIsin: this.selectedUnderlying,
        types: this.displayedTypes,
      } as ProductUnderlyingFilter;

      await this.getUnderlyingTypesAsync(this.filter);
    } catch (ex) {
      this.$log.error('Loading Error', ex);
    }
  }

  private trackQuickSearch(type: ProductTypeModel | undefined): void {
    if (!type) {
      return;
    }
    trackEvent(GaEvent.SelectItem, {
      item_list_id: 'quick_search',
      items: [{ item_id: type?.typeCode + '_' + type?.subTypeCode }],
    });
  }
}
</script>
