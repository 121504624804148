<template>
  <div v-if="!!article">
    <div class="row mb-4">
      <div class="col-12 mb-3 mb-md-5">
        <a :href="article.link" class="aspect-ratio-silver w-100 d-block">
          <picture>
            <source
              v-for="(source, i) in article.image.sources"
              v-bind:key="i"
              :media="source.media"
              :srcset="source.srcset"
            />
            <img class="aspect-ratio-filler zoom-animated-image" :src="article.image.src" :alt="article.image.alt" />
          </picture>
        </a>
      </div>
      <div class="col-12 col-md-8">
        <div class="w-100">
          <h2>
            <a :href="article.link"><ad-template-renderer :text="article.title"></ad-template-renderer></a>
          </h2>
        </div>
        <div class="w-100 fs-22 mb-3 ce-blog-article-overview__text">
          {{ article.teaser }}
        </div>
        <div class="ce-blog-article-overview__author fs-16">
          {{ article.author }}
          <span class="ce-blog-article-overview__like fs-14 ml-3">
            <ad-like-count :content-id="article.likeId" :show-count-on-left="true"></ad-like-count>
          </span>
        </div>
      </div>
    </div>
    <div class="row mb-6 mb-md-8">
      <div class="col-12 d-flex justify-content-center justify-content-md-start">
        <a :href="article.link" class="btn btn-secondary w-100 w-md-auto"><slot name="read-more">Read More</slot></a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { BlogArticleOverviewBlogArticle } from '@/src/types/ce-component-types';

@Component
export default class CeBlogArticleOverviewHighlightArticle extends Vue {
  @Prop({ required: true }) article!: BlogArticleOverviewBlogArticle;

  @Emit('likeArticle')
  likeArticle(article: BlogArticleOverviewBlogArticle) {
    return article;
  }
}
</script>
