<template>
  <div>
    <b-container fluid class="content-container mb-7 mb-md-8 mt-6 mt-md-7 mt-xl-8 px-md-4">
      <b-row>
        <b-col cols="2" class="hide-empty" v-if="showNavigation">
          <slot name="layout-navigation"></slot>
        </b-col>
        <b-col :cols="showNavigation ? '10' : '12'">
          <slot name="layout-content"></slot>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
/**
 * Selects correct containers for a content area and a sidebar, to properly display the ad-anchor-navigation.
 */
export default class AdAnchorLayout extends Vue {
  @Prop({ required: true, type: Boolean })
  showNavigation!: boolean;
}
</script>
