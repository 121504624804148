<template>
  <ce-glossary-content :entries="entries" />
</template>
<script lang="ts">
import { GlossaryEntry } from '@/src/types/ce-component-types';
import { GlossaryBlockSettings } from '@/src/types/episerver-api';
import { Vue, Component, Inject } from 'vue-property-decorator';

@Component
export default class AdGlossaryContent extends Vue {
  @Inject() settingsProperty!: GlossaryBlockSettings | undefined;

  get entries(): GlossaryEntry[] {
    return this.settingsProperty?.entries ? this.settingsProperty?.entries : [];
  }
}
</script>
