/// <reference types="@types/youtube" />;

import { getYoutubeIFrameAPIUrl } from '@/src/utils/url-helper';

let loadingPromise: Promise<typeof YT> | null = null;

/**
 * Use this function to trigger the async loading of the youtube iFrame API.
 *
 * Always access the YT namespace through this function so that we can guarantee that it is
 * only loaded when truly needed.
 */
export async function loadYoutubeAPI() {
  if (loadingPromise) return loadingPromise;

  const scriptTag = document.createElement('script');

  scriptTag.src = getYoutubeIFrameAPIUrl();
  document.body.appendChild(scriptTag);

  loadingPromise = new Promise<typeof YT>((resolve) => {
    // eslint-disable-next-line
    (window as any).onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
      resolve(YT);
    };
  });

  return loadingPromise;
}
