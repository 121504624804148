<template>
  <div>
    <!-- this part is moved to CeAdShortNewsContainer -->
    <h1>Short News</h1>
    <div class="row">
      <div class="col-12 col-md-8 pb-4">
        <div class="px-md-0 py-4 ce-blog-short-news__news-tile">
          <div class="ce-blog-short-news__date fs-18"> 09 Dez 2019 </div>
          <h3><a href="#">Handelszeiten am Montag den 20. Januar (Martin Luther King Tag)</a></h3>
          <div class="ce-blog-short-news__text">
            <ul>
              <li>Gold und Silber: 19 Uhr Handelsende.</li>
              <li>Brent: Normaler Handel.</li>
              <li>US Aktien: Kleinere Volumen und angepasste Spreads. </li>
              <li>US Indizes und Nikkei 225: Kleinere Volumen ab 19 Uhr.</li>
            </ul>
          </div>
          <div class="ce-blog-short-news__likes d-flex align-items-center pt-2">
            <button class="ce-author-like__button text-nowrap px-0">
              <ad-icon-like height="1.71em"></ad-icon-like>
            </button>
            <span class="font-weight-bold fs-14 pl-1">12</span>
          </div>
        </div>
        <div class="px-md-0 py-4 ce-blog-short-news__news-tile">
          <div class="ce-blog-short-news__date fs-18"> 09 Dez 2019 </div>
          <h3><a href="#">Maschinenbau: Viel Schatten, wenig Licht</a></h3>
          <div class="ce-blog-short-news__text">
            Mit einem Jahresumsatz von rund 233 Milliarden Euro, mehr als 6.500 Unternehmen und 1,35 Millionen
            Beschäftigten gilt der Maschinen- und Anlagebau als Rückgrat der deutschen Industrie.
          </div>
          <div class="ce-blog-short-news__likes d-flex align-items-center pt-2">
            <button class="ce-author-like__button text-nowrap px-0">
              <ad-icon-like height="1.71em"></ad-icon-like>
            </button>
            <span class="font-weight-bold fs-14 pl-1">12</span>
          </div>
        </div>
        <div class="px-md-0 py-4 ce-blog-short-news__news-tile">
          <div class="ce-blog-short-news__date fs-18"> 06 Dez 2019 </div>
          <h3><a href="#">Gold - Edelmetall bereit für den nächsten Sprung? Startet Gold wieder durch?</a></h3>
          <div class="ce-blog-short-news__text">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,
            ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
          </div>
          <div class="ce-blog-short-news__likes d-flex align-items-center pt-2">
            <button class="ce-author-like__button text-nowrap px-0">
              <ad-icon-like height="1.71em"></ad-icon-like>
            </button>
            <span class="font-weight-bold fs-14 pl-1">12</span>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-start">
        <button class="btn btn-secondary w-100 w-md-auto">Show more news</button>
      </div>
    </div>
    <!-- till here CeAdShortNewsContainer -->
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CeBlogShortNews extends Vue {}
</script>

<style lang="scss">
.ce-blog-short-news {
  &__news-tile {
    border-top: 1px dashed $peacock-blue;
  }

  &__date {
    color: $peacock-blue;
  }

  &__header {
    color: $marine-blue;
  }

  &__text {
    color: $gunmetal;
  }

  &__likes {
    color: $peacock-blue;
    svg {
      color: $clarinet;
    }
  }
}
</style>
