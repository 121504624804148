<template>
  <span>
    <template v-if="viewMode === blockViewMode.Popup">
      <b-modal
        :id="modalId"
        size="lg"
        centered
        header-class="border-bottom-0 pr-4"
        body-class="pl-7 pr-5 mt-n4 missing-cookie-body"
        footer-class="missing-cookie-modal-footer border-top-0 pl-7 py-5"
      >
        <ad-icon-warnings class="position-absolute warning" style="top: 5px" /><slot name="message"></slot>

        <template #modal-header-close>
          <ad-icon-close width="0.7em" height="0.7em" class="marine-blue" />
        </template>
        <template #modal-footer>
          <b-button class="m-0" :href="settingsProperty.cookieSettingsPageUrl">
            <slot name="button-text"></slot>
          </b-button>
        </template>
      </b-modal>
    </template>
    <template v-else-if="viewMode === blockViewMode.Content">
      <div class="p-4" :style="{ backgroundImage: 'url(' + settingsProperty.backgroundImageUrl + ')' }">
        <div class="missing-cookie-body pb-2"><slot name="message"></slot></div>
        <b-button class="m-0" :href="settingsProperty.cookieSettingsPageUrl">
          <slot name="button-text"></slot>
        </b-button>
      </div>
    </template>
    <template v-else-if="viewMode === blockViewMode.Text">
      <div>
        <span class="missing-cookie-body">
          <slot name="message"></slot>
          <a :href="settingsProperty.cookieSettingsPageUrl"><slot name="button-text"></slot></a>
        </span>
      </div>
    </template>
  </span>
</template>
<script lang="ts">
import { MissingCookieModalId, MissingCookieViewMode } from '@/src/types/enumerations';
import { MissingCookieSettings } from '@/src/types/episerver-api';
import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';

@Component
export default class AdMissingCookieBlock extends Vue {
  blockViewMode = MissingCookieViewMode;

  @Prop({ required: true }) viewMode!: MissingCookieViewMode;
  @Prop() modalId: MissingCookieModalId | undefined;
  @Inject() settingsProperty!: MissingCookieSettings;
}
</script>
<style lang="scss">
.missing-cookie-modal-footer {
  justify-content: left !important;
}

.missing-cookie-body {
  color: $mulberry;

  h3,
  h4 {
    color: $mulberry;
  }

  ul > li::before {
    color: $mulberry !important;
  }

  .ade-icon.warning {
    left: rem(50);
  }
}
</style>
