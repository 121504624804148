<script lang="ts">
import Vue, { PropType, VNode } from 'vue';

/**
 * This can be used to declaratively render vnodes (that have been passed as slots for example).
 * This has to be a functional component so that a VNode can be returned from the render function.
 *
 * @example
 *
 * ```html
 *  <li v-for="(h, i) in $slots.items" v-bind:key="i">
 *    <ce-node-renderer :node="h" /><br />
 *  </li>
 * ```
 */
export default Vue.extend({
  name: 'CeNodeRenderer',
  props: {
    node: {
      required: true,
      type: Object as PropType<VNode>,
    },
  },
  functional: true,
  render(h, context) {
    return context.props.node;
  },
});
</script>
