<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <label v-if="label" class="fs-14 ce-radio-buttons--buttons-label">{{ label }}</label>
      <b-skeleton type="input"></b-skeleton>
    </template>
    <slot></slot>
  </b-skeleton-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AdSkeletonRadioButtons extends Vue {
  @Prop({ default: true, type: Boolean }) loading;
  @Prop({ default: null, type: String }) label;
}
</script>
