<template>
  <div class="mb-6">
    <h2 v-if="$slots['news-with-short-news-teaser-headline']">
      <slot name="news-with-short-news-teaser-headline"></slot>
    </h2>
    <div class="row justify-content-between mb-0 mb-md-5 pb-md-3">
      <slot name="news-with-short-news-teaser-image-top"></slot>
      <slot name="news-with-short-news-teaser-image-bottom"></slot>
    </div>
    <div class="row justify-content-md-between">
      <div class="d-flex flex-column flex-lg-row col-12 col-md-7 mb-5 mb-md-0">
        <div class="row w-100">
          <slot name="news-with-short-news-teaser-image-right"></slot>
          <div v-if="globalPageOverviewUrl" class="col-12">
            <a :href="globalPageOverviewUrl" class="btn btn-secondary mt-5 mb-3 mb-md-0 w-100 w-md-auto">
              <slot name="news-with-short-news-teaser-more-button-text"></slot>
            </a>
          </div>
        </div>
      </div>
      <div v-if="$slots['news-with-short-news-teaser-short-news-teaser']" class="col-12 col-md-4">
        <slot name="news-with-short-news-teaser-short-news-teaser"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CeAdNewsShortNewsTeaser extends Vue {
  @Prop()
  globalPageOverviewUrl!: string | null;
}
</script>
