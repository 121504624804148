import { InstrumentDataFieldBase, UnderlyingDataField } from '@/src/types/episerver-api-instrument';
import { PriceModel, ProductModel, UnderlyingModel } from '@/src/types/the-q-api';
import { ApiModel, ApiTypeValue, FormatDataField } from '@/src/types/vue-api';
import evaluateExpressionByKey from './format-helper';

export abstract class InstrumentFormat {
  public abstract trySelect(
    field: FormatDataField,
    value: ApiTypeValue,
    model: ApiModel | null,
    isPush: boolean
  ): string | boolean;
}

export class SmallValueFormat extends InstrumentFormat {
  smallFormatThreshold = parseFloat(process.env.VUE_APP_SMALL_VALUE_THRESHOLD);
  private asNumber(value: ApiTypeValue): number | undefined {
    if (typeof value === 'number') return value;
    const price = value as PriceModel;
    if (typeof price?.amount === 'number') return price.amount;
    return undefined;
  }

  public trySelect(field: FormatDataField, value: ApiTypeValue): string | boolean {
    if (!(field as InstrumentDataFieldBase)?.smallFormat) return false;
    const number = this.asNumber(value);
    if (number && number < this.smallFormatThreshold) return (field as InstrumentDataFieldBase).smallFormat ?? false;
    return false;
  }
}

export class PushFormat extends InstrumentFormat {
  public trySelect(
    field: UnderlyingDataField,
    value: ApiTypeValue,
    model: UnderlyingModel | ProductModel,
    isPush = false
  ): string | boolean {
    if (isPush && field.pushFormat) {
      return field.pushFormat;
    }

    return false;
  }
}

export class UnderlyingCategoryFormat extends InstrumentFormat {
  public trySelect(field: UnderlyingDataField, value: ApiTypeValue, model: UnderlyingModel): string | boolean {
    let format: string | undefined;
    if (field?.customFormats && field?.customFormats?.length !== 0) {
      for (const customFormat of field.customFormats) {
        const modelValue = evaluateExpressionByKey(customFormat.key, model);
        if (modelValue == customFormat.value) {
          format = customFormat.format;
        }
      }
    }

    return format || false;
  }
}
