<template>
  <div>
    <h1 class="mb-md-3 mb-2">
      <ad-underlying-preheadline-text
        class="fs-16 pr-1"
        :udp-preheadlines="settingsProperty.udpPreHeadlines"
        :underlying="underlyingModel"
      ></ad-underlying-preheadline-text>
      <ad-underlying-data-value fieldKey="underlying.name"></ad-underlying-data-value>
    </h1>
    <h2 v-if="headerProperties && headerProperties.length">
      <span v-for="(prop, index) in headerProperties" v-bind:key="prop">
        <template v-if="index !== 0"> / </template>
        {{ prop }}
      </span>
    </h2>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { Component, Inject, Prop } from 'vue-property-decorator';
import { UnderlyingDetailPageSetting } from '@/src/types/episerver-api';

@Component
export default class AdUnderlyingPageHeader extends Vue {
  @Inject()
  settingsProperty?: UnderlyingDetailPageSetting;

  @Prop()
  underlyingModel?: UnderlyingModel;

  get headerProperties(): unknown[] | null {
    if (this.settingsProperty?.headerProperties && this.underlyingModel) {
      const underlying = this.underlyingModel;
      let props = this.settingsProperty?.headerProperties.map((key) => underlying[key]);
      props = props.filter((p) => p);
      return props;
    }
    return null;
  }
}
</script>
