<template>
  <div v-if="underlying" class="mb-6">
    <ad-chart-configuration-property :chart-configuration-property="chartConfiguration">
      <ad-underlying-data-property :underlying="underlying" :key="underlying.isin">
        <b-card class="mb-2 mb-3">
          <b-row class="mb-md-3">
            <b-col>
              <b-row>
                <b-col>
                  <h3 class="mb-0">
                    {{ underlying.name }}
                  </h3>
                  <span class="fs-18"
                    ><ad-underlying-data-value :underlyingItem="underlying" fieldKey="underlying.last"
                  /></span>
                  <span class="mr-lg-5 pl-1"><slot name="indications-info-block"></slot></span>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-md-none">
                  <span class="font-weight-normal"
                    ><ad-underlying-data-value fieldKey="underlying.changeAbsolute" :underlyingItem="underlying[0]" />
                    /&nbsp;
                    <ad-underlying-data-value fieldKey="underlying.changePercentage" :underlyingItem="underlying[0]" />
                  </span>
                </b-col>
                <b-col class="mt-3 d-none d-md-inline">
                  <div class="fs-14 font-weight-bold blue"><slot name="price-change-label"></slot></div>
                  <div class="d-none d-md-block fs-18 font-weight-normal"
                    ><ad-underlying-data-value fieldKey="underlying.changeAbsolute" :underlyingItem="underlying[0]" />
                    /&nbsp;
                    <ad-underlying-data-value fieldKey="underlying.changePercentage" :underlyingItem="underlying[0]"
                  /></div>
                </b-col>
              </b-row>
              <b-row class="mt-2 d-none d-md-block">
                <b-col class="mt-1 fs-14"><slot name="indications-text"></slot> </b-col>
              </b-row>
              <b-row class="fs-14 mt-3">
                <b-col>
                  <b-row>
                    <b-col cols="12">
                      <span class="font-weight-bold"><slot name="nsin-label">Nsin</slot></span>
                      <span class="ml-1"
                        ><ad-underlying-data-value v-if="nsinKey" :fieldKey="nsinKey" :underlyingItem="underlying"
                      /></span>
                    </b-col>
                    <b-col cols="12 pr-0">
                      <span class="font-weight-bold"><slot name="isin-label">ISIN</slot></span>
                      <span class="ml-1"
                        ><ad-underlying-data-value fieldKey="underlying.isin" :underlyingItem="underlying"
                      /></span>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="d-md-none">
                  <span class="text-right"><slot name="indications-text"></slot></span>
                </b-col>
              </b-row>
              <b-row class="mt-3 no-gutters d-md-none">
                <b-col>
                  <slot name="teaser-chart" />
                </b-col>
              </b-row>
            </b-col>
            <b-col md="8" class="d-none d-md-block">
              <slot name="teaser-chart" />
            </b-col>
          </b-row>
        </b-card>
      </ad-underlying-data-property>
    </ad-chart-configuration-property>
  </div>
</template>

<script lang="ts">
import { UnderlyingModel } from '@/src/types/the-q-api';
import { InstrumentFieldKeys } from '@/src/types/episerver-api';
import { ChartConfiguration } from '@/src/types/vue-api';
import { Component, Inject, Vue, Prop } from 'vue-property-decorator';
import { isAxiosError } from '@/src/types/type-guards';
import { HttpStatusCodes } from '@/src/types/enumerations';
import { Action, Getter } from 'vuex-class';
@Component({})
export default class AdUnderlyingTeaserBlog extends Vue {
  @Prop() underlyingIsin!: string;

  @Inject({ default: null }) nsinKeys!: InstrumentFieldKeys | null;

  @Getter('underlyingByKey', { namespace: 'underlying' })
  underlyingByKey!: (isin: string) => UnderlyingModel;

  @Action('loadUnderlyingsAndSaveByIsinKeyAsync', { namespace: 'underlying' })
  loadUnderlyingsAndSaveByIsinKeyAsync!: (isins: string[]) => Promise<void>;

  statusCode: number | HttpStatusCodes = 200;
  chartConfiguration = new ChartConfiguration();

  async created(): Promise<void> {
    try {
      await this.loadUnderlyingsAndSaveByIsinKeyAsync([this.underlyingIsin]);
    } catch (error: unknown) {
      this.$log.debug('Error:', error);

      if (isAxiosError(error)) {
        this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
      }
    }

    this.chartConfiguration.forceDropDownForChartPeriods = true;
    this.chartConfiguration.legendInTwoLineModeAtTablet = true;
  }

  get nsinKey(): string | null {
    return this.nsinKeys?.underlyingKey || null;
  }

  get underlying(): UnderlyingModel | null {
    return this.underlyingByKey(this.underlyingIsin);
  }
}
</script>
