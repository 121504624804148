<template>
  <b-nav-item class="anchor-navigation-li" v-show="showAnchor" v-bind:href="'#' + contentId" @click="passClick"
    ><slot name="anchor-header"></slot
  ></b-nav-item>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class AdAnchor extends Vue {
  @Prop()
  contentId!: string;

  @Getter('registeredAnchors', { namespace: 'htmlAnchors' })
  registeredAnchors!: { [anchorId: string]: boolean };

  get showAnchor(): boolean {
    return this.registeredAnchors[this.contentId] ?? false;
  }

  passClick(e: Event): void {
    this.$parent?.$emit('anchor-nav-click', e);
  }
}
</script>
<style lang="scss">
.anchor-navigation-li {
  .nav-link {
    padding-left: 0;
    font-size: $fs-14;
  }

  > a:not(.active) {
    color: $cool-grey;
  }
}
</style>
