import { VNode } from 'vue';

/**
 * Vue adds whitespace as node between other nodes in the $slots.<property-name> array, when inline elements are passed as slots.
 * Therefore the nodes with the undefined tags are filtered out here. Check https://github.com/vuejs/vue/issues/2723
 */
export function filterVNodes(nodes: VNode[] | undefined): VNode[] {
  if (nodes !== undefined) {
    // Whitespace nodes have the tag undefined
    return nodes.filter((node: VNode) => node.tag !== undefined);
  }
  return new Array<VNode>();
}
