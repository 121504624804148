<template>
  <div class="ce-glossary-desktop-letter-selector" :class="{ open }">
    <button class="ce-glossary-desktop-letter-selector--current-letter-button" @click="open = !open">
      {{ currentLetter.toUpperCase() }}
    </button>

    <div class="ce-glossary-desktop-letter-selector--letter-container">
      <div class="ce-glossary-desktop-letter-selector--letter-row-container">
        <button
          v-for="(letter, index) in firstLettersRow"
          :key="index"
          class="ce-glossary-desktop-letter-selector--letter-button"
          :class="{ 'is-current': letter === currentLetter }"
          :disabled="!entriesByLetter[letter] || entriesByLetter[letter].length === 0"
          @click="scrollToLetter(letter)"
        >
          {{ letter.toUpperCase() }}
        </button>
      </div>
      <div class="ce-glossary-desktop-letter-selector--letter-row-container">
        <button
          v-for="(letter, index) in secondLettersRow"
          :key="index"
          class="ce-glossary-desktop-letter-selector--letter-button"
          :class="{ 'is-current': letter === currentLetter }"
          :disabled="!entriesByLetter[letter] || entriesByLetter[letter].length === 0"
          @click="scrollToLetter(letter)"
        >
          {{ letter.toUpperCase() }}
        </button>
      </div>
    </div>

    <button class="ce-glossary-desktop-letter-selector--expansion-toggle" @click="open = !open">
      <ad-icon-chevron rotate="270" v-if="!open"></ad-icon-chevron>
      <ad-icon-chevron rotate="90" v-if="open"></ad-icon-chevron>
    </button>
  </div>
</template>

<script lang="ts">
import { GlossaryEntry } from '@/src/types/ce-component-types';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class CeGlossaryDesktopSectionLetterSelector extends Vue {
  @Prop({ default: {} }) entriesByLetter!: Record<string, GlossaryEntry[]>;
  @Prop() currentLetter!: string;
  @Prop() letters!: string[];

  open = false;

  @Emit('scrollToLetter')
  scrollToLetter(letter: string) {
    this.open = false;
    return letter;
  }

  get firstLettersRow() {
    const firstLetterOfSecondRow = Math.floor(this.letters.length / 2);
    return this.letters.slice(0, firstLetterOfSecondRow);
  }

  get secondLettersRow() {
    const firstLetterOfSecondRow = Math.floor(this.letters.length / 2);
    return this.letters.slice(firstLetterOfSecondRow);
  }
}
</script>

<style lang="scss">
$letter-button-width: 40;

.ce-glossary-desktop-letter-selector {
  display: flex;

  &--current-letter-button {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    transition: border-radius 0.5s;
    border: 0;
    border-radius: $border-radius;
    background: $silver;
    width: rem(58);
    color: $peacock-blue;

    &:hover {
      background: $peacock-blue;
      color: $white;
    }
  }

  &.open &--current-letter-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: $peacock-blue;
    color: $white;
  }

  &--letter-container {
    display: flex;
    flex-direction: column;
    transition: width 0.5s;
    width: 0;
    overflow: hidden;
    white-space: nowrap;

    @include media-breakpoint-up('xl') {
      flex-direction: row;
    }
  }

  &--letter-row-container {
    flex: 0 0 auto;
  }

  &.open &--letter-container {
    width: rem($letter-button-width + 1) * 13;

    @include media-breakpoint-up('xl') {
      width: rem($letter-button-width + 1) * 26;
    }
  }

  &--letter-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1px;
    margin-left: 1px;
    border: 0;
    background: $light-grey;
    width: rem($letter-button-width);
    min-width: 0;
    height: rem(56);
    color: $peacock-blue;

    &:disabled {
      color: $cool-grey;
    }

    &.is-current,
    &:hover:not(:disabled) {
      background: $peacock-blue;
      color: $white;
    }
  }

  &--expansion-toggle {
    margin-left: 1px;
    border: 0;
    background: transparent;
    color: $marine-blue;
    font-size: 0.6rem;
  }

  &.open &--expansion-toggle {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background: $peacock-blue;
    color: $white;
  }
}
</style>
