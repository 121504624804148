<template>
  <ad-icon-base iconName="xls" v-bind="$attrs" viewBox="10 10 25 25">
    <path
      d="M25.4,25.81l-4.2,5.6A1.5,1.5,0,0,1,20,32h0a1.51,1.51,0,0,1-1.18-.6l-4.2-5.6a.5.5,0,1,1,.8-.61l4.1,5.47V8.49a.5.5,0,0,1,1,0V30.68l4.1-5.48a.51.51,0,0,1,.7-.1.5.5,0,0,1,.1.7Z"
    />
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconSort extends Vue {}
</script>
