<template>
  <ad-icon-base v-bind="$attrs">
    <path
      d="M32.4,24.3c-0.3-0.3-0.9-0.3-1.2,0l-2.9,2.9l-2.9-2.9c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l2.9,2.9
      l-2.9,2.9c-0.3,0.3-0.3,0.8,0,1.2c0.3,0.3,0.9,0.3,1.2,0l2.9-2.9l2.9,2.9c0.3,0.3,0.9,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-2.9-2.9
      l2.9-2.9C32.8,25.1,32.8,24.6,32.4,24.3z M35.2,19C35.2,19,35.2,19,35.2,19c1.5-1.4,2.9-3.1,4.4-4.9c0.4-0.5,0.4-1.3,0-1.9l0,0
      C33,4.1,26.8,0,20.1,0S7.1,4.1,0.3,12.2c-0.5,0.5-0.5,1.3,0,1.9c5.7,7,11,11,16.5,12.1c-0.7,3.6,0.4,7.5,3.2,10.4
      c4.6,4.5,11.9,4.5,16.5,0c4.6-4.5,4.6-11.9,0-16.4C36.1,19.7,35.7,19.4,35.2,19z M1.7,13.2C8.2,5.3,14,1.7,20.1,1.7
      c6,0,11.8,3.7,18.2,11.5c-1.5,1.9-3,3.5-4.5,4.9c-2.6-1.4-5.6-1.7-8.4-1c0.8-1.1,1.2-2.4,1.2-3.8c0-3.7-3-6.6-6.7-6.6l0,0
      c-1.8,0-3.5,0.7-4.7,1.9c-1.3,1.2-2,2.9-2,4.7c0,3.7,3,6.6,6.7,6.6c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.3
      c-1.3,1.3-2.3,2.9-2.8,4.5c0,0,0,0,0-0.1C12.3,23.6,7.2,19.9,1.7,13.2z M20,18.3c-1.3,0-2.6-0.5-3.5-1.5c-2-2-1.9-5.1,0-7.1
      c2-1.9,5.1-1.9,7.1,0c2,2,1.9,5.1,0,7.1C22.6,17.8,21.3,18.3,20,18.3z M35.4,35.4c-3.9,3.9-10.2,3.9-14.1,0s-3.9-10.2,0-14.1
      s10.2-3.9,14.1,0S39.3,31.5,35.4,35.4z"
    />
    <template #title>
      <slot name="watchlist-icon-tooltip">Watchlist</slot>
    </template>
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class AdIconWatchlistRemove extends Vue {}
</script>
