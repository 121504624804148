import httpClient from '@/src/utils/http-service';
import {
  PriceModel,
  ProductModel,
  ProductPricePush,
  UnderlyingModel,
  UnderlyingPricePush,
  WatchListActiveInstrumentsModel,
  WatchListInstrumentDetailsModel,
  WatchListInstrumentIdsPayload,
} from '@src/types/the-q-api';
import { getTheQApiUrl } from '@/src/utils/url-helper';
import { Controller } from '@/src/types/enumerations';
import { Commit } from 'vuex';
import { WatchedUnderlying, WatchedProduct, WatchedProductModel, MutationPushPayloadHolder } from '@/src/types/vue-api';
import {
  createPushPayload,
  LightStreamerProductSubscriptionMutationTypes,
  LightStreamerUnderlyingSubscriptionMutationTypes,
  PUSH_UPDATE_PRODUCT,
  PUSH_UPDATE_UNDERLYING,
  tryUpdateProductValue,
  tryUpdateUnderlyingValue,
  watchlistNamespace,
} from '@/src/state/plugins/light-streamer/pushing-helper';
import { isNotNullOrUndefined } from '@/src/types/type-guards';
import { arrayMoveImmutable } from 'array-move';
import Vue from 'vue';
import { AxiosResponse } from 'axios';

export enum PersitAndShareMutationTypes {
  ADD_UNDERLYING = 'ADD_UNDERLYING',
  REMOVE_UNDERLYING = 'REMOVE_UNDERLYING',
  SORT_UNDERLYING = 'SORT_UNDERLYING',
  UPDATE_UNDERLYING = 'UPDATE_UNDERLYING',
  ADD_PRODUCT = 'ADD_PRODUCT',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT',
  SORT_PRODUCT = 'SORT_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
}
export const state = {
  watchedUnderlyings: [] as Array<WatchedUnderlying>,
  watchedProducts: [] as Array<WatchedProduct>,
  products: null as MutationPushPayloadHolder<Array<WatchedProductModel>, ProductModel> | null,
  underlyings: null as MutationPushPayloadHolder<Array<UnderlyingModel>, UnderlyingModel> | null,
};

export type State = typeof state;

function isPersistAndShareMutation(mutationType: string) {
  return (
    Object.keys(PersitAndShareMutationTypes).find((type) => watchlistNamespace + type === mutationType) !== undefined
  );
}

export function isPersistMutation(mutationType: string) {
  return isPersistAndShareMutation(mutationType);
}

export function isShareMutation(mutationType: string) {
  return isPersistAndShareMutation(mutationType);
}

export function reducePersistState(state: State) {
  return { watchedUnderlyings: state.watchedUnderlyings, watchedProducts: state.watchedProducts };
}

function filterwatchedUnderlyings(
  watchedUnderlyings: Array<WatchedUnderlying>,
  activeIsins: Array<string>
): Array<WatchedUnderlying> {
  return watchedUnderlyings.filter((wu) => activeIsins.find((isin) => isin === wu.isin));
}

function filterProducts(
  watchedProducts: Array<WatchedProduct>,
  activeProductCodes: Array<string>
): Array<WatchedProduct> {
  return watchedProducts.filter((wp) => activeProductCodes.find((code) => code === wp.securityCode));
}

function mapWatchedProductModel(watched: WatchedProduct, product: ProductModel): WatchedProductModel {
  const current = product.price?.bid?.amount;
  const add = watched.addPrice?.amount;
  const change = current && add ? current / add - 1 : null;

  return { ...product, watchlistAddPrice: watched?.addPrice, watchlistAddChange: change } as WatchedProductModel;
}

function mapWatchedProducts(
  watchedProducts: Array<WatchedProduct>,
  products: Array<ProductModel>
): Array<WatchedProductModel> {
  const watched = watchedProducts
    .map((wp) => {
      const product = products.find((p) => p.securityCode === wp.securityCode);
      if (product === undefined) return;
      return mapWatchedProductModel(wp, product);
    })
    .filter(isNotNullOrUndefined);
  return watched;
}

export const mutations = {
  [PersitAndShareMutationTypes.ADD_UNDERLYING](state: State, isin: string) {
    if (state.watchedUnderlyings.find((u) => u.isin === isin) !== undefined) return;
    state.watchedUnderlyings.push({
      isin: isin,
      addTimeStamp: new Date(),
    });
  },
  [PersitAndShareMutationTypes.REMOVE_UNDERLYING](state: State, isin: string) {
    const index = state.watchedUnderlyings.findIndex((u) => u.isin === isin);
    if (index === -1) return;
    state.watchedUnderlyings.splice(index, 1);
  },
  [PersitAndShareMutationTypes.UPDATE_UNDERLYING](state: State, watchedUnderlyings: Array<WatchedUnderlying>) {
    state.watchedUnderlyings = watchedUnderlyings;
  },
  [PersitAndShareMutationTypes.ADD_PRODUCT](state: State, product: { code: string; price: PriceModel }) {
    if (state.watchedProducts.find((p) => p.securityCode === product.code) !== undefined) return;
    state.watchedProducts.push({
      securityCode: product.code,
      addTimeStamp: new Date(),
      addPrice: product.price,
    });
  },
  [PersitAndShareMutationTypes.REMOVE_PRODUCT](state: State, code: string) {
    const index = state.watchedProducts.findIndex((p) => p.securityCode === code);
    if (index === -1) return;
    state.watchedProducts.splice(index, 1);
  },
  [PersitAndShareMutationTypes.UPDATE_PRODUCT](state: State, products: Array<WatchedProduct>) {
    state.watchedProducts = products;
  },
  [LightStreamerProductSubscriptionMutationTypes.CACHE_WATCHLIST_PRODUCT](
    state: State,
    productsPushPayload: MutationPushPayloadHolder<Array<WatchedProductModel>, ProductModel>
  ) {
    state.products = productsPushPayload;
  },
  [LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_WATCHLIST_UNDERLYING](
    state: State,
    underlyingPushPayload: MutationPushPayloadHolder<UnderlyingModel[], UnderlyingModel>
  ) {
    state.underlyings = underlyingPushPayload;
  },
  [PUSH_UPDATE_PRODUCT](
    state: State,
    pushItem: {
      productPricePush: ProductPricePush;
      pushSubscriptions: { [key: string]: boolean };
    }
  ) {
    state.products?.holder.forEach((p) =>
      tryUpdateProductValue(p, pushItem.productPricePush, pushItem.pushSubscriptions)
    );
  },
  [PUSH_UPDATE_UNDERLYING](
    state: State,
    pushItem: { underlying: UnderlyingPricePush; pushSubscriptions: { [key: string]: boolean } }
  ) {
    state.underlyings?.holder.forEach((tu) =>
      tryUpdateUnderlyingValue(tu, pushItem.underlying, pushItem.pushSubscriptions)
    );
  },
};

export const getters = {
  watchedUnderlyings: (state: State) => {
    return state.watchedUnderlyings;
  },
  watchedProducts: (state: State) => {
    return state.watchedProducts;
  },
  underlyings: (state: State) => {
    return state.underlyings?.holder;
  },
  products: (state: State) => {
    return state.products?.holder;
  },
  totalCount: (state: State) => {
    return state.watchedUnderlyings.length + state.watchedProducts.length;
  },
};

export const actions = {
  async fetchActiveAsync({ commit, state }: { commit: Commit; state: State }): Promise<void> {
    if (state.watchedProducts.length == 0 && state.watchedUnderlyings.length == 0) return;

    const instrumentIds: WatchListInstrumentIdsPayload = {
      productIds: state.watchedProducts.map((p) => p.securityCode),
      underlyingIds: state.watchedUnderlyings.map((u) => u.isin),
    };
    const response = await httpClient.post<
      WatchListInstrumentIdsPayload,
      AxiosResponse<WatchListActiveInstrumentsModel>
    >(getTheQApiUrl(Controller.Watchlist, 'FilterActive'), instrumentIds);

    commit(
      PersitAndShareMutationTypes.UPDATE_UNDERLYING,
      filterwatchedUnderlyings(state.watchedUnderlyings, response.data.underlyingIds)
    );
    commit(PersitAndShareMutationTypes.UPDATE_PRODUCT, filterProducts(state.watchedProducts, response.data.productIds));
  },

  async fetchDetailsAsync({ commit, state }: { commit: Commit; state: State }): Promise<void> {
    const instrumentIds: WatchListInstrumentIdsPayload = {
      productIds: state.watchedProducts.map((p) => p.securityCode),
      underlyingIds: state.watchedUnderlyings.map((u) => u.isin),
    };
    const response = await httpClient.post<
      WatchListInstrumentIdsPayload,
      AxiosResponse<WatchListInstrumentDetailsModel>
    >(getTheQApiUrl(Controller.Watchlist, 'FetchDetails'), instrumentIds);

    if (state.watchedUnderlyings.length !== response.data.underlyings.length) {
      commit(
        PersitAndShareMutationTypes.UPDATE_UNDERLYING,
        filterwatchedUnderlyings(
          state.watchedUnderlyings,
          response.data.underlyings.map((u) => u.isin)
        )
      );
    }

    if (state.watchedProducts.length !== response.data.products.length) {
      commit(
        PersitAndShareMutationTypes.UPDATE_PRODUCT,
        filterProducts(
          state.watchedProducts,
          response.data.products.map((p) => p.securityCode).filter(isNotNullOrUndefined)
        )
      );
    }
    const watchedProducts = mapWatchedProducts(state.watchedProducts, response.data.products);
    const productsPushPayload = createPushPayload<Array<WatchedProductModel>, ProductModel>(
      watchedProducts,
      (holder) => holder
    );

    commit(LightStreamerProductSubscriptionMutationTypes.CACHE_WATCHLIST_PRODUCT, productsPushPayload);

    const underlyingPushPayload = createPushPayload<Array<UnderlyingModel>, UnderlyingModel>(
      response.data.underlyings,
      (holder: UnderlyingModel[]): Array<UnderlyingModel> => holder
    );
    commit(LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_WATCHLIST_UNDERLYING, underlyingPushPayload);
  },

  addUnderlying({ commit }: { commit: Commit; state: State }, isin: string): void {
    commit(PersitAndShareMutationTypes.ADD_UNDERLYING, isin);
  },

  removeUnderlying({ commit }: { commit: Commit; state: State }, isin: string): void {
    commit(PersitAndShareMutationTypes.REMOVE_UNDERLYING, isin);
  },

  addProduct({ commit }: { commit: Commit; state: State }, product: { code: string; price: PriceModel }): void {
    commit(PersitAndShareMutationTypes.ADD_PRODUCT, product);
  },

  removeProduct({ commit }: { commit: Commit; state: State }, code: string): void {
    commit(PersitAndShareMutationTypes.REMOVE_PRODUCT, code);
  },
  updateUnderlyingSorting(
    { commit, state }: { commit: Commit; state: State },
    { oldIndex, newIndex }: { oldIndex: number; newIndex: number }
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sortedwatchedUnderlyings = arrayMoveImmutable(state.underlyings?.holder!, oldIndex, newIndex);

    commit(
      LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_WATCHLIST_UNDERLYING,
      createPushPayload<Array<UnderlyingModel>, UnderlyingModel>(sortedwatchedUnderlyings, (holder) => holder)
    );
    commit(
      PersitAndShareMutationTypes.UPDATE_UNDERLYING,
      arrayMoveImmutable(state.watchedUnderlyings, oldIndex, newIndex)
    );
  },
  updateProductSorting(
    { commit, state }: { commit: Commit; state: State },
    { oldIndex, newIndex }: { oldIndex: number; newIndex: number }
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sortedProducts = arrayMoveImmutable(state.products?.holder!, oldIndex, newIndex);

    commit(
      LightStreamerProductSubscriptionMutationTypes.CACHE_WATCHLIST_PRODUCT,
      createPushPayload<Array<WatchedProductModel>, ProductModel>(sortedProducts, (holder) => holder)
    );

    const sortedWatchedProducts = arrayMoveImmutable(state.watchedProducts, oldIndex, newIndex);
    commit(PersitAndShareMutationTypes.UPDATE_PRODUCT, sortedWatchedProducts);
  },
};
