<template>
  <div class="content-container container-fluid mb-7 mb-md-8">
    <h2>Markets</h2>
    <div class="row mb-0 mb-md-5">
      <div class="col-12 col-md-7 mb-3 mb-md-0">
        <a class="aspect-ratio-16-9 d-block" href="#">
          <picture>
            <source media="(min-width:650px)" srcset="https://picsum.photos/seed/12/740/414" />
            <source media="(min-width:465px)" srcset="https://picsum.photos/seed/12/316/185" />
            <img class="aspect-ratio-filler zoom-animated-image" src="https://picsum.photos/seed/12/500/500" />
          </picture>
        </a>
      </div>
      <div class="d-flex flex-column justify-content-md-center col-12 col-md-4 mb-5 mb-md-0"
        ><div class="w-100 ce-news-teaser-markets__small-header">Markt vor 9</div>
        <div class="w-100">
          <h3>
            <a href="#">Robuster Aufwärtstrend im Dax</a>
          </h3>
        </div>
        <div class="w-100 mb-3">
          Die Korrekturversuche der letzten Woche haben die Bullen im Dax relativ gut kontern können. Wird man sich
          diese Woche wieder gen Norden orientieren?
        </div>
        <div class="w-100 ce-news-teaser-markets__date">
          05.11.2019 <span class="ce-news-teaser-markets__like d-md-none"><ad-icon-like /> 12</span>
        </div>
      </div>
    </div>
    <div class="row mb-0 mb-md-5 pt-md-5 d-flex justify-content-md-between mb-0 mb-md-5">
      <div class="d-flex col-12 col-md-6 flex-column-reverse flex-md-column">
        <div class="row p-0 d-flex justify-content-md-center mt-3 mt-md-0">
          <div class="d-flex col flex-column justify-content-md-center col-12 col-md-8"
            ><div class="w-100 ce-news-teaser-markets__small-header">Fokus Wallstreet</div>
            <div class="w-100">
              <h3>
                <a href="#">Caterpillar – Neues Jahreshoch! Und jetzt?</a>
              </h3>
            </div>
            <div class="w-100 ce-news-teaser-markets__date">
              05.11.2019 | 03:03 <span class="ce-news-teaser-markets__like d-md-none"><ad-icon-like /> 12</span>
            </div>
          </div>
        </div>
        <div class="col-12 p-0 mt-0 mt-md-5">
          <a href="#" class="aspect-ratio-16-9 d-block">
            <picture>
              <source media="(min-width:650px)" srcset="https://picsum.photos/seed/1/631/370" />
              <source media="(min-width:465px)" srcset="https://picsum.photos/seed/1/316/185" />
              <img class="aspect-ratio-filler zoom-animated-image" src="https://picsum.photos/seed/1/500/500" />
            </picture>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-5 mt-5 mt-md-0 d-flex flex-column justify-content-start">
        <div class="row">
          <div class="col-12 mb-0 mb-md-0">
            <a href="#" class="aspect-ratio-16-9 w-100 d-block">
              <picture>
                <source media="(min-width:650px)" srcset="https://picsum.photos/seed/14/520/292" />
                <source media="(min-width:465px)" srcset="https://picsum.photos/seed/14/316/185" />
                <img class="aspect-ratio-filler zoom-animated-image" src="https://picsum.photos/seed/1/500/500" />
              </picture>
            </a>
          </div>
          <div class="d-flex flex-column col-12 col-md-8 mt-3 mt-md-5"
            ><div class="w-100 ce-news-teaser-markets__small-header">Weekly Market Forecast</div>
            <div class="w-100">
              <h3>
                <a href="#">Lorem ipsum dolor sit amet consectetuer!</a>
              </h3>
            </div>
            <div class="w-100 ce-news-teaser-markets__date"
              >05.11.2019 | 02:53 <span class="ce-news-teaser-markets__like d-md-none"><ad-icon-like /> 12</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-0 mt-5 mt-md-0 pt-md-5">
      <div class="col-12">
        <a href="#" class="btn btn-secondary w-100 w-md-auto">Show more</a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CeNewsTeaserMarkets extends Vue {}
</script>

<style lang="scss">
.ce-news-teaser-markets {
  &__small-header {
    color: $peacock-blue;
    font-size: rem(16);
  }

  &__date {
    font-size: rem(16);
  }

  &__like {
    padding-left: map-get($spacers, 2);
    color: $peacock-blue;
    font-weight: bold;
  }
}
</style>
