<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-skeleton-table :rows="rows" :columns="columns" :table-props="{ striped: true }"></b-skeleton-table>
    </template>
    <slot></slot>
  </b-skeleton-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AdSkeletonTable extends Vue {
  @Prop({ default: true, type: Boolean, required: true }) loading;
  @Prop({ default: 5, type: Number }) columns;
  @Prop({ default: 5, type: Number }) rows;
}
</script>
