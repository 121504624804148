import { Commit } from 'vuex';

export const state = {
  anchors: {} as { [anchorId: string]: boolean },
};

type State = typeof state;

export enum MutationTypes {
  ADD_ANCHOR_ID = 'ADD_ANCHOR_ID',
  REMOVE_ANCHOR_ID = 'REMOVE_ANCHOR_ID',
}

export const getters = {
  registeredAnchors(state: State): { [anchorId: string]: boolean } {
    return state.anchors;
  },
};

export const mutations = {
  [MutationTypes.ADD_ANCHOR_ID](state: State, anchorId: string) {
    // New object needs to be in order to trigger watchers i.e object reference changes
    state.anchors = { ...state.anchors, [anchorId]: true };
  },
  [MutationTypes.REMOVE_ANCHOR_ID](state: State, anchorId: string) {
    delete state.anchors[anchorId];
    // New object needs to be in order to trigger watchers i.e object reference changes
    state.anchors = { ...state.anchors };
  },
};

export const actions = {
  addAnchorId({ commit }: { commit: Commit }, anchorId: string) {
    commit(MutationTypes.ADD_ANCHOR_ID, anchorId);
  },
  removeAnchorId({ commit }: { commit: Commit }, anchorId: string) {
    commit(MutationTypes.REMOVE_ANCHOR_ID, anchorId);
  },
};
