import Sortable from 'sortablejs';
import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const createSortable = (el, options) => {
  return Sortable.create(el, {
    delay: 100, //for scrolling in touch screen
    delayOnTouchOnly: true,
    touchStartThreshold: 3, //fix button/link click issue on sensible mobile devices
    ...options,
  });
};

Vue.directive('sortable-table', {
  bind: (el: HTMLElement, binding: DirectiveBinding) => {
    el.classList.add('draggable');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const table = el as any;
    table._sortable = createSortable(table.querySelector('tbody'), binding.value());
  },
});
