<template>
  <ad-icon-base iconName="play" v-bind="$attrs">
    <path
      d="M38.4,17.5L3.7,1.1C3.4,1,3,0.9,2.7,0.9c-1,0-2,0.6-2.5,1.7C0.1,2.9,0,3.3,0,3.6v32.7c0,1.5,1.2,2.7,2.7,2.7
	c0.4,0,0.7-0.1,1-0.2l34.7-16.4c1.4-0.6,2-2.2,1.4-3.6C39.5,18.3,39,17.8,38.4,17.5z M37.6,20.9L3,37.2c-0.1,0-0.2,0.1-0.3,0.1
	c-0.5,0-0.9-0.4-0.9-0.9V3.6c0-0.1,0-0.2,0.1-0.4c0.2-0.4,0.6-0.6,1-0.5l34.7,16.4c0.2,0.1,0.4,0.3,0.5,0.5
	C38.3,20.1,38.1,20.7,37.6,20.9C37.6,20.9,37.6,20.9,37.6,20.9z"
    />
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconPlay extends Vue {}
</script>
