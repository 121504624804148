<template>
  <ad-icon-base iconName="search" v-bind="$attrs">
    <path
      d="M24.5,0.5C15.9,0.5,9,7.4,9,15.9c0,3.6,1.3,7.1,3.5,9.8L0.3,38c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0
	l12.2-12.2c2.7,2.7,6.6,4.4,10.8,4.4c8.6,0,15.5-6.9,15.5-15.5S33.1,0.5,24.5,0.5z M24.5,29.6c-7.5,0-13.7-6.1-13.7-13.7
	S17,2.3,24.5,2.3s13.7,6.1,13.7,13.7S32.1,29.6,24.5,29.6z"
    />
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconSearch extends Vue {}
</script>
