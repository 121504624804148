<template>
  <main class="ce-content" v-bind="$attrs">
    <div v-if="(isFieldStoreLoaded && isUnderlyingTranslationLoaded) || isPageEditMode" class="guard-wrap">
      <slot name="default"></slot>
    </div>
  </main>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  inheritAttrs: false,
})
export default class AdMainLoadDataGuard extends Vue {
  @Prop({ default: false, type: Boolean }) isPageEditMode!: boolean;

  @Getter('isFieldStoreLoaded', { namespace: 'fieldFormats' })
  isFieldStoreLoaded!: boolean;

  @Getter('isUnderlyingTranslationsLoaded', { namespace: 'underlying' })
  isUnderlyingTranslationLoaded!: boolean;
}
</script>
