<template>
  <keep-alive>
    <b-tr v-if="isRowVisible">
      <b-td class="w-33">
        <slot name="headline"></slot>
      </b-td>
      <b-td class="text-center align-middle w-33">
        <slot
          name="first"
          :setVisible="setVisibleFirst"
          :productItem="firstProduct"
          :field-Key="settingsProperty.fieldKey"
        ></slot>
      </b-td>
      <b-td class="text-center align-middle w-33">
        <slot
          name="second"
          :setVisible="setVisibleSecond"
          :productItem="secondProduct"
          :field-Key="settingsProperty.fieldKey"
        ></slot>
      </b-td>
    </b-tr>
  </keep-alive>
</template>
<script lang="ts">
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { InstrumentDataRow } from '@src/types/episerver-api-instrument';
import { ProductModel } from '@/src/types/the-q-api';
@Component
export default class AdProductInfoTableRow extends Vue {
  @Inject() settingsProperty!: InstrumentDataRow;
  @Prop({ required: true }) firstProduct!: ProductModel;
  @Prop({ required: true }) secondProduct!: ProductModel;

  private isFirstValueVisible = true;
  private isSecondValueVisible = true;

  get isRowVisible(): boolean {
    return this.isFirstValueVisible || this.isSecondValueVisible;
  }

  public setVisibleFirst(hasValue: boolean): void {
    this.isFirstValueVisible = this.settingsProperty?.showOnEmpty || hasValue;
  }

  public setVisibleSecond(hasValue: boolean): void {
    this.isSecondValueVisible = this.settingsProperty?.showOnEmpty || hasValue;
  }
}
</script>
