import numeral from 'numeral';

/**
 * Custom currency formatter
 */
numeral.register('format', 'customCurrency', {
  regexps: {
    format: /c[A-Za-z]*/,
  },
  format: function (value: number, format: string, roundingFunction) {
    // eslint-disable-next-line no-useless-escape
    const afterMatch = format.match(/([\+|\-|\)|\s]*c[a-zA-Z]*)$/);
    format = format.replace(/\s?c[a-zA-Z]*\s?/, '');

    // format the number
    const output = numeral._.numberToFormat(value, format, roundingFunction);

    return (output + (afterMatch ? afterMatch[0] : '')).replace('c', '');
  },
});
