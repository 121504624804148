<template>
  <b-form-group class="bg-transparent border-0 p-0">
    <ad-skeleton-radio-buttons :label="label" :loading="loading">
      <ce-radio-buttons
        id="product-sub-type-buttons"
        stretched
        :label="label"
        v-bind="$attrs"
        :value="value"
        @input="applyFilter($event)"
      >
        <span v-for="element in slotsToRender" :key="element.value" :value="element.value">
          <slot name="{{element.label}}">{{ element.label }}</slot>
        </span>
      </ce-radio-buttons>
    </ad-skeleton-radio-buttons>
  </b-form-group>
</template>

<script lang="ts">
import { ProductSearchPageSetting } from '@/src/types/episerver-api';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { ProductSubTypeBo } from '@src/types/enumerations';

class SlotElement {
  readonly value: ProductSubTypeBo | null;
  readonly label: string;

  constructor(val: ProductSubTypeBo | null, label: string) {
    this.value = val;
    this.label = label;
  }
}

@Component({
  inheritAttrs: false,
})
export default class AdProductSearchFilterSubtype extends Vue {
  @Inject() settingsProperty!: ProductSearchPageSetting;
  @Prop({ default: false, type: Boolean }) wide!: boolean;

  @Prop({ required: true })
  value!: string;

  @Prop({ default: false }) loading!: boolean;

  selectedSubType: ProductSubTypeBo | null = null;

  get label() {
    return this.$slots['filter-label-text'] && this.$slots['filter-label-text'][0]
      ? this.$slots['filter-label-text'][0].text
      : undefined;
  }

  get slotsToRender(): Array<SlotElement> {
    if (!this.loading) {
      const slotsArray: Array<SlotElement> = [
        new SlotElement(ProductSubTypeBo.Call, this.getLabelForCall()),
        new SlotElement(ProductSubTypeBo.Put, this.getLabelForPut()),
      ];

      if (this.isLabelForBothActive()) {
        slotsArray.push(new SlotElement(null, this.getLabelForBoth()));
      }

      return slotsArray;
    }

    return [];
  }

  getLabelValue(name: string): string {
    const entry = this.$slots[name];
    const val = entry && entry.length > 0 ? entry[0].text : undefined;

    return val ? val : '';
  }

  applyFilter(val: string): void {
    this.$emit('input', val);
    this.$emit('selected-value-changed');
  }

  getLabelForPut(): string {
    return this.getLabelValue('subtype-label-put');
  }

  getLabelForCall(): string {
    return this.getLabelValue('subtype-label-call');
  }

  getLabelForBoth(): string {
    return this.getLabelValue('subtype-label-both');
  }

  isLabelForBothActive(): boolean {
    const labelSlot = this.getLabelForBoth();
    return labelSlot.trim().length > 0;
  }
}
</script>
