<template>
  <b-col cols="6" md="4" class="px-1 mb-2">
    <b-row class="no-gutters border rounded h-49 d-flex align-items-center justify-content-center">
      <b-link :href="getBrokerUrl" title="Broker Link" target="_blank" @click="onClick">
        <slot name="broker-image" />
      </b-link>
    </b-row>
  </b-col>
</template>

<script lang="ts">
import { Component, Vue, Prop, Inject, InjectReactive } from 'vue-property-decorator';
import { ProductModel } from '@src/types/the-q-api';
import { InstrumentFieldKeys } from '@/src/types/episerver-api';
import evaluateExpressionByKey from '@/src/utils/value-formatter/format-helper';

@Component({})
export default class AdBroker extends Vue {
  @Prop({ required: true, type: String }) brokerUrl!: string;
  @Prop({ type: String }) brokerName?: string | null;
  /* eslint-disable @typescript-eslint/no-empty-function */
  @Prop({
    default: () => {},
    type: Function,
  })
  brokerClick!: (brokerName: string) => void;
  /* eslint-enable @typescript-eslint/no-empty-function */
  @InjectReactive() product!: ProductModel | null;
  @Inject({ default: null }) nsinKeys!: InstrumentFieldKeys | null;

  get getBrokerUrl(): string {
    let url = this.brokerUrl.toLowerCase();

    if (this.product) {
      if (this.nsinKeys?.productKey) {
        const value = evaluateExpressionByKey(this.nsinKeys.productKey, {
          // setting product to get value from productItem and set fieldkey, e.g. product.last
          product: this.product,
        });
        if (value)
          url = this.replaceKey(
            url,
            this.nsinKeys.productKey.substr(this.nsinKeys.productKey.indexOf('.') + 1, this.nsinKeys.productKey.length),
            value.toString()
          );
      }
      url = this.replaceKey(url, 'isin', this.product.isin.toString());
    }

    return url;
  }

  replaceKey(url: string, key: string, value: string) {
    return url.replace(`[[${key}]]`, value);
  }

  onClick() {
    if (this.brokerName) {
      this.brokerClick(this.brokerName);
    }
  }
}
</script>

<style scoped lang="scss">
.h-49 {
  height: rem(49) !important;
}
</style>
