<template>
  <div v-if="isVisible" :class="['note-block', 'mb-2']">
    <h3 :class="['mb-2', { 'd-none': !showHeadline }]">{{ blockHeadline }}</h3>
    <div><slot name="default"></slot></div>
  </div>
</template>
<script lang="ts">
import { HeadlineBlock } from '@/src/types/vue-api';
import { NoteBlockSettings } from '@/src/types/episerver-api';
import { Component, Inject, InjectReactive, Vue } from 'vue-property-decorator';
import { ProductModel } from '@/src/types/the-q-api';
import { InstrumentDataFieldBase, ProductHistoryDataField } from '@/src/types/episerver-api-instrument';
import evaluateExpressionByKey, { getFormatDataField } from '@/src/utils/value-formatter/format-helper';

@Component({
  inheritAttrs: false,
})
export default class AdNote extends Vue implements HeadlineBlock {
  @Inject() settingsProperty!: NoteBlockSettings;
  @InjectReactive() product!: ProductModel | null;
  private showHeadline = true;
  public get isVisible() {
    if (!this.settingsProperty.conditionFieldKey || !this.product) return true;

    const field = getFormatDataField(this.settingsProperty.conditionFieldKey);
    if (!field) return false;
    const value = evaluateExpressionByKey(
      (field as InstrumentDataFieldBase | ProductHistoryDataField).valueExpression,
      this.product
    );
    return value == this.settingsProperty.conditionValue;
  }

  public get blockHeadline(): string | undefined {
    return this.isVisible ? this.settingsProperty.headline : undefined;
  }

  setHeadlineVisibility(visible: boolean) {
    this.showHeadline = visible;
  }
}
</script>
