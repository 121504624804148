import { ApiTypeValue } from '@/src/types/vue-api';
import { ComparisonOperators } from '@/src/types/enumerations';

function prepareValues(
  first: ApiTypeValue,
  second: string | null | number
): { first: ApiTypeValue; second: string | null | number } {
  const result = { first: first, second: second };
  result.second = second === 'null' ? '' : second;

  if (first == null || first == undefined) {
    result.first = '';
  } else if (second === 'Date.now()') {
    result.first = typeof first === 'string' && first.length != 0 ? Date.parse(first) : null;
    result.second = Date.now();
  }
  return result;
}

export function evaluateCondition(first: ApiTypeValue, second: string | null, operation: ComparisonOperators): boolean {
  const values = prepareValues(first, second);

  switch (operation) {
    case ComparisonOperators.Equal:
      return values.first == values.second;
    case ComparisonOperators.NotEqual:
      return values.first != values.second;
  }
  //Other operators require both values to be defined
  if (
    values.first === undefined ||
    values.first === null ||
    values.first === '' ||
    values.second === undefined ||
    values.second === null ||
    values.second === ''
  )
    return false;
  switch (operation) {
    case ComparisonOperators.Less:
      return values.first < values.second;
    case ComparisonOperators.Greater:
      return values.first > values.second;
    case ComparisonOperators.LessOrEqual:
      return values.first <= values.second;
    case ComparisonOperators.GreaterOrEqual:
      return values.first >= values.second;
  }
  return false;
}
