<template>
  <span
    class="icon-info-block pl-1"
    :id="'info-block' + _uid"
    :class="{ 'info-block-full-mobile': isMobileFullScreen }"
  >
    <ad-icon-info width="0.8em" height="0.8em" :id="iconId" icon-name="" />
    <b-popover
      :target="iconId"
      triggers="focus hover"
      :placement="getPlacement"
      :fallbackPlacement="getFallbackPlacement"
      :custom-class="settingsProperty.showPopoverInBigSize ? 'info-block-popover-lg' : null"
      :container="isMobileFullScreen ? 'info-block' + _uid : null"
    >
      <slot name="default"></slot>
    </b-popover>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';
import { PopoverPosition, PopoverFallback } from '@/src/types/enumerations';
import { PopoverSettings } from '@/src/types/episerver-api';

@Component
export default class AdInfoBlock extends Vue {
  @Inject() settingsProperty: PopoverSettings | undefined;
  @Prop() showOnMobileFullScreen: boolean | undefined;
  @Prop() placement: PopoverPosition | undefined;
  @Prop() fallbackPlacement: PopoverFallback | undefined;

  private static _UUID = 0;
  private currentUuId = AdInfoBlock._UUID++;

  private get iconId() {
    return `info-block-icon-${this.currentUuId}`;
  }

  private get getPlacement() {
    return this.isMobileFullScreen
      ? PopoverPosition.BottomLeft
      : PopoverPosition[this.placement ?? this.settingsProperty?.placement ?? 'Auto'];
  }

  private get getFallbackPlacement() {
    return this.isMobileFullScreen
      ? []
      : PopoverFallback[this.fallbackPlacement ?? this.settingsProperty?.fallbackPlacement ?? 'Flip'];
  }

  private get isMobileFullScreen() {
    return (this.showOnMobileFullScreen || this.settingsProperty?.showOnMobileFullScreen) && !this.$screen.md;
  }
}
</script>
<style lang="scss">
.icon-info-block {
  color: $marine-blue;

  :hover {
    color: $windows-blue;
  }

  .ade-icon {
    margin-top: -2px;
  }
}

.info-block-popover-lg {
  @include media-breakpoint-up('md') {
    min-width: rem(450);
  }

  @include media-breakpoint-up('xl') {
    min-width: rem(600);
  }
}

.info-block-full-mobile {
  .popover {
    top: auto !important;
    left: auto;
    transform: none !important;
    margin-left: $grid-gutter-spacer;
    min-width: calc(100vw - #{$grid-gutter-width});
  }
}
</style>
