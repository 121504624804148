import httpClient from './http-service';
import { Controller } from '../types/enumerations';
import { ContentSearchResultItem } from '../types/episerver-api';
import { SearchResult } from '../types/the-q-api';
import { getTheQApiUrl, getEpiserverApiUrl } from './url-helper';

export async function requestRapidSearch(isinNsinOrName: string): Promise<SearchResult | null> {
  if (!isinNsinOrName) return null;
  const response = await httpClient.get<SearchResult>(getTheQApiUrl(Controller.GlobalSearch, 'RapidSearch'), {
    params: { isinNsinOrName: isinNsinOrName },
  });
  return response.data;
}

export async function requestSuggestSearchTheQ(query: string, controller: AbortController): Promise<SearchResult> {
  const theqRequest = await httpClient.get<SearchResult>(getTheQApiUrl(Controller.GlobalSearch, 'Suggest'), {
    params: { query: query },
    signal: controller.signal,
  });

  return theqRequest.data;
}

export async function requestSuggestEpiServer(
  query: string,
  controller: AbortController
): Promise<ContentSearchResultItem[]> {
  const epiServerRequest = await httpClient.get<ContentSearchResultItem[]>(
    getEpiserverApiUrl(Controller.GlobalSearch, 'Suggest'),
    {
      params: { query: query },
      signal: controller.signal,
    }
  );

  return epiServerRequest.data;
}
