<template>
  <ad-skeleton-image :loading="!realtimeUnderlyingIsin" width="100%" height="600px">
    <div class="bg-light pb-6 pt-6 pt-xl-7">
      <b-container fluid class="content-container my-0">
        <b-row>
          <b-col xl="8" cols="12" class="pr-xl-9">
            <div>
              <b-form-group
                class="focus-white bg-white"
                label-for="underlying-teaser-dropdownlist"
                id="underlying-teaser-dropdownlist-group"
              >
                <template #label><slot name="underlying-filter-label">Choose Underlying</slot></template>
                <ad-underlying-list
                  form-element-state-id="underlying-teaser-dropdownlist-group"
                  id="underlying-filter-dropdownlist"
                  v-model="realtimeUnderlyingIsin"
                  group-items
                ></ad-underlying-list>
              </b-form-group>
            </div>
            <ad-underlying-data-property :underlying="underlying" :key="teaserUnderlyingIsin">
              <div>
                <div class="mt-5 mb-3">
                  <slot name="underlying-teaser"></slot>
                </div>
                <div>
                  <slot name="realtime-chart"></slot>
                </div>
              </div>
            </ad-underlying-data-property>
          </b-col>
          <b-col xl="4" cols="12">
            <ad-collapse-on-mobile show-collapsed class="bg-white" breakpoint="xl" :showDesktopHeader="false">
              <template v-if="!cardView" #header><slot name="quick-search-header">Quick search</slot></template>
              <div :class="{ 'card p-3': cardView }">
                <h3 v-if="cardView" class="mb-5"><slot name="quick-search-header">Quick search</slot></h3>
                <slot name="quick-search-block" :initialUnderlying="realtimeUnderlyingIsin"></slot>
              </div>
            </ad-collapse-on-mobile>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </ad-skeleton-image>
</template>

<script lang="ts">
import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { UnderlyingModel } from '@/src/types/the-q-api';

@Component
export default class AdRealtimeBlock extends Vue {
  realtimeUnderlyingIsin: string | null = null;

  @Action('loadUnderlyingAsync', { namespace: 'underlying' })
  loadUnderlyingAsync!: (isin: string[] | string) => Promise<void>;

  @Getter('underlying', { namespace: 'underlying' })
  underlying!: UnderlyingModel | null;

  @Prop({ default: null }) selectedUnderlying!: string | null;

  get teaserUnderlyingIsin(): string | null {
    return this.underlying?.isin || null;
  }

  get cardView(): boolean {
    return this.$screen.xl;
  }

  @Watch('selectedUnderlying', { immediate: true })
  selectUnderlying() {
    if (this.selectedUnderlying !== null) {
      this.realtimeUnderlyingIsin = this.selectedUnderlying;
    }
  }

  @Watch('realtimeUnderlyingIsin', { immediate: true })
  async loadUnderlying(): Promise<void> {
    if (this.realtimeUnderlyingIsin) {
      await this.loadUnderlyingAsync(this.realtimeUnderlyingIsin);
    }
  }
}
</script>
