import chooseFormat from '@/src/utils/value-formatter/format-selecting-service';
import { ApiModel, ApiTypeValue, FormatDataField } from '@/src/types/vue-api';

import {
  ValueFormatter,
  PriceFormatter,
  NumberFormatter,
  DateFormatter,
  DefaultFormatter,
  BooleanFormatter,
  StringFormatter,
} from './formatters';
import { InstrumentDataFieldBase } from '@/src/types/episerver-api-instrument';
import { isPriceModel } from '@/src/types/type-guards';
import { getFormatDataField } from './format-helper';
import Vue from 'vue';

function createFormatService(defaultFormatter: ValueFormatter, ...formatters: ValueFormatter[]) {
  return {
    format: (
      value: ApiTypeValue,
      formatTemplate: string | null,
      translations: { [key: string]: string | null } | null
    ) => {
      for (const formatter of formatters) {
        if (formatter.applicableFor(value, formatTemplate)) {
          return formatter.format(value, formatTemplate, translations);
        }
      }

      return defaultFormatter.format(value, '', translations);
    },
  };
}

const service = createFormatService(
  new DefaultFormatter(),
  new PriceFormatter(),
  new DateFormatter(),
  new NumberFormatter(),
  new BooleanFormatter(),
  new StringFormatter()
);

export function formatValue(
  value: ApiTypeValue,
  formatTemplate: string | null,
  translations: { [key: string]: string | null } | null
): string {
  return service.format(value, formatTemplate, translations);
}

function isDataFieldSetNullValue(value: ApiTypeValue, isShowEmptyPlaceholderIfZero = false) {
  if (isPriceModel(value)) {
    value = value.amount;
  }
  return value === null || (isShowEmptyPlaceholderIfZero && value === 0);
}

export function formatField(
  value: ApiTypeValue | null,
  fieldKey: string,
  model: ApiModel | null = null,
  isPush = false
): string {
  const dataField: FormatDataField | undefined = getFormatDataField(fieldKey);
  if (isDataFieldSetNullValue(value, (dataField as InstrumentDataFieldBase)?.isShowEmptyPlaceholderIfZero)) {
    return dataField?.nullValue || '';
  }

  let formatTemplate: string | undefined;
  if (dataField) {
    formatTemplate = chooseFormat(value, dataField, model, isPush);
  }
  return formatValue(value, formatTemplate || null, dataField?.translations || null);
}
