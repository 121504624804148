<template>
  <div class="ce-contact bg-light overflow-hidden">
    <div class="container content-container content-container-x my-0">
      <div class="row align-items-stretch">
        <div class="col-12 col-lg-4 py-6 pr-lg-4 d-flex flex-column justify-content-center">
          <div class="ce-contact-text--header fs-44 mb-3"><slot name="contact-header"></slot></div>
          <div class="ce-contact-text--default fs-18"><slot name="contact-phone-label"></slot></div>
          <div class="ce-contact-text--bold fs-26">
            <a class="phone" :href="phoneLink"><slot name="contact-phone-number"></slot></a>
          </div>
          <div class="ce-contact-text--default fs-18 mb-3"><slot name="contact-office-hours"></slot></div>
          <div class="ce-contact-text--default fs-18"><slot name="contact-email-label"></slot></div>
          <a :href="emailLink" class="ce-contact-text--mail fs-26">
            <slot name="contact-email"></slot>
          </a>
        </div>
        <div class="col-12 col-lg-8 p-0">
          <picture class="ce-contact-image__picture">
            <source media="(min-width:650px)" :srcset="this.image" />
            <source media="(max-width:649px)" :srcset="this.imageSmall" />
            <img :src="this.image" class="h-100" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
@Component
export default class CeContact extends Vue {
  @Prop({ type: String })
  image;
  @Prop({ type: String })
  imageSmall;
  @Prop({ type: String })
  contactPhone;
  @Prop({ type: String })
  contactEmail;

  get phoneLink() {
    return this.contactPhone ? `tel:${this.contactPhone}` : '';
  }

  get emailLink() {
    return this.contactPhone ? `mailto:${this.contactEmail}` : '';
  }
}
</script>

<style lang="scss">
/*------------------------------------*\
  #Different types of text in whole contact
\*------------------------------------*/
.ce-contact-text--header {
  line-height: rem(38);
  color: $heading-line-color;
}

.ce-contact-text--default {
  line-height: rem(22);
  color: $gunmetal;
}
.ce-contact-text--bold {
  padding-top: map-get($spacers, 1);
  padding-bottom: map-get($spacers, 2);
  line-height: rem(26);
  color: $gunmetal;
  font-weight: bold;

  & .phone {
    color: $gunmetal;
  }
}

.ce-contact-text--mail {
  line-height: rem(31);
  color: $bright-sky-blue;
}

/*------------------------------------*\
  #Image
\*------------------------------------*/
.ce-contact-image {
  &__picture img {
    object-fit: cover;
    object-position: center;
    width: calc((100vw - 100% * 12 / 8) / 2 + 100%);

    @include media-breakpoint-down('md') {
      width: 100%;
    }
  }
}
</style>
