<template>
  <div class="ce-header-search-flyout">
    <b-navbar-toggle class="ce-header-search-flyout__toggler ce-header-back fs-14" target="nav-collapse-back"
      ><ad-icon-go-back width="1.71em" height="100%" class="mr-2"></ad-icon-go-back
      ><span><slot name="go-back"></slot></span
    ></b-navbar-toggle>
    <b-collapse
      accordion="header"
      id="nav-collapse-back"
      class="ce-header-flyout ce-header-search-flyout__flyout"
      is-nav
    >
      <b-navbar-nav class="ce-header-nav-main ce-header-search-flyout__background ce-header-back-slot">
        <slot name="back-menu"></slot>
      </b-navbar-nav>
    </b-collapse>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CeHeaderBack extends Vue {}
</script>

<style lang="scss">
.sticky {
  .ce-header-back {
    display: flex;
  }
}

.ce-header-back {
  display: none;
  align-items: center;
  &:active,
  &:focus {
    span,
    svg {
      color: $bright-sky-blue;
    }
  }
  &.not-collapsed.not-collapsed {
    span,
    svg {
      color: $link-color;
    }
  }
}
.ce-header-back-slot.ce-header-back-slot {
  .nav-item {
    padding-left: map-get($spacers, 3);
    .nav-link {
      color: $gunmetal;
    }
  }

  li:last-child {
    a,
    .nav-link {
      margin-right: map-get($spacers, 3);
      border-bottom: 4px solid $peacock-blue;
      padding-bottom: rem(10);
      color: $peacock-blue;
    }
  }
}
</style>
