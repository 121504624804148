<template>
  <div>
    <b-container fluid class="content-container mt-6 mt-md-7 mt-xl-8 mb-6">
      <div class="mb-5">
        <h1 class="d-inline"
          ><slot name="headline"></slot><span class="icon-size align-text-top"><slot name="info-icon"></slot></span
        ></h1>
      </div>
      <div class="position-relative mb-6" v-if="cookiesAccepted && $slots['description']">
        <p class="fs-18 w-75">
          <slot name="description"></slot>
        </p>
        <a
          v-if="settingsProperty.realtimeButtonUrl"
          :href="settingsProperty.realtimeButtonUrl"
          class="btn btn-secondary btn-realtime w-sm-100"
        >
          <slot name="realtime-button-text"></slot>
        </a>
      </div>
      <div v-else-if="!cookiesAccepted">
        <slot name="missing-cookie" :view-mode="missingCookieBlockViewMode">Please accept cookies</slot>
      </div>
    </b-container>
    <ad-error :http-status-code="statusCode">
      <template v-if="cookiesAccepted">
        <b-container fluid class="content-container my-0">
          <h2><slot name="product-headline-text"></slot></h2>
          <div v-if="!hasProducts" class="mb-7">
            <p class="fs-18 w-75"><slot name="empty-product-text"></slot></p>
            <a
              v-if="settingsProperty.productsButtonUrl"
              :href="settingsProperty.productsButtonUrl"
              class="btn btn-secondary w-sm-100"
            >
              <slot name="products-button-text"></slot>
            </a>
          </div>
        </b-container>

        <b-container v-if="hasProducts" fluid class="content-container my-0">
          <b-row class="mb-2">
            <b-col cols="12" lg="4">
              <b-form-group
                class="focus-white"
                id="product-type-filter-dropdownlist-label"
                label-for="product-type-filter-dropdownlist"
              >
                <template #label><slot name="product-type-filter-label">Choose Product Type</slot></template>
                <ad-product-type-list
                  form-element-state-id="product-type-filter-dropdownlist-label"
                  id="product-type-filter-dropdownlist"
                  :availableItems="availableProductTypes"
                  v-model="productTypeFilter"
                  :disabled="loading"
                >
                  <template #all-items-label><slot name="all-items-filter-label">All</slot></template>
                </ad-product-type-list>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="4">
              <b-form-group
                class="focus-white"
                label-for="underlying-filter-dropdownlist"
                id="underlying-filter-dropdownlist-label"
              >
                <template #label><slot name="underlying-filter-label">Choose Underlying</slot></template>
                <ad-underlying-list
                  form-element-state-id="underlying-filter-dropdownlist-label"
                  id="underlying-filter-dropdownlist"
                  :availableItems="availableProductUnderlyings"
                  v-model="productUnderlyingFilter"
                  :disabled="loading"
                >
                  <template #all-items-label><slot name="all-items-filter-label">All</slot></template>
                </ad-underlying-list>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid class="mt-0 mb-7">
          <ad-instrument-table
            v-if="hasProducts || loading"
            :settings="productTableSettings"
            :instruments="filteredProducts"
            :loading="loading"
            show-empty
            v-sortable-table="sortProducts"
            :key="sortProductKey"
          >
            <template #empty>
              <slot name="empty-filter-product-text"></slot>
            </template>
            <template #row-details="{ data }">
              <ad-product-data-property :product="data.item.instrument">
                <!-- wrap in div to prevent multiple root nodes returned from render function error  -->
                <div class="wrap">
                  <slot name="product-detail-page-teaser" :data="data">{{ data.item.instrument }}</slot>
                </div>
              </ad-product-data-property>
            </template>
            <template #row-instrument="{ setting, instrument }">
              <ad-product-data-value
                :isLinkToDetailPage="setting.isLinkToDetailPage"
                :product-item="instrument"
                :field-key="setting.key"
              />
            </template>
            <template #row-custom-instrument="{ instrument }">
              <span role="button" class="pr-2" @click="removeProduct(instrument.securityCode)">
                <ad-icon-delete iconName="" />
              </span>
            </template>
          </ad-instrument-table>
        </b-container>
        <b-container fluid class="content-container my-0">
          <h2><slot name="underlying-headline-text"></slot></h2>
          <div v-if="!hasUnderlyings" class="mb-7">
            <p class="fs-18 w-75">
              <slot name="empty-underlying-text"></slot>
            </p>
            <a
              v-if="settingsProperty.underlyingButtonUrl"
              :href="settingsProperty.underlyingButtonUrl"
              class="btn btn-secondary w-sm-100"
            >
              <slot name="underlyings-button-text"></slot>
            </a>
          </div>
        </b-container>
        <b-container fluid class="mb-7 mb-md-8">
          <ad-instrument-table
            v-if="hasUnderlyings || loading"
            :settings="underlyingTableSettings"
            :instruments="underlyings"
            selectable
            select-mode="single"
            selected-variant="secondary"
            @row-clicked="selectUnderlying"
            :loading="loading"
            v-sortable-table="sortUnderlyings"
            :key="sortUnderlyingKey"
          >
            <template #row-instrument="{ setting, instrument }">
              <ad-underlying-data-value
                :isLinkToDetailPage="setting.isLinkToDetailPage"
                :underlying-item="instrument"
                :field-key="setting.key"
              />
            </template>
            <template #row-custom-instrument="{ instrument }">
              <span class="mr-3">
                <ad-icon-chart-line
                  :height="selectIconSize"
                  :width="selectIconSize"
                  iconName=""
                  class="bg-light-blue-grey rounded"
                />
              </span>
              <span role="button" @click="removeUnderlying(instrument.isin)">
                <ad-icon-delete iconName="" />
              </span>
            </template>
          </ad-instrument-table>
        </b-container>
      </template>
      <div class="pb-9" v-if="selectedUnderlyingIsin && !settingsProperty.isRealtimeDisabled">
        <slot name="realtime-block" :selectedUnderlying="selectedUnderlyingIsin"></slot>
      </div>
    </ad-error>
  </div>
</template>
<script lang="ts">
import { Action, Getter } from 'vuex-class';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { WatchlistPageSettingPayload, TableColumnSettingBase } from '@/src/types/episerver-api';
import { UnderlyingModel, ProductModel } from '@/src/types/the-q-api';
import { InstrumentTableSettingData, WatchedProductModel } from '@/src/types/vue-api';
import { TextAlign, HttpStatusCodes, MissingCookieViewMode } from '@/src/types/enumerations';
import CookieManager from '@/src/utils/cookie';
import { isAxiosError } from '@/src/types/type-guards';

@Component
export default class AdWatchlistPage extends Vue {
  @Inject() settingsProperty!: WatchlistPageSettingPayload;

  @Getter('underlyings', { namespace: 'watchlist' })
  underlyings!: Array<UnderlyingModel> | null;

  @Getter('products', { namespace: 'watchlist' })
  products!: Array<WatchedProductModel> | null;

  @Action('fetchDetailsAsync', { namespace: 'watchlist' })
  fetchDetailsAsync!: () => Promise<void>;

  @Action('removeUnderlying', { namespace: 'watchlist' })
  removeWatchedUnderlying!: (isin: string) => Promise<void>;

  @Action('removeProduct', { namespace: 'watchlist' })
  removeWatchedProduct!: (code: string) => Promise<void>;

  @Action('updateUnderlyingSorting', { namespace: 'watchlist' })
  updateUnderlyingSorting!: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;

  @Action('updateProductSorting', { namespace: 'watchlist' })
  updateProductSorting!: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;

  private missingCookieBlockViewMode = MissingCookieViewMode.Text;
  private selectIconSize = '1.8em';

  private productTypeFilter = null;
  private productUnderlyingFilter = null;
  private productTableSettings!: InstrumentTableSettingData;

  private selectedUnderlyingIsin: string | null = null;
  private cookieManager!: CookieManager;
  private underlyingTableSettings!: InstrumentTableSettingData;
  private loading = false;
  private statusCode = HttpStatusCodes.OK;
  private sortProductKey = 0;
  private sortUnderlyingKey = 0;

  async created(): Promise<void> {
    this.cookieManager = new CookieManager(this.$cookies);
    this.mapTableColumns();
    await this.loadWatchlistWithErrorHandling();
    if (Array.isArray(this.underlyings) && this.underlyings.length > 0) {
      this.selectedUnderlyingIsin = this.underlyings[0].isin;
    }
  }

  private get cookiesAccepted(): boolean {
    return this.cookieManager?.comfort || false;
  }

  private get hasProducts(): boolean {
    return this.loading || (Array.isArray(this.products) && this.products?.length !== 0);
  }

  private get hasUnderlyings(): boolean {
    return Array.isArray(this.underlyings) && this.underlyings.length > 0;
  }

  get availableProductTypes() {
    return this.products?.map((p) => p.productType) || null;
  }

  get availableProductUnderlyings() {
    return (
      this.products?.reduce((dict, product) => {
        const underlyings = product.underlyings?.reduce((d, u) => ({ [u.isin]: u.isin, ...d }), {}) || {};
        return { ...underlyings, ...dict };
      }, {}) || {}
    );
  }

  get filteredProducts(): Array<ProductModel> {
    if (!Array.isArray(this.products)) return [];
    let products = Array.from(this.products);
    if (this.productUnderlyingFilter !== null) {
      products = products.filter(
        (p) => p.underlyings?.filter((u) => u.isin == this.productUnderlyingFilter).length !== 0 || false
      );
    }
    if (this.productTypeFilter !== null) {
      products = products.filter((p) => p.productType === this.productTypeFilter);
    }
    return products;
  }

  async loadWatchlistWithErrorHandling() {
    this.loading = true;

    try {
      await this.fetchDetailsAsync();
    } catch (error: unknown) {
      this.$log.error('Loading Error', error);

      if (isAxiosError(error)) {
        this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
      }
    }

    this.loading = false;
  }

  private mapTableColumns() {
    const actionsColumn = {
      headline: '',
      textAlign: TextAlign.Center,
      isEllipsis: false,
      columnWidth: null,
    } as TableColumnSettingBase;
    const underlyingTableColumns = this.settingsProperty.underlyingTableColumns;
    this.underlyingTableSettings = {
      desktopTableColumnsSetting: [...underlyingTableColumns.desktopTableColumnsSetting, actionsColumn],
      tabletTableColumnsSetting: [...underlyingTableColumns.tabletTableColumnsSetting, actionsColumn],
      mobileTableColumnsSetting: [...underlyingTableColumns.mobileTableColumnsSetting, actionsColumn],
      tableColumnSorting: [],
      hideDetailColumn: underlyingTableColumns.hideDetailColumn,
    } as InstrumentTableSettingData;
    const productTableColumns = this.settingsProperty.productTableColumns;
    this.productTableSettings = {
      desktopTableColumnsSetting: [...productTableColumns.desktopTableColumnsSetting, actionsColumn],
      tabletTableColumnsSetting: [...productTableColumns.tabletTableColumnsSetting, actionsColumn],
      mobileTableColumnsSetting: [...productTableColumns.mobileTableColumnsSetting, actionsColumn],
      tableColumnSorting: [],
      hideDetailColumn: productTableColumns.hideDetailColumn,
    } as InstrumentTableSettingData;
  }

  selectUnderlying(item: UnderlyingModel): void {
    this.selectedUnderlyingIsin = item.isin;
  }

  removeUnderlying(isin: string) {
    this.removeWatchedUnderlying(isin);
    this.fetchDetailsAsync();
  }

  removeProduct(code: string) {
    this.removeWatchedProduct(code);
    this.fetchDetailsAsync();
  }

  private sortProducts() {
    const onEnd = (oldIndex, newIndex) => {
      this.updateProductSorting({ oldIndex, newIndex });
      this.sortProductKey++;
    };
    return {
      onEnd: (evt) => onEnd(evt.oldIndex, evt.newIndex),
    };
  }

  private sortUnderlyings() {
    const onEnd = (oldIndex, newIndex) => {
      this.updateUnderlyingSorting({ oldIndex, newIndex });
      this.sortUnderlyingKey++;
    };
    return {
      onEnd: (evt) => onEnd(evt.oldIndex, evt.newIndex),
    };
  }
}
</script>
<style lang="scss">
@include media-breakpoint-up('lg') {
  .btn-realtime {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
