import numeral from 'numeral';
import '@/src/utils/value-formatter/numeral-locales';
import '@/src/utils/value-formatter/numeral-custom-currency-formatter';
import '@/src/utils/value-formatter/ordinal-custom-formatter';
import dateFormat from 'date-fns/format';
import { PriceModel } from '@/src/types/the-q-api';
import { ApiTypeValue } from '@/src/types/vue-api';
import { isPriceModel } from '@/src/types/type-guards';
import { getCurrentLocale } from '@src/utils/date-helper';
import { DefaultFormat } from '@/src/types/enumerations';
import { getFormatDataField } from './format-helper';

export abstract class ValueFormatter<T = ApiTypeValue> {
  abstract applicableFor(value: ApiTypeValue, formatTemplate: string | null): boolean;
  abstract format(
    value: T,
    formatTemplate: string | null,
    translations: { [key: string]: string | null } | null
  ): string;
}

export class PriceFormatter implements ValueFormatter<PriceModel> {
  applicableFor(value: ApiTypeValue): boolean {
    return isPriceModel(value);
  }
  format(value: PriceModel, formatTemplate: string | null): string {
    const result = numeral(value.amount).format(formatTemplate || getFormatDataField(DefaultFormat.Decimal)?.format!);

    return result || '';
  }
}

export class NumberFormatter implements ValueFormatter<number> {
  applicableFor(value: ApiTypeValue): boolean {
    return typeof value === 'number';
  }
  format(value: number, formatTemplate: string | null): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return numeral(value).format(formatTemplate || getFormatDataField(DefaultFormat.Decimal)?.format!);
  }
}

export class DateFormatter implements ValueFormatter<string | number> {
  applicableFor(value: ApiTypeValue, formatTemplate: string | null): boolean {
    if (formatTemplate == null || formatTemplate.length == 0) return false;
    if (value instanceof Date) return true;
    if (typeof value !== 'string') return false;
    return !isNaN(Date.parse(value));
  }
  format(value: string | number, formatTemplate: string | null): string {
    if (typeof value == 'string') value = Date.parse(value);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return dateFormat(value, formatTemplate || getFormatDataField(DefaultFormat.DateTime)?.format!, {
      locale: getCurrentLocale(),
    });
  }
}

export class BooleanFormatter implements ValueFormatter<ApiTypeValue> {
  applicableFor(value: ApiTypeValue): boolean {
    return typeof value === 'boolean';
  }
  format(value: ApiTypeValue, formatTemplate: string, translations: { [s: number]: string } | null): string {
    let formateValue = '';

    if (value !== null) {
      if (translations != null && translations[value.toString()]) {
        formateValue = translations[value.toString()];
      } else {
        formateValue = value ? '+' : '-';
      }
    }

    return formateValue;
  }
}

export class StringFormatter implements ValueFormatter<ApiTypeValue> {
  applicableFor(value: ApiTypeValue): boolean {
    return typeof value === 'string';
  }
  format(value: ApiTypeValue, formatTemplate: string, translations: { [s: number]: string } | null): string {
    let formateValue = '';

    if (value !== null) {
      if (translations != null && translations[value.toString()]) {
        formateValue = translations[value.toString()];
      } else {
        formateValue = value.toString();
      }
    }
    return formateValue;
  }
}

export class DefaultFormatter implements ValueFormatter<ApiTypeValue> {
  applicableFor(): boolean {
    return true;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  format(value: ApiTypeValue): string {
    return '';
  }
}
