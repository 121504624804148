import numeral from 'numeral';

numeral.locales['de-de'] = {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ordinal: function (number) {
    return '.';
  },
  customOrdinal: function (number) {
    switch (number) {
      case 1:
        return 'Erste';
      case 2:
        return 'Zweite';
      case 3:
        return 'Dritte';
      case 4:
        return 'Vierte';
      case 5:
        return 'Fünfte';
      default:
        return number + '.';
    }
  },
  ordinalRemoveNumber: true,
  currency: {
    symbol: '€',
  },
};
