<template>
  <div class="content-container container-fluid mb-7">
    <h2><slot name="headline"></slot></h2>
    <ce-sub-page-tiles v-if="isSupPageTiles" :tiles="tiles" />
    <ce-tiles v-else :tiny="isTinyTiles" :tiles="tiles" />
  </div>
</template>
<script lang="ts">
import { TileContent } from '@/src/types/ce-component-types';
import { Component, Inject, Vue } from 'vue-property-decorator';

enum TileTeaserColor {
  PeacockBlue = 'PeacockBlue',
  DeepTurquoise = 'DeepTurquoise',
  Grape = 'Grape',
  Mulberry = 'Mulberry',
}

enum TileTeaserView {
  Tiles = 'Tiles',
  TinyTiles = 'TinyTiles',
  SupPageTiles = 'SupPageTiles',
}

interface Tiles {
  items: Tile[];
  view: TileTeaserView;
}

interface Tile {
  color: TileTeaserColor;
  title: string;
  text: string | null;
  link: string;
}

@Component
export default class AdTileTeasers extends Vue {
  @Inject() settingsProperty!: Tiles;

  private get isSupPageTiles(): boolean {
    return this.settingsProperty.view == TileTeaserView.SupPageTiles;
  }

  private get isTinyTiles(): boolean {
    return this.settingsProperty.view == TileTeaserView.TinyTiles;
  }

  private get tiles(): TileContent[] {
    return this.settingsProperty.items.map((t) => {
      return {
        color: this.mapTileColor(t.color),
        title: t.title,
        text: t.text,
        link: t.link,
      } as TileContent;
    });
  }

  private mapTileColor(color: TileTeaserColor): string {
    switch (color) {
      case TileTeaserColor.PeacockBlue:
        return 'peacock-blue';
      case TileTeaserColor.DeepTurquoise:
        return 'deep-turquoise';
      case TileTeaserColor.Grape:
        return 'grape';
      case TileTeaserColor.Mulberry:
        return 'mulberry';
    }
  }
}
</script>
