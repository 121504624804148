<template>
  <ad-info-block
    v-if="iconVisible"
    :showOnMobileFullScreen="true"
    :placement="placement"
    :fallbackPlacement="fallbackPlacement"
  >
    <span>{{ popoverText }}</span>
  </ad-info-block>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { KnockoutTimeIndicativeInfo, PopoverFallback, PopoverPosition } from '@/src/types/enumerations';

@Component
export default class AdUnderlyingRelatedInfoPopup extends Vue {
  @Prop() underlyings!: UnderlyingModel[] | null;

  get placement(): PopoverPosition {
    return PopoverPosition.Right;
  }

  get fallbackPlacement(): PopoverFallback {
    return PopoverFallback.Flip;
  }

  get popoverText(): string | undefined {
    const indicativeSlot = this.$slots['ko-time-in-cet-is-indicative'];
    const indicativeText = indicativeSlot && indicativeSlot.length ? indicativeSlot[0].text?.trim() : undefined;
    const auctionSlot = this.$slots['closing-auction-may-also-lead-to-ko'];
    const auctionText = auctionSlot && auctionSlot.length ? auctionSlot[0].text?.trim() : undefined;
    return this.showKoTimeInCetIsIndicative ? indicativeText : auctionText;
  }

  get iconVisible(): boolean {
    const defaultUnderlying = this.underlyings?.[0];
    const notNull = defaultUnderlying?.koTimeIndicativeInfo !== null;
    const textPresent = this.popoverText !== undefined;
    return notNull && textPresent;
  }

  get showKoTimeInCetIsIndicative(): boolean {
    const defaultUnderlying = this.underlyings?.[0];
    return defaultUnderlying?.koTimeIndicativeInfo === KnockoutTimeIndicativeInfo.KoTimeInCetIsIndicative;
  }
}
</script>
