<template>
  <ce-chapter-select :chapters="chapters" :activeChapter="activeChapter" class="mt-6 mt-lg-8">
    <template #heading><slot name="headline"></slot></template>
    <template #you-are-here><slot name="you-are-here"></slot></template>
  </ce-chapter-select>
</template>

<script lang="ts">
import { Component, Inject, Vue } from 'vue-property-decorator';
import { EducationContentTreeBlockSettings } from '@/src/types/episerver-api';
import { ChapterNavigationChapter } from '@/src/types/ce-component-types';

@Component
export default class AdEducationContentTree extends Vue {
  @Inject() settingsProperty!: EducationContentTreeBlockSettings;

  get chapters(): ChapterNavigationChapter[] {
    return this.settingsProperty.chapters.map(
      (chapter) =>
        ({
          identifier: chapter.contentId.toString(),
          title: chapter.name ?? chapter.navigationName,
          url: chapter.url,
        }) as ChapterNavigationChapter
    );
  }
  get activeChapter(): ChapterNavigationChapter | null {
    if (this.settingsProperty.activeChapter == null) return null;

    const activeChapter = this.settingsProperty.activeChapter;

    return {
      identifier: activeChapter.contentId.toString(),
      title: activeChapter.name ?? activeChapter.navigationName,
      url: activeChapter.url,
    } as ChapterNavigationChapter;
  }
}
</script>
