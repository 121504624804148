<template>
  <b-row class="no-gutters hr" v-if="result">
    <ad-suggest-dropdown-column
      cols="12"
      :md="result.hasBothItemsGroups ? 6 : 12"
      v-if="result.hasTheqItems"
      :currentIndex="currentIndex"
      :groups="result.theqItems"
      @updateCurrentIndex="updateCurrentIndex"
    />
    <ad-suggest-dropdown-column
      cols="12"
      :md="result.hasBothItemsGroups ? 6 : 12"
      v-if="result.hasEpiItems"
      :class="{ 'bl-md': result.hasTheqItems, 'pl-md-3': result.hasTheqItems }"
      :currentIndex="currentIndex"
      :groups="result.epiItems"
      @updateCurrentIndex="updateCurrentIndex"
    />
  </b-row>
</template>
<script lang="ts">
import { GlobalSearchSuggestions } from '@/src/types/vue-api';
import AdSuggestDropdownColumn from './ad-suggest-dropdown-column.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    AdSuggestDropdownColumn,
  },
})
export default class AdSuggestSearchDropdown extends Vue {
  @Prop({ required: true, default: { name: 'unknown', data: [] } }) section!: { data: GlobalSearchSuggestions };
  @Prop({ type: [Number, String], required: false, default: Infinity }) currentIndex;

  get result() {
    return this.section.data;
  }

  getItemByIndex(itemIndex: number) {
    return itemIndex < this.section.data.length ? this.section.data.allItems[itemIndex] : undefined;
  }

  updateCurrentIndex(index) {
    this.$emit('updateCurrentIndex', index);
  }
}
</script>
