import numeral from 'numeral';
import locales from 'numeral/locales';
import '@/src/utils/value-formatter/custom-locales';
import Vue from 'vue';
import { getCurrentCulture, getCurrentWebsiteKey } from '../culture-helper';
const defaultCulture = 'en';

try {
  const allLocales = numeral.locales;
  let current = getCurrentCulture().toLowerCase();
  if (allLocales[current] == undefined) {
    current = getCurrentWebsiteKey().toLowerCase();
  }
  if (allLocales[current] == undefined) {
    throw `Could not find cultures configurations '${getCurrentWebsiteKey().toLowerCase()}' or '${getCurrentCulture().toLowerCase()}'`;
  }
  numeral.locale(current);
  Vue.$log.debug('Number format', current, numeral(1000).format('0,0.00'));
} catch (e) {
  Vue.$log.fatal('Number format region could not be set:', e);
  numeral.locale(defaultCulture, locales);
}
