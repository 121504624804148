<template>
  <div class="ce-glossary-content mt-6">
    <div class="ce-glossary-content--top-navigation">
      <button
        v-for="letter in letters"
        :key="letter"
        class="ce-glossary-content--top-navigation-letter"
        :disabled="!entriesByLetter[letter] || entriesByLetter[letter].length === 0"
        @click="scrollToLetter(letter)"
      >
        {{ letter.toUpperCase() }}
      </button>
    </div>
    <ce-glossary-mobile-section-navigation-button
      :entriesByLetter="entriesByLetter"
      :letters="letters"
      @scrollToLetter="scrollToLetter"
    >
      <slot name="mobile-a-z-label">A-Z</slot>
    </ce-glossary-mobile-section-navigation-button>
    <div class="pt-7" v-for="letter in lettersWithWords" :key="letter" :data-glossary-letter="letter">
      <div class="d-none d-md-block">
        <ce-glossary-desktop-section-letter-selector
          :entriesByLetter="entriesByLetter"
          :currentLetter="letter"
          :letters="letters"
          @scrollToLetter="scrollToLetter"
        >
        </ce-glossary-desktop-section-letter-selector>
      </div>
      <div class="ce-glossary-content--mobile-letter-heading d-md-none">{{ letter.toUpperCase() }}</div>
      <div class="pb-4"></div>
      <div
        v-for="(entry, index) in entriesByLetter[letter]"
        :key="index"
        class="ce-glossary-content--word p-0 pt-4 pb-4 col-12 col-lg-7"
      >
        <h3>{{ entry.word }}</h3>
        <p>{{ entry.definition }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { GlossaryEntry } from '@/src/types/ce-component-types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CeGlossaryContent extends Vue {
  @Prop({ default: [] }) entries!: GlossaryEntry[];
  letters = 'abcdefghijklmnopqrstuvwxyz'.split('');

  get entriesByLetter() {
    const entriesByLetter: Record<string, GlossaryEntry[]> = {};

    for (const entry of this.entries) {
      const letter = entry.word.toLowerCase()[0];
      if (!entriesByLetter[letter]) entriesByLetter[letter] = [];
      entriesByLetter[letter].push(entry);
    }
    return entriesByLetter;
  }

  get lettersWithWords() {
    return this.letters.filter((letter) => !!this.entriesByLetter[letter] && this.entriesByLetter[letter].length > 0);
  }

  scrollToLetter(letter: string) {
    const section = document.querySelector(`[data-glossary-letter="${letter}"]`);

    if (!section) return;

    section.scrollIntoView({ behavior: 'smooth' });
  }
}
</script>

<style lang="scss">
.ce-glossary-content {
  &--word {
    border-top: 1px dashed $peacock-blue;

    p {
      margin: 0;
    }
  }

  &--top-navigation-letter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    margin-bottom: 1px;
    border: 0;

    background: $light-grey;
    width: rem(48);
    height: rem(56);
    color: $peacock-blue;
    font-size: rem(28);

    &:disabled {
      color: $cool-grey;
    }

    &:hover:not(:disabled) {
      background: $peacock-blue;
      color: $white;
    }

    @include media-breakpoint-up('md') {
      margin-right: map-get($spacers, 4);
      margin-bottom: map-get($spacers, 4);
      border-radius: 50%;
      width: rem(56);
    }
  }

  &--mobile-letter-heading {
    color: $marine-blue;
    font-size: rem(42);
  }
}
</style>
