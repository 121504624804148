<template>
  <!-- Component has no Template -->
  <span @subscribe-to-push="subscribePushValue" />
</template>
<script lang="ts">
import Vue from 'vue';
import { ProductModel } from '@/src/types/the-q-api';
import { formatField } from '@/src/utils/value-formatter/formatting-service';
import { Component, Prop, Inject, Watch } from 'vue-property-decorator';
import { InstrumentDataFieldBase } from '@/src/types/episerver-api-instrument';
import { InstrumentFieldKeys, ProductDetailBlockSetting } from '@/src/types/episerver-api';
import { Action } from 'vuex-class';
import evaluateExpressionByKey, { getFormatDataField } from '@/src/utils/value-formatter/format-helper';

const CITIFIRST_TEXT = 'CitiFirst\u00A9';
const SYMBOL_CHANGE_UP = '\u25B2';
const SYMBOL_CHANGE_DOWN = '\u25BC';

@Component({})
export default class AdProductPageTitle extends Vue {
  @Inject() settingsProperty?: ProductDetailBlockSetting;
  @Inject({ default: null }) nsinKeys!: InstrumentFieldKeys | null;
  @Prop() productModel!: ProductModel;
  @Prop({ default: false }) hidePrices!: boolean;

  @Action('subscribePushValue', { namespace: 'product' })
  subscribePushValue!: (key: string) => Promise<void>;

  created() {
    this.updateTitle();

    // start push listener
    this.subscribePushValue('product.price.bid.amount');
    this.subscribePushValue('product.price.ask.amount');
  }

  private getNSINValue(): string | undefined {
    if (!this.settingsProperty) return;
    if (!this.nsinKeys?.productKey) return;

    const expression = (getFormatDataField(this.nsinKeys.productKey) as InstrumentDataFieldBase)?.valueExpression;
    if (!expression) {
      return;
    }
    const productNsinValue = evaluateExpressionByKey(expression, { product: this.productModel });
    const productNsin = formatField(productNsinValue, this.nsinKeys.productKey);
    return productNsin;
  }

  private getPriceData(): string | undefined {
    if (!this.productModel.price) {
      // hide if no price information available
      return;
    }

    const ask = formatField(this.productModel.price?.ask?.amount, 'product.price.ask.amount');
    const bid = formatField(this.productModel.price?.bid?.amount, 'product.price.bid.amount');

    const changeAbsolute = this.productModel.price?.changeAbsolute?.amount;

    let changeIndicator = '';

    // no change indicator if change is 0
    if (changeAbsolute && !isNaN(changeAbsolute) && Math.abs(changeAbsolute) > 0.0) {
      changeIndicator = changeAbsolute > 0 ? SYMBOL_CHANGE_UP : SYMBOL_CHANGE_DOWN;
    }

    return `${bid} / ${ask} ${changeIndicator}`;
  }

  @Watch('productModel', { deep: true })
  private updateTitle(): void {
    if (this.productModel) {
      const nsin = this.getNSINValue();
      const price = this.hidePrices ? null : this.getPriceData();
      const productName = formatField(
        this.productModel.productNameTranslation?.productNameFirstLine ?? null,
        'productNameTranslation.productNameFirstLine',
        this.productModel
      );

      const titleString = [price, nsin, productName, CITIFIRST_TEXT]
        .filter((val: string | null | undefined): boolean => !!val)
        .join(' - ');

      document.title = titleString;
    }
  }
}
</script>
