<template>
  <div :class="headerSearchClass">
    <!-- mobile only search flyout -->
    <div class="ce-header-search-flyout">
      <b-navbar-toggle class="ce-header-search-flyout__toggler" target="header-search-flyout"
        ><ad-icon-search></ad-icon-search><span class="sr-only">Search</span></b-navbar-toggle
      >
      <b-collapse
        accordion="header"
        id="header-search-flyout"
        class="ce-header-flyout ce-header-search-flyout__flyout"
        is-nav
      >
        <!-- @todo adesso: placeholder for search functionality - START -->
        <b-navbar-nav class="ce-header-nav-main ce-header-search-flyout__background px-3 py-3">
          <b-input-group :class="globalSearchFlyoutInputClass">
            <b-input-group-append class="ce-header-quicksearch__icon fs-18 py-0">
              <ad-icon-search></ad-icon-search>
            </b-input-group-append>
            <ad-global-search @changeValidationState="changeGlobalSearchFlyoutInputValidationState">
              <template #error-message><slot name="global-search-error-message"></slot></template
            ></ad-global-search>
          </b-input-group>
        </b-navbar-nav>

        <slot name="best-match"> </slot>
        <!-- @todo adesso: placeholder for search functionality - END -->
      </b-collapse>
    </div>

    <b-dropdown v-if="bestMatchEnabled" variant="match" class="ce-header-bestmatch bv-d-lg-down-none py-1">
      <template v-slot:button-content
        ><ad-icon-best-match></ad-icon-best-match
        ><span class="fs-16"><slot name="find-best-match-dropdown-button-text">Find Best Match</slot></span></template
      >
      <b-dropdown-form form-class="ce-header-bestmatch-form">
        <!-- @todo adesso: placeholder for search functionality - START -->
        <slot name="best-match"> </slot>
        <!-- @todo adesso: placeholder for search functionality - END -->
      </b-dropdown-form>
    </b-dropdown>

    <div :class="globalSearchInputClass">
      <!-- @todo adesso: placeholder for search form functionality - START -->
      <b-input-group class="py-0 align-items-center">
        <b-input-group-append class="ce-header-quicksearch__icon fs-18 py-0">
          <ad-icon-search></ad-icon-search>
        </b-input-group-append>
        <ad-global-search @changeValidationState="changeGlobalSearchInputValidationState"
          ><template #error-message><slot name="global-search-error-message"></slot></template
        ></ad-global-search>
      </b-input-group>
      <!-- @todo adesso: placeholder for search form functionality - END -->
    </div>
    <b-link variant="link" v-if="pageRoutes.watchlistUrl" @click="watchlistClicked" class="ce-header-search-watchlist">
      <span class="sr-only"><slot name="watchist-tooltip">Entries on watchlist</slot></span>
      <ad-icon-watchlist height="100%" width="2.3125em">
        <template #title>
          <slot name="watchist-tooltip">Entries on watchlist</slot>
        </template>
      </ad-icon-watchlist>
      <b-badge variant="pink" v-if="watchlistCount && cookieComfortAccepted">{{ watchlistCount }}</b-badge>
    </b-link>
    <b-link
      :href="youtubeChannelUrl"
      :title="youtubeTooltip"
      target="_blank"
      class="youtube-link"
      v-if="youtubeChannelUrlExist"
    >
      <img class="logo" src="~@assets/yt_icon_rgb.png" alt="YouTube Logo" />
    </b-link>
  </div>
</template>

<script lang="ts">
import { PageRoutes } from '@/src/types/episerver-api';
import CookieManager from '@/src/utils/cookie';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component
export default class CeHeaderSearch extends Vue {
  @Prop({ default: false, type: Boolean }) private bestMatchEnabled!: boolean;

  @Getter('totalCount', { namespace: 'watchlist' })
  watchlistCount!: number;

  @Action('fetchActiveAsync', { namespace: 'watchlist' })
  fetchWatchlistAsync!: () => Promise<void>;

  @Inject() pageRoutes!: PageRoutes;

  private cookieManager!: CookieManager;
  private isGlobalSearchInputValid: boolean = true;
  private isGlobalSearchFlyoutInputValid: boolean = true;

  get cookieComfortAccepted(): boolean {
    return this.cookieManager?.comfort || false;
  }

  get headerSearchClass(): (string | Record<string, boolean>)[] {
    return [
      'ce-header-search d-flex align-items-center',
      { 'show-invalid': !(this.isGlobalSearchInputValid && this.isGlobalSearchFlyoutInputValid) },
    ];
  }

  get globalSearchInputClass(): (string | Record<string, boolean>)[] {
    return ['ce-header-quicksearch bv-d-lg-down-none py-0', { 'in-valid': !this.isGlobalSearchInputValid }];
  }

  get globalSearchFlyoutInputClass(): (string | Record<string, boolean>)[] {
    return [
      'ce-header-quicksearch__input-group py-0 align-items-center',
      { 'in-valid': !this.isGlobalSearchFlyoutInputValid },
    ];
  }

  created() {
    this.fetchWatchlistAsync();
    this.cookieManager = new CookieManager(this.$cookies);
  }

  private watchlistClicked() {
    if (this.pageRoutes.watchlistUrl) {
      window.location.href = this.pageRoutes.watchlistUrl;
    }
  }

  private get youtubeChannelUrlExist() {
    const slot = this.$slots['youtube-channel-url'];
    return slot && slot[0] && typeof slot[0].text === 'string';
  }

  private get youtubeChannelUrl() {
    return this.$slots['youtube-channel-url']
      ? this.$slots['youtube-channel-url'][0].text?.trim()
      : this.$props.youtubeChannelUrl.trim();
  }

  private get youtubeTooltip() {
    return this.$slots['youtube-tooltip'] ? this.$slots['youtube-tooltip'][0].text?.trim() : '';
  }
  private changeGlobalSearchInputValidationState(isValid: boolean) {
    this.isGlobalSearchInputValid = isValid;
  }
  private changeGlobalSearchFlyoutInputValidationState(isValid: boolean) {
    this.isGlobalSearchFlyoutInputValid = isValid;
  }
}
</script>

<style lang="scss">
.ce-header-search-flyout {
  &__toggler.navbar-toggler.navbar-toggler {
    border-color: transparent;
    color: $heading-line-color;

    &.not-collapsed.not-collapsed {
      color: $link-color;
    }
  }
  &__flyout.navbar-collapse {
    @include media-breakpoint-down('lg') {
      background: $light-grey;
    }
  }
  &__background {
    background: $light-grey;
  }

  @include media-breakpoint-up('xl') {
    display: none;
  }
}
.sticky .ce-header-search-flyout {
  &__toggler.navbar-toggler {
    @include media-breakpoint-down('lg') {
      color: $black;
    }
  }
}
.ce-header-bestmatch {
  svg {
    margin-right: rem(8);
  }

  .dropdown-toggle {
    border-color: $header-highlight-color;
    background-color: $header-highlight-color;
  }

  .dropdown-menu {
    margin: 0;
    border-width: 0;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    min-width: 410px;
  }

  button {
    // Important required to overwrite .btn class to match spacings of design
    padding: 0.25rem 1rem !important;
  }
}

/* CSS class for YouTube link */
.youtube-link {
  display: inline-block;
}

.youtube-link img {
  margin-left: 16px;
  height: 36px;
}

.ce-header-bestmatch-form {
  padding: 0;
}

.ce-header-quicksearch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: map-get($spacers, 4);
  border: 1px solid $cool-grey;
  border-radius: $border-radius;
  width: 300px;
  &:focus-within {
    background: $light-grey;
  }
  &.is-invalid {
    border-color: $deep-red;
  }

  &__icon {
    padding: rem(9) map-get($spacers, 2) rem(9) map-get($spacers, 3);
    color: $clarinet;
  }

  &__input.form-control.form-control.form-control {
    border-color: transparent;
    background-color: transparent;
    height: 20px;
    &::placeholder {
      color: $gunmetal;
    }
  }

  & .input-group {
    flex-wrap: nowrap;
  }

  &__input-group.input-group {
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid $cool-grey;
    border-radius: $border-radius;
    background: $white;
    padding: 5px 0;
    &:focus-within {
      background: $light-grey;
    }
  }

  &__input {
    outline: none;
    border: 0;
    background: transparent;
    padding: 5.75px;
    padding-left: 0;
    width: 100%;
  }
}

.show-invalid .ce-header-quicksearch {
  &__input-group.input-group.in-valid {
    border-color: $deep-red;
  }
}

.ce-header-search {
  .v-select {
    flex: 1 1 0;
  }
}

.sticky .ce-header-search-watchlist {
  @include media-breakpoint-down('lg') {
    &,
    &:focus,
    &:hover {
      color: $black;
    }
  }
}

.ce-header-search-watchlist {
  display: block;
  position: relative;
  margin: 0;
  padding: rem(2) map-get($spacers, 2);
  color: $black;

  &:focus,
  &:hover {
    color: $black;
  }

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background: $pink;
    padding: 0 0.3rem;
    line-height: rem(22);
    color: $white;
  }

  @include media-breakpoint-down('lg') {
    margin-top: -5px;
  }

  @include media-breakpoint-up('xl') {
    margin-left: map-get($spacers, 2);
    color: $black;

    &:hover {
      color: $black;
    }

    &:focus {
      color: $black;
    }
  }
}
</style>
