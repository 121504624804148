<template>
  <ce-blog-article-overview :articles="articles">
    <template #read-more>
      <slot name="read-more">Read more</slot>
    </template>
    <template #heading>
      <slot name="heading"></slot>
    </template>
  </ce-blog-article-overview>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BlogPageSettings } from '@/src/types/episerver-api';
import { BlogArticleOverviewBlogArticle, ImageData } from '@/src/types/ce-component-types';
import { getImageResizerUrl } from '@/src/utils/url-helper';

@Component({})
export default class AdArticleOverviewTeaserContainer extends Vue {
  @Prop({ required: true }) items!: Array<BlogPageSettings>;

  isLoading = false;
  canLoadMore = false;
  additionalContent = '';
  contentItemsCount = 0;

  get articles(): BlogArticleOverviewBlogArticle[] {
    return this.items.map((i) => {
      return {
        title: i.headline,
        teaser: i.previewText,
        date: i.publishedDate,
        image: {
          src: getImageResizerUrl(i.imageUrlWithSizeParameter, 500, 500),
          alt: '',
          sources: [
            {
              Media: '(min-width:650px)',
              SrcSet: getImageResizerUrl(i.imageUrlWithSizeParameter, 1920, 1080),
            },
            {
              Media: '(min-width:465px)',
              SrcSet: getImageResizerUrl(i.imageUrlWithSizeParameter, 316, 185),
            },
          ],
        } as ImageData,
        author: i.author,
        likeId: i.contentId,
        link: i.url,
      } as BlogArticleOverviewBlogArticle;
    });
  }
}
</script>
