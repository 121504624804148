<template>
  <ad-collapse-on-mobile showCollapsed v-if="rows">
    <template #header>
      <slot name="block-header"></slot>
    </template>
    <ad-simple-table :headers="headers" :rows="rows" />
    <b-button class="mt-3 w-sm-100" variant="light" @click="excelExport">
      <ad-icon-xls /><slot name="button-excel-export"></slot>
    </b-button>
  </ad-collapse-on-mobile>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import { RollingHistoryModel } from '@/src/types/the-q-api';
import { Component } from 'vue-property-decorator';
import AdProductHistoryBase from '../ad-product-history-base';
import { getTheQApiUrl } from '@/src/utils/url-helper';
import { Controller } from '@/src/types/enumerations';
import { downloadFilePost } from '@/src/utils/download-file';

@Component({
  computed: {
    ...mapGetters('product', ['product']),
  },
})
export default class AdProductRollingHistory extends AdProductHistoryBase {
  getData(): RollingHistoryModel[] | null | undefined {
    return this.product?.rollingHistory;
  }
  excelExport(): void {
    const url = getTheQApiUrl(Controller.RollingHistory, 'Excel');
    downloadFilePost(url, this.buildExcelRequest(), 'RollingHistory.xlsx');
  }
}
</script>
