<template>
  <a :href="settingsProperty.linkToShortNewsDetailPage + '#' + contentId" class="ce-ad-short-news-item-teaser__item">
    <div>
      <div class="ce-ad-short-news-item-teaser__date fs-16">{{ publicationDate | formatField(dateFormat) }}</div>
      <div><slot name="headline"></slot></div>
    </div>
    <ad-icon-chevron height="0.89em" class="ce-ad-short-news-item-teaser__icon"></ad-icon-chevron>
  </a>
</template>

<script lang="ts">
import { DefaultFormat } from '@/src/types/enumerations';
import { ShortNewsTeaserSettings } from '@/src/types/episerver-api';
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';

@Component
export default class CeAdShortNewsItemTeaser extends Vue {
  @Prop({ type: String }) contentId!: string;
  @Prop({ type: String }) publicationDateTimestamp!: string;

  dateFormat = DefaultFormat.Date;

  get publicationDate(): Date {
    return new Date(Number.parseInt(this.publicationDateTimestamp));
  }

  @Inject() settingsProperty!: ShortNewsTeaserSettings;
}
</script>

<style lang="scss">
.ce-ad-short-news-item-teaser {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px dashed $peacock-blue;
    padding: map-get($spacers, 2);
    padding-left: 0;
    color: $marine-blue;
  }
  &__date {
    color: $peacock-blue;
  }
  &__icon {
    transform: rotate(270deg);
    color: $clarinet;
  }
}
</style>
