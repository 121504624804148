<template>
  <div v-bind="$attrs" v-b-visible="isVisible" v-if="show"><slot></slot></div>
  <div v-else v-bind="$attrs"><slot></slot></div>
</template>

<script lang="ts">
import { TableField } from '@/src/types/vue-api';
import { pushTriggerFrequenz } from '@/src/state/plugins/light-streamer/pushing-helper';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class AdColumnVisibleWrapper extends Vue {
  @Prop({ required: true }) fields!: Array<TableField>;
  @Prop({ required: true }) index!: number;
  @Prop({ required: true }) field!: TableField;

  get show() {
    return (
      this.index % pushTriggerFrequenz === 0 &&
      this.fields.length > 0 &&
      this.fields[this.fields.length - 1].key == this.field.key
    );
  }

  isVisible(isVisible) {
    this.$emit('visibleChange', isVisible, this.index);
  }
}
</script>
