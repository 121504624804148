import { Option, OptionGroup } from '@src/types/the-q-api';
import { DropDownItemType } from '@/src/types/enumerations';
import { DropDownItem } from '../types/vue-api';

export function createAllOption(result: DropDownItem[], label: string): void {
  result.unshift({ type: DropDownItemType.item, text: label, value: null } as DropDownItem);
}

function createOption(option: Option): DropDownItem {
  return {
    type: DropDownItemType.item,
    text: option.text,
    value: option.value,
  } as DropDownItem;
}

function createHeader(text: string, index: number): DropDownItem {
  return {
    type: DropDownItemType.header,
    text: text,
    value: DropDownItemType.header + index,
  } as DropDownItem;
}

function createSplitter(index: number): DropDownItem {
  return {
    type: DropDownItemType.splitter,
    text: null,
    value: DropDownItemType.splitter + index,
  } as DropDownItem;
}

function trimLastSplitter(result: DropDownItem[]): void {
  if (result.length !== 0 && result[result.length - 1].type === DropDownItemType.splitter) result.pop();
}

export function convertOptions(
  options: Option[] | { (): Array<DropDownItem> },
  allLabel: string | null = null
): DropDownItem[] {
  const result: DropDownItem[] = typeof options === 'function' ? options() : options.map((o) => createOption(o));

  if (allLabel) createAllOption(result, allLabel);
  return result;
}

function createGroup(group: OptionGroup, index: number): DropDownItem[] {
  if (!group.options || group.options.length == 0) return [];
  if (!group.header) return convertOptions(group.options);

  let result: DropDownItem[] = [];

  result.push(createHeader(group.header, index));
  result = result.concat(group.options.map((o) => createOption(o)));
  result.push(createSplitter(index));

  return result;
}

export function convertGroups(groups: OptionGroup[], allLabel: string | null = null): DropDownItem[] {
  let result: DropDownItem[] = [];
  groups.forEach((g, i) => {
    result = result.concat(createGroup(g, i));
  });
  trimLastSplitter(result);
  if (allLabel) {
    result.unshift({ type: DropDownItemType.splitter } as DropDownItem);
    createAllOption(result, allLabel);
  }
  return result;
}

export function convertToDropdownItems(
  allLabel: string | null,
  entries: string[],
  formatTextFunc: (v: string) => string = (x) => x
): DropDownItem[] {
  const result = entries
    ? entries.map((x) => ({ type: DropDownItemType.item, text: formatTextFunc(x), value: x }) as DropDownItem)
    : [];

  if (allLabel) createAllOption(result, allLabel);

  return result;
}
