<template>
  <div>
    <div class="row mb-5 mb-md-7">
      <div v-if="first" class="col-12 col-md-6 mb-5 mb-md-0">
        <div class="row d-flex">
          <div class="col-12 mb-3 mb-md-5">
            <a :href="first.link" class="aspect-ratio-16-9 w-100 d-block">
              <picture>
                <source
                  v-for="(source, i) in first.image.sources"
                  v-bind:key="i"
                  :media="source.media"
                  :srcset="source.srcset"
                />
                <img class="aspect-ratio-filler zoom-animated-image" :src="first.image.src" :alt="first.image.alt" />
              </picture>
            </a>
          </div>
          <div class="col-0 col-md-2" />
          <div class="col-12 col-md-10">
            <div class="w-100 fs-16 ce-blog-article-overview__date">{{ first.date | formatField(dateFormatKey) }}</div>
            <div class="w-100">
              <h3>
                <a :href="first.link"><ad-template-renderer :text="first.title"></ad-template-renderer></a>
              </h3>
            </div>
            <div class="w-100 fs-16 mb-3 ce-blog-article-overview__text">
              {{ first.teaser }}
            </div>
            <div class="ce-blog-article-overview__author fs-16">
              {{ first.author }}
              <span class="ce-blog-article-overview__like fs-14 ml-3">
                <ad-like-count :content-id="first.likeId" :show-count-on-left="true"></ad-like-count>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-0 col-md-1"></div>
      <div v-if="second" class="col-12 col-md-5">
        <div class="row">
          <div class="col-12 mb-3 mb-md-5">
            <a href="#" class="aspect-ratio-16-9 w-100 d-block">
              <picture>
                <source
                  v-for="(source, i) in second.image.sources"
                  v-bind:key="i"
                  :media="source.media"
                  :srcset="source.srcset"
                />
                <img class="aspect-ratio-filler zoom-animated-image" :src="second.image.src" :alt="second.image.alt" />
              </picture>
            </a>
          </div>
          <div class="col-12 col-md-10">
            <div class="w-100 fs-16 ce-blog-article-overview__date">{{ second.date | formatField(dateFormatKey) }}</div>
            <div class="w-100">
              <h3>
                <a :href="second.link"><ad-template-renderer :text="second.title"></ad-template-renderer></a>
              </h3>
            </div>
            <div class="w-100 fs-16 mb-3 ce-blog-article-overview__text">{{ second.teaser }} </div>
            <div class="ce-blog-article-overview__author fs-16">
              {{ second.author }}
              <span class="ce-blog-article-overview__like fs-14 ml-3">
                <ad-like-count :content-id="second.likeId" :show-count-on-left="true"></ad-like-count>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex mb-5 mb-md-7">
      <div v-if="third" class="col-12 col-md-6 mb-5 mb-md-0">
        <div class="aspect-ratio-16-9 mb-3 mb-md-5">
          <div class="row aspect-ratio-filler">
            <div class="col-12 col-md-10 px-0 d-flex align-items-end">
              <a :href="third.link" class="aspect-ratio-16-9 d-block">
                <picture>
                  <source
                    v-for="(source, i) in third.image.sources"
                    v-bind:key="i"
                    :media="source.media"
                    :srcset="source.srcset"
                  />
                  <img class="aspect-ratio-filler zoom-animated-image" :src="third.image.src" :alt="third.image.alt" />
                </picture>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="w-100 fs-16 ce-blog-article-overview__date">
              {{ third.date | formatField(dateFormatKey) }}
            </div>
            <div class="w-100">
              <h3>
                <a :href="third.link"><ad-template-renderer :text="third.title"></ad-template-renderer></a>
              </h3>
            </div>
            <div class="w-100 fs-16 mb-3 ce-blog-article-overview__text">
              {{ third.teaser }}
            </div>
            <div class="ce-blog-article-overview__author fs-16">
              {{ third.author }}
              <span class="ce-blog-article-overview__like fs-14 ml-3">
                <ad-like-count :content-id="third.likeId" :show-count-on-left="true"></ad-like-count>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="fourth" class="col-12 col-md-6 pb-0 pb-md-6">
        <div class="row d-flex">
          <div class="col-12 mb-3 mb-md-5">
            <a :href="fourth.link" class="aspect-ratio-16-9 w-100 d-block">
              <picture>
                <source
                  v-for="(source, i) in fourth.image.sources"
                  v-bind:key="i"
                  :media="source.media"
                  :srcset="source.srcset"
                />
                <img class="aspect-ratio-filler zoom-animated-image" :src="fourth.image.src" :alt="fourth.image.alt" />
              </picture>
            </a>
          </div>
          <div class="col-0 col-md-2" />
          <div class="col-12 col-md-10">
            <div class="w-100 fs-16 ce-blog-article-overview__date">
              {{ fourth.date | formatField(dateFormatKey) }}</div
            >
            <div class="w-100">
              <h3>
                <a :href="fourth.link"><ad-template-renderer :text="fourth.title"></ad-template-renderer></a>
              </h3>
            </div>
            <div class="w-100 fs-16 mb-3 ce-blog-article-overview__text">
              {{ fourth.teaser }}
            </div>
            <div class="ce-blog-article-overview__author fs-16">
              {{ fourth.author }}
              <span class="ce-blog-article-overview__like fs-14 ml-3">
                <ad-like-count :content-id="fourth.likeId" :show-count-on-left="true"></ad-like-count>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="fifth"
      class="row d-flex flex-column-reverse flex-lg-row align-items-center mb-5 mb-lg-7"
      :class="{ 'flex-lg-row-reverse': fifthChunkReversed }"
    >
      <div class="col-12 col-lg-4">
        <div class="w-100 fs-16 ce-blog-article-overview__date"> {{ fifth.date | formatField(dateFormatKey) }}</div>
        <div class="w-100 pb-0 pb-lg-2">
          <h3>
            <a :href="fifth.link">
              <ad-template-renderer :text="fifth.title"></ad-template-renderer>
            </a>
          </h3>
        </div>
        <div class="w-100 fs-16 mb-3 ce-blog-article-overview__text">
          {{ fifth.teaser }}
        </div>
        <div class="ce-blog-article-overview__author fs-16">
          {{ fifth.author }}
          <span class="ce-blog-article-overview__like fs-14 ml-3">
            <button class="ce-author-like__button text-nowrap px-0" @click="likeArticle(fifth)">
              <ad-icon-like height="1.71em"></ad-icon-like>
            </button>
            {{ fifth.likes }}
          </span>
        </div>
      </div>
      <div class="col-12 col-lg-8 mb-3 mb-lg-0">
        <a :href="fifth.link" class="aspect-ratio-16-9 w-100 d-block">
          <picture>
            <source
              v-for="(source, i) in fifth.image.sources"
              v-bind:key="i"
              :media="source.media"
              :srcset="source.srcset"
            />
            <img class="aspect-ratio-filler zoom-animated-image" :src="fifth.image.src" :alt="fifth.image.alt" />
          </picture>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Inject } from 'vue-property-decorator';
import Vue from 'vue';
import { BlogArticleOverviewBlogArticle } from '@/src/types/ce-component-types';
import { CommonFormatsFieldKeys } from '@/src/types/episerver-api';

/**
 * Renders a chunk of 5 blog overview articles in a specific layout
 */
@Component
export default class CeBlogArticleOverviewArticleChunk extends Vue {
  @Inject({ default: null }) commonFormatsKeys!: CommonFormatsFieldKeys | null;
  @Prop({ required: true }) articles!: BlogArticleOverviewBlogArticle[];

  /**
   * Indicates the index of this chunk in the list of all chunks
   * Is required to decide if the fifth block of this chunk should be reversed or not.
   */
  @Prop({ required: true }) chunkIndex!: number;

  get first() {
    return this.articles[0];
  }
  get second() {
    return this.articles[1];
  }
  get third() {
    return this.articles[2];
  }
  get fourth() {
    return this.articles[3];
  }
  get fifth() {
    return this.articles[4];
  }

  get fifthChunkReversed() {
    return this.chunkIndex % 2 !== 0;
  }

  get dateFormatKey(): string {
    return this.commonFormatsKeys?.publishDateFormatFieldKey || 'date';
  }

  @Emit('likeArticle')
  likeArticle(article: BlogArticleOverviewBlogArticle) {
    return article;
  }
}
</script>
