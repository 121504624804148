var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.options && _vm.options.length == 1)?_c('div',[_c('div',{staticClass:"fs-14"},[_vm._v(_vm._s(_vm.options[0].translation))])]):(_vm.options)?_c('div',{staticClass:"mb-2 mb-lg-3"},[_c('b-form-radio-group',_vm._b({class:[
      'd-none',
      { 'd-xl-block': !_vm.chartConfigurationProperty.forceDropDownForChartPeriods },
      { 'd-md-block': _vm.chartConfigurationProperty.forceRadioButtonsForChartPeriods },
      'text-nowrap',
    ],attrs:{"id":"btn-radios-periods","name":"radios-btn-periods","size":"sm","button-variant":"default","checked":_vm.value,"options":_vm.options,"buttons":""},on:{"input":_vm.input}},'b-form-radio-group',_vm.$attrs,false)),_c('b-form-group',{class:[
      'focus-white',
      { 'd-xl-none': !_vm.chartConfigurationProperty.forceDropDownForChartPeriods },
      { 'd-md-none': _vm.chartConfigurationProperty.forceRadioButtonsForChartPeriods },
      'mb-0',
    ],attrs:{"id":'input-group-periods' + _vm._uid,"label-for":"periods-input"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("header")]},proxy:true}],null,true)},[_c('ad-drop-down-list',_vm._b({attrs:{"options":_vm.dropdownOptions,"value":_vm.value,"form-element-state-id":'input-group-periods' + _vm._uid},on:{"input":_vm.input}},'ad-drop-down-list',_vm.$attrs,false))],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }