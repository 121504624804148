<template>
  <div
    class="ce-radio-buttons"
    :class="{ 'dropdown-required-on-mobile': dropdownRequired, stretched: stretched !== false }"
  >
    <label v-if="label" class="fs-14 ce-radio-buttons--buttons-label">{{ label }}</label>
    <div class="ce-radio-buttons--buttons" ref="buttonContainer">
      <button
        class="fs-14"
        v-for="(option, i) in getChildNodes()"
        :key="i"
        @click="handleSelect(option.data.attrs.value)"
        :disabled="option.data.attrs.disabled"
        :type="'button'"
        :class="{
          selected: option.data.attrs.value === value,
          'sibling-hovered': hoveredOptionIndex !== -1 && hoveredOptionIndex !== i,
          'last-of-row': lastOfRowIndices.has(i),
          'first-of-row': firstOfRowIndices.has(i),
          'first-of-first-row-with-multiple-rows': firstOfRowIndices.size > 1 && i === 0,
          'last-row': rowIndexByOptionIndex[i] === rowCount - 1,
        }"
        v-on:mouseover="hoveredOptionIndex = i"
        v-on:focus="hoveredOptionIndex = i"
        v-on:mouseleave="hoveredOptionIndex = -1"
        v-on:blur="hoveredOptionIndex = -1"
      >
        <ce-node-renderer :node="option"></ce-node-renderer>
      </button>
    </div>

    <div class="ce-radio-buttons--dropdown">
      <b-form-group
        :id="'ce-radio-button-dropdown-form-group' + _uid"
        :label="label"
        :label-for="'ce-radio-button-dropdown' + _uid"
        class="focus-white"
      >
        <ad-drop-down-list
          :form-element-state-id="'ce-radio-button-dropdown-form-group' + _uid"
          :options="getDropdownOptions()"
          :value="value"
          @input="handleSelect($event)"
          :id="'ce-radio-button-dropdown' + _uid"
        />
      </b-form-group>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import Vue, { VNode } from 'vue';
import { DropDownItem } from '@/src/types/vue-api';
import { DropDownItemType } from '@/src/types/enumerations';
import { filterVNodes } from '@/src/utils/vnodes-filter';

@Component({})
export default class CeRadioButtons extends Vue {
  @Prop({ default: null }) label!: string;
  @Prop({ default: false }) stretched!: boolean;

  @Prop({ default: null }) value: unknown;

  dropdownRequired = false;

  /**
   * Radio buttons are placed in a flexbox that is allowed to wrap on desktop.
   * The indices of each rows first option is placed in the firstOfRowIndices
   */
  firstOfRowIndices = new Set<number>();
  lastOfRowIndices = new Set<number>();
  rowIndexByOptionIndex: { [key: number]: number } = {};
  rowCount = 1;

  hoveredOptionIndex = -1;

  observer = new IntersectionObserver(() => {
    this.handleResize();
  });

  get buttonContainer() {
    return this.$refs.buttonContainer as HTMLDivElement;
  }

  getChildNodes(): VNode[] {
    return filterVNodes(this.$slots.default);
  }

  getDropdownOptions(): DropDownItem[] {
    return (
      this.getChildNodes().map((node) => ({
        value: node.data?.attrs?.value,
        text: this.extractText(node),
        type: DropDownItemType.item,
      })) ?? []
    );
  }

  mounted() {
    window.addEventListener('resize', this.handleResize);
    if (this.buttonContainer.parentElement) {
      this.observer.observe(this.buttonContainer.parentElement);
    }
    this.handleResize();
  }

  updated() {
    this.handleResize();
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    this.observer.disconnect();
  }

  extractText(node: VNode): string {
    return node.children ? node.children.map((child) => this.extractText(child)).join('') : node.text || '';
  }

  handleResize() {
    if (this.buttonContainer.parentElement) {
      this.buttonContainer.parentElement.classList.remove('dropdown-required-on-mobile');
    }

    const newFirstOfRowIndices = new Set<number>();
    const newLastOfRowIndices = new Set<number>();
    const newRowIndexByOptionIndex: { [key: number]: number } = {};
    let row = 0;
    let lastTopValue = -1;
    const options = this.buttonContainer.children;
    for (let i = 0; i < options.length; i++) {
      const top = options[i].getBoundingClientRect().top;
      if (top !== lastTopValue) {
        newFirstOfRowIndices.add(i);
        newLastOfRowIndices.add(i - 1);
        row++;
      }
      newRowIndexByOptionIndex[i] = row;
      lastTopValue = top;
    }

    this.rowCount = row + 1;

    if (options.length > 0) newLastOfRowIndices.add(options.length - 1);

    if (
      !this.areSetsEqual(this.firstOfRowIndices, newFirstOfRowIndices) ||
      !this.areSetsEqual(this.lastOfRowIndices, newLastOfRowIndices)
    ) {
      this.firstOfRowIndices = newFirstOfRowIndices;
      this.lastOfRowIndices = newLastOfRowIndices;
      this.rowIndexByOptionIndex = newRowIndexByOptionIndex;
    }

    const dropdownRequired = options.length > 4 || this.buttonContainer.clientWidth < this.buttonContainer.scrollWidth;
    this.dropdownRequired = dropdownRequired;
    if (dropdownRequired && this.buttonContainer.parentElement) {
      this.buttonContainer.parentElement.classList.add('dropdown-required-on-mobile');
    }
  }

  areSetsEqual<T>(setA: Set<T>, setB: Set<T>): boolean {
    if (setA.size !== setB.size) return false;
    return [...setA.values()].every((value) => setB.has(value));
  }

  handleSelect(value: unknown) {
    this.$emit('input', value);
  }
}
</script>

<style lang="scss">
.ce-radio-buttons {
  &--buttons-label {
    display: block;
    margin-top: rem(-5);
    margin-bottom: map-get($spacers, 2);

    .dropdown-required-on-mobile & {
      display: none;
    }

    @include media-breakpoint-up('md') {
      .dropdown-required-on-mobile & {
        display: block;
      }
    }
  }

  &--buttons {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    .dropdown-required-on-mobile & {
      display: none;
    }
    button {
      flex: 0 0 auto;
      margin-right: 1px;
      margin-bottom: map-get($spacers, 2);
      border: 2px solid transparent;
      background: $silver;
      padding: rem(4) rem(12);
      color: $peacock-blue;

      &:hover {
        background: $peacock-blue;
        color: $white;
      }
      &:focus {
        outline: none;
        border: 2px solid $peacock-blue;
      }

      .stretched & {
        flex: 1 0 auto;
      }
    }
    .selected {
      background: $peacock-blue;
      color: $white;

      &.sibling-hovered {
        background: $windows-blue;
        color: $white;
      }
    }

    .last-of-row {
      margin-right: 0;
      border-top-right-radius: rem(8);
      border-bottom-right-radius: rem(8);
    }
    .first-of-row {
      border-top-left-radius: rem(8);
      border-bottom-left-radius: rem(8);
    }

    .last-row {
      margin-bottom: 0;
    }

    @include media-breakpoint-up('md') {
      flex-wrap: wrap;

      .dropdown-required-on-mobile & {
        display: flex;
      }
    }
  }

  &--dropdown {
    display: none;

    .form-group.focus-white {
      margin-left: 0;
    }

    @include media-breakpoint-down('sm') {
      .dropdown-required-on-mobile & {
        display: block;
      }
    }
  }

  button:disabled {
    color: $light-blue-grey;

    &:hover {
      background: inherit;
      color: $grey;
    }
  }
}
</style>
