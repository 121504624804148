<template>
  <ad-icon-base iconName="close" v-bind="$attrs">
    <path
      d="M39.6,2l-18,18l18,18c0.5,0.5,0.5,1.1,0,1.6c-0.5,0.5-1.1,0.5-1.6,0l-18-18l-18,18c-0.5,0.5-1.1,0.5-1.6,0
	c-0.5-0.5-0.5-1.1,0-1.6l18-18L0.4,2c-0.5-0.5-0.5-1.1,0-1.6s1.1-0.5,1.6,0l18,18l18-18c0.5-0.5,1.1-0.5,1.6,0
	C40.1,0.9,40.1,1.5,39.6,2z"
    />
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconClose extends Vue {}
</script>
