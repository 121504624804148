<template>
  <b-button :disabled="!link || !this.currentProduct" :href="link" v-bind="$attrs">
    <slot></slot>
  </b-button>
</template>

<script lang="ts">
import { Component, Inject, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { ProductModel } from '@/src/types/the-q-api';
import { InstrumentFieldKeys, PageRoutes } from '@/src/types/episerver-api';
import { tryCreateProductLink } from '@/src/utils/url-helper';
import evaluateExpressionByKey from '@/src/utils/value-formatter/format-helper';

@Component({
  inheritAttrs: false,
})
export default class AdProductLink extends Vue {
  @InjectReactive() product!: ProductModel | null;
  @Inject() pageRoutes!: PageRoutes;
  @Inject() nsinKeys!: InstrumentFieldKeys | null;
  @Prop() productItem!: ProductModel | null;

  get currentProduct(): ProductModel | null {
    return this.productItem || this.product;
  }

  get link(): string | null {
    if (this.currentProduct) {
      let nsin;

      if (this.pageRoutes.urlKeys?.productKey) {
        nsin = evaluateExpressionByKey(this.pageRoutes.urlKeys?.productKey, { product: this.currentProduct });
      } else {
        nsin = this.currentProduct.isin;
      }

      const underlying =
        this.currentProduct.underlyings && this.currentProduct.underlyings.length
          ? this.currentProduct.underlyings[0].name
          : '-';

      return tryCreateProductLink(this.pageRoutes, this.currentProduct.productType, underlying, nsin);
    }

    return null;
  }
}
</script>
