import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const mutationObservers: { [id: string]: MutationObserver } = {};

Vue.directive('attach-mutation-observer', {
  inserted: function (el: HTMLElement, binding: DirectiveBinding) {
    const id = el.getAttribute('id');
    const config = { attributes: true, childList: true, subtree: true };
    const callback: (mutations: MutationRecord[], observer: MutationObserver) => void = binding.value.mutationCallback;
    if (!callback || !id) {
      return;
    }
    const observer = new MutationObserver(callback);
    observer.observe(el, config);
    mutationObservers[id] = observer;
  },
  unbind(el: HTMLElement) {
    // This if check is required because unbind is called at the beginning, when the element is not even rendered
    if (!el.getAttribute) return;
    const id = el.getAttribute('id');
    if (!id) return;
    delete mutationObservers[id];
  },
});
