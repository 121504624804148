<template>
  <b-row id="productPriceDataRow">
    <b-col cols="7" md="4" class="mb-2">
      <h3 class="mb-0"><slot name="bid-label"></slot></h3>
      <div class="fs-26 gun-metal font-weight-bold">
        <ad-product-data-value
          v-if="product && !isMarketClosedOrInterruption && !isProductKoed && !isProductMatured"
          fieldKey="product.price.bid"
        />
        <div v-else>
          <span v-if="isMarketClosedOrInterruption && !isProductKoed && !isProductMatured">-</span>
          <slot name="knocked-out-label" v-if="isProductKoed || isBarrierReached"></slot>
          <slot name="matured-label" v-if="isProductMatured"></slot>
        </div>
      </div>
      <div
        v-if="!product || (!isMarketClosedOrInterruption && !isProductKoed && !isProductMatured)"
        class="fs-18 gun-metal font-weight-light"
      >
        <ad-product-data-value fieldKey="product.price.bidSize" /> <slot name="bid-ask-size-unit"></slot>
      </div>
      <div class="d-none d-md-inline-block mt-2" v-if="showSellButton">
        <b-button
          class="d-none d-md-inline-block"
          size="trade"
          variant="danger"
          :id="sellBrokersOverlayTargetId"
          :disabled="showSellButtonDisabled"
          @click="trackBuySellButton('sell')"
        >
          <slot name="sell-button-label"></slot>
        </b-button>
        <slot name="sell-brokers-overlay"></slot>
      </div>
      <div class="d-none d-md-block mt-2" v-if="showBuySellButton">
        <b-button
          variant="success"
          size="sm"
          class="w-100"
          :id="buySellBrokersOverlayTargetId"
          :disabled="showBuySellButtonDisabled"
          @click="trackBuySellButton('buysell')"
          ><slot name="buy-sell-button-label"></slot
        ></b-button>
        <slot name="buy-sell-brokers-overlay"></slot>
      </div>
    </b-col>
    <b-col cols="5" md="4" class="mb-2 text-right d-md-none" v-if="showSellButton">
      <b-button
        variant="danger"
        size="trade"
        :id="sellBrokersOverlayMobileTargetId"
        :disabled="showSellButtonDisabled"
        @click="trackBuySellButton('sell')"
        ><slot name="sell-button-label"></slot
      ></b-button>
      <slot name="sell-brokers-overlay-mobile"></slot>
    </b-col>
    <b-col cols="7" md="4" class="mb-2">
      <h3 class="mb-0"><slot name="ask-label"></slot></h3>
      <div class="fs-26 gun-metal font-weight-bold">
        <ad-product-data-value
          v-if="product && !isMarketClosedOrInterruption && !isBidOnlyPhase && !isProductKoed && !isProductMatured"
          fieldKey="product.price.ask"
        />
        <div v-else>
          <span v-if="(isMarketClosedOrInterruption || isBidOnlyPhase) && !isProductKoed && !isProductMatured">-</span>
          <slot name="knocked-out-label" v-if="isProductKoed || isBarrierReached"></slot>
          <slot name="matured-label" v-if="isProductMatured"></slot>
        </div>
      </div>
      <div
        v-if="!product || (!isMarketClosedOrInterruption && !isBidOnlyPhase && !isProductKoed && !isProductMatured)"
        class="fs-18 gun-metal font-weight-light"
      >
        <ad-product-data-value fieldKey="product.price.askSize" />
        <slot name="bid-ask-size-unit"></slot>
      </div>
      <div v-if="isBidOnlyPhase" class="fs-18 visibility-hidden"> -</div>
      <div class="d-none d-md-inline-block mt-2" v-if="showBuyButton">
        <b-button
          variant="success"
          size="trade"
          :id="buyBrokersOverlayTargetId"
          :disabled="showBuyButtonDisabled"
          @click="trackBuySellButton('buy')"
          ><slot name="buy-button-label"></slot
        ></b-button>
        <slot name="buy-brokers-overlay"></slot>
      </div>
    </b-col>
    <b-col cols="5" md="4" class="d-md-none mb-2 text-right" v-if="showBuyButton">
      <b-button
        variant="success"
        size="trade"
        :id="buyBrokersOverlayMobileTargetId"
        :disabled="showBuyButtonDisabled"
        @click="trackBuySellButton('buy')"
        ><slot name="buy-button-label"></slot
      ></b-button>
      <slot name="buy-brokers-overlay-mobile"></slot>
    </b-col>
    <b-col cols="12" class="mb-2 d-md-none">
      <b-button
        variant="success"
        size="sm"
        class="w-100"
        :id="buySellBrokersOverlayMobileTargetId"
        v-if="showBuySellButton"
        :disabled="showBuySellButtonDisabled"
        @click="trackBuySellButton('buysell')"
        ><slot name="buy-sell-button-label"></slot
      ></b-button>
      <slot name="buy-sell-brokers-overlay-mobile"></slot>
      <b-button size="sm" class="w-100" disabled v-if="isSecondaryMarket">
        <slot name="is-secondary-market-button-label"></slot
      ></b-button>
    </b-col>
    <b-col cols="12" md="4" class="">
      <h3 class="mb-0"><slot name="change-label"></slot></h3>
      <div
        v-if="!product || (!isMarketClosedOrInterruption && !isProductKoed && !isProductMatured)"
        class="fs-26 gun-metal font-weight-bold"
      >
        <ad-product-data-value fieldKey="product.price.changeAbsolute" /> /
        <ad-product-data-value fieldKey="product.price.changePercent" />
      </div>
      <div
        v-if="!product || (!isMarketClosedOrInterruption && !isProductKoed && !isProductMatured)"
        class="mt-2 mt-sm-0 fs-18 gun-metal font-weight-light"
      >
        <ad-product-data-value fieldKey="product.price.timeStamp" />
      </div>
      <div
        v-if="product && (isMarketClosedOrInterruption || isProductKoed || isProductMatured)"
        class="fs-26 gun-metal font-weight-bold"
      >
        -
      </div>
    </b-col>
    <b-col cols="8" class="d-none d-md-inline" v-if="isSecondaryMarket">
      <div class="d-none d-md-block">
        <b-button size="sm" class="w-100" disabled><slot name="is-secondary-market-button-label"></slot></b-button>
      </div>
    </b-col>
  </b-row>
</template>
<script lang="ts">
import { Component, Prop, Vue, InjectReactive } from 'vue-property-decorator';
import { ProductModel } from '@src/types/the-q-api';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';

@Component
export default class AdProductPriceData extends Vue {
  @Prop({ default: false, type: Boolean }) isBuySellConfigured!: boolean;
  @Prop({ default: true, type: Boolean }) isBuyConfigured!: boolean;
  @Prop({ default: true, type: Boolean }) isSellConfigured!: boolean;
  @Prop({ required: true, type: String }) sellBrokersOverlayTargetId!: string;
  @Prop({ required: true, type: String }) sellBrokersOverlayMobileTargetId!: string;
  @Prop({ required: true, type: String }) buyBrokersOverlayTargetId!: string;
  @Prop({ required: true, type: String }) buyBrokersOverlayMobileTargetId!: string;
  @Prop({ required: true, type: String }) buySellBrokersOverlayTargetId!: string;
  @Prop({ required: true, type: String }) buySellBrokersOverlayMobileTargetId!: string;

  @InjectReactive() product!: ProductModel | null;

  get showBuyButton(): boolean {
    return this.isBuyConfigured && !this.showBuySellButton && !this.isSecondaryMarket && !!this.product;
  }

  get showBuyButtonDisabled(): boolean {
    return this.isMarketClosedOrInterruption || this.isBidOnlyPhase || this.isProductKoed || this.isProductMatured;
  }

  get showSellButton(): boolean {
    return this.isSellConfigured && !this.showBuySellButton && !this.isSecondaryMarket && !!this.product;
  }

  get showSellButtonDisabled(): boolean {
    return this.isMarketClosedOrInterruption || this.isProductKoed || this.isProductMatured;
  }

  get showBuySellButton(): boolean {
    return this.isBuySellConfigured && !this.isSecondaryMarket && !!this.product;
  }

  get showBuySellButtonDisabled(): boolean {
    return (this.showBuyButtonDisabled && this.showSellButtonDisabled) || this.isProductKoed || this.isProductMatured;
  }

  get isProductMatured(): boolean {
    return !!this.product?.isMatured;
  }

  get isProductKoed(): boolean {
    return this.product?.knockout != null;
  }

  get isBarrierReached(): boolean {
    return this.product?.barrierBreached != null && this.product.barrierBreached;
  }

  get isMarketClosedOrInterruption(): boolean {
    return (
      (this.product?.price?.bid?.amount === null || this.product?.price.bid.amount === 0) &&
      (this.product?.price?.ask?.amount === null || this.product?.price.ask.amount === 0)
    );
  }

  get isBidOnlyPhaset(): boolean {
    return this.product?.price?.ask?.amount == 0;
  }

  get isSecondaryMarket(): boolean {
    return this.product?.isSecondaryMarket != null && this.product?.isSecondaryMarket;
  }

  get isBidOnlyPhase(): boolean {
    return this.product?.price.bid.amount &&
      this.product?.price?.bidSize !== null &&
      !this.product?.price.ask.amount &&
      !this.isProductKoed
      ? true
      : false;
  }

  trackBuySellButton(buttonType: string): void {
    trackEvent(GaEvent.SelectItem, {
      item_list_id: 'buysell',
      items: [{ item_id: buttonType }],
    });
  }
}
</script>
<style scoped>
.btn-trade {
  padding: 0;
  width: 125px;
  height: 45px;
}
.visibility-hidden {
  visibility: hidden;
}
</style>
