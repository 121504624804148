<template>
  <div class="m-30-20">
    <slot name="default"></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: 'AdHtmlOrText',
};
</script>
