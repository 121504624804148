const inViewportAttributeName = 'data-in-viewport';
const wasOnceInViewportAttributeName = 'data-was-once-in-viewport';

/**
 * Describes an element and a handler function that should be called
 * whenever this element moves into the viewport.
 */
export type ElementViewportWatcher = {
  el: HTMLElement;
  firstAppearanceHandler?: () => void;
  appearanceHandler?: () => void;
};

let elementsToCheck: ElementViewportWatcher[] = [];

export function isElementInViewport(el: HTMLElement): boolean {
  const viewportHeight = window.innerHeight;
  const clientRect = el.getBoundingClientRect();
  const displayThreshold = clientRect.height / 2;
  const inViewport = clientRect.bottom >= displayThreshold && clientRect.top <= viewportHeight - displayThreshold;
  return inViewport;
}

export function checkForAllElementsIfAppearanceShouldTrigger(): void {
  elementsToCheck.forEach(({ el, firstAppearanceHandler, appearanceHandler }) => {
    const hasInViewportAttribute = Boolean(el.getAttribute(inViewportAttributeName));
    const hasWasOnceInViewportAttribute = Boolean(el.getAttribute(wasOnceInViewportAttributeName));

    const inViewport = isElementInViewport(el);

    if (inViewport && !hasWasOnceInViewportAttribute) {
      el.setAttribute(wasOnceInViewportAttributeName, 'true');
      firstAppearanceHandler?.();
    }

    if (inViewport && !hasInViewportAttribute) {
      el.setAttribute(inViewportAttributeName, 'true');
      appearanceHandler?.();
    }

    if (!inViewport) {
      el.removeAttribute(inViewportAttributeName);
    }
  });
}

export function startCheckingAppearance(watcher: ElementViewportWatcher): void {
  elementsToCheck.push(watcher);

  if (elementsToCheck.length === 1) {
    document.addEventListener('scroll', checkForAllElementsIfAppearanceShouldTrigger);
  }
}

export function stopCheckingAppearance(el: Element): void {
  elementsToCheck = elementsToCheck.filter((e: ElementViewportWatcher) => e.el !== el);
  if (elementsToCheck.length === 0) {
    document.removeEventListener('scroll', checkForAllElementsIfAppearanceShouldTrigger);
  }
}
