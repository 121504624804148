<template>
  <div class="ad-like-count">
    <span class="font-weight-bold fs-14 pr-1" v-if="!!likeCount && showCountOnLeft">{{ likeCount }}</span>
    <button class="ce-author-like__button text-nowrap px-0" @click="likeContent" :disabled="wasLiked">
      <ad-icon-like height="1.71em"></ad-icon-like>
    </button>
    <span class="font-weight-bold fs-14 pl-1" v-if="!!likeCount && !showCountOnLeft">{{ likeCount }}</span>
  </div>
</template>

<script lang="ts">
import { CookieCreateState, MissingCookieModalId } from '@/src/types/enumerations';
import ContentLikeService from '@/src/utils/content-like-service';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AdLikeCount extends Vue {
  @Prop({ required: true, type: Number })
  contentId!: number;
  @Prop({ default: false, type: Boolean })
  showCountOnLeft!: boolean;

  private contentLikeService: ContentLikeService | undefined;
  private likeCount = 0;

  async likeContent(): Promise<void> {
    const result = await this.contentLikeService?.likeContent(this.contentId);
    if (result?.cookieState === CookieCreateState.NotAccepted) {
      this.$bvModal.show(MissingCookieModalId.MissingLikeCookieModal);
    } else {
      this.likeCount = result?.likeCount ?? this.likeCount;
    }
  }

  async created(): Promise<void> {
    this.contentLikeService = new ContentLikeService(this.$cookies);
    try {
      this.likeCount = await this.contentLikeService.getLikeCount(this.contentId);
    } catch (err) {
      this.$log.error(err);
    }
  }

  get wasLiked(): boolean {
    return this.contentLikeService?.wasLiked(this.contentId) ?? false;
  }
}
</script>
<style lang="scss">
.ce-author-like {
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0;
    color: $peacock-blue;

    .ad-icon {
      color: $clarinet;
    }
  }
  &__button {
    border: 0;
    background-color: inherit;
    color: $peacock-blue;
    &:focus {
      outline: none;
      svg {
        stroke-width: rem(2);
      }
    }
  }

  &__counter {
    font-weight: bold;
  }
}
</style>
