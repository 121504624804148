<template>
  <b-form-group :id="'year-filter-group' + _uid" :label-for="'year-filter-dropdownlist' + _uid" class="focus-white">
    <template #label>
      <slot name="label-text" />
    </template>
    <ad-drop-down-list
      :input-id="'year-filter-dropdownlist' + _uid"
      :form-element-state-id="'year-filter-group' + _uid"
      :options="items"
      :value="value"
      v-model="currentSelection"
      @input="setValue"
      v-bind="$attrs"
    >
      <template #all-items-label>
        <slot name="all-items-label">All</slot>
      </template>
    </ad-drop-down-list>
  </b-form-group>
</template>

<script lang="ts">
import { FolderDownloadsYearFilterBlockPayloadSettings } from '@/src/types/episerver-api';
import { DropDownItem } from '@/src/types/vue-api';
import { convertOptions } from '@src/utils/dropdown-option-provider';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class AdFolderDownloadsYearFilter extends Vue {
  @Inject()
  settingsProperty!: FolderDownloadsYearFilterBlockPayloadSettings;
  @Prop()
  value!: string | null;
  currentSelection: string | null = '';

  mounted() {
    this.currentSelection = this.settingsProperty.years.selectedValue;
    this.setValue(this.currentSelection);
  }

  get items(): DropDownItem[] {
    return convertOptions(this.settingsProperty.years.items, null);
  }

  setValue(newValue) {
    this.$emit('input', newValue);
    this.$emit('set-filter', this.settingsProperty.name, newValue);
  }
}
</script>
