import { Vue } from 'vue-property-decorator';
import axios from 'axios';
import {
  ContentSearchResultItem,
  GlobalSearchBlockSettings,
  GlobalSearchSuggestSettings,
  InstrumentFieldKeys,
  PageRoutes,
} from '@src/types/episerver-api';
import {
  SearchResult,
  SearchResultProduct,
  SearchResultProductType,
  SearchResultUnderlying,
} from '@src/types/the-q-api';
import {
  GlobalSearchSuggestions,
  SearchSuggestionGroup,
  SearchSuggestion,
  ProductSearchParametersFilter,
} from '@src/types/vue-api';
import { tryCreateProductSearchLink, tryCreateProductLink, tryCreateUnderlyingLink } from '@src/utils/url-helper';
import evaluateExpressionByKey from '@src/utils/value-formatter/format-helper';
import { requestRapidSearch, requestSuggestSearchTheQ, requestSuggestEpiServer } from '@src/utils/global-search';
import { Prop } from 'vue-property-decorator';

export abstract class AdGlobalSearchBase extends Vue {
  @Prop({ default: false, type: Boolean }) showOnlyProductsInSearch!: boolean; // If this is true, only products are displayed in the search result and episerver search is deactiveted
  searchResult: GlobalSearchSuggestions | null = null;

  mapContentSuggestion(index: number, item: ContentSearchResultItem) {
    return new SearchSuggestion(index, item.name, item.url);
  }

  mapUnderlyingSuggestion(
    index: number,
    underlying: SearchResultUnderlying,
    routes: PageRoutes
  ): SearchSuggestion | undefined {
    if (!routes.urlKeys.underlyingKey) return;
    const id = evaluateExpressionByKey(routes.urlKeys.underlyingKey, { underlying: underlying });
    if (!id || typeof id !== 'string') return;
    const url = tryCreateUnderlyingLink(routes, id, underlying.name);
    return new SearchSuggestion(index, underlying.name, url);
  }

  mapProductTypeSuggestion(
    index: number,
    type: SearchResultProductType,
    suggestSettings: GlobalSearchSuggestSettings,
    routes: PageRoutes
  ): SearchSuggestion {
    const filter = new ProductSearchParametersFilter();
    filter.subType = type.productSubType;
    filter.underlyingIsinOrIsins = type.underlyingIsin;
    const name = `${type.productTypeSubtypeName} ${suggestSettings.productUnderlyingPreposition} ${type.underlyingName}`;
    const url = tryCreateProductSearchLink(routes, type.productType, filter);
    return new SearchSuggestion(index, name, url);
  }

  mapProductSuggestion(
    index: number,
    product: SearchResultProduct,
    routes: PageRoutes,
    productNsinKey: string | null | undefined
  ): SearchSuggestion | undefined {
    //TODO: implement correct name
    if (!routes.urlKeys.productKey) return;
    const name = product.productNameTranslation?.productNameFirstLine || product.isin;
    const urlId = evaluateExpressionByKey(routes.urlKeys.productKey, { product: product });
    if (!urlId || typeof urlId !== 'string') return;
    const url = tryCreateProductLink(routes, product.productType, product.underlyingName, urlId);
    if (!url) return;
    let nsinDetail;
    if (productNsinKey) {
      nsinDetail = evaluateExpressionByKey(productNsinKey, { product: product })?.toString();
    }
    return new SearchSuggestion(index, name, url, nsinDetail);
  }

  isDefinedSuggestion = (item: SearchSuggestion | undefined): item is SearchSuggestion => {
    return !!item;
  };

  mapSuggestSearchResult(
    theqResults: SearchResult,
    epiResults: ContentSearchResultItem[],
    searchSettings: GlobalSearchBlockSettings,
    routes: PageRoutes,
    nsinKeys: InstrumentFieldKeys | null
  ): GlobalSearchSuggestions {
    const result = new GlobalSearchSuggestions();
    let index = 0;

    if (!this.showOnlyProductsInSearch) {
      if (theqResults.underlyings && theqResults.underlyings.length !== 0) {
        result.underlyings = theqResults.underlyings.map(
          (group) =>
            new SearchSuggestionGroup(
              group.name || '',
              group.items.map((u) => this.mapUnderlyingSuggestion(index++, u, routes)).filter(this.isDefinedSuggestion)
            )
        );
      }
    }
    if (!this.showOnlyProductsInSearch) {
      if (theqResults.productTypes && theqResults.productTypes.length !== 0) {
        result.productTypes = new SearchSuggestionGroup(
          searchSettings.suggestSearchSettings.productTypesHeadline,
          theqResults.productTypes[0].items.map((t) =>
            this.mapProductTypeSuggestion(index++, t, searchSettings.suggestSearchSettings, routes)
          )
        );
      }
    }

    if (theqResults.products && theqResults.products?.length !== 0) {
      result.products = theqResults.products.map((group) => {
        return new SearchSuggestionGroup(
          `${group.name} ${searchSettings.suggestSearchSettings.productUnderlyingPreposition} ${group.underlyingName}`,
          group.items
            .map((p) => this.mapProductSuggestion(index++, p, routes, nsinKeys?.productKey))
            .filter(this.isDefinedSuggestion)
        );
      });
    }

    if (epiResults.length !== 0) {
      result.content = new SearchSuggestionGroup(
        searchSettings.suggestSearchSettings.websiteContentHeadline,
        epiResults.map((c) => this.mapContentSuggestion(index++, c))
      );
    }

    return result;
  }

  // AbortController is used to cancel all pending http requests, if a new page is loaded.
  // If pending requests are not cancelled, than the requested page loads first after the pending request resolves.
  protected abortController: AbortController | undefined;
  protected theQSearchResult: SearchResult = {} as SearchResult;
  protected epiServerSearchResult: ContentSearchResultItem[] = [];
  protected hasAnySuggestSearchResult: boolean = true;
  protected resetValidationState() {
    this.hasAnySuggestSearchResult = true;
  }

  async requestSuggestions(
    query: string,
    searchSettings: GlobalSearchBlockSettings,
    routes: PageRoutes,
    nsinKeys: InstrumentFieldKeys | null
  ): Promise<void> {
    this.resetValidationState();
    if (!this.abortController) {
      return;
    }
    if (query.length < 3) {
      return;
    }
    try {
      // The call to EPiServer takes significantly longer (i.e. 10s), Hence we want show the results from TheQ already
      // and when the results from EPiServer are there we update the results.
      const theqRequest = requestSuggestSearchTheQ(query, this.abortController).then((response) => {
        this.theQSearchResult = response;
        this.searchResult = this.mapSuggestSearchResult(
          response,
          this.epiServerSearchResult,
          searchSettings,
          routes,
          nsinKeys
        );
      });
      if (!this.showOnlyProductsInSearch) {
        const epiServerRequest = requestSuggestEpiServer(query, this.abortController).then((response) => {
          this.epiServerSearchResult = response;
          this.searchResult = this.mapSuggestSearchResult(
            this.theQSearchResult,
            response,
            searchSettings,
            routes,
            nsinKeys
          );
        });

        await Promise.allSettled([theqRequest, epiServerRequest]);
      } else {
        await Promise.allSettled([theqRequest]);
      }

      this.hasAnySuggestSearchResult = !!this.searchResult?.length;
      return;
    } catch (ex) {
      if (!axios.isCancel(ex)) {
        throw ex;
      }
      return;
    }
  }

  async rapidSearchBase(
    query: string,
    searchSettings: GlobalSearchBlockSettings,
    routes: PageRoutes
  ): Promise<string | null> {
    const searchResult: SearchResult | null = await requestRapidSearch(query);
    if (!searchResult) return null;

    if (
      searchResult.products?.length === 1 &&
      searchResult.products[0].items.length === 1 &&
      routes.urlKeys.productKey
    ) {
      const product = searchResult.products[0].items[0];
      const isinOrNsin = evaluateExpressionByKey(routes.urlKeys.productKey, { product: product })?.toString();
      if (!isinOrNsin || typeof isinOrNsin !== 'string') return null;
      return tryCreateProductLink(routes, product.productType, product.underlyingName, isinOrNsin);
    }

    if (
      searchResult.underlyings?.length === 1 &&
      searchResult.underlyings[0].items.length === 1 &&
      routes.urlKeys.underlyingKey
    ) {
      const underlying = searchResult.underlyings[0].items[0];
      const isinOrNsin = evaluateExpressionByKey(routes.urlKeys.underlyingKey, { underlying: underlying });
      if (!isinOrNsin || typeof isinOrNsin !== 'string') return null;
      return tryCreateUnderlyingLink(routes, isinOrNsin, underlying.name);
    }

    return null;
    //if not found, exits
  }
}
