<template>
  <component :is="{ template: `<span>${text}</span>` }"> </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AdTemplateRenderer extends Vue {
  /*
   * Renders value containing a text with brackets e.g 'text with brackets {{ value }}'
   * as template, so that {{ value }} will be replaced
   */
  @Prop({ default: null }) text!: string | null;
}
</script>
