<template>
  <ad-icon-base iconName="download" v-bind="$attrs">
    <g>
      <path
        d="M0.8,26.7C0.4,26.7,0,27,0,27.5v8.3c0,1.4,1.1,2.5,2.5,2.5h35c1.4,0,2.5-1.1,2.5-2.5v-8.3
		c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8v8.3c0,0.5-0.4,0.8-0.8,0.8h-35c-0.5,0-0.8-0.4-0.8-0.8v-8.3
		C1.7,27,1.3,26.7,0.8,26.7z"
      />
      <path
        d="M20.8,1.7C20.4,1.7,20,2,20,2.5v27l-4.3-6.9c-0.2-0.4-0.8-0.5-1.1-0.3c-0.4,0.2-0.5,0.8-0.3,1.1l4.4,7.1
		c0.7,1.2,2.3,1.5,3.4,0.8c0.3-0.2,0.6-0.5,0.8-0.8l4.4-7.1c0.2-0.4,0.1-0.9-0.3-1.1c-0.4-0.2-0.8-0.1-1.1,0.3l-4.3,6.9v-27
		C21.7,2,21.3,1.7,20.8,1.7L20.8,1.7z"
      />
    </g>
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconDownload extends Vue {}
</script>
