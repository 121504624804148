<template>
  <b-col cols="12" md="6" lg="12" v-if="isVisible">
    <b-alert
      :show="true"
      class="status-icon-alert border-pill px-4 py-2 mb-3 w-sm-100 text-center fs-14 d-lg-inline-block"
      variant="danger"
    >
      <slot name="default"></slot>
    </b-alert>
  </b-col>
</template>

<script lang="ts">
import { DisplayConditionSetting } from '@/src/types/episerver-api';
import { Component, Inject, InjectReactive, Vue } from 'vue-property-decorator';
import { ProductModel } from '@/src/types/the-q-api';
import { evaluateCondition } from '@/src/utils/condition-evaluator';
import { InstrumentDataFieldBase, ProductHistoryDataField } from '@/src/types/episerver-api-instrument';
import evaluateExpressionByKey, { getFormatDataField } from '@/src/utils/value-formatter/format-helper';

@Component({
  inheritAttrs: false,
})
export default class AdProductStatusIcon extends Vue {
  @Inject() settingsProperty!: DisplayConditionSetting[];
  @InjectReactive() product!: ProductModel | null;

  get isVisible() {
    return (
      this.product &&
      this.settingsProperty.reduce<boolean>((result, condition) => {
        return result && this.evaluateCondition(condition);
      }, true)
    );
  }

  private evaluateCondition(condition: DisplayConditionSetting): boolean {
    const fieldExpression = (
      getFormatDataField(condition.fieldKey) as InstrumentDataFieldBase | ProductHistoryDataField
    )?.valueExpression;
    if (!fieldExpression) {
      this.$log.error('Data field not found: ' + condition.fieldKey);
      return false;
    }
    const currentValue = evaluateExpressionByKey(fieldExpression, this.product);
    const result = evaluateCondition(currentValue, condition.value, condition.operation);
    this.$log.debug(condition.fieldKey, currentValue, condition.operation, condition.value, result);
    return result;
  }
}
</script>
<style lang="scss">
.status-icon-alert {
  min-width: 223px;
}
</style>
