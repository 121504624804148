<template>
  <div class="ce-tab-element w-100 h-100">
    <div class="ce-tab-element__header">
      <button class="ce-tab-element__scroll-button" @click="adjustCurrentTab(-1)" v-if="containerToSmall">
        <ad-icon-chevron direction="left"></ad-icon-chevron>
      </button>
      <ul ref="tabHeaderList" class="nav nav-tabs no-bullets">
        <li
          class="nav-item ce-tab-element__tab-header"
          v-for="(header, i) in filteredHeaders"
          v-bind:key="i"
          v-bind:class="{ active: i === currentTab, 'sibling-hovered': hoveredTab !== -1 && hoveredTab !== i }"
          v-on:click="changeTab(i)"
          v-on:keydown.enter="changeTab(i)"
          v-on:mouseover="changeHoveredTab(i)"
          v-on:focus="changeHoveredTab(i)"
          v-on:mouseleave="changeHoveredTab(-1)"
          v-on:blur="changeHoveredTab(-1)"
          tabindex="0"
          role="button"
        >
          <vTabHeader :node="header" />
        </li>
      </ul>
      <button class="ce-tab-element__scroll-button" @click="adjustCurrentTab(+1)" v-if="containerToSmall">
        <ad-icon-chevron direction="right"></ad-icon-chevron>
      </button>
    </div>
    <vTabContent :tabs="filteredTabs" :key="currentTab" :index="currentTab" />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import Vue, { VNode } from 'vue';
import { filterVNodes } from '@src/utils/vnodes-filter';

@Component
export default class CeTabElement extends Vue {
  $refs!: {
    tabHeaderList: HTMLUListElement;
  };

  get filteredHeaders(): VNode[] {
    return filterVNodes(this.$slots.headers);
  }

  get filteredTabs(): VNode[] {
    return filterVNodes(this.$slots.tabs);
  }

  private containerToSmall = false;

  currentTab = 0;
  changeTab(tabIndex: number): void {
    this.currentTab = tabIndex;
    this.$refs.tabHeaderList.children[tabIndex].scrollIntoView({
      behavior: 'smooth',
      inline: 'nearest',
      block: 'nearest',
    });
  }

  hoveredTab = -1;
  changeHoveredTab(tabIndex: number): void {
    this.hoveredTab = tabIndex;
  }

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.containerToSmall = this.$refs.tabHeaderList.clientWidth < this.$refs.tabHeaderList.scrollWidth;
  }

  adjustCurrentTab(by: number) {
    const slotCount = this.filteredHeaders.length;
    this.changeTab((this.currentTab + slotCount + by) % slotCount);
  }
}

Vue.component<Props>('vTabHeader', {
  functional: true,
  render: function (h, context) {
    return context.props.node;
  },
});

Vue.component('vTabContent', {
  props: ['tabs', 'index'],
  render: function (h) {
    if (this.index > this.tabs.length - 1)
      return h('div', {}, ['Something is wrong. There are more tab headers then tab contents.']);
    return this.tabs[this.index];
  },
});

type Props = {
  node: VNode;
};
</script>

<style lang="scss">
.ce-tab-element {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: map-get($spacers, 5);
    border-bottom: 1px solid $gunmetal;

    .nav-tabs {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: nowrap;
      justify-content: flex-start;
      border-bottom: 0;
      overflow: auto;

      @include media-breakpoint-up('md') {
        overflow: hidden;
      }
    }
  }

  &__scroll-button {
    border: 0;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    background: $white;
    color: $marine-blue;
  }

  & &__tab-header.nav-item {
    flex: 1 0 auto;
    margin-bottom: 0;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    padding: map-get($spacers, 2);
    color: $peacock-blue;

    &:hover,
    &:focus {
      border-color: $marine-blue;
      * {
        color: $marine-blue;
      }
    }

    &:focus {
      outline: 2px auto $peacock-blue;
    }

    * {
      color: $peacock-blue;
    }

    &.active {
      border-color: $peacock-blue;
      * {
        color: $marine-blue;
      }
    }

    &.sibling-hovered {
      * {
        color: $windows-blue;
      }

      &.active {
        border-color: $windows-blue;
      }
    }
  }
}
</style>
