import { SortingDirection } from '../types/enumerations';
import { isPriceModel } from '../types/type-guards';
import evaluateExpressionByKey from './value-formatter/format-helper';

export function sortBy<T>(
  array: Array<T>,
  valueExpression: string,
  expressionModelKey: string,
  direction: SortingDirection
): Array<T> {
  if (array.length === 0) {
    return [];
  }
  return Array.from(array).sort((a, b) => {
    let result = 0;
    const aValue = evaluateExpressionByKey(valueExpression, { [expressionModelKey]: a });
    const bValue = evaluateExpressionByKey(valueExpression, { [expressionModelKey]: b });
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      result = aValue.localeCompare(bValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      result = aValue - bValue;
    } else if (isPriceModel(aValue) && isPriceModel(bValue)) {
      result = (aValue.amount || 0) - (bValue.amount || 0);
    } else if (!aValue) {
      result = -1;
    } else if (!bValue) {
      result = 1;
    } else {
      result = 0;
    }
    if (direction === SortingDirection.Descending) result *= -1;
    return result;
  });
}

export function sortByOrder<TModel>(
  array: Array<TModel>,
  sortOrders: Array<{ sort: number }>,
  idKey: string
): Array<TModel> {
  if (array.length === 0) return [];

  return Array.from(array).sort((a, b) => {
    let aSort = sortOrders.find((u) => u[idKey] === a[idKey])?.sort;
    let bSort = sortOrders.find((u) => u[idKey] === b[idKey])?.sort;
    if (aSort === undefined) aSort = Number.MAX_SAFE_INTEGER;
    if (bSort === undefined) bSort = Number.MAX_SAFE_INTEGER;
    return aSort - bSort;
  });
}
