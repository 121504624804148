import store from '@/src/state/store';
import { ApiTypeValue, FormatDataField } from '@/src/types/vue-api';

export function getFormatDataField(fieldKey: string): FormatDataField | undefined {
  return store.getters['fieldFormats/getFormatDataField'](fieldKey);
}

export function setExpressionValue(key: string, value, model): void {
  const format = getFormatDataField(key);
  const expression = format?.fieldKey;

  if (!expression) {
    return;
  }

  const names = expression.split('.');

  for (let i = 0; i < names.length; i++) {
    if (i < names.length - 1) {
      if (model[names[i]] === undefined) {
        model[names[i]] = {};
      }
      model = model[names[i]];
    } else {
      model[names[i]] = value;
    }
  }
}

function tryEvaluateExpressionValue(expression: string, model): ApiTypeValue | undefined {
  try {
    const val = expression.split('.').reduce((dataModel, prop) => dataModel && dataModel[prop], model);
    return val as unknown as ApiTypeValue;
  } catch {
    return undefined;
  }
}

export function hasExpressionField(key: string, model): boolean {
  const format = getFormatDataField(key);
  const expression = format?.fieldKey;
  const value = expression ? tryEvaluateExpressionValue(expression, model) : undefined;
  return value === undefined ? false : true;
}

export default function evaluateExpressionByKey(key: string, model): ApiTypeValue {
  const format = getFormatDataField(key);
  let expression = format?.fieldKey;

  if (expression == null) {
    // try to get expression from key, because key is id + expression e.g. 1541product.price.ask.amount
    expression = key.replace(/^[0-9]*/g, '');
  }

  return tryEvaluateExpressionValue(expression, model) ?? null;
}
