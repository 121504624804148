<template>
  <ad-icon-base viewBox="0 0 40 40" v-bind="$attrs">
    <path
      d="M18.3,10c0,0.8,0.8,1.7,1.7,1.7c1,0,1.7-0.7,1.7-1.7S21,8.3,20,8.3S18.3,9.2,18.3,10z M20,15
		c-0.5,0-0.8,0.3-0.8,0.8v16.7c0,0.5,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V15.8C20.8,15.3,20.5,15,20,15z"
    />
    <path
      d="M0,20c0,11,9,20,20,20s20-9,20-20S31,0,20,0S0,9,0,20z M38.3,20c0,10.2-8.2,18.3-18.3,18.3S1.7,30.2,1.7,20
		S9.8,1.7,20,1.7S38.3,9.8,38.3,20z"
    />
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconInfo extends Vue {}
</script>
