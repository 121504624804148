<template>
  <b-row>
    <b-col cols="12" :lg="isArchiveDetail ? 12 : 9" class="pt-4 py-lg-3 hr align-self-start">
      <b-row class="align-items-start">
        <b-col cols="12" :lg="isArchiveDetail ? 3 : 4" class="mb-2">
          <div class="mb-0 fs-14 font-weight-bold blue"><slot name="isin-label"></slot></div>
          <div class="fs-26 gun-metal font-weight-normal">
            <ad-product-data-value fieldKey="product.isin" />
            <span
              class="mt-0 blue icon-copy"
              v-if="product"
              v-clipboard="product.isin"
              v-clipboard:success="clipboardSuccessHandler"
              @click="onCloseCopyPopover"
              v-b-popover.top.click="copyConfirmationText"
              role="button"
              ><ad-icon-copy id="icon-copy" height="0.8em" />
            </span>
          </div>
        </b-col>
        <b-col cols="12" :lg="isArchiveDetail ? 2 : 3" class="mb-0">
          <b-row>
            <b-col cols="5" md="4" lg="12">
              <div class="mb-0 fs-14 font-weight-bold blue"><slot name="nsin-label"></slot></div>
            </b-col>
            <b-col cols="7" md="8" lg="12">
              <div class="fs-18 gun-metal font-weight-normal">
                <slot name="nsin-field"></slot>
                <span
                  class="mt-0 blue icon-copy"
                  v-if="product"
                  v-clipboard="productNsinKeyValue"
                  v-clipboard:success="clipboardSuccessHandler"
                  @click="onCloseCopyPopover"
                  v-b-popover.top.click="nsinCopiedText"
                  role="button"
                >
                  <ad-icon-copy id="icon-copy" height="0.8em" />
                </span>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" :lg="isArchiveDetail ? 2 : 3" class="mb-0">
          <b-row>
            <b-col cols="5" md="4" lg="12">
              <div class="mb-0 fs-14 font-weight-bold blue"><slot name="product-type-label"></slot></div>
            </b-col>
            <b-col cols="7" md="8" lg="12">
              <div class="fs-18 gun-metal font-weight-normal">
                <ad-product-data-value fieldKey="product.typeTranslations.singular" />
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="2" class="mb-0">
          <b-row>
            <b-col cols="5" md="4" lg="12">
              <div class="mb-0 fs-14 font-weight-bold blue"><slot name="maturity-date-label"></slot></div>
            </b-col>
            <b-col cols="7" md="8" lg="12">
              <div class="fs-18 gun-metal font-weight-normal">
                <ad-product-data-value fieldKey="product.maturityDate" />
                <slot name="maturity-date-open" v-if="product && !product.maturityDate"></slot>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="2" class="mb-0" v-if="isArchiveDetail">
          <b-row>
            <b-col cols="5" md="4" lg="12">
              <div class="mb-0 fs-14 font-weight-bold blue"><slot name="underlying-label"></slot></div>
            </b-col>
            <b-col cols="7" md="8" lg="12">
              <div class="fs-18 gun-metal font-weight-normal">
                <ad-underlying-data-value fieldKey="underlying.name" />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" lg="3" class="pb-4 py-lg-3 hr-lg bl-lg align-items-stretch" v-if="!isArchiveDetail">
      <b-row class="align-items-start flex-grow-1">
        <ad-skeleton-text class="col" :loading="underlying == null">
          <b-col cols="12" lg="12" class="mb-2 align-self-start" v-if="tradingHoursVisible">
            <b-row class="align-items-start">
              <b-col cols="5" md="4" lg="12">
                <div class="mb-0 fs-14 font-weight-bold blue"><slot name="trading-time-label"></slot></div>
              </b-col>
              <b-col cols="7" md="8" lg="12">
                <div class="mt-sm-0 fs-18 gun-metal font-weight-normal align-self-start">
                  <span
                    ><ad-underlying-data-value fieldKey="underlying.tradingTimes.productTime.from" /> -
                    <ad-underlying-data-value fieldKey="underlying.tradingTimes.productTime.to" /></span
                  ><br />
                  <span v-if="euronextTimesVisible"
                    >(<ad-underlying-data-value fieldKey="underlying.tradingTimes.euronextTime.from" /> -
                    <ad-underlying-data-value fieldKey="underlying.tradingTimes.euronextTime.to" />
                    Euronext)</span
                  >
                </div>
              </b-col>
            </b-row>
            <b-row class="align-items-start">
              <b-col cols="5" md="4" lg="12">
                <div class="mb-0 fs-14 font-weight-bold blue" v-if="knockoutTimesVisible">
                  <slot name="knock-out-time-label"></slot>
                </div>
              </b-col>
              <b-col cols="7" md="8" lg="12">
                <div class="mt-sm-0 fs-18 gun-metal font-weight-normal align-self-start" v-if="knockoutTimesVisible">
                  <ad-underlying-data-value fieldKey="underlying.tradingTimes.knockoutTime.from" /> -
                  <ad-underlying-data-value fieldKey="underlying.tradingTimes.knockoutTime.to" />
                  <slot name="underlying-related-info-popup" :underlyings="product.underlyings"></slot>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </ad-skeleton-text>
      </b-row>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { Component, Inject, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { ProductModel, UnderlyingModel, UnderlyingTradingTimes } from '@src/types/the-q-api';
import { InstrumentFieldKeys, ProductDetailBlockSetting } from '@/src/types/episerver-api';
import evaluateExpressionByKey from '@/src/utils/value-formatter/format-helper';
import { ApiTypeValue } from '@/src/types/vue-api';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';

@Component({})
export default class AdProductMainInformation extends Vue {
  @Prop({ default: false }) displayKnockOutTime!: boolean;
  @Inject() settingsProperty?: ProductDetailBlockSetting;
  @Inject() nsinKeys!: InstrumentFieldKeys | null;

  @InjectReactive() product!: ProductModel | null;
  @InjectReactive() underlying!: UnderlyingModel | null;

  get isArchiveDetail(): boolean | null {
    if (!this.product) return null;
    return this.product?.lifetimeCycle != null;
  }

  get productNsinKeyValue(): ApiTypeValue {
    if (!this.nsinKeys?.productKey) {
      return null;
    }

    return evaluateExpressionByKey(this.nsinKeys?.productKey, {
      // setting product to get value from productItem and set fieldkey, e.g. product.last
      product: this.product,
    });
  }

  getTradingTimes(): UnderlyingTradingTimes | null {
    if (
      this.underlying?.tradingTimes?.productTime === null ||
      this.underlying?.tradingTimes?.productTime === undefined
    ) {
      return null;
    }
    return this.underlying.tradingTimes;
  }

  clipboardSuccessHandler({ value }) {
    trackEvent(GaEvent.SelectItem, {
      item_list_id: 'product_identifier',
      items: [{ item_id: value }],
    });
  }
  get knockoutTimesVisible(): boolean {
    return (
      (this.settingsProperty?.displayKnockOutTime === undefined ? false : this.settingsProperty?.displayKnockOutTime) &&
      this.getTradingTimes()?.knockoutTime !== null
    );
  }

  get euronextTimesVisible(): boolean {
    return (
      (this.settingsProperty?.displayEuronextTime === undefined ? false : this.settingsProperty?.displayEuronextTime) &&
      this.getTradingTimes()?.euronextTime !== null
    );
  }

  get tradingHoursVisible(): boolean {
    const tradingTimes = this.getTradingTimes();
    if (tradingTimes !== null) {
      if (tradingTimes.productTime !== null) {
        return true;
      }
    }
    return false;
  }

  get copyConfirmationText(): string {
    return this.settingsProperty?.isinCopiedText ? this.settingsProperty?.isinCopiedText : 'Copied';
  }

  get nsinCopiedText(): string {
    return this.settingsProperty?.nsinCopiedText ? this.settingsProperty?.nsinCopiedText : 'Copied';
  }

  onCloseCopyPopover(): void {
    setTimeout(() => {
      this.$root.$emit('bv::hide::popover');
    }, 3000);
  }
}
</script>
<style scoped lang="scss">
.icon-copy {
  color: $marine-blue;

  :hover {
    color: $bright-sky-blue;
  }
}
</style>
