import Vue from 'vue';

/**
 * Use this directive to make an element fade in on mount.
 *
 * Example:
 *
 * <div v-appear>
 *   I appear when mounted.
 * </div>
 */
Vue.directive('appear', {
  inserted: (el: HTMLElement) => {
    el.classList.add(`show-animation-delay-0`);
    el.classList.add(`animation-triggered`);
  },
});
