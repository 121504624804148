<template>
  <div class="row">
    <a href="#" class="col-12 col-md-4 d-block mb-3 mb-md-0">
      <picture>
        <source media="(min-width:650px)" srcset="https://picsum.photos/seed/2/1600/900" />
        <source media="(min-width:465px)" srcset="https://picsum.photos/seed/2/1600/900" />
        <img src="https://picsum.photos/seed/2/1600/900" class="w-100" />
      </picture>
    </a>
    <div class="col-12 col-md-5 pt-md-2">
      <p class="fs-12 mb-2">Advertisement</p>
      <h3>Lorem ipsum dolor med</h3>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est magni similique dolores quasi </p>
      <a href="#">Learn more</a>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CeContentAd extends Vue {}
</script>
