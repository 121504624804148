<template>
  <div class="product-search-filter-dropdownlist-container">
    <b-form-group
      :id="'dropdown-list-filter-group' + _uid"
      class="focus-white"
      :label-for="'dropdown-list-filter' + _uid"
    >
      <template #label><slot name="dropdown-label-text">Dropdown label</slot></template>
      <ad-skeleton-input :loading="loading">
        <ad-drop-down-list
          v-bind="$attrs"
          :options="dropdownItems"
          :placeholder="allItemsLabelText"
          :is-selectable="isSelectable"
          :value="value"
          @input="setValue"
          :form-element-state-id="'dropdown-list-filter-group' + _uid"
          id="dropdown-list-filter"
        />
      </ad-skeleton-input>
    </b-form-group>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DropDownItem } from '@/src/types/vue-api';
import { convertToDropdownItems } from '@/src/utils/dropdown-option-provider';

@Component({
  inheritAttrs: false,
})
export default class AdProductSearchFilterDropdown extends Vue {
  @Prop({ default: false, type: Boolean }) wide!: boolean;
  @Prop({ required: true })
  translations!: string[];
  @Prop({ required: true, default: null })
  value!: string | null;
  @Prop({ required: true })
  availableItems!: string[];
  @Prop({ required: true, type: Boolean }) loading!: boolean;
  @Prop({ default: false, type: Boolean }) hideDisabledItems!: boolean;

  dropDownItems: DropDownItem[] = [];

  get dropdownItems(): DropDownItem[] {
    const allDropDownItems = convertToDropdownItems(this.allItemsLabelText, this.translations);
    return this.hideDisabledItems === true
      ? allDropDownItems.filter(
          (item) =>
            // Remove all items that are not available in the 'availableItems' array and is not allItemsLabel
            item.text === this.allItemsLabelText || (item.value && this.availableItemsContains(item.value as string))
        )
      : allDropDownItems;
  }

  get allItemsLabelText(): string | null {
    const slot = this.$slots['all-items-label-text'];
    return slot && slot.length ? slot[0].text ?? null : null;
  }

  @Watch('availableItems', { deep: true })
  changeFilter(): void {
    const isPresent = this.value && this.availableItemsContains(this.value);

    if (this.value && !isPresent) {
      this.$emit('input', null);
    }
  }

  setValue(newValue: string | null): void {
    this.$emit('input', newValue);
    this.$emit('selected-value-changed');
  }

  isSelectable(option: DropDownItem): boolean {
    if (!option.value?.toString()) {
      return true;
    }

    const strToCompare = option.value?.toString();
    return this.availableItemsContains(strToCompare);
  }

  availableItemsContains(value: string): boolean {
    const strToCompare = value.toUpperCase();
    return this.availableItems?.some((x) => x.toUpperCase() === strToCompare) === true;
  }
}
</script>
