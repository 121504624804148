<template>
  <ad-skeleton-text :loading="currentProduct === null">
    <ad-product-link variant="link" v-if="isLink" :product-item="currentProduct">
      <ad-instrument-data-value
        v-bind="$attrs"
        :value="value"
        :fieldKey="fieldKey"
        :instrument="currentProduct"
        @subscribe-to-push="subscribePushValue"
      ></ad-instrument-data-value>
    </ad-product-link>
    <ad-instrument-data-value
      v-else
      v-bind="$attrs"
      :value="value"
      :fieldKey="fieldKey"
      :instrument="currentProduct"
      @subscribe-to-push="subscribePushValue"
    ></ad-instrument-data-value>
  </ad-skeleton-text>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Inject, InjectReactive } from 'vue-property-decorator';
import { ProductModel } from '@/src/types/the-q-api';
import { ApiTypeValue } from '@/src/types/vue-api';
import { PageRoutes, InstrumentFieldKeys, InstrumentDataRowSettings } from '@/src/types/episerver-api';
import evaluateExpressionByKey from '@/src/utils/value-formatter/format-helper';
import { Action, Getter } from 'vuex-class';
import { isPriceModel } from '@src/types/type-guards';

@Component({
  inheritAttrs: false,
})
export default class AdProductDataValue extends Vue {
  @Inject() pageRoutes!: PageRoutes;
  @Inject() nsinKeys!: InstrumentFieldKeys | null;
  @Prop({ required: true }) fieldKey!: string;
  @Prop({ default: false, type: Boolean }) isLinkToDetailPage!: boolean;
  @Inject({ default: null }) settingsProperty?: InstrumentDataRowSettings | null;
  /**
   * Product can be obtained with three different approaches in this component.
   * 1. Pass "product" as Prop
   * 2. Inject "product" from parent
   * 3. Fallback to reading from state. When reading from state, make sure that
   *    `loadProductAsync` (see state/modules/product.ts) was called before,
   *     otherwise it will not be available.
   */

  // 1. Pass as Prop
  @Prop() productItem!: ProductModel | null;

  // 2. Inject from Parent
  @InjectReactive({ from: 'product' }) productInjected!: ProductModel | null;

  // 3. Read from State
  @Getter('product', { namespace: 'product' })
  private product!: ProductModel | null;

  @Action('subscribePushValue', { namespace: 'product' })
  subscribePushValue!: (fieldKey: string) => Promise<void>;

  get value(): ApiTypeValue {
    return evaluateExpressionByKey(this.fieldKey, {
      // setting product to get value from productItem and set fieldkey, e.g. product.last
      product: this.currentProduct,
    });
  }

  get currentProduct(): ProductModel | null {
    return this.productItem || this.productInjected || this.product;
  }

  public get hasValue(): boolean {
    if (isPriceModel(this.value)) {
      return this.value.amount !== null;
    }

    return this.value !== null && this.value !== undefined;
  }

  private get isLink() {
    return this.settingsProperty?.isLinkToDetailPage || this.isLinkToDetailPage || false;
  }

  @Watch('hasValue', { immediate: true })
  onValueChange(): void {
    this.$emit('has-value', this.hasValue);
  }
}
</script>
