<template>
  <ad-collapse-on-mobile>
    <template #header>
      <slot name="header"></slot>
    </template>
    <b-row>
      <b-col v-for="typeGroup in productTypeGroups" :key="typeGroup.header" cols="12" sm="6">
        <ad-skeleton-text :loading="typeGroup.options.length === 0">
          <h3> {{ typeGroup.header }}</h3>
        </ad-skeleton-text>
        <ad-product-type-count-table :types="typeGroup.options" :selectedUnderlying="underlying.isin">
          <template #header-name><slot name="table-header-name"></slot></template>
          <template #header-count><slot name="table-header-count"></slot></template>
        </ad-product-type-count-table>
      </b-col>
    </b-row>
    <hr class="mt-5 mb-6 mt-lg-6 mb-lg-7 d-none d-sm-block border-secondary" />
  </ad-collapse-on-mobile>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OptionGroup, ProductTypeGroupOptionsFilter, UnderlyingModel } from '@src/types/the-q-api';
import { Action, Getter } from 'vuex-class';

@Component({})
export default class AdProductTypesCount extends Vue {
  private filter = {} as ProductTypeGroupOptionsFilter;

  @Getter('underlying', { namespace: 'underlying' })
  underlying!: UnderlyingModel | null;

  @Getter('productTypeGroupOptions', { namespace: 'product' })
  productTypeGroupOptions!: (filter: ProductTypeGroupOptionsFilter) => OptionGroup[];

  @Action('getProductTypeGroupOptionsAsync', { namespace: 'product' })
  getProductTypeGroupOptionsAsync!: (filter: ProductTypeGroupOptionsFilter) => Promise<void>;

  get productTypeGroups() {
    return this.productTypeGroupOptions(this.filter);
  }

  @Watch('underlying')
  private async getProductTypes(): Promise<void> {
    if (this.underlying) {
      try {
        this.filter = {
          underlyingIsin: this.underlying.isin,
        } as ProductTypeGroupOptionsFilter;
        await this.getProductTypeGroupOptionsAsync(this.filter);
      } catch (ex) {
        this.$log.error(ex);
      }
    }
  }
}
</script>
