<template>
  <ad-icon-base iconName="check" v-bind="$attrs">
    <path
      d="M38.5,5.7l-26,26.7L1.5,21.2c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l11.5,11.7c0.4,0.4,0.9,0.4,1.3,0
	L39.7,7c0.4-0.4,0.4-0.9,0-1.3C39.4,5.4,38.8,5.4,38.5,5.7z"
    />
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconCheck extends Vue {}
</script>
