import { lowerFirst } from 'lodash';
import Vue from 'vue';
import { ProductKey } from '../types/enumerations';
import { BestMatchSearchRequestFilter } from '../types/vue-api';
/**
 * maps the keyDataField and keyDataValue for best match search request
 */
export function setBestMatchSearchFilterForRequest(filter: BestMatchSearchRequestFilter): void {
  const keyDataField = lowerFirst(filter.filterProperty);

  filter.keyDataField = ProductKey[filter.filterProperty];

  if (filter.keyDataField === undefined) {
    Vue.$log.fatal('Could not found product key', filter.filterProperty, 'in keys', ProductKey);
  }

  filter.keyDataValue = filter[keyDataField];
}
