<template>
  <ad-icon-base iconName="go-back" v-bind="$attrs">
    <path
      d="M40,14.9c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.3-0.2-0.5-0.2h-37
	l9.1-6.8c0.4-0.3,0.4-0.8,0.2-1.2c-0.3-0.4-0.8-0.4-1.2-0.2L1,13c-0.6,0.5-1,1.2-1,2V15c0,0.7,0.4,1.5,1,2l9.3,7c0,0,0,0,0,0
	c0.4,0.3,0.9,0.2,1.2-0.2c0.3-0.4,0.2-0.9-0.2-1.2l-9.1-6.8h36.1v17.5c0,0.5,0.3,0.8,0.8,0.8c0.5,0,0.8-0.3,0.8-0.8V15
	C40,15,40,14.9,40,14.9z"
    />
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconGoBack extends Vue {}
</script>
