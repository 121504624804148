<template>
  <b-button
    v-if="isVisible"
    class="watchlist p-2 border-0 miw-auto rounded-circle d-inline-block"
    :variant="isUnderlyingInWatchlist && cookieComfortAccepted ? 'watchlist-remove' : 'watchlist-add'"
    @click="toggleItem"
  >
    <ad-icon-watchlist-remove v-if="isUnderlyingInWatchlist" :height="iconSize" :width="iconSize" />
    <ad-icon-watchlist-add v-else :height="iconSize" :width="iconSize" />
  </b-button>
</template>

<script lang="ts">
import { InjectReactive, Component, Vue, Inject, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { WatchedUnderlying } from '@/src/types/vue-api';
import { PageRoutes } from '@/src/types/episerver-api';
import CookieManager from '@/src/utils/cookie';
import { MissingCookieModalId } from '@/src/types/enumerations';

@Component({})
export default class AdUnderlyingWatchlistToggle extends Vue {
  @Getter('watchedUnderlyings', { namespace: 'watchlist' })
  watchedUnderlyings!: Array<WatchedUnderlying>;

  @Action('addUnderlying', { namespace: 'watchlist' })
  addUnderlying!: (isin: string) => Promise<void>;

  @Action('removeUnderlying', { namespace: 'watchlist' })
  removeUnderlying!: (isin: string) => Promise<void>;

  @Inject() pageRoutes!: PageRoutes;

  /**
   * Underlyings can be obtained with three different approaches in this component.
   * 1. Bind "underlying" argument to an UnderlyingModel
   * 2. Inject "underlying" from parent
   * 3. Fallback to reading from state. When reading from state, make sure that
   *    `loadUnderlyingAysnc` (see state/modules/underlying.ts) was called before,
   *     otherwise it will not be available.
   */

  // 1. Pass as Prop
  @Prop() underlyingItem!: UnderlyingModel | null;

  // 2. Inject from Parent
  @InjectReactive({ from: 'underlying' }) underlyingInjected!: UnderlyingModel | null;

  private cookieManager!: CookieManager;
  private iconSize = '2.5em';

  get isVisible() {
    return this.currentUnderlying?.isin && this.pageRoutes.watchlistUrl;
  }

  get currentUnderlying(): UnderlyingModel | null {
    return this.underlyingItem || this.underlyingInjected;
  }

  private get isUnderlyingInWatchlist(): boolean {
    if (this.currentUnderlying === null) return false;
    return this.watchedUnderlyings.find((u) => u.isin === this.currentUnderlying?.isin) !== undefined;
  }

  get cookieComfortAccepted(): boolean {
    return this.cookieManager?.comfort || false;
  }

  async created(): Promise<void> {
    this.cookieManager = new CookieManager(this.$cookies);
  }

  toggleItem() {
    if (this.cookieComfortAccepted) {
      if (this.currentUnderlying && this.currentUnderlying.isin) {
        if (this.isUnderlyingInWatchlist) this.removeUnderlying(this.currentUnderlying.isin);
        else this.addUnderlying(this.currentUnderlying.isin);
      }
    } else {
      this.$bvModal.show(MissingCookieModalId.MissingWatchlistCookieModal);
    }
  }
}
</script>
