import httpClient from '@/src/utils/http-service';
import Vue from 'vue';
import qs from 'qs';
import {
  OptionGroup,
  Option,
  UnderlyingModel,
  UnderlyingPricePush,
  TopFlopTableSearchPayload,
  TopFlopTableSearchFilter,
} from '@src/types/the-q-api';
import { getTheQApiUrl } from '@/src/utils/url-helper';
import { Controller } from '@/src/types/enumerations';
import { Commit } from 'vuex';
import { MutationPushPayloadHolder } from '@/src/types/vue-api';
import { isMutationPushPayloadHolder } from '@/src/types/type-guards';
import { uniqBy } from 'lodash';
import {
  createPushPayload,
  LightStreamerUnderlyingSubscriptionMutationTypes,
  PUSH_SUBSCRIPTION_UNDERLYING_GETTER,
  PUSH_UPDATE_UNDERLYING,
  tryUpdateUnderlyingValue,
} from '@/src/state/plugins/light-streamer/pushing-helper';
import { AxiosResponse } from 'axios';

export const state = {
  underlying: null as MutationPushPayloadHolder<UnderlyingModel, UnderlyingModel> | null,
  productUnderlying: null as MutationPushPayloadHolder<UnderlyingModel, UnderlyingModel> | null,
  teaserUnderlyings: null as MutationPushPayloadHolder<Array<UnderlyingModel>, UnderlyingModel> | null,
  underlyings: null as MutationPushPayloadHolder<Array<UnderlyingModel>, UnderlyingModel> | null,
  topFlopTableSearchResult: null as MutationPushPayloadHolder<
    { [contentId: number]: Array<UnderlyingModel> },
    UnderlyingModel
  > | null,
  underlyingPushValues: {} as { [key: string]: boolean },
  underlyingsByKey: createPushPayload(
    {},
    (holder) => Object.values(holder) as Array<UnderlyingModel>
  ) as MutationPushPayloadHolder<{ [key: string]: UnderlyingModel }, UnderlyingModel>,
  underlyingOptionGroups: null as OptionGroup[] | null,
  underlyingOptions: null as Option[] | null,
  underlyingTranslations: null as { [key: string]: string } | null,
};

enum UnderlyingMutation {
  CACHE_PUSH_SUBSCRIPTION = 'CACHE_PUSH_SUBSCRIPTION',
  CACHE_OPTION_GROUP = 'CACHE_OPTION_GROUP_UNDERLYING',
  CACHE_OPTION = 'CACHE_OPTION_UNDERLYING',
  CACHE_TRANSLATION_UNDERLYING = 'CACHE_TRANSLATION_UNDERLYING',
}

type State = typeof state;

export const mutations = {
  [LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_UNDERLYING](
    state: State,
    underlyingPushPayload: MutationPushPayloadHolder<UnderlyingModel, UnderlyingModel>
  ) {
    state.underlying = underlyingPushPayload;
  },
  [LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_UNDERLYINGS_BY_KEY](
    state: State,
    underlyings: Array<UnderlyingModel>
  ) {
    underlyings.forEach((u) => Vue.set(state.underlyingsByKey.holder, u.isin, u));
  },
  [LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_PRODUCT_UNDERLYING](
    state: State,
    underlyingPushPayload: MutationPushPayloadHolder<UnderlyingModel, UnderlyingModel>
  ) {
    state.productUnderlying = underlyingPushPayload;
  },
  [LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_TEASER_UNDERLYINGS](
    state: State,
    teaserUnderlyingPushPayload: MutationPushPayloadHolder<Array<UnderlyingModel>, UnderlyingModel>
  ) {
    state.teaserUnderlyings = teaserUnderlyingPushPayload;
  },
  [LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_UNDERLYINGS](
    state: State,
    underlyingPushPayload: MutationPushPayloadHolder<Array<UnderlyingModel>, UnderlyingModel>
  ) {
    state.underlyings = underlyingPushPayload;
  },
  [UnderlyingMutation.CACHE_PUSH_SUBSCRIPTION](state: State, fieldKey: string) {
    state.underlyingPushValues[fieldKey] = true;
  },
  [LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_TOP_FLOP_TABLE_SEARCH_PRODUCTS](
    state: State,
    topLeveragePushPayload: MutationPushPayloadHolder<{ [contentId: number]: Array<UnderlyingModel> }, UnderlyingModel>
  ) {
    state.topFlopTableSearchResult = topLeveragePushPayload;
  },
  [PUSH_UPDATE_UNDERLYING](
    state: State,
    pushItem: { underlying: UnderlyingPricePush; pushSubscriptions: { [key: string]: boolean } }
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allUnderlyingsInState = Object.values(state).reduce((prevVal: Array<UnderlyingModel>, statePropVal: any) => {
      if (isMutationPushPayloadHolder(statePropVal)) {
        return prevVal.concat(statePropVal.getSubscriptionPushItems(statePropVal.holder));
      } else {
        return prevVal;
      }
    }, new Array<UnderlyingModel>());

    allUnderlyingsInState.forEach((tu) =>
      tryUpdateUnderlyingValue(tu, pushItem.underlying, pushItem.pushSubscriptions)
    );
  },
  [UnderlyingMutation.CACHE_OPTION](state: State, options: Option[]) {
    state.underlyingOptions = options;
  },
  [UnderlyingMutation.CACHE_OPTION_GROUP](state: State, optionGroups: OptionGroup[]) {
    state.underlyingOptionGroups = optionGroups;
  },
  [UnderlyingMutation.CACHE_TRANSLATION_UNDERLYING](state: State, translations: { [key: string]: string }) {
    state.underlyingTranslations = translations;
  },
};

export const getters = {
  underlying: (state: State) => {
    return state.underlying?.holder;
  },
  underlyingByKey: (state: State) => {
    return (isin: string) => {
      return state.underlyingsByKey.holder[isin];
    };
  },
  underlyings: (state: State) => {
    return state.underlyings?.holder;
  },
  teaserUnderlyings: (state: State) => {
    return state.teaserUnderlyings?.holder;
  },
  topFlopTableSearchResult: (state: State) => {
    return (contentId: number) => state.topFlopTableSearchResult?.holder[contentId] ?? null;
  },
  [PUSH_SUBSCRIPTION_UNDERLYING_GETTER]: (state: State) => {
    return state.underlyingPushValues;
  },
  underlyingOptions: (state: State) => {
    return state.underlyingOptions;
  },
  underlyingOptionGroups: (state: State) => {
    return state.underlyingOptionGroups;
  },
  underlyingTranslations: (state: State) => {
    return (isin: string) => {
      return state.underlyingTranslations != null ? state.underlyingTranslations[isin] : null;
    };
  },
  isUnderlyingTranslationsLoaded: (state: State) => {
    return !!state.underlyingTranslations;
  },
};

const cache = {
  underlyingOption: false,
  underlyingOptionGroup: false,
};

export const actions = {
  async loadUnderlyingAsync({ commit }: { commit: Commit }, isin: string): Promise<void> {
    // Return cached underling if already fetched
    if (state.underlying?.holder.isin === isin) {
      return Promise.resolve();
    }
    const response = await httpClient.get<UnderlyingModel>(getTheQApiUrl(Controller.Underlying, 'Get'), {
      params: { isin: isin },
    });

    const underlyingPushPayload = createPushPayload<UnderlyingModel, UnderlyingModel>(
      response.data,
      (holder: UnderlyingModel): Array<UnderlyingModel> => [holder]
    );

    commit(LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_UNDERLYING, underlyingPushPayload);
  },

  async loadFirstUnderlyingAsync({ commit }: { commit: Commit }): Promise<void> {
    // Return cached underling if already fetched
    if (state.underlying) {
      return Promise.resolve();
    }
    const response = await httpClient.get<UnderlyingModel>(getTheQApiUrl(Controller.Underlying, 'GetFirst'));

    const underlyingPushPayload = createPushPayload<UnderlyingModel, UnderlyingModel>(
      response.data,
      (holder: UnderlyingModel): Array<UnderlyingModel> => [holder]
    );

    commit(LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_UNDERLYING, underlyingPushPayload);
  },

  registerProductUnderlyingForPush({ commit }: { commit: Commit }, underlying: UnderlyingModel): void {
    const underlyingPushPayload = createPushPayload<UnderlyingModel, UnderlyingModel>(
      underlying,
      (holder: UnderlyingModel): Array<UnderlyingModel> => [holder]
    );

    commit(LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_PRODUCT_UNDERLYING, underlyingPushPayload);
  },

  async loadTeaserUnderlyingsAsync({ commit }: { commit: Commit }, isins: string[] | string): Promise<void> {
    const response = await httpClient.get<UnderlyingModel[]>(getTheQApiUrl(Controller.Underlying, 'GetUnderlyings'), {
      params: { isins: isins },
    });

    const teaserUnderlyingPushPayload = createPushPayload<Array<UnderlyingModel>, UnderlyingModel>(
      response.data,
      (holder: UnderlyingModel[]): Array<UnderlyingModel> => holder
    );

    commit(LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_TEASER_UNDERLYINGS, teaserUnderlyingPushPayload);
  },
  async loadUnderlyingsAndSaveByIsinKeyAsync({ commit }: { commit: Commit }, isins: string[] | string): Promise<void> {
    const response = await httpClient.get<UnderlyingModel[]>(getTheQApiUrl(Controller.Underlying, 'GetUnderlyings'), {
      params: { isins: isins },
    });

    commit(LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_UNDERLYINGS_BY_KEY, response.data);
  },

  async loadTopFlopTableSearchAsync(
    { commit, state }: { commit: Commit; state: State },
    payload: TopFlopTableSearchPayload
  ): Promise<void> {
    const response = await httpClient.post<TopFlopTableSearchFilter, AxiosResponse<Array<UnderlyingModel>>>(
      getTheQApiUrl(Controller.Underlying, 'GetTopFlopTableSearch'),
      payload.filter
    );

    const getSubscriptionPushItemsFunc = (holder: {
      [contentId: number]: Array<UnderlyingModel>;
    }): Array<UnderlyingModel> => {
      const allProducts = Object.values(holder).reduce(
        (prevVal: Array<UnderlyingModel>, arr: Array<UnderlyingModel>) => prevVal.concat(arr),
        new Array<UnderlyingModel>()
      );
      // Get only unique products, so that the subscription is not triggered for multiple same products
      return uniqBy(allProducts, 'isin');
    };

    const topLeveragePushPayload = createPushPayload<{ [contentId: number]: Array<UnderlyingModel> }, UnderlyingModel>(
      { ...state?.topFlopTableSearchResult?.holder, [payload.contentId]: response.data },
      getSubscriptionPushItemsFunc
    );

    commit(
      LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_TOP_FLOP_TABLE_SEARCH_PRODUCTS,
      topLeveragePushPayload
    );
  },

  clearTeaserUnderlyings({ commit }: { commit: Commit }): void {
    const teaserUnderlyingPushPayload = createPushPayload<Array<UnderlyingModel>, UnderlyingModel>(
      [],
      (holder: UnderlyingModel[]): Array<UnderlyingModel> => holder
    );

    commit(LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_TEASER_UNDERLYINGS, teaserUnderlyingPushPayload);
  },

  async subscribePushValue({ commit, state }: { commit: Commit; state: State }, fieldKey: string): Promise<void> {
    if (state.underlyingPushValues[fieldKey] !== true) commit(UnderlyingMutation.CACHE_PUSH_SUBSCRIPTION, fieldKey);
  },

  async getUnderlyingOptions({ commit }: { commit: Commit; state: State }, isLoadOptionGroup: boolean): Promise<void> {
    if (isLoadOptionGroup) {
      if (!cache.underlyingOptionGroup) {
        cache.underlyingOptionGroup = true;
        const response = await httpClient.get<OptionGroup[]>(
          getTheQApiUrl(Controller.Underlying, 'GetUnderlyingsOptionGroups')
        );
        commit(UnderlyingMutation.CACHE_OPTION_GROUP, response.data);
      }
    } else {
      if (!cache.underlyingOption) {
        cache.underlyingOption = true;
        const response = await httpClient.get<Option[]>(getTheQApiUrl(Controller.Underlying, 'GetUnderlyingsOptions'));
        commit(UnderlyingMutation.CACHE_OPTION, response.data);
      }
    }
  },

  async init({ commit }: { commit: Commit }): Promise<void> {
    const response = await httpClient.get<{ [key: string]: string }>(
      getTheQApiUrl(Controller.Underlying, 'GetUnderlyingTranslations')
    );
    commit(UnderlyingMutation.CACHE_TRANSLATION_UNDERLYING, response.data);
  },
};
