<template>
  <b-button class="d-inline" :disabled="!link || !this.currentUnderlying" :href="link" v-bind="$attrs">
    <slot></slot>
  </b-button>
</template>

<script lang="ts">
import { PageRoutes } from '@/src/types/episerver-api';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { tryCreateUnderlyingLink } from '@/src/utils/url-helper';
import evaluateExpressionByKey from '@/src/utils/value-formatter/format-helper';
import { Component, Inject, InjectReactive, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class AdUnderlyingLink extends Vue {
  @Inject() pageRoutes!: PageRoutes;
  @InjectReactive() underlying!: UnderlyingModel | null;
  @Prop() underlyingItem!: UnderlyingModel | null;

  get currentUnderlying(): UnderlyingModel | null {
    return this.underlyingItem || this.underlying;
  }

  get link(): string | null {
    // The isin check is needed, since an empty object may be passed down from AdProductDetails through AdUnderlyingDataProperty
    if (this.currentUnderlying?.isin) {
      let nsin;
      if (this.pageRoutes.urlKeys?.underlyingKey) {
        nsin = evaluateExpressionByKey(this.pageRoutes.urlKeys?.underlyingKey, {
          underlying: this.currentUnderlying,
        });
      } else {
        nsin = this.currentUnderlying.isin;
      }

      return tryCreateUnderlyingLink(this.pageRoutes, nsin, this.currentUnderlying.name);
    }

    return null;
  }
}
</script>
