<template>
  <a
    :class="['p-3 d-inline-block ce-download', buttonClass, { disabled: disabled, 'full-width': isFullWidth }]"
    :href="url"
    @click.prevent="disabled ? undefined : handleButtonClick()"
  >
    <div class="row no-gutters">
      <div v-if="hasImageSlot" class="col-3">
        <div class="aspect-ratio-120-93 d-block">
          <slot name="image"></slot>
        </div>
      </div>
      <div v-if="!isIconAtRight" :class="alignment" class="col-2 ce-download__icon d-flex justify-content-center">
        <ad-icon-download />
      </div>
      <div :class="colNumber" class="ce-download__text d-flex flex-column align-items-start text-left">
        <div class="d-flex align-items-start mb-1">
          <slot name="text"></slot>
        </div>
        <div v-if="!hideFileFormat" class="fs-14 ce-download__file-format">
          <slot name="fileFormat"></slot>
        </div>
      </div>
      <div v-if="isIconAtRight" class="col-2 ce-download__icon d-flex justify-content-center align-items-center">
        <ad-icon-download />
      </div>
    </div>
  </a>
</template>
<script lang="ts">
import { Prop, Vue, Component } from 'vue-property-decorator';
import { downloadFileGetOrOpenInNewWindowForExternalUrl } from '@src/utils/download-file';
import { HttpStatusCodes } from '@src/types/enumerations';

@Component
export default class CeDownload extends Vue {
  @Prop({ default: '' }) url!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) isIconAtRight!: boolean;
  @Prop({ type: Boolean, default: false }) hideFileFormat!: boolean;
  @Prop({ type: Boolean, default: false }) isFullWidth!: boolean;
  /* eslint-disable @typescript-eslint/no-empty-function */
  @Prop({
    default: () => {},
    type: Function,
  })
  downloadClick!: () => void;
  /* eslint-enable @typescript-eslint/no-empty-function */

  get hasImageSlot() {
    return !!this.$slots.image;
  }

  get colNumber() {
    if (this.$slots.image) return 'col-7';
    return 'col-10';
  }

  get alignment() {
    if (this.$slots.image) return 'pt-1 align-items-start';
    return 'align-items-center';
  }

  get buttonClass() {
    if (this.hasImageSlot) return '';
    return 'imageless';
  }

  async handleButtonClick() {
    if (this.disabled) return;
    this.downloadClick();

    const result = await downloadFileGetOrOpenInNewWindowForExternalUrl(this.url);

    if (result !== HttpStatusCodes.OK) {
      this.$emit('downloadError', result);
    }
  }
}
</script>
<style lang="scss">
.ce-download {
  margin-bottom: map-get($spacers, 2);
  border: 1px solid $silver;
  border-radius: 4px;
  background: $white;
  width: rem(410);
  max-width: 100%;

  &:hover {
    .ce-download {
      &__icon {
        color: $bright-sky-blue;
      }
      &__text {
        color: $marine-blue;
      }
    }
  }

  &:focus {
    outline-color: $peacock-blue;
  }

  &:disabled {
    background: $light-grey;
    cursor: default;

    .ce-download {
      &__text {
        color: $cool-grey;
      }
      &__file-format {
        color: $cool-grey;
      }
      &__icon {
        color: $cool-grey;
      }
    }
  }

  &__text {
    line-height: 1.3;
    color: $peacock-blue;
  }

  &__file-format {
    color: $peacock-blue;
  }

  &__icon {
    color: $clarinet;
  }

  &.full-width {
    width: 100%;
  }
}

.imageless:not(:disabled) {
  background: $silver;
  &:hover {
    background: $peacock-blue;
    .ce-download {
      &__text {
        color: $white;
      }
      &__icon {
        color: $white;
      }
      &__file-format {
        color: $white;
      }
    }
  }
}
</style>
