<template>
  <ad-icon-base
    iconName="chevron-down"
    v-bind="$attrs"
    viewBox="0 10 40 20"
    :width="width"
    :height="height"
    :rotate="iconRotate"
  >
    <path
      d="M18,29.2c1.1,1.1,2.8,1.1,3.9,0l17.9-17.6c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-0.9-0.4-1.3,0L20.5,27.9
	c-0.4,0.4-0.9,0.3-1.3,0L1.5,10.3c-0.4-0.3-0.9-0.3-1.3,0c-0.3,0.3-0.3,0.9,0,1.2L18,29.2L18,29.2z"
    />
  </ad-icon-base>
</template>
<script lang="ts">
import { Size, Direction } from '@/src/types/enumerations';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class AdIconChevron extends Vue {
  @Prop() size: Size | undefined;
  @Prop({ default: Direction.down }) direction!: Direction | string;
  @Prop({ type: [String, Number] }) rotate?: string | number | undefined;

  private get sizeLength(): number | undefined {
    switch (this.size) {
      case Size.xxs:
        return 7.2;
      case Size.xs:
        return 10;
      case Size.s:
        return 12;
      case Size.m:
        return 16;
      case Size.l:
        return 22;
      case Size.xl:
        return 24;
      case Size.xxl:
        return 36;
      default:
        return undefined;
    }
  }
  get iconRotate(): string | number {
    if (this.rotate !== undefined) return this.rotate;
    if (typeof this.direction == 'number') return this.direction;

    switch (this.direction) {
      case 'up':
        return Direction.up;
      case 'down':
        return Direction.down;
      case 'left':
        return Direction.left;
      case 'right':
        return Direction.right;
    }
    return 0;
  }

  get width(): string | undefined {
    return this.sizeLength !== undefined ? this.sizeLength + 'px' : undefined;
  }
  get height(): string | undefined {
    return this.sizeLength !== undefined ? this.sizeLength / 2 + 'px' : undefined;
  }
}
</script>
