<template>
  <ad-icon-base v-bind="$attrs" stroke-width="0.5">
    <path
      d="M8.5,8a.5.5,0,0,0-.5.5H8V30a2,2,0,0,0,2.06,2H31.5a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H10.06A1,1,0,0,1,9,30V8.5A.5.5,0,0,0,8.5,8Z"
    /><path
      d="M12,29.68,16.81,20H24a.49.49,0,0,0,.44-.26l5-9.5a.5.5,0,1,0-.88-.47L23.7,19H16.5a.51.51,0,0,0-.45.28l-5,10a.5.5,0,0,0,.23.67.49.49,0,0,0,.67-.22Z"
    />
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconChartCandlestick extends Vue {}
</script>
