<template>
  <a class="link btn btn-default ce-author-like-share__share-button flex-grow-1" :href="this.fullUrl" target="_blank">
    <slot name="label">Share Facebook</slot>
  </a>
</template>

<script lang="ts">
import { getFacebookShareIntentURL } from '@/src/utils/url-helper';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CeFacebookButton extends Vue {
  @Prop({ default: '' }) private facebookAppId!: string;
  @Prop({ default: '' }) private shareURL!: string;
  get fullUrl(): string {
    return getFacebookShareIntentURL({ urlToShare: this.shareURL, appId: this.facebookAppId });
  }
}
</script>
