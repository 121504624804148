<template>
  <!-- a-d is separated by the dash to not trigger a-b-blockers -->
  <div class="a-d-marker fs-12"><slot>Advertisement</slot></div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CeAdMarker extends Vue {}
</script>

<style lang="scss">
.a-d-marker {
  position: absolute;
  right: 0;
  bottom: 100%;
}
</style>
