<template>
  <div class="content-container container-fluid mb-7 mb-md-8">
    <h2><slot name="headline"></slot></h2>
    <ad-index :index="0" v-if="items[0]">
      <ce-node-renderer :node="items[0]" />
    </ad-index>
    <div class="row mb-0 mb-md-5 pt-md-5 d-flex justify-content-md-between mb-0 mb-md-5">
      <ad-index :index="1" v-if="items[1]">
        <ce-node-renderer :node="items[1]" />
      </ad-index>
      <ad-index :index="2" v-if="items[2]">
        <ce-node-renderer :node="items[2]" />
      </ad-index>
    </div>
    <div class="row pt-0 mt-5 mt-md-0 pt-md-5" v-if="showMoreUrl">
      <div class="col-12">
        <a :href="showMoreUrl" class="btn btn-secondary w-100 w-md-auto"><slot name="showmore">Show more</slot></a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { filterVNodes } from '@src/utils/vnodes-filter';
import { VNode } from 'vue';

@Component
export default class CeAdNewsTeaserMarketsContainer extends Vue {
  @Prop() showMoreUrl: string | undefined;

  get items(): VNode[] {
    return filterVNodes(this.$slots.items);
  }
}
</script>

<style lang="scss">
.ce-news-teaser-markets {
  &__small-header {
    color: $peacock-blue;
    font-size: rem(16);
  }

  &__date {
    font-size: rem(16);
  }

  &__like {
    padding-left: map-get($spacers, 2);
    color: $peacock-blue;
    font-weight: bold;
  }
}
</style>
