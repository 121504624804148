<template>
  <div v-bind="$attrs">
    <b-table-simple striped borderless>
      <b-thead>
        <b-th v-for="(header, h) in headerItems" :key="h" :class="headerClass(h)">
          <template v-for="(column, c) in header">{{ column }}<br :key="c" /></template>
        </b-th>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(row, r) in rowItems" :key="r">
          <b-td v-for="(cell, c) in row" :key="c" :class="cellClass(c)">
            <template v-for="(field, f) in cell">{{ field }}<br :key="f" /></template>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  inheritAttrs: false,
})
export default class AdSimpleTable extends Vue {
  @Prop({ required: true }) headers!: string[];
  @Prop({ required: true }) rows!: string[][];

  get headerItems(): string[][] {
    return this.group(this.headers);
  }

  get rowItems(): string[][][] {
    return this.rows.map((row) => this.group(row));
  }

  headerClass(colIndex: number): string[] {
    return ['align-bottom', 'pt-0', ...this.cellTextAlign(colIndex)];
  }

  cellClass(colIndex: number): string[] {
    const styles = this.cellTextAlign(colIndex);
    if (!this.$screen.md) styles.push('align-bottom');
    styles.push('text-nowrap');
    return styles;
  }

  private cellTextAlign(colIndex: number): string[] {
    const styles: string[] = [];

    switch (colIndex) {
      case 0:
        styles.push('text-left');
        break;
      case 1:
        styles.push('text-right');
        if (this.headers.length > 2) {
          styles.push('text-md-left');
          styles.push('text-xl-right');
        }
        break;
      default:
        styles.push('text-right');
        break;
    }
    return styles;
  }

  group(data: string[]): string[][] {
    if (this.$screen.xl || data.length < 3) return data.map((i) => [i]);

    const result = data.reduce<string[][]>(
      (cols, item, index) => {
        if (index != 0) cols[index % 2].push(item);
        return cols;
      },
      [[], []]
    );
    result.reverse();
    if (this.$screen.sm) {
      result.unshift([data[0]]);
    } else {
      result[0].unshift(data[0]);
    }

    return result;
  }
}
</script>
