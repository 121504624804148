<template>
  <b-container class="content-container-sm container-fluid mb-7 mb-md-8">
    <b-row>
      <b-col class="col-12 offset-md-2 col-md-8">
        <div :class="containerClass">
          <b-input-group :class="inputClass" ref="searchInput">
            <b-input-group-prepend class="ad-priip-search__icon fs-18 pr-2 py-0">
              <b-spinner v-if="isLoading"></b-spinner>
              <ad-icon-search v-else></ad-icon-search>
            </b-input-group-prepend>
            <b-form-input
              :placeholder="settingsProperty?.inputPlaceholder"
              class="border-0 bg-transparent"
              v-model="isin"
              @keypress.enter="search"
              @input="resetSearchState"
            ></b-form-input>
            <b-popover
              v-if="hasFailed"
              :target="$refs.searchInput"
              triggers="focus hover"
              :show.sync="showPopover"
              variant="danger"
              placement="bottom"
              ><div class="text-danger"
                ><ad-icon-warnings class="mr-2 align-top" /><slot name="error-message"></slot></div
            ></b-popover>
            <b-popover
              v-if="!isInputValid"
              :target="$refs.searchInput"
              triggers="focus hover"
              :show.sync="showPopover"
              variant="danger"
              placement="bottom"
              ><div class="text-danger"
                ><ad-icon-warnings class="mr-2 align-top" /><slot name="no-results-message"></slot></div
            ></b-popover>
          </b-input-group>
          <button class="btn btn-secondary py-1 fs-18" @click="search">
            <slot name="search-button-label" />
          </button>
        </div> </b-col
    ></b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Inject, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { isAxiosError } from 'axios';
import pDebounce from 'p-debounce';
import { PriipSearchBlockSettings } from '@/src/types/episerver-api';
import { PriipModel } from '@/src/types/the-q-api';
import { SearchState } from '@/src/types/enumerations';

@Component
export default class AdPriipSearch extends Vue {
  @Prop()
  public detailPagePath!: string | null;

  @Prop()
  public minInputLength!: number;

  @Inject() settingsProperty?: PriipSearchBlockSettings;

  @Getter('product', { namespace: 'priip' })
  product!: PriipModel | null;

  @Action('loadProductAsync', { namespace: 'priip' })
  loadProductAsync!: (isin: string) => Promise<void>;

  private isin = null as string | null;
  private searchState = SearchState.Initial;
  private isLoading = false;
  private showPopover = false;
  private flashMessageTimeoutId = -1;
  private debounceDelay = 500;

  @Watch('searchState')
  private stateChanged(newValue: SearchState, oldValue: SearchState) {
    if (newValue != SearchState.Initial) {
      this.flashMessage();
    }
  }

  private debounceSearch = pDebounce(this.startSearch, this.debounceDelay);

  private async search() {
    if ((this.isin?.length ?? 0) < this.minInputLength) {
      this.searchState = SearchState.NotFound;
      return;
    }
    this.debounceSearch();
  }

  private async startSearch() {
    this.resetSearchState();
    this.isLoading = true;

    try {
      await this.loadProductAsync(this.isin!);
      if (this.product) {
        this.resetSearchState();
        this.navigateToDetailPage(this.product.isin);
      } else {
        this.searchState = SearchState.NotFound;
      }
    } catch (error: unknown) {
      console.log(error);
      if (isAxiosError(error) && (error?.response?.status === 404 || error?.response?.status === 400)) {
        this.searchState = SearchState.NotFound;
      } else {
        this.searchState = SearchState.Failed;
      }
    } finally {
      this.isLoading = false;
    }
  }

  private navigateToDetailPage(isin: string) {
    const urlWithFragment = this.detailPagePath + isin;
    document.location.href = urlWithFragment;
  }

  private resetSearchState() {
    this.searchState = SearchState.Initial;
  }

  private get hasFailed(): boolean {
    return this.searchState == SearchState.Failed;
  }

  private get isInputValid(): boolean {
    return this.searchState != SearchState.NotFound;
  }

  private get isFine(): boolean {
    return this.searchState == SearchState.Initial;
  }

  private get containerClass(): (string | Record<string, boolean>)[] {
    return ['d-flex align-items-center', { 'show-invalid': !this.isFine }];
  }

  private get inputClass(): (string | Record<string, boolean>)[] {
    return ['ad-priip-search-input-group mr-2 p-2', { 'in-valid': !this.isFine }];
  }

  private flashMessage() {
    this.showPopover = true;
    const self = this;

    if (this.flashMessageTimeoutId > 0) {
      window.clearTimeout(this.flashMessageTimeoutId);
      this.flashMessageTimeoutId = -1;
    }
    this.flashMessageTimeoutId = window.setTimeout(() => {
      var timeoutId = self.flashMessageTimeoutId;
      self.hideMessage(timeoutId);
    }, 5000);
  }

  private hideMessage(timeoutId: number) {
    this.showPopover = false;
    if (timeoutId === this.flashMessageTimeoutId) {
      this.flashMessageTimeoutId = -1;
    }
  }
}
</script>
<style lang="scss">
.ad-priip-search-input-group {
  border: 1px solid $cool-grey;
  border-radius: $border-radius;

  &:focus-within {
    background: $light-grey;
  }

  input {
    height: 20px;
  }
  .spinner-border {
    width: 1.3em;
    height: 1.3em;
  }
}
</style>
