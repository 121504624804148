<template>
  <ad-product-teaser-property
    :teaserSettingsProperty="teaserSettings"
    :isAddColumnDistributionFee="filter.isAddColumnDistributionFee"
  >
    <div class="product-search-table">
      <ad-instrument-table
        v-bind="$attrs"
        @sorting-value-changed="sortingChanged"
        :instruments="products"
        :sort-orders="filter.sortOrders"
        :is-show-column="isShowColumn"
        sorting
        :settings="settingsProperty"
        :rows="25"
        :loading="loading"
        @pushing-items="pushingItems"
      >
        <template #row-details="{ data }">
          <ad-product-data-property :product="data.item.instrument">
            <!-- wrap in div to prevent multiple root nodes returned from render function error  -->
            <div class="wrap">
              <slot name="product-detail-page-teaser" :data="data">{{ data.item.instrument }}</slot>
            </div>
          </ad-product-data-property>
        </template>
        <template #row-instrument="{ setting, instrument }">
          <ad-product-data-value
            :is-link-to-detail-page="setting.isLinkToDetailPage"
            :product-item="instrument"
            :field-key="setting.formatId"
          />
        </template>
        <template #row-watch-list="{ instrument }">
          <ad-product-watchlist-toggle :product-item="instrument" />
        </template>
      </ad-instrument-table>
    </div>
  </ad-product-teaser-property>
</template>

<script lang="ts">
import {
  ProductSearchPageSetting,
  InstrumentMergeColumnSetting,
  PageRoutes,
  ProductSearchTableSetting,
} from '@t/episerver-api';
import { ProductModel, SortOrder } from '@t/the-q-api';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { ProductSearchParametersFilter, ProductTeaserSettings } from '@t/vue-api';
import { BRow } from 'bootstrap-vue';
import { flatMapMergeColumns, getProductTeaserSettings } from '@src/utils/table-columns-helper';

@Component({
  inheritAttrs: false,
})
export default class AdProductSearchTable extends Vue {
  @Inject() settingsProperty!: ProductSearchPageSetting;
  @Inject() pageRoutes!: PageRoutes;
  @Prop({ required: true }) filter!: ProductSearchParametersFilter;
  @Prop({ required: true }) products!: Array<ProductModel>;
  @Prop({ required: true, type: Boolean }) loading!: boolean;

  private currentOpenItem;

  private pushingItems(min: number, max: number) {
    this.$emit('pushing-items', min, max);
  }

  sortingChanged(sortOrders: Array<SortOrder>) {
    this.filter.sortOrders = sortOrders;
    this.$emit('sorting-value-changed');
  }

  isShowColumn(column: InstrumentMergeColumnSetting) {
    return (
      !flatMapMergeColumns([column]).find((c) => (c as ProductSearchTableSetting).isDistributionSwitchColumn) ||
      this.filter.isAddColumnDistributionFee
    );
  }

  toggleDetails(e: Event, row: BRow) {
    row.toggleDetails(e);

    if (this.currentOpenItem && this.currentOpenItem !== row.item) {
      this.currentOpenItem._showDetails = false;
    }
    this.currentOpenItem = row.item;
  }

  get teaserSettings(): ProductTeaserSettings {
    return getProductTeaserSettings(this.settingsProperty);
  }
}
</script>
