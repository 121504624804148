<script lang="ts">
/**
 * VRuntimeTemplate should not be used in a v-for loop directly.
 * That's why this component is created which uses VRuntimeComponent internally.
 */
import VRuntimeTemplate from 'v-runtime-template';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ components: VRuntimeTemplate })
export default class AdRuntimeComponent extends Vue {
  @Prop({ required: true, type: String }) htmlTemplate;

  render(h) {
    return h(VRuntimeTemplate, { props: { template: this.htmlTemplate } });
  }
}
</script>
