<template>
  <header class="ce-header fs-16">
    <b-navbar type="light" variant="light" class="ce-header__meta important">
      <!-- Right aligned nav items -->
      <b-navbar-nav class="bv-d-lg-down-none ml-auto">
        <b-nav-item-dropdown class="ce-header__selection-dropdown" right no-caret v-if="selectedCountry">
          <template v-slot:button-content
            >{{ selectedCountry }}<b-icon icon="caret-down" class="ml-2"></b-icon>
          </template>
          <slot name="countries"></slot>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown class="ce-header__selection-dropdown" right no-caret v-if="selectedLanguage">
          <template v-slot:button-content
            >{{ selectedLanguage }}<b-icon icon="caret-down" class="ml-2"></b-icon>
          </template>
          <slot name="languages"></slot>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    <div class="ce-header__logo d-xl-none py-1">
      <a class="h-100" v-bind:href="logoLinkHref" v-bind:title="logoLinkTitle">
        <img class="logo" src="~@assets/citi_logo_header.svg" alt="Citi Logo" width="64" />
      </a>
    </div>
    <b-navbar
      toggleable="xl"
      class="ce-header__navbar-menu pt-0"
      v-for="(classes, index) of navInstanceClasses"
      v-bind:key="index"
      v-bind:class="classes"
    >
      <div class="row w-100 d-flex align-items-center accordion">
        <div class="col-6 d-xl-none">
          <ce-header-back class="ce-header__back" v-if="enableGoBack"
            ><template v-slot:back-menu><slot name="back-menu" /></template>
            <template #go-back><slot name="go-back" /></template
          ></ce-header-back>
        </div>
        <div
          class="col-6 col-xl-12 d-flex justify-content-end justify-content-xl-between align-items-center ce-header__menu py-1 py-xl-0 pr-xl-0"
        >
          <div class="d-flex">
            <div class="ce-header__logo d-none d-xl-block pb-3 pt-2">
              <a class="h-100" v-bind:href="logoLinkHref" v-bind:title="logoLinkTitle">
                <img class="logo" src="~@assets/citi_logo_header.svg" alt="Citi Logo" width="64" />
              </a>
            </div>
            <ce-header-nav
              :selectedCountry="selectedCountry"
              :countryDropdownLabel="countryDropdownLabel"
              :selectedLanguage="selectedLanguage"
              :languageDropdownLabel="languageDropdownLabel"
              class="align-self-end"
              id="collapse"
            >
              <template v-slot:main-menu><slot name="main-menu"></slot></template>
              <template v-slot:countries><slot name="countries"></slot></template>
              <template v-slot:languages><slot name="languages"></slot></template>
            </ce-header-nav>
          </div>
          <slot name="header-search"></slot>
        </div>
      </div>
    </b-navbar>
  </header>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CeHeader extends Vue {
  @Prop({ default: '/' }) private logoLinkHref!: string;
  @Prop({ default: 'Back to Start' }) private logoLinkTitle: string | undefined;
  @Prop({ default: '/' }) private watchlistLinkHref!: string;
  @Prop() private selectedCountry!: string;
  @Prop() private countryDropdownLabel: string | undefined;
  @Prop() private selectedLanguage!: string;
  @Prop() private languageDropdownLabel: string | undefined;
  @Prop({ type: Boolean, default: false }) private enableGoBack!: boolean;

  private shouldStick = false;

  /** @todo: get number from list */
  get watchlistCount(): number {
    return 12;
  }

  get navInstanceClasses() {
    /**
     * We need to use position: fixed for the sticky nav, because it has to be
     * inside of the <header> tag, so that we can not use position: sticky
     * without reworking the layout of this component.
     *
     * switching to position fixed leads to a layout shift of the whole page,
     * so that we have to keep the original nav in place (but hide it visually)
     * when we need to display the fixed navigation.
     */
    if (this.shouldStick) return ['sticky', 'hidden'];
    return [''];
  }

  mounted(): void {
    window.addEventListener('scroll', this.updateStickyness);
    this.updateStickyness();
  }

  destroy(): void {
    window.removeEventListener('scroll', this.updateStickyness);
  }
  updateStickyness(): void {
    this.shouldStick = Math.round(window.scrollY) >= 131;
  }
}
</script>

<style lang="scss">
.ce-header {
  background: $white;

  &,
  .navbar-brand {
    font-size: $h6-font-size;
  }

  .navbar-brand {
    margin: 0;
    padding: 0;
  }
  &__selection-dropdown {
    ul {
      height: 220px;
      overflow-y: scroll;
    }
  }
  .bg-light {
    background: transparent !important;
  }

  &__meta.important {
    padding: 0 $grid-gutter-width;
    line-height: 1.875;

    .dropdown-menu {
      margin: 0;
      border: 0;
      border-radius: 0;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
      padding: map-get($spacers, 2) calc($grid-gutter-width / 2) map-get($spacers, 3);
      min-width: 300px;

      li {
        border-bottom: 1px solid $cool-grey;
      }
    }

    .dropdown-item {
      padding: rem(14) 0;
      line-height: 1;
      color: $gunmetal;
      font-size: $fs-14;

      &:hover,
      &:focus {
        background: transparent;
        color: $marine-blue;
      }

      &.active {
        border-bottom: 4px solid $header-highlight-color;
        background: transparent;
        padding-bottom: rem(10);
        color: $header-highlight-color;
      }
    }
  }

  &__logo {
    z-index: 2;
    padding: 0 $grid-gutter-width 0;
  }

  &__navbar-menu-slots {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media-breakpoint-up('lg') {
      width: 100%;
    }
  }

  &__menu {
    pointer-events: all;
  }
  & &__navbar-menu {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    pointer-events: none;

    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      border-bottom: 1px solid $cool-grey;
      background-color: $white;
      padding: 0.5rem;
      width: 100%;
      pointer-events: all;
      @include media-breakpoint-up('xl') {
        padding: 0 1.25rem;
      }
    }

    &.hidden {
      visibility: hidden;
    }

    @include media-breakpoint-down('lg') {
      position: absolute;
      top: 0;
      right: 0;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      padding-top: rem(50);
      &.sticky {
        justify-content: space-between;
      }
    }
  }

  @include media-breakpoint-up('xl') {
    border-bottom: 1px solid $cool-grey;
  }
}

.sticky .ce-header-flyout {
  @include media-breakpoint-down('lg') {
    position: fixed !important;
    top: rem(66.25);
    left: 100vw;
    transition: left 0.2s ease-in-out;
    width: 100vw;
    height: calc(100vh - #{$header-height-default});
    overflow-x: hidden;
    &.show {
      left: 0;
    }
  }
}

.ce-header-flyout {
  background: $white;
  overflow-y: visible;

  @include media-breakpoint-down('lg') {
    position: fixed !important;
    top: rem(116);
    left: 100vw;
    transition: left 0.2s ease-in-out;
    width: 100vw;
    height: calc(100vh - #{$header-height-default});
    overflow-y: scroll;
    overscroll-behavior: contain;

    &.show {
      left: 0;
    }
  }
}
</style>
