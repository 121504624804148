<template>
  <div class="ad-anchor-navigation">
    <div class="ad-anchor-sticky">
      <h4 class="anchor-heading">
        <slot name="anchor-navigation-headline"></slot>
      </h4>
      <b-nav class="anchors-list" v-b-scrollspy="100">
        <slot name="anchors"></slot>
      </b-nav>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { scrollToElement, jumpToAnchor } from '@src/utils/scroll-helpers';
@Component
export default class AdAnchorNavigation extends Vue {
  mounted() {
    this.$on('anchor-nav-click', this.scrollToView);

    this.$nextTick(jumpToAnchor);
  }

  scrollToView(e: Event): void {
    e.preventDefault();

    const targetHref = e.target != null ? e.target['href'] : null;
    if (targetHref != null) {
      history.replaceState({}, '', targetHref); // to avoid browser behavior of jumping to element (would be incorrectly positioned)
      const $element = document.getElementById(location.hash.substr(1));
      scrollToElement($element, true);
    }
  }
}
</script>
<style lang="scss">
.ad-anchor-sticky {
  /* stylelint-disable-next-line */
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  bottom: 0;
}

.ad-anchor-navigation {
  // dotted line
  background-image: linear-gradient($marine-blue 33%, rgba(255, 255, 255, 0) 0%);
  background-position: left top;
  background-repeat: repeat-y;
  background-size: 2px 6px;
  height: 100%;

  .anchor-heading {
    margin: 0;
    margin-left: 0.7rem;
    text-transform: uppercase;
    font-size: $fs-14 !important;
    font-weight: bold;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  /* stylelint-disable selector-no-qualifying-type */
  ul.anchors-list {
    flex-direction: column;
    margin-left: -0.7rem;
    padding-left: 0.5rem !important;

    .anchor-navigation-li {
      margin: 1rem 0 1rem 0.5rem;

      &::before {
        // overwrite from _typography.scss
        margin-left: -0.52rem !important;
      }
    }
  }
}
</style>
