<template>
  <ad-skeleton-text :loading="underlying == null">
    <span v-bind="$attrs" v-if="preHeadlineText">{{ preHeadlineText }}</span>
  </ad-skeleton-text>
</template>
<script lang="ts">
import { UdpPreheadline } from '@/src/types/episerver-api';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class AdUnderlyingPreheadlineText extends Vue {
  @Prop() udpPreheadlines!: UdpPreheadline[];
  @Prop() underlying!: UnderlyingModel;

  get preHeadlineText(): string | null {
    if (this.udpPreheadlines === undefined || this.udpPreheadlines === null) {
      return null;
    }

    const udpPreheadline = this.udpPreheadlines.find((udpPreheadline) =>
      udpPreheadline.forUnderlyings?.some((isin) => isin === this.underlying?.isin)
    );

    if (udpPreheadline === null || udpPreheadline === undefined) {
      return null;
    }

    return udpPreheadline.preheadlineText;
  }
}
</script>
