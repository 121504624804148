<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-skeleton class="w-90"></b-skeleton>
    </template>
    <slot></slot>
  </b-skeleton-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AdSkeletonText extends Vue {
  @Prop({ default: true, type: Boolean, required: true }) loading;
}
</script>
