// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

import Vue from 'vue';
import { camelCase, upperFirst } from 'lodash';

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the current directory
  '@components',
  // Look in subdirectories
  true,
  // Include all .vue files
  /.*\.vue$/
);

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  // Get the PascalCase version of the component name
  const componentName = upperFirst(camelCase(fileName.replace(/^.+\/([^/]+)\/.*.vue/, '$1')));

  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig);
});
