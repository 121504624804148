import httpClient from '@/src/utils/http-service';
import Vue from 'vue';
import {
  Option,
  OptionGroup,
  ProductModel,
  ProductPricePush,
  ProductSearchDetailedResultModel,
  ProductTypeGroupOptionsFilter,
  ProductTypeOptionsFilter,
} from '@src/types/the-q-api';
import { getTheQApiUrl } from '@/src/utils/url-helper';
import { Controller, ProductTypeBo, TopLeverageTableFilter } from '@/src/types/enumerations';
import { Commit } from 'vuex';
import { MutationPushPayloadHolder, ProductSearchParametersFilter } from '@/src/types/vue-api';
import { unionBy, uniqBy } from 'lodash';
import { isMutationPushPayloadHolder, isNotNullOrUndefined } from '@/src/types/type-guards';
import {
  createPushPayload,
  LightStreamerProductSubscriptionMutationTypes,
  PUSH_SUBSCRIPTION_PRODUCT_GETTER,
  PUSH_UPDATE_PRODUCT,
  tryUpdateProductValue,
} from '@/src/state/plugins/light-streamer/pushing-helper';
import { ProductType, ProductUnderlyingFilter } from '@/src/types/episerver-api';
import { AxiosResponse } from 'axios';

export const state = {
  productPushValue: {} as { [key: string]: boolean },
  product: null as MutationPushPayloadHolder<ProductModel, ProductModel> | null,
  products: null as MutationPushPayloadHolder<Array<ProductModel>, ProductModel> | null,
  concatProducts: null as MutationPushPayloadHolder<Array<ProductModel>, ProductModel> | null,
  productSearchDetailResult: null as MutationPushPayloadHolder<ProductSearchDetailedResultModel, ProductModel> | null,
  topLeverageTableSearchResult: null as MutationPushPayloadHolder<
    { [key: string]: Array<ProductModel> },
    ProductModel
  > | null,
  productUnderlyingTypes: {} as { [key: string]: ProductType[] },
  productTypeOptions: {} as { [key: string]: Option[] },
  productTypeGroupOptions: {} as { [key: string]: OptionGroup[] },
};

export enum MutationTypes {
  PRODUCT_PUSH_VALUE = 'PRODUCT_PUSH_VALUE',
  PRODUCT_UNDERLYING_TYPES = 'PRODUCT_UNDERLYING_TYPES',
  PRODUCT_TYPE_OPTIONS = 'PRODUCT_TYPE_OPTIONS',
  PRODUCT_TYPE_GROUP_OPTIONS = 'PRODUCT_TYPE_GROUP_OPTIONS',
}

type State = typeof state;

function getProductUnderlyingFilterKey(filter: ProductUnderlyingFilter) {
  return filter.underlyingIsin + filter.types.flatMap((t) => t.subTypeCode + t.typeCode).join('-');
}

function getProductTypeOptionsFilterKey(filter: ProductTypeOptionsFilter) {
  return filter.underlyingIsin ?? 'no-underlying';
}

function getProductTypeGroupOptionsFilterKey(filter: ProductTypeGroupOptionsFilter) {
  return filter.underlyingIsin || 'no-underlying' + filter.onlyLeverage;
}

async function QueryProductsWithPushPayload(
  isinOrNsins: string[]
): Promise<MutationPushPayloadHolder<ProductModel[], ProductModel>> {
  const responseData: Array<ProductModel | undefined> = await Promise.all(
    isinOrNsins.map(async (id) => {
      try {
        const response = await httpClient.get<ProductModel>(getTheQApiUrl(Controller.Product, 'Get'), {
          params: { isinOrNsin: id },
        });
        return response.data;
      } catch {
        return;
      }
    })
  );
  const data: Array<ProductModel> = responseData.filter(isNotNullOrUndefined);
  const getSubscriptionPushItemsFunc = (holder: Array<ProductModel>): Array<ProductModel> => {
    // Get only unique products, so that the subscription is not triggered for multiple same products
    return uniqBy(holder, 'isin') as Array<ProductModel>;
  };
  return createPushPayload<Array<ProductModel>, ProductModel>(data, getSubscriptionPushItemsFunc);
}

export const getters = {
  product: (state: State): ProductModel | null => {
    return state.product?.holder ?? null;
  },
  productByIsin: (state: State) => {
    return (productIsin: string) => state.concatProducts?.holder.find((p) => p.isin == productIsin) ?? null;
  },
  productSearchDetailResult: (state: State): ProductSearchDetailedResultModel | {} => {
    return state.productSearchDetailResult?.holder ?? {};
  },
  topLeverageSearchResult: (state: State) => {
    return (filter: TopLeverageTableFilter) => state.topLeverageTableSearchResult?.holder[filter] ?? null;
  },
  productUnderlyingTypes: (state: State) => {
    return (filter: ProductUnderlyingFilter) =>
      state.productUnderlyingTypes[getProductUnderlyingFilterKey(filter)] || [];
  },
  productTypeOptions: (state: State) => {
    return (filter: ProductTypeOptionsFilter) => state.productTypeOptions[getProductTypeOptionsFilterKey(filter)] || [];
  },
  productTypeGroupOptions: (state: State) => {
    return (filter: ProductTypeGroupOptionsFilter) =>
      state.productTypeGroupOptions[getProductTypeGroupOptionsFilterKey(filter)] || [];
  },
  [PUSH_SUBSCRIPTION_PRODUCT_GETTER]: (state: State) => {
    return state.productPushValue;
  },
};

export const mutations = {
  [LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCT](
    state: State,
    productPushPayload: MutationPushPayloadHolder<ProductModel, ProductModel>
  ) {
    state.product = productPushPayload;
  },
  [LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCTS](
    state: State,
    productPushPayload: MutationPushPayloadHolder<Array<ProductModel>, ProductModel>
  ) {
    state.products = productPushPayload;
  },
  [LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCTS_CONCAT](
    state: State,
    productPushPayload: MutationPushPayloadHolder<Array<ProductModel>, ProductModel>
  ) {
    state.concatProducts == null
      ? (state.concatProducts = productPushPayload)
      : (state.concatProducts.holder = unionBy(state.concatProducts.holder, productPushPayload.holder, 'securityCode'));
  },
  [LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCTS_SEARCH](
    state: State,
    productSearchPushPayload: MutationPushPayloadHolder<ProductSearchDetailedResultModel, ProductModel>
  ) {
    state.productSearchDetailResult = productSearchPushPayload;
  },
  [LightStreamerProductSubscriptionMutationTypes.CACHE_TOP_LEVERAGE_TABLE_SEARCH_PRODUCTS](
    state: State,
    topLeveragePushPayload: MutationPushPayloadHolder<{ [key: string]: Array<ProductModel> }, ProductModel>
  ) {
    state.topLeverageTableSearchResult = topLeveragePushPayload;
  },
  [MutationTypes.PRODUCT_PUSH_VALUE](state: State, fieldKey: string) {
    state.productPushValue[fieldKey] = true;
  },
  [MutationTypes.PRODUCT_UNDERLYING_TYPES](state: State, productUnderlyingTypes: { [key: string]: ProductType[] }) {
    state.productUnderlyingTypes = productUnderlyingTypes;
  },
  [MutationTypes.PRODUCT_TYPE_OPTIONS](state: State, productTypeOptions: { [key: string]: Option[] }) {
    state.productTypeOptions = productTypeOptions;
  },
  [MutationTypes.PRODUCT_TYPE_GROUP_OPTIONS](state: State, productTypeGroupOptions: { [key: string]: OptionGroup[] }) {
    state.productTypeGroupOptions = productTypeGroupOptions;
  },
  [PUSH_UPDATE_PRODUCT](
    state: State,
    pushItem: {
      productPricePush: ProductPricePush;
      pushSubscriptions: { [key: string]: boolean };
    }
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allProductsInState = Object.values(state).reduce((prevVal: Array<ProductModel>, statePropVal: any) => {
      if (isMutationPushPayloadHolder(statePropVal)) {
        return prevVal.concat(statePropVal.getSubscriptionPushItems(statePropVal.holder));
      } else {
        return prevVal;
      }
    }, new Array<ProductModel>());

    allProductsInState.forEach((p) => tryUpdateProductValue(p, pushItem.productPricePush, pushItem.pushSubscriptions));
  },
};

const cache = {
  productUnderlyingTypesKeys: {} as { [key: string]: boolean },
  productTypeOptionsKeys: {} as { [key: string]: boolean },
  productTypeGroupOptionsKeys: {} as { [key: string]: boolean },
};

export const actions = {
  async loadProductAsync({ commit, state }: { commit: Commit; state: State }, isinOrNsin: string): Promise<void> {
    // Return cached product if already fetched
    if (state.product) {
      return Promise.resolve();
    }

    // Fetch and save in Cache
    const response = await httpClient.get<ProductModel>(getTheQApiUrl(Controller.Product, 'Get'), {
      params: { isinOrNsin: isinOrNsin },
    });

    const productPushPayload = createPushPayload<ProductModel, ProductModel>(
      response.data,
      (holder: ProductModel): Array<ProductModel> => [holder]
    );

    commit(LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCT, productPushPayload);
  },

  async loadProductsAsync({ commit }: { commit: Commit }, isinOrNsins: string[]): Promise<void> {
    const pushPayload = await QueryProductsWithPushPayload(isinOrNsins);

    commit(LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCTS, pushPayload);
  },

  async loadConcatProductsAsync({ commit }: { commit: Commit }, isinOrNsins: string[]): Promise<void> {
    const pushPayload = await QueryProductsWithPushPayload(isinOrNsins);

    commit(LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCTS_CONCAT, pushPayload);
  },

  async loadProductSearchDetailedResultAsync(
    { commit }: { commit: Commit },
    {
      filter,
      minPushItem,
      maxPushItem,
    }: { filter: ProductSearchParametersFilter; minPushItem: number; maxPushItem: number }
  ): Promise<void> {
    // Fetch and save in Cache
    filter.underlyingIsins =
      typeof filter.underlyingIsinOrIsins === 'string' ? [filter.underlyingIsinOrIsins] : filter.underlyingIsinOrIsins;

    const response = await httpClient.post<
      ProductSearchParametersFilter,
      AxiosResponse<ProductSearchDetailedResultModel>
    >(getTheQApiUrl(Controller.ProductSearch, 'Search'), filter);
    Vue.$log.debug('sub items', state.productSearchDetailResult?.holder.items.length, minPushItem, maxPushItem);

    const productSearchPushPayload = createPushPayload<ProductSearchDetailedResultModel, ProductModel>(
      response.data,
      (holder: ProductSearchDetailedResultModel): Array<ProductModel> => holder.items.slice(minPushItem, maxPushItem)
    );

    commit(LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCTS_SEARCH, productSearchPushPayload);
  },

  updateProductSearchDetailedResultPushItems(
    { commit, state }: { commit: Commit; state: State },
    { minPushItem, maxPushItem }: { minPushItem: number; maxPushItem: number }
  ): void {
    // Fetch and save in Cache
    const productSearchPushPayload = createPushPayload<ProductSearchDetailedResultModel, ProductModel>(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.productSearchDetailResult?.holder!,
      (holder: ProductSearchDetailedResultModel): Array<ProductModel> => holder.items.slice(minPushItem, maxPushItem)
    );

    commit(LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCTS_SEARCH, productSearchPushPayload);
  },

  async loadFirstProductAsync(
    { commit, state }: { commit: Commit; state: State },
    leverageType: ProductTypeBo
  ): Promise<void> {
    // Return cached product if already fetched
    if (state.product) {
      return Promise.resolve();
    }

    // Fetch and save in Cache
    const response = await httpClient.get<ProductModel>(getTheQApiUrl(Controller.Product, 'GetFirst'), {
      params: { productType: leverageType },
    });

    const productPushPayload = createPushPayload<ProductModel, ProductModel>(
      response.data,
      (holder: ProductModel): Array<ProductModel> => [holder]
    );

    commit(LightStreamerProductSubscriptionMutationTypes.CACHE_PRODUCT, productPushPayload);
  },

  async loadTopLeverageTableSearchAsync(
    { commit, state }: { commit: Commit; state: State },
    filter: TopLeverageTableFilter
  ): Promise<void> {
    const response = await httpClient.get<ProductSearchDetailedResultModel>(
      getTheQApiUrl(Controller.ProductSearch, 'TopLeverageTableSearch'),
      {
        params: { searchParam: filter },
      }
    );

    const getSubscriptionPushItemsFunc = (holder: { [key: string]: Array<ProductModel> }): Array<ProductModel> => {
      const allProducts = Object.values(holder).reduce(
        (prevVal: Array<ProductModel>, arr: Array<ProductModel>) => prevVal.concat(arr),
        new Array<ProductModel>()
      );
      // Get only unique products, so that the subscription is not triggered for multiple same products
      return uniqBy(allProducts, 'isin') as Array<ProductModel>;
    };

    const topLeveragePushPayload = createPushPayload<{ [key: string]: Array<ProductModel> }, ProductModel>(
      { ...state?.topLeverageTableSearchResult?.holder, [filter]: response.data },
      getSubscriptionPushItemsFunc
    );

    commit(
      LightStreamerProductSubscriptionMutationTypes.CACHE_TOP_LEVERAGE_TABLE_SEARCH_PRODUCTS,
      topLeveragePushPayload
    );
  },
  async subscribePushValue({ commit, state }: { commit: Commit; state: State }, fieldKey: string): Promise<void> {
    if (state.productPushValue[fieldKey] !== true) commit(MutationTypes.PRODUCT_PUSH_VALUE, fieldKey);
  },
  async getUnderlyingTypesAsync(
    { commit, state }: { commit: Commit; state: State },
    filter: ProductUnderlyingFilter
  ): Promise<void> {
    const key = getProductUnderlyingFilterKey(filter);
    if (cache.productUnderlyingTypesKeys[key] === undefined) {
      cache.productUnderlyingTypesKeys[key] = true;
      const response = await httpClient.post<ProductUnderlyingFilter, AxiosResponse<ProductType[]>>(
        getTheQApiUrl(Controller.Product, 'UnderlyingTypes'),
        filter
      );
      commit(
        MutationTypes.PRODUCT_UNDERLYING_TYPES,
        Object.assign({}, state.productUnderlyingTypes, { [getProductUnderlyingFilterKey(filter)]: response.data })
      );
    }
  },
  async getProductTypeOptionsAsync(
    { commit, state }: { commit: Commit; state: State },
    filter: ProductTypeOptionsFilter
  ): Promise<void> {
    const key = getProductTypeOptionsFilterKey(filter);
    if (cache.productUnderlyingTypesKeys[key] === undefined) {
      cache.productUnderlyingTypesKeys[key] = true;
      const response = await httpClient.get<Option[]>(getTheQApiUrl(Controller.Product, 'GetProductTypes'), {
        params: { underlyingIsin: filter.underlyingIsin },
      });
      commit(
        MutationTypes.PRODUCT_TYPE_OPTIONS,
        Object.assign({}, state.productTypeOptions, { [getProductTypeOptionsFilterKey(filter)]: response.data })
      );
    }
  },
  async getProductTypeGroupOptionsAsync(
    { commit, state }: { commit: Commit; state: State },
    filter: ProductTypeGroupOptionsFilter
  ): Promise<void> {
    const key = getProductTypeGroupOptionsFilterKey(filter);
    if (cache.productTypeGroupOptionsKeys[key] === undefined) {
      cache.productTypeGroupOptionsKeys[key] = true;
      const response = await httpClient.get<OptionGroup[]>(getTheQApiUrl(Controller.Product, 'GetProductTypeGroups'), {
        params: { underlyingIsin: filter.underlyingIsin, onlyLeverage: filter.onlyLeverage },
      });
      commit(
        MutationTypes.PRODUCT_TYPE_GROUP_OPTIONS,
        Object.assign({}, state.productTypeGroupOptions, {
          [getProductTypeGroupOptionsFilterKey(filter)]: response.data,
        })
      );
    }
  },
};
