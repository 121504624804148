<template>
  <div
    :class="[
      'd-inline-block',
      'w-sm-100',
      'text-nowrap',
      'text-left',
      'mr-1',
      'mb-2',
      'px-3',
      'px-md-2',
      'py-2',
      'rounded',
      stateClass,
    ]"
  >
    <b-form-checkbox :checked="value" @change="toggle" :disabled="disabled" name="switch-button" switch>
      <slot name="default"></slot>
    </b-form-checkbox>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class AdSwitchButton extends Vue {
  @Prop({ default: false, type: Boolean }) value!: boolean;
  @Prop({ default: false, type: Boolean }) disabled!: boolean;
  @Prop() activeStateClass: string | undefined;

  get displayClass() {
    return this.$screen.sm ? 'd-inline-block' : 'd-block';
  }

  get stateClass(): string {
    return this.value && this.activeStateClass ? this.activeStateClass : 'bg-light';
  }

  toggle() {
    if (!this.disabled) this.$emit('input', !this.value);
  }
}
</script>
