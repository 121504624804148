<template>
  <ad-error :http-status-code="statusCode">
    <b-row v-if="isGroupSelected && !hasListLayout">
      <ad-downloads-items
        v-for="index in columnIndexes"
        :files="columnFiles(index)"
        :key="index"
        :cols="12 / columnsCount"
      />
    </b-row>
    <b-row v-if="isGroupSelected && hasListLayout" class="mt-2">
      <ad-downloads-list
        v-for="index in columnIndexes"
        :files="columnFiles(index)"
        :key="index"
        :cols="12 / columnsCount"
      >
        <template #no-data-found><slot name="no-data-found" /></template>
      </ad-downloads-list>
    </b-row>
  </ad-error>
</template>
<script lang="ts">
import { Vue, Component, Inject, Prop, InjectReactive, Watch } from 'vue-property-decorator';
import { DownloadsBlockPayloadSettings, CommonFormatsFieldKeys } from '@/src/types/episerver-api';
import { getProductDocuments, getFolderDocuments } from '@/src/utils/document-service';
import { DownloadGroupItem, DocumentServiceConfiguration } from '@/src/types/vue-api';
import AdDownloadsItems from './ad-downloads-items.vue';
import AdDownloadsList from './ad-downloads-list.vue';
import { DefaultFormat, DocumentType, HttpStatusCodes } from '@/src/types/enumerations';
import { isAxiosError } from '@/src/types/type-guards';
import { ProductModel } from '@/src/types/the-q-api';
import { range } from 'lodash';

@Component({
  components: {
    AdDownloadsItems,
    AdDownloadsList,
  },
})
export default class AdDownloads extends Vue {
  @Prop({ default: 1 }) cols!: number;
  @Prop({ default: null }) md!: number | null;
  @Prop({ default: null }) xl!: number | null;
  @Prop({ type: Boolean, default: false }) hasListLayout!: boolean | null;
  @Inject() settingsProperty!: DownloadsBlockPayloadSettings;
  @Inject({ default: null }) commonFormatsKeys!: CommonFormatsFieldKeys | null;
  @InjectReactive() product!: ProductModel | null;
  @Prop({ default: () => {} })
  filterState!: { [key: string]: string | number | null } | null;
  @Prop({ default: 'year' })
  yearFilterName!: string | null;
  @Prop({ default: 'group' })
  groupFilterName!: string | null;
  @Prop({ default: null })
  group!: string | null;

  apiDocuments: DownloadGroupItem[] | null = null;
  filteredApiDocuments: DownloadGroupItem[] | null = null;
  statusCode: number | HttpStatusCodes = HttpStatusCodes.OK;

  @Watch('filterState', { immediate: true, deep: true })
  filterApiDocuments() {
    if (!this.isGroupSelected) {
      //no action, invisible anyway
      return;
    }

    if (!this.filterState || !this.yearFilterName || !this.filterState[this.yearFilterName!]) {
      this.filteredApiDocuments = this.apiDocuments;
      return;
    }

    this.filteredApiDocuments =
      this.apiDocuments
        ?.map((_) => {
          return {
            type: _.type,
            childListName: _.childListName,
            files: _.files.filter((f) => f.year == this.filterState![this.yearFilterName!]),
          } as DownloadGroupItem;
        })
        .filter((_) => _.files.length > 0) || [];
  }

  get isGroupSelected() {
    return (
      !this.group ||
      !this.filterState ||
      !this.groupFilterName ||
      !this.filterState[this.groupFilterName] ||
      this.filterState[this.groupFilterName] == this.group
    );
  }

  async created(): Promise<void> {
    // initially fetch documents (required especially for folder documents, e.g. on legal documents page)
    await this.fetchDocuments();
  }

  @Watch('product') // product is watched (i.e., fetch documents if product is changed)
  async fetchDocuments() {
    try {
      await this.requestApiDocuments();
    } catch (error: unknown) {
      this.$log.error('Loading Error', error);

      if (isAxiosError(error)) {
        this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
      } else {
        this.statusCode = HttpStatusCodes.UnknownError;
      }
    }
  }

  private async requestApiDocuments() {
    const config = new DocumentServiceConfiguration(DefaultFormat.DocumentDate);
    const requests: Promise<DownloadGroupItem[]>[] = [];
    if (this.product?.isin && this.settingsProperty.types.length > 0) {
      requests.push(getProductDocuments(this.product?.isin, this.settingsProperty.types, config));
    }
    if (this.settingsProperty.parentFolderIds.length > 0) {
      requests.push(
        getFolderDocuments(
          this.settingsProperty.parentFolderIds,
          this.settingsProperty.types,
          this.settingsProperty.linkedDocumentFolderIds,
          config
        )
      );
    }
    if (requests.length == 0) return;

    this.apiDocuments = (await Promise.all(requests)).flatMap((group) => group);
    this.filterApiDocuments();
    this.$forceUpdate();
  }

  get columnsCount(): number {
    if (this.$screen.xl) return this.xl || this.md || this.cols;
    if (this.$screen.md) return this.md || this.cols;
    return this.cols;
  }

  get columnIndexes(): number[] {
    return range(1, this.columnsCount + 1);
  }

  private get baseProspectus() {
    return (
      this.filteredApiDocuments?.filter(
        (d) => d.type === DocumentType.BaseProspectus || d.type === DocumentType.SecuritiesNote
      ) || []
    );
  }

  private get otherDownloads() {
    return [
      ...(this.filteredApiDocuments?.filter(
        (d) => d.type !== DocumentType.BaseProspectus && d.type !== DocumentType.SecuritiesNote
      ) || []),
      ...this.settingsProperty.files,
      ...this.settingsProperty.links,
    ];
  }

  private get downloadsPerColumn() {
    let otherColumnsCount = this.columnsCount;
    if (this.baseProspectus.length !== 0) otherColumnsCount--;
    return Math.ceil(this.otherDownloads.length / otherColumnsCount);
  }

  columnFiles(index: number) {
    if (this.columnsCount === 1) {
      return [...(this.filteredApiDocuments || []), ...this.settingsProperty.files, ...this.settingsProperty.links];
    }

    if (this.baseProspectus.length !== 0) {
      if (index == 1) return this.baseProspectus;
      else index--;
    }

    const from = this.downloadsPerColumn * (index - 1);
    const to = this.downloadsPerColumn * index;
    return this.otherDownloads.slice(from, to);
  }
}
</script>
