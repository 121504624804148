<template>
  <ad-collapse-on-mobile v-bind="$attrs">
    <template #header>
      <slot name="block-header"></slot>
    </template>
    <b-row class="no-gutters">
      <b-col cols="12" md="3" class="pb-1 pb-mb-0">
        <div class="marine-blue font-weight-bold fs-14"><slot name="trigger-header"></slot></div>
        <span class="d-inline-block mr-2 fs-26"
          ><ad-product-data-value :fieldKey="settingsProperty.triggerProbabilityFieldKey"></ad-product-data-value
        ></span>
        <span class="d-inline-block align-text-bottom fs-18"><slot name="info-block"></slot></span>
      </b-col>
      <b-col cols="12" md="9">
        <div class="marine-blue font-weight-bold fs-14"><slot name="providedby-header"></slot></div>
        <span class="fs-26"><slot name="providedby-value"></slot></span>
      </b-col>
    </b-row>
    <div class="mt-4 ml-n3 mr-n2 mx-md-0 trigger-probability-chart" ref="chartContainer"></div>
  </ad-collapse-on-mobile>
</template>
<script lang="ts">
import { ChartPoint, ProductModel } from '@/src/types/the-q-api';
import { Component, Inject, Ref, Watch } from 'vue-property-decorator';
import { Vue } from 'vue-property-decorator';
import * as Highcharts from 'highcharts';
import { formatField } from '@/src/utils/value-formatter/formatting-service';
import { utcDate } from '@/src/utils/date-helper';
import { ChartColors, Controller, DefaultFormat, InstrumentOriginTimeZone } from '@/src/types/enumerations';
import httpClient from '@src/utils/http-service';
import { getTheQApiUrl } from '@/src/utils/url-helper';
import { TriggerProbabilityApiFilter } from '@/src/types/episerver-api';
import { Getter } from 'vuex-class';

interface FormatSettings {
  triggerProbabilityFieldKey: string;
  xAxisLabelFormatFieldKey: string | null;
  yAxisLabelFormatFieldKey: string | null;
  columnLabelFormatFieldKey: string | null;
  tooltipDateFormatFieldKey: string | null;
  tooltipValueFormatFieldKey: string | null;
}

export class TriggerProbabilityApiFilterItem implements TriggerProbabilityApiFilter {
  timeZone: InstrumentOriginTimeZone;
  symbol: string;

  constructor(symbol: string, timeZone: InstrumentOriginTimeZone) {
    this.symbol = symbol;
    this.timeZone = timeZone;
  }
}

@Component({ inheritAttrs: false })
export default class AdTriggerProbabilityBlock extends Vue {
  @Inject() settingsProperty!: FormatSettings;
  @Ref() chartContainer;
  @Getter('product', { namespace: 'product' })
  product!: ProductModel;

  private get chartColor(): string {
    return ChartColors.triggerProbability.toString();
  }

  initChart(): void {
    if (!this.chartContainer) return;

    const chartData = this.mapChartData();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const options = {
      series: chartData,
      xAxis: {
        labels: {
          formatter: (point) => context.formatDateLabel(point, context),
          y: context.dateLabelsMargin,
        },
        tickLength: 0,
      },
      yAxis: {
        min: 0,
        max: 1,
        gridLineDashStyle: 'Dot',
        title: {
          text: undefined,
        },
        tickAmount: 6,
        labels: {
          style: {
            color: context.chartColor,
          },
          formatter: function (point: Highcharts.AxisLabelsFormatterContextObject): string {
            return context.formatAxisValueLabel(point, context);
          },
        },
      },
      plotOptions: {
        column: {
          pointWidth: context.pointWidth,
          dataLabels: {
            enabled: context.$screen.md,
            inside: false,
            formatter: function (this: Highcharts.PointLabelObject, options: Highcharts.DataLabelsOptions) {
              return context.formatColumnValueLabel(this, options, context);
            },
            align: 'center',
            y: 10,
          },
        },
      },
      chart: {
        type: 'column',
        height: context.chartHeight,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      tooltip: {
        useHTML: true,
        borderWidth: 0,
        formatter: function (this: Highcharts.TooltipFormatterContextObject, tooltip: Highcharts.Tooltip) {
          return context.formatTooltip(this, tooltip, context);
        },
      },
      scrollbar: { enabled: false },
      credits: {
        enabled: false,
      },
    } as Highcharts.Options;
    Highcharts.chart(this.chartContainer, options);
  }

  get pointWidth() {
    if (this.$screen.lg) return 50;
    else if (this.$screen.md) return 40;
    else return 20;
  }

  get chartHeight() {
    if (this.$screen.lg) return 345;
    else return 300;
  }

  get dateLabelsMargin() {
    return this.$screen.lg ? 35 : 20;
  }

  formatDateLabel(point: Highcharts.AxisLabelsFormatterContextObject, context: AdTriggerProbabilityBlock): string {
    const timestamp = context.getPointTimestamp(point.pos, context.data);
    return timestamp
      ? formatField(timestamp, context.settingsProperty.xAxisLabelFormatFieldKey || DefaultFormat.Date)
      : '';
  }

  formatColumnValueLabel(
    point: Highcharts.PointLabelObject,
    options: Highcharts.DataLabelsOptions,
    context: AdTriggerProbabilityBlock
  ): string {
    return point.y !== null && point.y !== undefined
      ? formatField(point.y * 100, context.settingsProperty.columnLabelFormatFieldKey || DefaultFormat.Decimal)
      : '';
  }

  formatAxisValueLabel(point: Highcharts.AxisLabelsFormatterContextObject, context: AdTriggerProbabilityBlock): string {
    return formatField(point.value, context.settingsProperty.yAxisLabelFormatFieldKey || DefaultFormat.Percent);
  }

  formatTooltip(
    point: Highcharts.TooltipFormatterContextObject,
    tooltip: Highcharts.Tooltip,
    context: AdTriggerProbabilityBlock
  ): string {
    const timestamp = typeof point.x == 'number' ? context.getPointTimestamp(point.x, context.data) : null;
    const date = timestamp
      ? formatField(timestamp, context.settingsProperty.tooltipDateFormatFieldKey || DefaultFormat.Date)
      : '';
    const value =
      typeof point.y == 'number'
        ? formatField(point.y, context.settingsProperty.tooltipValueFormatFieldKey || DefaultFormat.Percent)
        : '';
    return `${date}<br/><span class="value">${value}</span>`;
  }

  data: ChartPoint[] = [];
  private getPointTimestamp(index: number, data: ChartPoint[]) {
    return index < data.length ? utcDate(data[index].x) : undefined;
  }

  get tickPositions(): (number | null)[] {
    return this.data.map((p) => p.x);
  }

  COLUMN_COUNT = 10;
  protected mapChartData(): Highcharts.SeriesColumnOptions[] {
    if (this.data.length == 0) return [];
    const result: Highcharts.PointOptionsObject[] = [];
    for (let i = 0; i < this.COLUMN_COUNT; i++) {
      if (i < this.data.length) {
        result.push({ x: i, y: this.data[i].y });
      } else {
        result.push({ x: i, y: undefined });
      }
    }
    return [{ data: result, color: this.chartColor, type: 'column' }];
  }

  protected async requestChartData(
    symbol: string | undefined,
    timezone: InstrumentOriginTimeZone
  ): Promise<ChartPoint[] | null> {
    if (!symbol) return null;
    try {
      const response = await httpClient.get<ChartPoint[]>(getTheQApiUrl(Controller.TriggerProbabilityCharts, 'Get'), {
        params: new TriggerProbabilityApiFilterItem(symbol, timezone),
      });
      return response.data;
    } catch (ex) {
      this.$log.error(ex);
      return null;
    }
  }

  @Watch('product')
  async loadData() {
    const data = await this.requestChartData(
      this.product.origin.timeZone == InstrumentOriginTimeZone.AET
        ? this.product.securityCode ?? this.product.isin
        : this.product.isin,
      this.product.origin.timeZone
    );
    this.data = data || [];
    this.initChart();
  }

  @Watch('$screen.breakpoint')
  redrawChart() {
    this.initChart();
  }
}
</script>
<style lang="scss">
.trigger-probability-chart {
  .highcharts-xaxis-grid {
    display: none;
  }

  .highcharts-data-label text {
    fill: $gunmetal !important;
    color: $gunmetal !important;
    font-size: rem(18) !important;
    font-weight: normal !important;
  }

  .highcharts-text-outline {
    display: none;
  }

  .highcharts-yaxis-labels text {
    font-size: rem(14) !important;
  }
}
</style>
