<template>
  <ad-icon-base iconName="best-match" v-bind="$attrs">
    <path
      d="M24.7,0.5c-0.1,0-0.2,0-0.3,0C15.9,0.5,9,7.4,9,15.9v0c0,3.6,1.2,7,3.5,9.8L0.3,37.9c-0.3,0.4-0.3,0.9,0,1.3
			c0.3,0.3,0.9,0.3,1.3,0L13.7,27c2.9,2.8,6.7,4.4,10.7,4.4c8.5,0.1,15.5-6.7,15.6-15.3C40.1,7.6,33.3,0.6,24.7,0.5z M24.4,29.6
			c-7.5,0-13.6-6.1-13.6-13.6c0-7.5,6.1-13.6,13.6-13.6S38,8.4,38,16C38,23.5,31.9,29.6,24.4,29.6z M30.7,13
			C30.7,13,30.7,13,30.7,13c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0h-3.4l0,0h-0.2l-1.1-3.6C25.5,9,25.3,8.8,25,8.6
			c-0.3-0.1-0.6-0.2-0.9-0.1c-0.4,0.1-0.7,0.4-0.8,0.7L22,12.9h-3.4c-0.6,0-1.2,0.5-1.2,1.2c0,0.4,0.2,0.7,0.5,1l2.9,2.3l-1.1,3.7
			c-0.1,0.3-0.1,0.6,0.1,0.9c0.1,0.3,0.4,0.5,0.7,0.6c0.4,0.1,0.8,0,1.1-0.2l2.9-2.2l2.8,2.2c0.2,0.2,0.5,0.3,0.7,0.3
			c0,0,0.1,0,0.1,0c0.3,0,0.6-0.2,0.8-0.4c0.2-0.3,0.3-0.7,0.2-1.1l-1.1-3.7c0,0,0,0,0,0L31,15c0.3-0.2,0.5-0.6,0.5-1
			C31.5,13.6,31.2,13.2,30.7,13z M27.7,16l-0.8,0.6c-0.2,0.2-0.3,0.4-0.2,0.7l1.2,3.8l-3-2.3c-0.2-0.2-0.5-0.2-0.8,0l-3,2.3l1.2-3.8
			c0.1-0.3,0-0.5-0.2-0.7l-3.1-2.4h3.6c0.3,0,0.5-0.2,0.6-0.4l1.4-3.8l1.2,3.8c0.1,0.3,0.3,0.4,0.6,0.4l1,0h2.8L27.7,16z"
    />
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconBestMatch extends Vue {}
</script>
