<template>
  <div v-bind="$attrs" ref="chartContainer"></div>
</template>
<script lang="ts">
import * as Highcharts from 'highcharts';
import Stock from 'highcharts/modules/stock';
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { DataSeries, LabelPosition } from '@/src/types/vue-api';
import { AdChartBase } from './ad-chart-base';
import BrokenAxisModule from 'highcharts/modules/broken-axis';
import { millisecondsInDay, utcDate } from '@/src/utils/date-helper';
import { ChartPatterns } from '@src/types/enumerations';

BrokenAxisModule(Highcharts);
Stock(Highcharts);

@Component({})
export default class AdStockChart extends Vue {
  @Ref() chartContainer;
  @Prop() series!: DataSeries[];
  @Prop() pointInterval?: number | undefined;
  @Prop() xAxisTickInterval?: number | undefined;
  @Prop() xAxisLabelStep?: number | undefined;
  @Prop() xAxisLabelMargin?: number | undefined;
  @Prop() xAxisMin?: number | undefined;
  @Prop() xAxisMax?: number | undefined;
  @Prop() marginLeft?: number;
  @Prop() marginRight?: number;
  @Prop() height?: number | undefined;
  @Prop({ required: true }) labelDateFormatter!: (chart: AdChartBase, value: number) => string;
  @Prop({ required: true }) labelValueFormatter!: (chart: AdChartBase, value: number) => string;
  @Prop({ required: true }) tooltipDateFormatter!: (chart: AdChartBase, value: number) => string;
  @Prop({ required: true }) tooltipValueFormatter!: (
    chart: AdChartBase,
    value: Highcharts.TooltipFormatterContextObject,
    label: LabelPosition
  ) => string;
  @Prop({ default: false }) navigator!: boolean;
  @Prop({ default: false }) scrollbar!: boolean;
  @Prop({ default: 'line' }) type!: string;
  @Prop() breakThreshold: number | undefined;
  @Prop() repeatedBreaks?: Highcharts.XAxisBreaksOptions[];
  @Prop({ default: 0 }) tickOffset!: number;

  formatTooltip(point: Highcharts.TooltipFormatterContextObject) {
    const labelPosition = point.series.yAxis.side == 3 ? LabelPosition.Left : LabelPosition.Right;
    return `<span class="timestamp">${
      typeof point.x == 'number' ? this.tooltipDateFormatter(this.$parent as AdChartBase, point.x) : ''
    }</span>
            <br/>
            <span class="value">${this.tooltipValueFormatter(
              this.$parent as AdChartBase,
              point,
              labelPosition
            )}</span>`;
  }

  private get _xAxisLabelMargin() {
    if (this.xAxisLabelMargin) return this.xAxisLabelMargin;
    else if (!this.$screen.md) return 20;
    return 30;
  }

  private stockChart: Highcharts.Chart | undefined;

  @Watch('$screen.breakpoint')
  @Watch('series', { immediate: true })
  initChart(): void {
    if (!this.chartContainer) return;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const breaks = context.findThresholdBreaks();

    const tickPositioner = function (this: Highcharts.Axis) {
      return this.tickPositions?.map((t) => {
        //if a label falls on a holiday move it to the end of the break
        const holidayBreak = breaks.find((b) => b.from && b.to && b.from < t && b.to > t);
        if (holidayBreak) return holidayBreak.to;
        //else move them to the beginning of the trade time
        if (context.tickOffset) return Math.floor(t / millisecondsInDay) * millisecondsInDay + context.tickOffset;
        return t;
      });
    };

    const options = {
      series: context.mapChartSeries(),
      yAxis: context.mapYAxis(),
      xAxis: {
        type: 'datetime',
        gridLineWidth: 1,
        gridLineDashStyle: 'Dot',
        minTickInterval: context.xAxisTickInterval,
        tickPixelInterval: 110,
        labels: {
          step: context.xAxisLabelStep,
          formatter: (point) => context.labelDateFormatter(context.$parent as AdChartBase, point.value as number),
          y: context._xAxisLabelMargin,
        },
        tickLength: 0,
        tickPositioner: tickPositioner,
        min: context.xAxisMin,
        max: context.xAxisMax,
        ordinal: false,
        breaks: breaks.length != 0 ? breaks.concat(...(context.repeatedBreaks || [])) : undefined,
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          turboThreshold: 6000,
          connectNulls: true,
          dataGrouping: {
            enabled: context.pointInterval == undefined,
          },
        },
      },
      rangeSelector: {
        enabled: false,
      },
      chart: {
        styledMode: false,
        height: context.height,
        marginLeft: context.marginLeft,
        marginRight: context.marginRight,
      },
      tooltip: {
        useHTML: true,
        borderWidth: 0,
        shared: false,
        split: false,
        followPointer: true,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          return context.formatTooltip(this);
        },
      },
      scrollbar: {
        enabled: context.scrollbar,
      },
      navigator: {
        enabled: context.navigator,
        adaptToUpdatedData: false,
        margin: 10,
        height: 30,
        xAxis: {
          labels: {
            formatter: (point) => context.labelDateFormatter(context.$parent as AdChartBase, point.value as number),
          },
        },
        yAxis: {
          visible: false,
        },
      },
      credits: {
        enabled: false,
      },
    } as Highcharts.Options;

    this.stockChart = Highcharts.stockChart(this.chartContainer, options);
    this.$log.debug(options);
    this.calcChartMarginLeft(this.stockChart.yAxis);
    this.calcChartMarginRight(this.stockChart.yAxis);
  }

  labelLength(axis: Highcharts.Axis[], side: number): number {
    const labels = axis
      .filter((a) => a.side == side)
      .flatMap((a) => a.tickPositions)
      .filter((n: number | undefined): n is number => !!n);
    const max = Math.max(0, ...labels);
    const digits = this.labelValueFormatter(this.$parent as AdChartBase, max).length;
    //We have to calculate the label margin manually based on the length of the longest value.
    return digits;
  }

  calcChartMarginLeft(axis: Highcharts.Axis[]): number {
    const length = this.labelLength(axis, 3);
    this.$emit('marginChange', this.$parent, LabelPosition.Left, length);
    return length;
  }
  calcChartMarginRight(axis: Highcharts.Axis[]): number {
    const length = this.labelLength(axis, 1);
    this.$emit('marginChange', this.$parent, LabelPosition.Right, length);
    return length;
  }

  private lastSeriesUpdate: number[] = [];
  private mapChartSeries(): Highcharts.SeriesLineOptions[] {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    this.lastSeriesUpdate = this.series.map((s) => (s.data.length != 0 ? s.data[s.data.length - 1].x : 0));
    return this.series.map((s) => {
      return {
        type: context.type,
        data: s.data,
        color: s.color,
        yAxis: s.labels == LabelPosition.Left ? 0 : 1,
        stickyTracking: true,
        pointWidth: context.type == ChartPatterns.Candlestick ? 3 : undefined,
      } as Highcharts.SeriesLineOptions;
    });
  }

  //flat all the series and look for periods where there is no points
  private findThresholdBreaks(): Highcharts.XAxisBreaksOptions[] {
    if (!this.breakThreshold || !this.series?.length) return [];
    const breaks: Highcharts.XAxisBreaksOptions[] = [];
    let prevPoint: number | undefined;
    const points = this.series.flatMap((s) => s.data.map((p) => p.x)).sort();
    for (const point of points) {
      if (prevPoint && point - prevPoint >= this.breakThreshold) {
        breaks.push({
          from: prevPoint,
          to: point,
          breakSize: 0,
        });
      }
      prevPoint = point;
    }
    return breaks;
  }

  private mapYAxis(): Highcharts.YAxisOptions[] {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const leftColor = this.series.find((s) => s.labels == LabelPosition.Left && s.data.length !== 0)?.color;
    const rightColor = this.series.find((s) => s.labels == LabelPosition.Right && s.data.length !== 0)?.color;
    const axis = [
      {
        gridLineDashStyle: 'Dot' as Highcharts.DashStyleValue,
        opposite: false,
        labels: {
          style: {
            align: 'right',
            color: leftColor,
            whiteSpace: 'nowrap',
            textOverflow: 'none',
          },
          formatter: (point) => this.labelValueFormatter(context.$parent as AdChartBase, point.value),
        },
      },
      {
        gridLineDashStyle: 'Dot' as Highcharts.DashStyleValue,
        opposite: true,
        visible: !!rightColor,
        labels: {
          style: {
            align: 'left',
            color: rightColor,
            whiteSpace: 'nowrap',
            textOverflow: 'none',
          },
          formatter: (point) => this.labelValueFormatter(this.$parent as AdChartBase, point.value),
        },
      },
    ];
    return axis;
  }

  public pushValue(series: number, x: number, y: number, updateTreshold: number | undefined, isOhlc = false) {
    if (
      !this.stockChart ||
      this.lastSeriesUpdate.length < series ||
      series < 0 ||
      this.stockChart.series.length - 1 < series
    ) {
      return;
    }
    const delta = x - this.lastSeriesUpdate[series];
    const seriesData = this.stockChart.series[series];
    const pointsCount = seriesData.points.length;

    /*if (isRelative && !isOhlc) {
      const first = pointsCount > 0 ? seriesData.points[pointsCount - 1].y : undefined;
      y = !first ? 1 : y / first;
    }*/

    if (pointsCount == 0 || (updateTreshold && delta > updateTreshold)) {
      this.lastSeriesUpdate[series] = x;
      this.addPoint(seriesData, x, y, isOhlc);
      this.$log.debug(
        `Pushed new chart point ${utcDate(
          x
        )}, ${y} with delta from previois ${delta}ms over threshold ${updateTreshold}`
      );
      if (this.navigator && series == 0) {
        const navigator = this.stockChart as Highcharts.Chart & { navigator: Highcharts.NavigatorOptions };
        this.addPoint(navigator.series[0], x, y, isOhlc);
      }
    } else if (delta > 0) {
      this.$log.debug(
        `Updated chart point ${utcDate(
          x
        )} with ${y} with delta from previois ${delta}ms under threshold ${updateTreshold}`
      );
      this.updatePoint(seriesData.points[pointsCount - 1], x, y, isOhlc);
    }
  }

  private addPoint(series: Highcharts.Series, x: number, y: number, isOhlc: boolean): void {
    series.addPoint(isOhlc ? { x: x, open: y, high: y, low: y, close: y } : { x: x, y: y });
  }

  private updatePoint(
    point: Highcharts.Point & { high?: number; low?: number },
    x: number,
    y: number,
    isOhlc: boolean
  ): void {
    if (!isOhlc) {
      point.update(y);
    }
    const high = Math.max(point.high || 0, y);
    const low = Math.min(point.low || 0, y);
    point.update({
      x: x,
      high: high,
      low: low,
      close: y,
      y: y,
    });
  }

  public mounted() {
    this.initChart();
  }
}
</script>
<style lang="scss">
$chart-date-font-size: $fs-14;
$chart-date-font-size-md: $fs-10;
$chart-date-font-size-navigator: $fs-12;
$chart-font-size: $fs-14;
$chart-font-size-sm: $fs-10;
$chart-tooltip-value-font-size: $fs-18;

.highcharts-plot-background {
  fill: $light-grey;
  stroke: $gunmetal;
  stroke-width: 1;
}

.highcharts-axis .highcharts-axis-line {
  display: none;
}

.highcharts-crosshair-thin {
  stroke: $cool-grey;
  stroke-width: 1;
}

.highcharts-grid .highcharts-grid-line {
  stroke: $cool-grey;
}

.highcharts-xaxis-labels text {
  fill: $marine-blue !important;
  font-size: $chart-date-font-size !important;

  @include media-breakpoint-down('md') {
    font-size: $chart-date-font-size-md !important;
  }
}

.highcharts-navigator-xaxis.highcharts-xaxis-labels text {
  font-size: $chart-date-font-size-navigator !important;

  @include media-breakpoint-down('md') {
    font-size: $chart-date-font-size-md !important;
  }
}

.highcharts-yaxis-labels text {
  font-size: $chart-font-size !important;
  font-weight: bold;

  @include media-breakpoint-down('sm') {
    font-size: $chart-font-size-sm !important;
  }
}

.highcharts-tooltip {
  font-size: $chart-font-size;

  .highcharts-tooltip-box {
    background-color: $white;
    fill: $white;

    .highcharts-label-box {
      margin-top: rem(5);
      fill: $white;
    }
  }
  .value {
    font-size: $chart-tooltip-value-font-size;
  }
}
.highcharts-crosshair {
  stroke: $bright-sky-blue;
}
.highcharts-navigator-handle {
  stroke: $light-grey;
  fill: $bright-sky-blue;
}

.highcharts-background {
  fill: transparent !important;
}

.highcharts-scrollbar {
  .highcharts-scrollbar-track {
    fill: $white;
  }

  .highcharts-scrollbar-thumb {
    stroke: $bright-sky-blue;
    fill: $bright-sky-blue;
  }

  .highcharts-scrollbar-rifles {
    stroke: $white;
  }

  .highcharts-scrollbar-button {
    fill: $white;
  }
}
</style>
