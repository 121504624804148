<template>
  <ad-error :http-status-code="statusCode">
    <ad-underlying-data-property :underlying="underlying">
      <b-container fluid class="content-container mb-7 mb-md-8 mt-6 mt-md-7 mt-xl-8 px-md-4 underlying-detail">
        <b-row>
          <b-col cols="12" lg="11" order="2">
            <ad-underlying-detail-page-header :underlyingModel="underlying" />
          </b-col>
          <b-col
            v-if="settingsProperty?.isWatchlistButtonEnabled"
            cols="12"
            lg="1"
            order="1"
            order-lg="3"
            class="text-right pt-lg-3 pb-3 pb-lg-0"
          >
            <ad-underlying-watchlist-toggle />
          </b-col>
        </b-row>
        <b-row v-if="settingsProperty?.isPriceDataEnabled">
          <b-col class="b-skeleton-m">
            <hr class="mb-3 mt-3" />
            <b-row>
              <b-col cols="9" sm="6" lg="4" class="pr-lg-0">
                <h3 class="mb-0"><slot name="price-value"></slot></h3>
                <span class="price">
                  <ad-underlying-data-value fieldKey="underlying.last"></ad-underlying-data-value>
                  <slot name="info-icon"></slot>
                </span>
              </b-col>
              <b-col cols="12" sm="6" lg="8">
                <h3 class="mb-0 mt-3 mt-sm-0"><slot name="price-change"></slot></h3>
                <span class="price">
                  <ad-underlying-data-value fieldKey="underlying.changeAbsolute"></ad-underlying-data-value>
                  /
                  <ad-underlying-data-value fieldKey="underlying.changePercentage"> </ad-underlying-data-value
                ></span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mt-3 mb-6 mb-sm-3">
                <slot name="price-indication" v-bind:underlyingModel="underlying"></slot>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <hr class="mb-6 mt-lg-0 mt-0 d-none d-sm-block" />
            <b-row>
              <b-col v-if="settingsProperty?.isPriceDataEnabled" xl="8" class="pr-xl-9">
                <slot name="underlying-chart"></slot>
              </b-col>
              <b-col xl="4">
                <slot name="underlying-data-table"></slot>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <hr class="mb-6 mt-lg-7 d-none d-sm-block border-secondary" />
            <ad-product-types-count>
              <template #header>
                <slot name="ptc-header"></slot>
              </template>
              <template #table-header-name>
                <slot name="ptc-table-header-name"></slot>
              </template>
              <template #table-header-count>
                <slot name="ptc-table-header-count"></slot>
              </template>
            </ad-product-types-count>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <slot name="teaser"></slot>
          </b-col>
        </b-row>
      </b-container>
    </ad-underlying-data-property>
  </ad-error>
</template>
<script lang="ts">
import { UnderlyingModel } from '@/src/types/the-q-api';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import AdUnderlyingDetailPageHeader from '@components/organisms/pages/ad-underlying-detail-page/ad-underlying-detail-page-header.vue';
import AdProductTypesCount from '@components/organisms/pages/ad-underlying-detail-page/ad-product-types-count.vue';
import { UnderlyingDetailPageSetting } from '@/src/types/episerver-api';
import { isAxiosError } from '@/src/types/type-guards';
import { HttpStatusCodes } from '@/src/types/enumerations';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    AdUnderlyingDetailPageHeader,
    AdProductTypesCount,
  },
})
export default class AdUnderlyingDetailPage extends Vue {
  @Prop({ required: true }) isin!: string | null;
  @Prop({ default: false }) editMode!: boolean;

  @Inject()
  settingsProperty?: UnderlyingDetailPageSetting;

  @Getter('underlying', { namespace: 'underlying' })
  underlying!: UnderlyingModel | null;

  @Action('loadUnderlyingAsync', { namespace: 'underlying' })
  loadUnderlyingAsync!: (isin: string) => Promise<void>;

  @Action('loadFirstUnderlyingAsync', { namespace: 'underlying' })
  loadFirstUnderlyingAsync!: () => Promise<void>;

  statusCode: number | HttpStatusCodes = 200;

  async created(): Promise<void> {
    try {
      if (this.editMode) {
        await this.loadFirstUnderlyingAsync();
      } else if (this.isin != null) {
        await this.loadUnderlyingAsync(this.isin);
      }
    } catch (error: unknown) {
      this.$log.debug('Error:', error);

      if (isAxiosError(error)) {
        this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
      }
    }
  }
}
</script>

<style lang="scss">
.no-inner-space p {
  margin: 0 !important;
  padding: 0 !important;
}

.underlying-detail {
  hr {
    margin-right: -$grid-gutter-spacer;
    margin-left: -$grid-gutter-spacer;
  }
}
</style>
