import httpClient from '@/src/utils/http-service';
import { getEpiserverApiUrl } from '@/src/utils/url-helper';
import { Controller } from '@/src/types/enumerations';
import { Commit } from 'vuex';
import { FormatDataField } from '@/src/types/vue-api';

interface FormatDataFieldStore {
  [key: string]: FormatDataField;
}

export const state = {
  fieldStore: null as FormatDataFieldStore | null,
};

enum MutationTypes {
  CACHE_FORMAT_DATA_FIELD = 'CACHE_FORMAT_DATA_FIELD',
}

type State = typeof state;

export const mutations = {
  [MutationTypes.CACHE_FORMAT_DATA_FIELD](state: State, fieldStore: FormatDataFieldStore) {
    state.fieldStore = fieldStore;
  },
};

export const getters = {
  fieldStore: (state: State) => {
    return state.fieldStore;
  },
  isFieldStoreLoaded: (state: State) => {
    return !!state.fieldStore;
  },
  getFormatDataField:
    (state: State) =>
    (key: string): FormatDataField | null => {
      return state.fieldStore ? state.fieldStore[key] ?? null : null;
    },
};

export const actions = {
  async init({ commit, state }: { commit: Commit; state: State }): Promise<void> {
    // 1. Check if data is already loaded
    if (state.fieldStore) {
      return Promise.resolve();
    }

    // 2. Fetch the formats from the Epi and cache it
    const response = await httpClient.get<FormatDataField[]>(getEpiserverApiUrl(Controller.FieldFormats, 'GetAll'), {});

    const fieldStore = response.data.reduce(
      (store, field) =>
        field.formatId
          ? { ...store, [field.fieldKey]: field, [field.formatId]: field }
          : { ...store, [field.fieldKey]: field },
      {}
    );

    commit(MutationTypes.CACHE_FORMAT_DATA_FIELD, fieldStore);
  },
};
