<template>
  <div class="mb-6 ce-short-news-teaser">
    <slot name="short-news-headline"></slot>
    <slot name="newslist"></slot>
    <a
      :href="settingsProperty.linkToShortNewsDetailPage"
      class="btn btn-secondary w-100 w-md-auto"
      v-if="settingsProperty.showMoreButton"
    >
      <slot name="showmore">Show more</slot>
    </a>
  </div>
</template>

<script lang="ts">
import { ShortNewsTeaserSettings } from '@/src/types/episerver-api';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

@Component
export default class CeAdShortNewsTeaserContainer extends Vue {
  @Inject() settingsProperty!: ShortNewsTeaserSettings;
}
</script>
