<template>
  <div v-if="initialized">
    <slot v-bind:title="title" v-bind:duration="duration"></slot>
  </div>
</template>

<script lang="ts">
/// <reference types="@types/youtube" />;

import { loadYoutubeAPI } from '@/src/utils/youtube-frame-api';
import { Prop, Vue, Component, Watch } from 'vue-property-decorator';

@Component
export default class CeVideoInfo extends Vue {
  //youtubeId - id/code of the video to embed
  @Prop() youtubeId: string | undefined;
  @Prop() manualTitle: string | undefined;
  @Prop() manualDuration: string | undefined;

  youtubeTitle: string | undefined;
  youtubeDuration: string | undefined;

  initialized = false;

  get title() {
    if (!this.initialized) return '';
    if (this.youtubeId) return this.youtubeTitle;
    return this.manualTitle;
  }

  get duration() {
    if (!this.initialized) return '';
    if (this.youtubeId) return this.youtubeDuration;
    return this.manualDuration;
  }

  mounted() {
    this.retrieveYoutubeInfo();
  }

  @Watch('youtubeId')
  async retrieveYoutubeInfo() {
    if (!this.youtubeId) {
      this.initialized = true;
      return;
    }

    const yt = await loadYoutubeAPI();
    const d = document.createElement('div');
    d.style.display = 'none';
    document.body.appendChild(d);

    const player = new yt.Player(d, {
      host: 'https://www.youtube.com',
      videoId: this.youtubeId,
      events: {
        onReady: () => {
          // Somehow, the getVideoData func is not defined in type definitions
          // eslint-disable-next-line
          this.youtubeTitle = (player as any).getVideoData().title;
          const duration = player.getDuration();
          const minutes = Math.floor(duration / 60);
          const seconds = duration - minutes * 60;
          const format = (d: number) => d.toString().padStart(2, '0');
          this.youtubeDuration = [minutes, seconds].map(format).join(':');

          this.initialized = true;
          d.remove();
          player.destroy();
        },
        onError: () => {
          d.remove();
          player.destroy();
        },
      },
    });
  }
}
</script>
