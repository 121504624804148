<template>
  <div class="best-match-search-filter-container" :class="[{ 'show-invalid': showInvalid }]">
    <p v-if="$slots['filter-headline']" class="mb-3 filter-headline"><slot name="filter-headline" /></p>
    <b-row no-gutters>
      <b-col cols="12" :xl="teaser ? 12 : 10">
        <b-form-group
          :id="'product-type-filter-group' + _uid"
          class="focus-white"
          label-for="product-type-filter-dropdownlist"
        >
          <template #label>
            <slot name="filter-product-type-dropdown-label-text">[Dropdown label]</slot>
          </template>
          <ad-product-type-list
            :form-element-state-id="'product-type-filter-group' + _uid"
            id="product-type-filter-dropdownlist"
            :value="value.productType"
            only-leverage
            @input="productTypeChanged"
          >
          </ad-product-type-list>
        </b-form-group>
      </b-col>

      <b-col cols="12" :xl="teaser ? 6 : 5">
        <ad-product-search-filter-subtype
          :loading="isLoading"
          v-model="value.subType"
          @selected-value-changed="loadBestMatchMetaData"
        >
          <template #subtype-label-put>
            <slot name="filter-subtype-label-put">{{ bestMatchMetadataResultModel.translation.put }}</slot>
          </template>

          <template #subtype-label-call>
            <slot name="filter-subtype-label-call">{{ bestMatchMetadataResultModel.translation.call }}</slot>
          </template>
        </ad-product-search-filter-subtype>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col cols="12" :xl="teaser ? 12 : 10">
        <b-form-group
          :id="'underlying-filter-group' + _uid"
          class="focus-white"
          label-for="underlying-filter-dropdownlist"
        >
          <template #label>
            <slot name="filter-underlying-dropdown-label-text">[Dropdown label]</slot>
            <span class="deep-red">*</span>
          </template>
          <ad-underlying-list
            :form-element-state-id="'underlying-filter-group' + _uid"
            id="underlying-filter-dropdownlist"
            v-model="value.underlyingIsin"
            :available-items="underlyingNames"
            :loading="isLoading"
            group-items
            @selected-value-changed="underlyingChanged"
          >
            <template #multiple-placeholder>
              <slot name="filter-underlying-dropdown-multiple-placeholder">[Select items label]</slot>
            </template>
          </ad-underlying-list>
        </b-form-group>
        <ad-icon-warnings class="error-icon" />
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col cols="12" :xl="teaser ? 12 : 10">
        <slot
          name="maturity-date-filter"
          :onFilterValueChanged="onFilterValueChanged"
          :availableValues="availableValues"
        ></slot>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col cols="12" :xl="teaser ? 12 : 10">
        <b-row class="mt-2">
          <b-col
            cols="6"
            :class="{ 'best-match-numeric-filters': teaser }"
            v-if="isBestMatchSearchBlockSettings(settingsProperty) && settingsProperty.numericFilters.length > 1"
          >
            <b-form-group class="bg-transparent border-0 p-0">
              <ad-skeleton-radio-buttons :loading="isLoading">
                <ce-radio-buttons id="product-sub-type-buttons" stretched v-model="value.filterProperty">
                  <span
                    v-for="filters in settingsProperty.numericFilters"
                    :key="filters.filterProperty"
                    :value="filters.filterProperty"
                  >
                    <slot name="{{filters.filterLabel}}">{{ filters.filterLabel }}</slot>
                  </span>
                </ce-radio-buttons>
              </ad-skeleton-radio-buttons>
            </b-form-group>
          </b-col>
          <b-col cols="6" align-self="center">
            <slot
              name="additional-numeric-range-filter-elements"
              :isNumericFilterVisible="isNumericFilterVisible"
              :onFilterValueChanged="onFilterValueChanged"
              :availableValues="availableValues"
              :isLoading="isLoading"
            ></slot>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button
          :disabled="isButtonDisabled"
          @click="onButtonClick()"
          class="w-100 w-md-auto"
          variant="primary"
          size="s"
          ><slot name="filter-show-best-match-button-text"
            >[filter show best match button text placeholder]</slot
          ></b-button
        ></b-col
      >
      <b-col class="pt-2"
        ><b-button @click="goToProductSearchPage" variant="link" class="fs-12"
          ><slot name="go-to-full-seach-button-text">[Go to search]</slot>
          <ad-icon-chevron size="xxs" direction="right" aria-hidden="true"></ad-icon-chevron> </b-button
      ></b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { BestMatchSearchRequestFilter } from '@/src/types/vue-api';
import {
  BestMatchAvailableValuesModel,
  BestMatchMetadataResultModel,
  BestMatchSearchRequestModel,
} from '@/src/types/the-q-api';
import { BestMatchSearchPageSetting, BestMatchSearchBlockSetting, PageRoutes } from '@/src/types/episerver-api';
import { redirectToSearchProducts } from '@/src/utils/url-helper';
import { setBestMatchSearchFilterForRequest } from '@/src/utils/instrument-search-helper';
import { ProductTypeBo } from '@/src/types/enumerations';
import { Action } from 'vuex-class';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';

@Component({
  inheritAttrs: false,
})
export default class AdBestMatchSearchFilterContainer extends Vue {
  @Inject() pageRoutes!: PageRoutes;
  @Inject() settingsProperty!: BestMatchSearchPageSetting | BestMatchSearchBlockSetting;
  @Prop({ default: false, type: Boolean }) wide!: boolean;

  @Prop({ required: true })
  value!: BestMatchSearchRequestFilter;

  @Prop({ required: true })
  bestMatchMetadataResultModel!: BestMatchMetadataResultModel;

  @Prop({ default: false, type: Boolean })
  teaser!: boolean;

  @Action('loadBestMatchMetadataResultModelAsync', { namespace: 'bestMatch' })
  loadBestMatchMetadataResultModelAsync!: (filter: BestMatchSearchRequestModel) => Promise<void>;

  private showInvalid = false;

  get isButtonDisabled() {
    setBestMatchSearchFilterForRequest(this.value);

    return !this.value.keyDataValue;
  }

  get availableValues(): BestMatchAvailableValuesModel {
    return this.bestMatchMetadataResultModel?.availableValues ?? ({} as BestMatchAvailableValuesModel);
  }

  get isLoading() {
    return this.bestMatchMetadataResultModel?.availableValues == null;
  }

  get underlyingNames() {
    return this.availableValues.underlyingNames;
  }

  onButtonClick(): void {
    this.showInvalid = true;
    setBestMatchSearchFilterForRequest(this.value);

    if (this.value.keyDataValue) {
      this.trackBestMatch();
      this.$emit('best-match-button-click');
    }
  }

  private trackBestMatch(): void {
    trackEvent(GaEvent.SelectItem, {
      item_list_id: 'best_match',
      items: [{ item_id: this.value.productType + '_' + this.value.subType }],
    });
  }

  onFilterValueChanged() {
    // no action neccessary
  }

  async underlyingChanged(value: ProductTypeBo) {
    await this.loadBestMatchMetaData();
    this.$emit('underlying-changed', value);
  }

  async loadBestMatchMetaData() {
    this.loadBestMatchMetadataResultModelAsync(this.value);
  }

  productTypeChanged(value: ProductTypeBo) {
    this.$emit('product-type-changed', value);
  }

  isNumericFilterVisible(valueProperty: string) {
    return valueProperty === this.value.filterProperty;
  }

  goToProductSearchPage() {
    redirectToSearchProducts(this.value.underlyingIsin, this.pageRoutes, false, this.value.productType);
  }

  isBestMatchSearchBlockSettings(
    settings: BestMatchSearchPageSetting | BestMatchSearchBlockSetting
  ): settings is BestMatchSearchBlockSetting {
    const castedSettings = settings as BestMatchSearchBlockSetting;
    return castedSettings.numericFilters != undefined;
  }
}
</script>

<style lang="scss">
.product-search-sorting-container {
  @include media-breakpoint-down('sm') {
    margin-right: -$grid-gutter-spacer;
    margin-left: -$grid-gutter-spacer;
    border-radius: 0;
  }
}

.best-match-search-filter-container {
  .best-match-numeric-filters {
    .ce-radio-buttons--buttons {
      display: block !important;

      /* stylelint-disable-next-line */
      button {
        width: 100%;
      }
    }
  }
}
</style>
