<script lang="ts">
import { VNode } from 'vue';
import { Component, Provide, Prop, Vue, ProvideReactive } from 'vue-property-decorator';
import { ProductTeaserSettings } from '@/src/types/vue-api';

@Component({})
export default class AdProductTeaserProperty extends Vue {
  @Provide()
  @Prop({ default: null })
  private teaserSettingsProperty!: ProductTeaserSettings | null;

  @ProvideReactive()
  @Prop({ type: Boolean, default: true })
  private isAddColumnDistributionFee!: boolean;

  render(): VNode[] | VNode | undefined {
    return this.$scopedSlots.default?.({});
  }
}
</script>
