import { Commit, Dispatch } from 'vuex';
import { MutationPushPayloadHolder, SortedUnderlying } from '@/src/types/vue-api';
import { SortOrder, UnderlyingModel, UnderlyingPricePush, UnderlyingSearchFilterPayload } from '@/src/types/the-q-api';
import {
  createPushPayload,
  LightStreamerUnderlyingSubscriptionMutationTypes,
  PUSH_UPDATE_UNDERLYING,
  realtimeNamespace,
  tryUpdateUnderlyingValue,
} from '@/src/state/plugins/light-streamer/pushing-helper';
import { getTheQApiUrl } from '@/src/utils/url-helper';
import httpClient from '@/src/utils/http-service';
import { Controller } from '@/src/types/enumerations';
import Vue from 'vue';
import { sortBy, sortByOrder } from '@/src/utils/sorting-helper';
import { arrayMoveImmutable } from 'array-move';

export const state = {
  realtimeUnderlyings: {} as { [category: string]: Array<SortedUnderlying> },
  underlyings: null as MutationPushPayloadHolder<Array<UnderlyingModel>, UnderlyingModel> | null,
};

export type State = typeof state;

export enum MutationTypes {
  SORT_REALTIME_UNDERLYING = 'SORT_REALTIME_UNDERLYING',
}

export function isPersistMutation(mutationType: string) {
  return mutationType === realtimeNamespace + MutationTypes.SORT_REALTIME_UNDERLYING;
}

export function reducePersistState(state: State) {
  return { realtimeUnderlyings: state.realtimeUnderlyings };
}

export const getters = {
  realtimeUnderlyingSorting: (state: State) => {
    return (category: string) => state.realtimeUnderlyings[category] || [];
  },
  underlyings: (state: State) => {
    return state.underlyings?.holder;
  },
};

export const mutations = {
  [MutationTypes.SORT_REALTIME_UNDERLYING](state: State, sorting: { category: string; positions: SortedUnderlying[] }) {
    state.realtimeUnderlyings[sorting.category] = sorting.positions;
  },
  [LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_UNDERLYINGS](
    state: State,
    underlyingPushPayload: MutationPushPayloadHolder<Array<UnderlyingModel>, UnderlyingModel>
  ) {
    state.underlyings = underlyingPushPayload;
  },
  [PUSH_UPDATE_UNDERLYING](
    state: State,
    pushItem: { underlying: UnderlyingPricePush; pushSubscriptions: { [key: string]: boolean } }
  ) {
    state.underlyings?.holder.forEach((u) =>
      tryUpdateUnderlyingValue(u, pushItem.underlying, pushItem.pushSubscriptions)
    );
  },
};

function sortUnderlyings(
  sortOrder: SortOrder | null,
  underlyings: Array<UnderlyingModel>,
  realtimeUnderlyingSorting: Array<SortedUnderlying>
): Array<UnderlyingModel> {
  if (Array.isArray(underlyings)) {
    if (sortOrder !== null) {
      return sortBy(underlyings, sortOrder.key, 'underlying', sortOrder.direction);
    } else if (Array.isArray(realtimeUnderlyingSorting) && realtimeUnderlyingSorting.length !== 0) {
      Vue.$log.debug('sort d', realtimeUnderlyingSorting, sortOrder);
      return sortByOrder(underlyings, realtimeUnderlyingSorting, 'isin');
    } else {
      return Array.from(underlyings);
    }
  }
  return [];
}

export const actions = {
  saveRealtimeCategoryAndUpdateSorting(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { commit, getters, dispatch }: { commit: Commit; getters: any; dispatch: Dispatch },
    { category, oldIndex, newIndex }: { category: string; oldIndex: number; newIndex: number }
  ): void {
    const sortedUnderlyings = arrayMoveImmutable(getters.underlyings, oldIndex, newIndex) as Array<UnderlyingModel>;
    const sortPositions =
      sortedUnderlyings.map((underlying, index) => ({ isin: underlying.isin, sort: index }) as SortedUnderlying) || [];

    commit(MutationTypes.SORT_REALTIME_UNDERLYING, { category: category, positions: sortPositions });
    dispatch('resortUnderlyings', { sortOrder: null, category: category });
  },

  async searchUnderlyingsAsync(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { commit, getters }: { commit: Commit; getters: any },
    {
      filter,
      sortOrder,
      category,
      minPushItem,
      maxPushItem,
    }: {
      filter: UnderlyingSearchFilterPayload;
      sortOrder: SortOrder | null;
      category: string | null;
      minPushItem: number;
      maxPushItem: number;
    }
  ): Promise<void> {
    try {
      const response = await httpClient.get<UnderlyingModel[]>(getTheQApiUrl(Controller.Underlying, 'SearchBy'), {
        params: { realtimeCategory: filter.realtimeCategory },
      });

      const sortedData = sortUnderlyings(sortOrder, response.data, getters.realtimeUnderlyingSorting(category));

      const underlyingPushPayload = createPushPayload<Array<UnderlyingModel>, UnderlyingModel>(
        sortedData,
        (holder: UnderlyingModel[]): Array<UnderlyingModel> => holder.slice(minPushItem, maxPushItem)
      );

      commit(LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_UNDERLYINGS, underlyingPushPayload);
    } catch (ex) {
      Vue.$log.error('Error in vuex searchUnderlyingsAsync', ex);
    }
  },
  resortUnderlyings(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { commit, getters }: { commit: Commit; getters: any },
    {
      sortOrder,
      category,
      minPushItem,
      maxPushItem,
    }: {
      sortOrder: SortOrder | null;
      category: string | null;
      minPushItem: number;
      maxPushItem: number;
    }
  ): void {
    const sortedData = sortUnderlyings(sortOrder, getters.underlyings, getters.realtimeUnderlyingSorting(category));

    const underlyingPushPayload = createPushPayload<Array<UnderlyingModel>, UnderlyingModel>(
      sortedData,
      (holder: UnderlyingModel[]): Array<UnderlyingModel> => holder.slice(minPushItem, maxPushItem)
    );

    commit(LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_UNDERLYINGS, underlyingPushPayload);
  },
  updateUnderlyingsPushItems(
    { commit, state }: { commit: Commit; state: State },
    { minPushItem, maxPushItem }: { minPushItem: number; maxPushItem: number }
  ): void {
    // Fetch and save in Cache
    const underlyingPushPayload = createPushPayload<Array<UnderlyingModel>, UnderlyingModel>(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.underlyings?.holder!,
      (holder: UnderlyingModel[]): Array<UnderlyingModel> => holder.slice(minPushItem, maxPushItem)
    );

    commit(LightStreamerUnderlyingSubscriptionMutationTypes.CACHE_UNDERLYINGS, underlyingPushPayload);
  },
};
