<template>
  <span
    v-bind:class="[pushClass, 'ad-instrument-data-value', isWordWrap ? '' : 'text-nowrap']"
    :title="formatedValue"
    >{{ formatedValue }}</span
  >
</template>
<script lang="ts">
import { InstrumentDataFieldBase } from '@/src/types/episerver-api-instrument';
import { PriceModel, ProductModel, UnderlyingModel } from '@/src/types/the-q-api';
import { ApiTypeValue } from '@/src/types/vue-api';
import { getFormatDataField } from '@/src/utils/value-formatter/format-helper';
import { formatField } from '@/src/utils/value-formatter/formatting-service';

import { Component, Vue, Prop } from 'vue-property-decorator';
enum Push {
  High = 'high',
  Low = 'low',
  None = 'none',
}

@Component
export default class AdInstrumentDataValue extends Vue {
  @Prop({ required: true })
  value!: ApiTypeValue;
  @Prop({ required: true }) fieldKey!: string;
  @Prop() instrument: UnderlyingModel | ProductModel | undefined;
  private push!: boolean;

  private currentValue!: ApiTypeValue;

  private pushClass = Push.None;
  private isPush = false;
  private isWordWrap = false;

  get formatedValue(): string {
    return formatField(this.value, this.fieldKey, this.instrument, this.isPush);
  }

  created(): void {
    const field = getFormatDataField(this.fieldKey) as InstrumentDataFieldBase | undefined;
    this.push = !!field?.push;
    this.isWordWrap = !!field?.isWordWrap;

    if (this.push) {
      this.$emit('subscribe-to-push', this.fieldKey);
      this.$watch('value', this.setPushStyling);

      this.currentValue = this.cloneValue(this.value);

      this.$log.debug('pushing is set', this.fieldKey, this.value);
    }
  }

  setPushStyling(): void {
    this.isPush = true;
    this.pushClass == Push.None;

    //only number can have push styling
    if (typeof (((this.value as PriceModel)?.amount || this.value) as number) === 'number') {
      const formatedField = formatField(this.currentValue, this.fieldKey, this.instrument, this.isPush);
      const field = getFormatDataField(this.fieldKey);

      if (formatedField !== this.formatedValue && this.formatedValue !== field?.nullValue) {
        this.pushClass = this.getPushClass(
          ((this.currentValue as PriceModel).amount || this.currentValue) as number,
          ((this.value as PriceModel).amount || this.value) as number
        );
      }

      this.currentValue = this.cloneValue(this.value);
      window.setTimeout(() => (this.pushClass = Push.None), 500);
    }
  }

  getPushClass(currentValue: number | null, value: number | null): Push {
    value = value || 0;
    currentValue = currentValue || 0;
    // have to check value also for type number, because vue convert values
    // to getters, which can be empty objects
    if (typeof value !== 'number') {
      value = 0;
    }
    if (typeof currentValue !== 'number') {
      currentValue = 0;
    }

    if (value > currentValue) {
      return Push.High;
    }

    if (value < currentValue) {
      return Push.Low;
    }

    return Push.None;
  }

  cloneValue(value: ApiTypeValue): ApiTypeValue {
    if (typeof value === 'object') {
      return Object.assign({}, value);
    }

    return value;
  }
}
</script>
<style lang="scss">
.ad-instrument-data-value {
  transition-duration: 0.5s;
  transition-property: color;

  &.low {
    color: $deep-red;
  }

  &.high {
    color: $kelley-green;
  }
}
</style>
