import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options';

Vue.directive('form-element-state', {
  // When the bound element is inserted into the DOM...
  inserted: function (el: HTMLElement, binding: DirectiveBinding) {
    const formElement = el as HTMLInputElement;
    const bindingElement = document.getElementById(binding.value);

    if (formElement.validity && bindingElement) {
      const validElement = () => {
        if (formElement.validity.valid) {
          bindingElement.classList.add('valid');
          bindingElement.classList.remove('error');
        } else {
          bindingElement.classList.add('error');
          bindingElement.classList.remove('valid');
        }
      };

      formElement.addEventListener('focus', function () {
        bindingElement.classList.add('focus');
      });

      formElement.addEventListener('blur', function () {
        bindingElement.classList.remove('focus');
      });

      el.addEventListener('change', function () {
        bindingElement.classList.add('dirty');
        validElement();
      });
      el.addEventListener('keyup', function () {
        validElement();
      });
      el.addEventListener('invalid', function (e) {
        validElement();
        e.preventDefault();
      });

      if (formElement.disabled) {
        bindingElement.classList.add('disabled');
      }

      const observer = new MutationObserver(function (mutations) {
        for (let i = 0, mutation; (mutation = mutations[i]); i++) {
          if (mutation.attributeName == 'disabled') {
            if (mutation.target.disabled) {
              bindingElement.classList.add('disabled');
            } else {
              bindingElement.classList.remove('disabled');
            }
          }
        }
      });

      // Observe attributes change
      observer.observe(el, { attributes: true });
    } else {
      if (bindingElement) {
        Vue.$log.error('Element is not an input element', el);
      } else {
        Vue.$log.error('Directive: form-element-state', `Could not find element ${binding.value}.`, el);
      }
    }
  },
});
