<script lang="ts">
import { VNode } from 'vue';
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import { ChartConfiguration } from '@/src/types/vue-api';

@Component({})
export default class AdChartConfigurationProperty extends Vue {
  @Provide()
  @Prop({ default: new ChartConfiguration() })
  chartConfigurationProperty!: ChartConfiguration;

  render(): VNode[] | VNode | undefined {
    return this.$scopedSlots.default?.({});
  }
}
</script>
