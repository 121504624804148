<template>
  <div class="row no-gutters ce-author-big">
    <div class="ce-author-big-image-outer-wrapper col-12 col-md-4 mr-md-4" v-if="imageLink">
      <div class="ce-author-big-image-inner-wrapper">
        <img :src="imageLink" class="ce-author-big-image" />
      </div>
    </div>
    <div class="ce-author-big-text col-12 col-md p-0">
      <div class="ce-author-big-header fs-16 pt-2 pt-md-2">
        <slot name="headline"></slot>
      </div>
      <div class="ce-author-big-name fs-26 pt-0">
        <slot name="name"></slot>
      </div>
      <div class="ce-author-big-description fs-18 pt-2">
        <slot name="about"></slot>
      </div>
      <a
        v-if="linkedInUrl"
        :href="linkedInUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="link btn btn-default mt-3 mt-md-4 mr-2 w-100 w-md-auto"
      >
        <ad-icon-linked-in height="1.2em"></ad-icon-linked-in>
        <span class="pl-1">
          <slot name="linkedin-button-text">Connect with me</slot>
        </span>
      </a>
      <a
        v-if="xingUrl"
        :href="xingUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="link btn btn-default mt-3 mt-md-4 w-100 w-md-auto"
      >
        <ad-icon-xing height="1.2em" width="2.4em"></ad-icon-xing>
        <span class="pl-1">
          <slot name="xing-button-text">Connect with me</slot>
        </span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { AuthorBlockSettings } from '@/src/types/episerver-api';
import { Vue, Component, Inject } from 'vue-property-decorator';

@Component
export default class CeAuthorBig extends Vue {
  @Inject()
  settingsProperty!: AuthorBlockSettings;

  get imageLink(): string | null {
    return this.settingsProperty.imageUrl;
  }

  get linkedInUrl(): string | null {
    return this.settingsProperty.linkedInUrl;
  }

  get xingUrl(): string | null {
    return this.settingsProperty.xingUrl;
  }
}
</script>

<style lang="scss">
.ce-author-big {
  .ce-author-big-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .ce-author-big-header {
    color: $peacock-blue;
  }
  .ce-author-big-name {
    color: $marine-blue;
  }
  .ce-author-big-description {
    color: $gunmetal;
  }

  .ce-author-big-image-inner-wrapper {
    position: relative;
    padding-top: 100%;
    width: 100%;
  }
  .ce-author-big-image-outer-wrapper {
    flex: 0 0 auto;
    padding: 0;
    width: rem(154);
    @include media-breakpoint-up('md') {
      width: rem(190);
    }
  }
}
</style>
