<template>
  <div :class="documentSearchClass">
    <!-- Search input -->
    <div :class="globalSearchInputClass">
      <b-input-group class="py-0 align-items-center">
        <b-input-group-append class="ce-header-quicksearch__icon fs-18 py-0">
          <ad-icon-search></ad-icon-search>
        </b-input-group-append>
        <ad-global-search
          :showOnlyProductsInSearch="showOnlyProductsInSearch"
          @changeValidationState="changeGlobalSearchInputValidationState"
          ref="globalSearch"
        >
          <template #error-message> <slot name="search-error-message"></slot></template>
        </ad-global-search>
      </b-input-group>
    </div>
    <button class="btn btn-secondary py-1 fs-18" @click="onClickSearchButton">
      <ad-icon-search></ad-icon-search>
      <slot name="document-search-button-text"></slot>
    </button>
  </div>
</template>

<script lang="ts">
import AdGlobalSearch from '@/src/components/molecules/ad-global-search/index.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CeDocumentSearch extends Vue {
  protected showOnlyProductsInSearch = true;
  private isGlobalSearchInputValid: boolean = true;

  async onClickSearchButton() {
    await (this.$refs?.globalSearch as AdGlobalSearch)?.rapidSearch?.();
  }
  get documentSearchClass(): (string | Record<string, boolean>)[] {
    return ['ce-document-search d-flex align-items-center', { 'show-invalid': !this.isGlobalSearchInputValid }];
  }

  get globalSearchInputClass(): (string | Record<string, boolean>)[] {
    return ['ce-header-quicksearch mr-2 py-0', { 'in-valid': !this.isGlobalSearchInputValid }];
  }

  private changeGlobalSearchInputValidationState(isValid: boolean) {
    this.isGlobalSearchInputValid = isValid;
  }
}
</script>

<style lang="scss">
.ce-document-search {
  .v-select {
    flex: 1 1 0;
  }
}
</style>
