import { AxiosError } from 'axios';
import {
  InstrumentMergeColumnSetting,
  TableColumnSettingBase,
  TeaserImageSetting,
  TeaserSetting,
  TeaserVideoSetting,
} from './episerver-api';
import { OhlcChartPoint, OptionGroup, PriceModel } from './the-q-api';
import { HeadlineBlock, MutationPushPayloadHolder } from './vue-api';
import { TextImageTeaserTypes } from './enumerations';
import Vue from 'vue';

export function isOhlcChartPoint(point: unknown): point is OhlcChartPoint {
  const ohlc = point as OhlcChartPoint;
  return ohlc.close !== undefined && ohlc.high !== undefined && ohlc.low !== undefined && ohlc.open !== undefined;
}

export function isHeadlineBlock(block: Vue | HeadlineBlock): block is HeadlineBlock {
  const headlineBlock = block as HeadlineBlock;
  return (
    headlineBlock.isVisible !== undefined &&
    headlineBlock.blockHeadline !== undefined &&
    headlineBlock.setHeadlineVisibility !== undefined
  );
}

export function isMergeColumnSetting(
  columnSettings: TableColumnSettingBase | InstrumentMergeColumnSetting
): columnSettings is InstrumentMergeColumnSetting {
  return !!(columnSettings as InstrumentMergeColumnSetting)?.columnMergeSetting;
}

export function isPriceModel(value: unknown): value is PriceModel {
  const price = value as PriceModel;
  return price?.amount !== undefined && price?.currencyCode !== undefined;
}

export function isNumber(value: unknown): value is number {
  if (typeof value === 'number') return true;
  return false;
}

export function isOptionGroup(value: unknown): value is OptionGroup {
  const optionGroup = value as OptionGroup;
  return optionGroup?.header !== undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isMutationPushPayloadHolder(value: unknown): value is MutationPushPayloadHolder<any, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const payloadHolder = value as MutationPushPayloadHolder<any, any>;
  return payloadHolder?.holder !== undefined && payloadHolder?.getSubscriptionPushItems !== undefined;
}

export function isTeaserImageSetting(teaser: TeaserSetting): teaser is TeaserImageSetting {
  return teaser.type === TextImageTeaserTypes.Image;
}

export function isTeaserVideoSetting(teaser: TeaserSetting): teaser is TeaserVideoSetting {
  return teaser.type === TextImageTeaserTypes.Video;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAxiosError(candidate: any): candidate is AxiosError {
  return candidate.isAxiosError === true;
}

export function isNotNullOrUndefined<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
