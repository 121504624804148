<template>
  <div class="ce-preview">
    <div class="aspect-ratio-16-9">
      <div class="aspect-ratio-filler">
        <ce-video v-if="videoVisible" :videoPath="videoPath" :youtubeId="youtubeId" :autoPlay="true"></ce-video>
        <div v-if="!videoVisible" class="ce-preview--preview-wrapper">
          <slot name="preview-image">
            <picture v-if="youtubeId">
              <img class="aspect-ratio-filler zoom-animated-image" :src="youtubePreviewThumbnail" />
            </picture>
            <ce-video v-else :videoPath="videoPath + '#t=0.1'" :noControls="true"></ce-video>
          </slot>
        </div>
      </div>
      <a v-if="!videoVisible" href="javascript:void(0)" class="video-click-overlay" v-on:click="play">
        <button class="ce-preview-play-button btn btn-secondary rounded-circle py-0 border-0">
          <ad-icon-play width="40" height="40"></ad-icon-play>
        </button>
      </a>
    </div>
    <b-modal
      :id="modalId"
      centered
      modal-class="ce-video-modal"
      hide-footer
      hide-header
      size="xl"
      @ok="close"
      :visible="modalVisible"
      @change="modalVisibilityChange($event)"
    >
      <div class="video-preview-modal-video-wrapper">
        <ce-video :videoPath="videoPath" :youtubeId="youtubeId" :autoPlay="true"></ce-video>
      </div>
      <div class="ce-preview__close">
        <button @click="close" class="ce-preview__button important btn rounded-circle px-0 py-0 border-0">
          <ad-icon-close height="1em"></ad-icon-close>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { getYoutubeThumbnail } from '@utils/url-helper';
import { Prop, Vue, Component } from 'vue-property-decorator';

/**
 * Renders a preview of either a youtube video or a base html video
 *
 * This is decided by specifying either youtubeId (the id of the youtube video)
 * or the path to the video file itself.
 *
 * The preview extracts either the thumbnail from a youtube video or
 * uses a video player without controls as the preview for html videos.
 *
 * By filling the slot "preview-image", users of this component can overwrite
 * the default preview image.
 *
 * When clicking on the play button one of two things happens:
 * - on mobile: Preview is replaced with real video
 * - on desktop: A modal is openend which displays the real video.
 */
@Component
export default class CeVideoPreview extends Vue {
  //youtubeId - id/code of the video to embed
  @Prop({ default: '' }) youtubeId!: string;
  @Prop({ default: '' }) videoPath!: string;
  private modalId = 'video-preview-modal-' + this.youtubeId + this.videoPath;

  /**
   * Represents the 3 states of this component:
   * - preview-visible: Initial state. Preview image + blue play button
   * - modal-visible: After clicking the play button on desktop, the modal is opened
   * - video-visible: After clicking the play button on mobile, the video is displayed inline.
   */
  private previewState: 'preview-visible' | 'modal-visible' | 'video-visible' = 'preview-visible';

  private get modalVisible(): boolean {
    return this.previewState === 'modal-visible';
  }

  private get videoVisible(): boolean {
    return this.previewState === 'video-visible';
  }

  private get youtubePreviewThumbnail(): string {
    if (!this.youtubeId) return '';
    return getYoutubeThumbnail(this.youtubeId);
  }

  modalVisibilityChange(isVisible: boolean) {
    if (isVisible) {
      this.previewState = 'modal-visible';
    } else {
      this.previewState = 'preview-visible';
    }
  }

  close(): void {
    this.previewState = 'preview-visible';
  }

  play(): void {
    const shouldModalBeUsed = window.matchMedia('(min-width: 768px)').matches;
    if (shouldModalBeUsed) {
      this.previewState = 'modal-visible';
    } else {
      this.previewState = 'video-visible';
    }
  }
}
</script>

<style lang="scss">
.video-preview-modal-video-wrapper {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal-body.modal-body {
  padding: 0;
  width: 100%;
  height: 100%;
}

.ce-video-modal .modal-content {
  background: transparent;
}

.ce-video-modal .modal-dialog {
  padding: map-get($spacers, 5);
  max-width: calc((100vh - 10rem) * 16 / 9);
}

.ce-preview {
  .video-click-overlay {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  &__close {
    position: absolute;
    top: -74px;
    right: 0;
  }
  &__button.important.important.important {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(54);
    min-width: rem(54);
    height: rem(54);
  }
}

/**
 * This class is applied to an element that also has the .btn bootstrap class
 * The .btn handles the correct focus/hover/active styles but provides
 * values for padding and min-width, that are not appropriate here
 * Since the .btn class is using a very high specificity to declare the values
 * we have to use !important, as the linter would complain otherwise.
 */
.ce-preview-play-button {
  position: absolute;
  padding: 0 0 0 0.5rem !important;
  width: rem(88);
  min-width: rem(88) !important;
  height: rem(88);
  vertical-align: center;
  text-align: center;
}
</style>
