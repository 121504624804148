<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    :width="width"
    :viewBox="viewBox"
    :aria-labelledby="iconId"
    :style="style"
    class="ade-icon"
    role="presentation"
  >
    <title :id="iconId" lang="en"><slot name="title"></slot></title>
    <g fill="currentColor" stroke="currentColor">
      <slot />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
/**
 * Wraps the main content elements of an svg icon
 * AdIconBase renders the svg itself and controls dimensions and color of
 * the rendered svg paths.
 *
 * Dimensions (width/height) are set to 1.3em by default, so that the icon
 * has an apropriate base size when compared to text. In addition to that,
 * the icon scales automatically with the font-size of the parent container
 * by using em instead of rem.
 *
 * Color is controlled by setting the fill and stroke of the svg to "currentColor"
 * which is a CSS property that references the font-color of the parent container.
 *
 * As a user of an icon component, you control dimensions by adjusting the font-size
 * and the icon color by adjusting the color.
 */
export default class AdIconBase extends Vue {
  @Prop({ default: '1.3em', type: [String, Number] }) height!: string | number;
  @Prop({ default: '1.3em', type: [String, Number] }) width!: string | number;
  @Prop({ default: '0 0 40 40', type: String }) viewBox!: string;
  @Prop({ default: 0, type: [String, Number] }) rotate!: string | number;
  get iconId(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return `${(this as any)._uid}-icon`;
  }

  get style(): { [key: string]: string } {
    const style: { [key: string]: string } = {};
    if (this.rotate) style.transform = 'rotate(' + this.rotate + 'deg)';
    return style;
  }
}
</script>

<style lang="scss">
/**
  Css class name is set to ade because add blocker blocks images with classes start with ad-
*/
.ade-icon {
  overflow: visible;
}
</style>
