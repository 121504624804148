import {
  BestMatchSearchRequestModel,
  ChartPoint,
  DateRangeParameterModel,
  OhlcChartPoint,
  PriceModel,
  ProductModel,
  ProductSearchParameterModel,
  RollingHistoryModel,
  SortOrder,
  SubscriptionPush,
  UnderlyingModel,
  ValueRangeParameterModel,
} from '@src/types/the-q-api';

import {
  ChartColors,
  ChartPeriods,
  ChartSeries,
  DropDownItemType,
  InstrumentOriginTimeZone,
  ProductSubTypeBo,
  ProductTypeBo,
  RoutingParameters,
  UnderlyingType,
  DocumentType,
} from '@/src/types/enumerations';
import {
  ChartsApiFilter,
  ColumnMergeSetting,
  InstrumentMergeColumnSetting,
  InstrumentTableColumnSetting,
  InstrumentTableSetting,
  TableColumnSettingBase,
  DownloadLink,
  Download,
} from '@src/types//episerver-api';
import { MutationPayload } from 'vuex';
import { ItemUpdate, LightstreamerClient } from 'lightstreamer-client-web';
import { BvTableField } from 'bootstrap-vue';
import {
  GeneralFormatField,
  ProductDataField,
  ProductHistoryDataField,
  UnderlyingDataField,
} from '@src/types/episerver-api-instrument';
import { GroupedDownloadGroup } from './ce-component-types';

/**
 * Type used for routing in url helper
 */
export interface Route {
  route: RoutingParameters;
  parameter: string;
}

/**
 * Type used in AdDropDownList
 */
export interface DropDownItem {
  value: string | number | null;
  text: string | null;
  type: DropDownItemType;
}

/**
 * Value types used in api
 */
export type ApiTypeValue = string | number | PriceModel | Date | null;
export type ApiModel = ProductModel | UnderlyingModel | RollingHistoryModel;
export type FormatDataField = UnderlyingDataField | ProductDataField | ProductHistoryDataField | GeneralFormatField;

export enum LabelPosition {
  Left = 3,
  Right = 1,
}
export class DataSeries {
  data: ChartPoint[];
  color: string;
  labels: LabelPosition;
  series: ChartSeries;
  constructor(series: ChartSeries, data: ChartPoint[], color: ChartColors, labels: LabelPosition = 3) {
    this.data = data;
    this.color = color.toString();
    this.labels = labels;
    this.series = series;
  }
}

export interface HeadlineBlock {
  blockHeadline: string | undefined;
  isVisible: boolean;
  setHeadlineVisibility(visible: boolean): void;
}

export class OhlcDataSeries {
  data: OhlcChartPoint[];
  labels: LabelPosition;
  color: string;
  constructor(data: OhlcChartPoint[]) {
    this.data = data;
    this.labels = LabelPosition.Left;
    this.color = ChartColors.underlying.toString();
  }
}

export class ChartsApiFilterItem implements ChartsApiFilter {
  period: ChartPeriods;
  timeZone: InstrumentOriginTimeZone;
  symbol: string;
  pointInterval: number | null;
  timeFrom: number | null;
  timeTo: number | null;
  series: string;

  constructor(
    period: ChartPeriods,
    series: ChartSeries[],
    symbol: string,
    timeZone: InstrumentOriginTimeZone,
    pointInterval: number | null,
    timeFrom: number | null,
    timeTo: number | null
  ) {
    this.period = period;
    this.timeZone = timeZone;
    this.pointInterval = pointInterval;
    this.symbol = symbol;
    this.timeFrom = timeFrom;
    this.timeTo = timeTo;
    this.series = series.join(',');
  }
}

export class ProductTeaserSettings {
  desktopTableColumnsSetting: InstrumentTableColumnSetting[];
  tabletTableColumnsSetting: InstrumentTableColumnSetting[];
  mobileTableColumnsSetting: InstrumentTableColumnSetting[];

  constructor(
    desktopTableColumnsSetting: InstrumentTableColumnSetting[],
    tabletTableColumnsSetting: InstrumentTableColumnSetting[],
    mobileTableColumnsSetting: InstrumentTableColumnSetting[]
  ) {
    this.desktopTableColumnsSetting = desktopTableColumnsSetting;
    this.tabletTableColumnsSetting = tabletTableColumnsSetting;
    this.mobileTableColumnsSetting = mobileTableColumnsSetting;
  }
}
export class ChartConfiguration {
  forceDropDownForChartPeriods = false;
  forceRadioButtonsForChartPeriods = false;
  legendInTwoLineModeAtTablet = false;
}

export interface TableField extends BvTableField {
  sortNumber?: number;
  key?: string;
  label?: string;
  mergeColumnSettings?: ColumnMergeSetting[];
  isEllipsis?: boolean;
}

export class ProductSearchParametersFilter implements ProductSearchParameterModel {
  isRequestWithoutUnderlyingWhenNoResult = false;
  underlyingIsinOrIsins: string | string[] | null = null;
  productType: ProductTypeBo | null = null;
  categoryName: string | null = null;
  strikeStopLoss: ValueRangeParameterModel | null = null;
  koBarrier: ValueRangeParameterModel | null = null;
  omega: ValueRangeParameterModel | null = null;
  oneDayOmega: ValueRangeParameterModel | null = null;
  pureDelta: ValueRangeParameterModel | null = null;
  deltaOverRatio: ValueRangeParameterModel | null = null;
  sortOrders: SortOrder[] = [];
  subType: ProductSubTypeBo | null = null;
  countryName: string | null = null;
  sectorName: string | null = null;
  underlyingCategory: UnderlyingType | null = null;
  strike: ValueRangeParameterModel | null = null;
  stopLoss: ValueRangeParameterModel | null = null;
  issueDate: DateRangeParameterModel | null = null;
  maturityDate: DateRangeParameterModel | null = null;
  leverage: ValueRangeParameterModel | null = null;
  distanceToKnockout: ValueRangeParameterModel | null = null;
  distanceToKnockoutRelToSpot: ValueRangeParameterModel | null = null;
  changePercent: ValueRangeParameterModel | null = null;
  participation: ValueRangeParameterModel | null = null;
  capBonus: ValueRangeParameterModel | null = null;
  discount: ValueRangeParameterModel | null = null;
  sidewayYield: ValueRangeParameterModel | null = null;
  sidewayYieldPerAnno: ValueRangeParameterModel | null = null;
  bonusBarrier: ValueRangeParameterModel | null = null;
  revBonusBarrier: ValueRangeParameterModel | null = null;
  revCapBonusBarrier: ValueRangeParameterModel | null = null;
  capBonusBarrier: ValueRangeParameterModel | null = null;
  bonusLevel: ValueRangeParameterModel | null = null;
  revBonusLevel: ValueRangeParameterModel | null = null;
  revCapBonusLevel: ValueRangeParameterModel | null = null;
  capBonusLevel: ValueRangeParameterModel | null = null;
  bonusYield: ValueRangeParameterModel | null = null;
  riskBuffer: ValueRangeParameterModel | null = null;
  agio: ValueRangeParameterModel | null = null;
  sprintStartValue: ValueRangeParameterModel | null = null;
  sprintStopValue: ValueRangeParameterModel | null = null;
  factor: ValueRangeParameterModel | null = null;
  spreadWarrantCapFloor: ValueRangeParameterModel | null = null;
  maximumAmount: ValueRangeParameterModel | null = null;
  maximumYield: ValueRangeParameterModel | null = null;
  pageNumber = 1;
  pageSize = Number.parseInt(process.env.VUE_APP_DEFAULT_PAGE_SIZE);
  resetSortOrders: SortOrder[] = [];
  isAddColumnDistributionFee = false;
  underlyingIsins: string[] | null = null;
  defaultUnderlying: string | null = null;

  public getResetSecondaryFilter(): ProductSearchParametersFilter {
    // create new filter object, to trigger vue watches on object;
    const filter = new ProductSearchParametersFilter();
    filter.isRequestWithoutUnderlyingWhenNoResult = this.isRequestWithoutUnderlyingWhenNoResult;
    filter.productType = this.productType;
    filter.categoryName = this.categoryName;
    filter.sortOrders = this.sortOrders;
    filter.subType = this.subType;
    filter.countryName = this.countryName;
    filter.sectorName = this.sectorName;
    filter.underlyingCategory = this.underlyingCategory;
    filter.pageNumber = this.pageNumber;
    filter.pageSize = this.pageSize;
    filter.resetSortOrders = this.resetSortOrders;
    filter.isAddColumnDistributionFee = this.isAddColumnDistributionFee;
    filter.underlyingIsins = this.underlyingIsins;
    filter.defaultUnderlying = this.defaultUnderlying;
    filter.underlyingIsinOrIsins = this.underlyingIsinOrIsins;

    return filter;
  }
}

export class BestMatchSearchRequestFilter implements BestMatchSearchRequestModel {
  productType: ProductTypeBo = ProductTypeBo.Warrant;
  subType: ProductSubTypeBo = ProductSubTypeBo.Call;
  underlyingIsin: string | null = null;
  maturityDate: string | null = null;
  keyDataField: string | null = null;
  keyDataValue: number | null = null;
  numberOfItems = 5;
  filterProperty = '';
}

/**
 * Instrument data table type
 */
export interface InstrumentTableSettingData
  extends Omit<
    InstrumentTableSetting,
    'desktopTableColumnsSetting' | 'tabletTableColumnsSetting' | 'mobileTableColumnsSetting'
  > {
  desktopTableColumnsSetting: Array<InstrumentMergeColumnSetting | TableColumnSettingBase>;
  tabletTableColumnsSetting: Array<InstrumentMergeColumnSetting | TableColumnSettingBase>;
  mobileTableColumnsSetting: Array<InstrumentMergeColumnSetting | TableColumnSettingBase>;
}

/**
 * Lightstream
 */
export interface LightstreamSubscription {
  client: LightstreamerClient;
  mutation: MutationPushPayload;
  /**
   * push subscription mutations for items
   */
  subscriptionMutationTypes: { [s: number]: string };
  /**
   * namespace + mutation name for push item subscription (e.g. underlying.last)
   */
  pushItemSubscriptionGetter: string;
  namespaces: string[];
  fields: Array<string>;
  adapterSet: string;
  onUpdate: { (updateInfo: ItemUpdate, pushSubscriptions: { [key: string]: boolean }): void };
}

export class SearchSuggestion {
  name!: string;
  detail!: string | null;
  url!: string | undefined | null;
  listIndex: number;

  constructor(index: number, name: string, url: string | undefined | null, detail: string | null = null) {
    this.name = name;
    this.url = url;
    this.detail = detail;
    this.listIndex = index;
  }
}

export class SearchSuggestionGroup {
  name!: string;
  cssClass: string | undefined;
  data: SearchSuggestion[] = [];

  constructor(name: string, data: SearchSuggestion[], cssClass: string | undefined = undefined) {
    this.name = name;
    this.data = data;
    this.cssClass = cssClass;
  }
}

export class GlobalSearchSuggestions {
  underlyings: SearchSuggestionGroup[] = [];
  productTypes: SearchSuggestionGroup | undefined;
  content: SearchSuggestionGroup | undefined;
  products: SearchSuggestionGroup[] = [];

  get theqItems(): SearchSuggestionGroup[] {
    const result = [...this.underlyings];
    if (this.productTypes !== undefined) result.push(this.productTypes);
    result.push(...this.products);
    return result;
  }

  get hasTheqItems(): boolean {
    return this.underlyings.length != 0 || this.products.length !== 0 || this.productTypes !== undefined;
  }

  get epiItems(): SearchSuggestionGroup[] {
    return this.content !== undefined ? [this.content] : [];
  }

  get hasEpiItems(): boolean {
    return this.content !== undefined;
  }

  get allItems(): SearchSuggestion[] {
    return (this.content !== undefined ? [...this.theqItems, this.content] : this.theqItems).flatMap((g) => g.data);
  }

  get length(): number {
    return this.allItems.length;
  }

  get hasBothItemsGroups(): boolean {
    return this.hasEpiItems && this.hasTheqItems;
  }
}

export interface MutationPushPayloadHolder<TPayload, SubscriptionPush> {
  holder: TPayload;
  getSubscriptionPushItems: (holder) => Array<SubscriptionPush>;
}

export interface MutationPushPayload extends Omit<MutationPayload, 'payload'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: MutationPushPayloadHolder<any, SubscriptionPush> | { [key: string]: boolean };
}

export interface LayeredDownloadItem {
  file: DownloadLink | Download | GroupedDownloadGroupItem;
  depth: number;
}

export interface DownloadGroupItem {
  type: DocumentType;
  childListName: string | null;
  files: GroupedDownloadGroup[];
}

//Implemented by GroupedDownloadGroup type from queo
export interface GroupedDownloadGroupItem {
  title: string;
  link: string;
  date: number;
  year: number | null;
  format: string;
  rank: number;
  childDownloads?: GroupedDownloadGroupItem[];
}

export interface ImportantNoticeSettings {
  headline: string;
  description: string;
  underlyingIsins: string[];
  productIsins: string[];
}

export class DocumentServiceConfiguration {
  dateFormat: string;
  get datePlaceholder() {
    return '{asOfDate}';
  }

  get namePlaceholder() {
    return '{name}';
  }

  constructor(dateFormat: string) {
    this.dateFormat = dateFormat;
  }
}

export interface SortedUnderlying {
  isin: string;
  sort: number;
}

export interface WatchedUnderlying {
  isin: string;
  addTimeStamp: Date;
}

export interface WatchedProduct {
  securityCode: string;
  addTimeStamp: Date;
  addPrice: PriceModel;
}

export interface WatchedProductModel extends ProductModel {
  watchlistAddPrice: PriceModel;
  watchlistAddChange: number | null;
}

/**
 * Option for bootstrap-vue form checkbox, radios and select.
 * See  also {@link https://bootstrap-vue.org/docs/components/form-checkbox#checkbox-group-options-array}
 * {@link https://bootstrap-vue.org/docs/components/form-radio#grouped-radios}
 * {@link https://bootstrap-vue.org/docs/components/form-select#options-property}
 */
export class SelectOption {
  text: string | null;
  value: string;
  disabled: boolean;

  constructor(value: string, text: string | null, disabled = false) {
    this.text = text;
    this.value = value;
    this.disabled = disabled;
  }
}
