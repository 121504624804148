<template>
  <div class="ad-image">
    <div class="w-100">
      <slot name="picture"></slot>
    </div>
    <div v-if="hasDescription">
      <slot name="figcaption"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AdImage extends Vue {
  @Prop({ default: false, type: Boolean }) private hasDescription!: boolean;
}
</script>
