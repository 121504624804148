<template>
  <ad-icon-base v-bind="$attrs" stroke-width="0.5">
    <path
      d="M10.74,19.56a.68.68,0,0,0,0-.14V8.5a.47.47,0,0,0-.49-.5.46.46,0,0,0-.49.5V19.42a.68.68,0,0,0,0,.14A2.28,2.28,0,0,0,8,21.79v3.15a2.27,2.27,0,0,0,1.9,2.25.49.49,0,0,0-.12.34v4a.46.46,0,0,0,.49.5.47.47,0,0,0,.49-.5v-4a.49.49,0,0,0-.12-.34,2.28,2.28,0,0,0,1.9-2.25V21.79A2.29,2.29,0,0,0,10.74,19.56Zm.81,5.38a1.28,1.28,0,1,1-2.56,0V21.79a1.28,1.28,0,1,1,2.56,0Z"
    /><path
      d="M30.1,12.81a.49.49,0,0,0,.12-.34v-4a.46.46,0,0,0-.49-.5.47.47,0,0,0-.49.5v4a.49.49,0,0,0,.12.34,2.28,2.28,0,0,0-1.9,2.25v3.15a2.29,2.29,0,0,0,1.8,2.23.68.68,0,0,0,0,.14V31.5a.47.47,0,0,0,.49.5.46.46,0,0,0,.49-.5V20.58a.68.68,0,0,0,0-.14A2.28,2.28,0,0,0,32,18.21V15.06A2.27,2.27,0,0,0,30.1,12.81Zm.91,5.4a1.28,1.28,0,1,1-2.56,0V15.06a1.28,1.28,0,1,1,2.56,0Z"
    /><path
      d="M20.64,16.28V8.5a.5.5,0,1,0-1,0v7.78a2.28,2.28,0,0,0-1.77,2.22v3.15a2.29,2.29,0,0,0,1.77,2.23V31.5a.5.5,0,1,0,1,0V23.88a2.28,2.28,0,0,0,1.78-2.23V18.5A2.28,2.28,0,0,0,20.64,16.28Zm.79,5.37a1.28,1.28,0,1,1-2.56,0V18.5a1.28,1.28,0,1,1,2.56,0Z"
    />
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconChartLine extends Vue {}
</script>
