<template>
  <div class="ce-blog-article-overview">
    <h1 v-if="$slots['heading']"><slot name="heading"></slot></h1>
    <ce-blog-article-overview-highlight-article :article="highlightArticle" @likeArticle="likeArticle($event)">
      <template #read-more>
        <slot name="read-more">Read More</slot>
      </template>
    </ce-blog-article-overview-highlight-article>
    <ce-blog-article-overview-article-chunk
      v-for="(chunk, i) in articleChunks"
      v-bind:key="i"
      :articles="chunk"
      @likeArticle="likeArticle($event)"
      :chunkIndex="i"
    >
    </ce-blog-article-overview-article-chunk>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import CeBlogArticleOverviewHighlightArticle from './_blog-article-overview-highlight-article.vue';
import CeBlogArticleOverviewArticleChunk from './_blog-article-overview-article-chunk.vue';
import { BlogArticleOverviewBlogArticle } from '@/src/types/ce-component-types';

@Component({
  components: {
    CeBlogArticleOverviewHighlightArticle,
    CeBlogArticleOverviewArticleChunk,
  },
})
export default class CeBlogArticleOverview extends Vue {
  @Prop({ required: true }) articles!: BlogArticleOverviewBlogArticle[];

  get highlightArticle(): BlogArticleOverviewBlogArticle {
    return this.articles[0];
  }

  get articleChunks(): BlogArticleOverviewBlogArticle[][] {
    const chunks: BlogArticleOverviewBlogArticle[][] = [];

    const [, ...nonHighlightArticles] = this.articles;

    let chunk: BlogArticleOverviewBlogArticle[] = [];
    for (let i = 0; i < nonHighlightArticles.length; i++) {
      if (chunk.length === 5) {
        chunks.push(chunk);
        chunk = [];
      }
      chunk.push(nonHighlightArticles[i]);
    }

    chunks.push(chunk);

    return chunks;
  }
}
</script>

<style lang="scss">
.ce-blog-article-overview {
  &__date {
    color: $peacock-blue;
  }
  &__header {
    color: $marine-blue;
  }
  &__text {
    color: $gunmetal;
  }
  &__author {
    color: $gunmetal;
  }
  &__like {
    color: $peacock-blue;
    font-weight: bold;
  }
}
</style>
