<template>
  <ad-error :http-status-code="statusCode">
    <ad-instrument-table :loading="isLoading" :settings="settingsProperty" v-bind="$attrs" :instruments="products">
      <template #row-instrument="{ setting, instrument }">
        <ad-product-data-value
          :is-link-to-detail-page="setting.isLinkToDetailPage"
          :product-item="instrument"
          :field-key="setting.formatId"
        />
      </template>
      <template #row-watch-list="{ instrument }">
        <ad-product-watchlist-toggle :product-item="instrument" />
      </template>
    </ad-instrument-table>
  </ad-error>
</template>
<script lang="ts">
import { TopLeverageTableBlockSettings } from '@/src/types/episerver-api';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { ProductModel } from '@/src/types/the-q-api';
import { HttpStatusCodes, TopLeverageTableFilter } from '@/src/types/enumerations';
import { isAxiosError } from '@/src/types/type-guards';
import { Getter, Action } from 'vuex-class';

@Component({
  inheritAttrs: false,
})
export default class AdTopLeverageTable extends Vue {
  @Inject() settingsProperty!: TopLeverageTableBlockSettings;

  @Getter('topLeverageSearchResult', { namespace: 'product' })
  topLeverageSearchResult!: (key: TopLeverageTableFilter) => Array<ProductModel>;

  @Action('loadTopLeverageTableSearchAsync', { namespace: 'product' })
  loadTopLeverageTableSearchAsync!: (filter: TopLeverageTableFilter) => Promise<void>;

  private statusCode: number | HttpStatusCodes = HttpStatusCodes.OK;
  private isLoading = true;

  get products(): Array<ProductModel> {
    return this.topLeverageSearchResult(this.settingsProperty.filter);
  }

  async created(): Promise<void> {
    await this.loadProductsWithErrorHandling();
  }

  async loadProductsWithErrorHandling() {
    this.isLoading = true;

    try {
      await this.loadTopLeverageTableSearchAsync(this.settingsProperty.filter);
    } catch (error: unknown) {
      this.$log.error('Loading Error', error);

      if (isAxiosError(error)) {
        this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
      } else {
        this.statusCode = HttpStatusCodes.UnknownError;
      }
    }

    this.isLoading = false;
  }
}
</script>
