<template>
  <b-col v-bind="$attrs">
    <div v-for="(group, index) in groups" :key="group.name">
      <div :class="['pt-3', 'pr-3', { hr: index !== 0 }]">
        <h5 class="mb-0 fs-14 font-weight-bold">{{ group.name }}</h5>
        <div v-for="item in group.data" :key="item.url">
          <b-row class="mt-1 mb-2" @mouseenter="onMouseEnter(item.listIndex)" @mouseleave="onMouseLeave" role="button">
            <b-col
              :cols="item.detail ? 8 : 12"
              :class="['fs-18', 'cursor-pointer', currentIndex === item.listIndex ? 'peacock-blue' : 'gun-metal']"
            >
              {{ item.name }}
            </b-col>
            <b-col v-if="item.detail" cols="4" class="text-right">{{ item.detail }}</b-col>
          </b-row>
        </div>
      </div>
    </div>
  </b-col>
</template>
<script lang="ts">
import { SearchSuggestionGroup } from '@/src/types/vue-api';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AdSuggestDropdownColumn extends Vue {
  @Prop({ required: true, default: { data: [] } }) groups!: SearchSuggestionGroup[];
  @Prop({ required: true }) currentIndex!: number;

  onMouseEnter(index) {
    this.$emit('updateCurrentIndex', index);
  }
  onMouseLeave() {
    this.$emit('updateCurrentIndex', null);
  }
}
</script>
