<template>
  <div class="mb-5 mb-xl-0 mx-n2 mx-md-0">
    <b-row v-if="selectedPeriod" class="no-gutters" :style="chartPeriodsMarginStyle"
      ><b-col
        ><ad-chart-periods :options="periods" v-model="selectedPeriodKey" valueField="key" textField="translation">
          <template #header><slot name="periods-header"></slot></template>
        </ad-chart-periods>
      </b-col>
    </b-row>
    <b-row class="no-gutters">
      <b-col>
        <ad-stock-chart
          :series="data"
          :labelDateFormatter="formatLabelDate"
          :labelValueFormatter="formatLabelValue"
          :tooltipDateFormatter="formatTooltipDate"
          :tooltipValueFormatter="formatTooltipValue"
          :pointInterval="selectedPeriod.pointInterval"
          :xAxisTickInterval="xAxisTickInterval"
          :xAxisLabelStep="xAxisStep"
          :xAxisLabelMargin="teaserLayout ? 20 : undefined"
          :xAxisMax="xAxisMax"
          :xAxisMin="xAxisMin"
          :marginRight="5"
          :navigator="showNavigator"
          :scrollbar="showScrollbar"
          :height="chartHeight"
          :breakThreshold="dayBreakThreshold"
          :repeatedBreaks="repeatedBreaks"
          :tickOffset="tickOffset"
          @marginChange="updateChartMargin"
          ref="chart"
        ></ad-stock-chart></b-col
    ></b-row>
    <b-row class="pt-1 no-gutters" :style="chartLegendMarginStyle">
      <b-col :xl="teaserLayout ? 12 : 8">
        <ad-chart-legend :color="underlyingColor">
          <template #header><slot name="legend-header"></slot></template>
          <template #first-header><slot name="legend-first-header"></slot></template>
          <template #first-values>
            <ad-underlying-data-value
              fieldKey="underlying.performance.dailyHigh"
              :underlyingItem="underlying"
            ></ad-underlying-data-value>
            <ad-underlying-data-value
              fieldKey="underlying.performance.dailyLow"
              :underlyingItem="underlying"
            ></ad-underlying-data-value>
          </template>
          <template #second-header><slot name="legend-second-header"></slot></template>
          <template #second-values>
            <ad-underlying-data-value
              fieldKey="underlying.performance.fiftyTwoWeeksHigh"
              v-if="!isUnderlying52WeeksHighLowHidden"
              :underlyingItem="underlying"
            ></ad-underlying-data-value>
            <ad-underlying-data-value
              fieldKey="underlying.performance.fiftyTwoWeeksLow"
              v-if="!isUnderlying52WeeksHighLowHidden"
              :underlyingItem="underlying"
            ></ad-underlying-data-value>
          </template>
        </ad-chart-legend>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pt-2 no-gutters" :style="chartButtonsMarginStyle" v-if="!hideSwitches">
        <ad-switch-button v-model="relative"><slot name="button-relative"></slot></ad-switch-button>
        <ad-switch-button v-model="realtime"><slot name="button-realtime"></slot></ad-switch-button>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Ref, InjectReactive, Watch } from 'vue-property-decorator';
import AdStockChart from '@components/molecules/charts/ad-stock-chart.vue';
import AdChartPeriods from '@components/molecules/charts/ad-chart-periods.vue';
import AdChartLegend from '@components/molecules/charts/ad-chart-legend.vue';
import { PriceModel, UnderlyingModel } from '@src/types/the-q-api';
import { DataSeries, LabelPosition } from '@src/types/vue-api';
import { Action } from 'vuex-class';
import { AdChartBase } from '@components/molecules/charts/ad-chart-base';
import { ChartColors, ChartSeries, InstrumentOriginTimeZone } from '@src/types/enumerations';
import { utcParse } from '@/src/utils/date-helper';
import { InstrumentDataFieldBase } from '@/src/types/episerver-api-instrument';
import { getFormatDataField } from '@/src/utils/value-formatter/format-helper';

@Component({
  components: {
    'ad-stock-chart': AdStockChart,
    'ad-chart-periods': AdChartPeriods,
    'ad-chart-legend': AdChartLegend,
  },
})
export default class AdUnderlyingChart extends AdChartBase {
  @Ref() readonly chart!: AdStockChart;
  @InjectReactive() underlying!: UnderlyingModel | null;
  @Action('subscribePushValue', { namespace: 'underlying' })
  subscribePushValue!: (fieldKey: string) => Promise<void>;

  priceFormat(): string {
    return this.settingsProperty.tooltipValueFormatFieldKey || 'underlying.last';
  }

  currencyCode(): string {
    return this.underlying?.currencyCode || '';
  }

  private get underlyingColor(): ChartColors {
    return ChartColors.underlying;
  }

  @Watch('underlying')
  protected async loadChartData() {
    if (!this.underlying) return;

    [this.data, this.initialValueForRelativeData] = await Promise.all([
      this.loadUnderlyingData(),
      this.loadInitialData(),
    ]);
  }

  async loadUnderlyingData(): Promise<DataSeries[]> {
    if (!this.underlying) {
      return [];
    }

    const action = 'GetUnderlying' + (this.relative ? 'Relative' : '');
    const data =
      (await this.requestChartData(
        action,
        this.underlying.origin.timeZone == InstrumentOriginTimeZone.AET
          ? this.underlying.nsins.wkn ?? this.underlying.nsins.isin
          : this.underlying.nsins.isin,
        this.underlying.origin.timeZone
      )) ?? {};

    return data[ChartSeries.price]
      ? [new DataSeries(ChartSeries.price, data[ChartSeries.price], ChartColors.underlying, LabelPosition.Left)]
      : [];
  }

  private async loadInitialData(): Promise<{ [key: string]: number }> {
    if (!this.relative || !this.underlying) {
      return {};
    }
    return this.requestInitialData(
      'GetUnderlyingInitialPrice',
      this.underlying.nsins.isin,
      this.underlying.origin.timeZone
    );
  }

  pushValueFieldKey = 'underlying.last';
  pushTimestampFieldKey = 'underlying.timeStamp';

  get pushValue(): PriceModel | null | undefined {
    return this.underlying?.last;
  }

  get pushTimestamp(): number | undefined {
    return this.underlying?.timeStamp ? utcParse(this.underlying.timeStamp) : undefined;
  }

  pushChart() {
    this.$log.debug('try push chart');
    if (
      //push the value if chart is already initialised, value has amount and timestamp
      this.realtime &&
      this.chart &&
      this.pushValue &&
      this.pushValue.amount != null &&
      //if there is a min-max limitation for the xAsis, timestamp has to fit into that range
      this.pushTimestamp &&
      (!this.xAxisMin || this.pushTimestamp >= this.xAxisMin) &&
      (!this.xAxisMax || this.pushTimestamp <= this.xAxisMax)
    ) {
      const updateThreshold = this.selectedPeriod?.pointInterval
        ? this.selectedPeriod?.pointInterval * 1000
        : undefined;
      const pushValue = this.getChartPushValue(ChartSeries.price, this.pushValue.amount);
      if (pushValue) {
        this.chart.pushValue(0, this.pushTimestamp, pushValue, updateThreshold, false);
      }
    }
  }

  created() {
    super.created();
    if ((getFormatDataField(this.pushValueFieldKey) as InstrumentDataFieldBase)?.push) {
      this.subscribePushValue(this.pushValueFieldKey);
      this.subscribePushValue(this.pushTimestampFieldKey);
      this.$watch('pushValue', this.pushChart);
    }
  }
}
</script>
