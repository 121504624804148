import * as Highcharts from 'highcharts';
import { Vue, Inject, Watch, Prop } from 'vue-property-decorator';
import { utcToday, utcDate, utcTimestamp, millisecondsInDay, secondsInDay } from '@src/utils/date-helper';
import { ChartPeriod, ChartSettings, ProductChartSettings } from '@/src/types/episerver-api';
import { ChartPoint } from '@/src/types/the-q-api';
import { ChartSeries, Controller, DefaultFormat, InstrumentOriginTimeZone } from '@src/types/enumerations';
import { getTheQApiUrl } from '@src/utils/url-helper';
import httpClient from '@/src/utils/http-service';
import { formatField } from '@/src/utils/value-formatter/formatting-service';
import { ChartsApiFilterItem, DataSeries, LabelPosition, ChartConfiguration } from '@/src/types/vue-api';

export abstract class AdChartBase extends Vue {
  abstract priceFormat(axis: LabelPosition): string;
  abstract currencyCode(axis: LabelPosition): string;
  protected abstract loadChartData(): void;

  @Prop({ default: false }) teaserLayout!: boolean;
  @Prop({ default: false }) singlePeriod!: boolean;
  @Prop({ default: false }) hideSwitches!: boolean;
  @Prop({ default: true }) allowNavigator!: boolean;
  @Prop() maxHeightLg: number | undefined;
  @Prop() maxHeightMd: number | undefined;
  @Prop() maxHeight: number | undefined;
  @Inject() settingsProperty!: ChartSettings | ProductChartSettings;
  @Inject({ default: new ChartConfiguration() }) chartConfigurationProperty!: ChartConfiguration;
  protected selectedPeriodKey: string | null = null;
  protected relative = false;
  protected realtime = false;
  protected initiated = false;
  protected initialValueForRelativeData: { [key: string]: number } = {};

  created() {
    this.selectedPeriodKey = this.settingsProperty?.periods.length > 0 ? this.settingsProperty.periods[0].key : null;
    this.relative = this.settingsProperty.relativeButtonState;
    this.realtime = this.settingsProperty.realtimeButtonState;
  }

  protected get selectedPeriod(): ChartPeriod | undefined {
    return this.settingsProperty.periods.find((p) => p.key == this.selectedPeriodKey);
  }
  protected get isUnderlying52WeeksHighLowHidden() {
    return this.settingsProperty.isUnderlying52WeeksHighLowHidden;
  }
  get isIntraday() {
    return this.selectedPeriodKey === 'Intraday';
  }

  get showNavigator() {
    return (this.allowNavigator && this.selectedPeriod?.showNavigator) || false;
  }
  get showScrollbar() {
    return false;
  }
  get xAxisStep(): number | undefined {
    return this.selectedPeriod?.step || undefined;
  }

  protected get xAxisTickInterval(): number | null {
    return this.selectedPeriod?.axisTickInterval || null;
  }

  protected get xAxisMin(): number | undefined {
    if (this.selectedPeriod?.xAxisMinDate) {
      return utcTimestamp(Date.parse(this.selectedPeriod?.xAxisMinDate));
    } else if (this.settingsProperty.tradingTimeStart && this.isIntraday) {
      return utcToday() + this.settingsProperty.tradingTimeStart * 1000;
    } else {
      return undefined;
    }
  }
  protected get xAxisMax(): number | undefined {
    return this.settingsProperty.tradingTimeEnd ? utcToday() + this.settingsProperty.tradingTimeEnd * 1000 : undefined;
  }

  //cut periods with no data for more than a day
  protected get dayBreakThreshold(): number | undefined {
    return this.selectedPeriod?.pointInterval && this.selectedPeriod?.pointInterval < secondsInDay
      ? millisecondsInDay
      : undefined;
  }

  protected get tickOffset(): number | undefined {
    return !this.isIntraday && this.settingsProperty.tradingTimeStart
      ? this.settingsProperty.tradingTimeStart * 1000
      : undefined;
  }

  //creates a repeated break from trading end to trading start
  //if it is not intraday chart and resolution is less than a day
  protected get repeatedBreaks(): Highcharts.XAxisBreaksOptions[] {
    if (
      this.isIntraday ||
      !this.xAxisMin ||
      !this.settingsProperty.tradingTimeEnd ||
      !this.settingsProperty.tradingTimeStart
    ) {
      return [];
    } else {
      // from defines the trading time end (day before)
      // to defines the trading time start (today)
      // For more info view: https://api.highcharts.com/highstock/xAxis.breaks
      const from = new Date(Date.UTC(1971, 1, 1, 0, 0, 0)).getTime() + this.settingsProperty.tradingTimeEnd * 1000;
      const to = new Date(Date.UTC(1971, 1, 2, 0, 0, 0)).getTime() + this.settingsProperty.tradingTimeStart * 1000;
      return [
        {
          from: from,
          to: to,
          repeat: millisecondsInDay,
        },
      ];
    }
  }

  protected formatTooltipDate(chart: AdChartBase, value: number): string {
    const format = chart.selectedPeriod?.tooltipDateFormatFieldKey || DefaultFormat.DateTime;
    return formatField(utcDate(value), format);
  }

  protected formatTooltipValue(
    chart: AdChartBase,
    point: Highcharts.TooltipFormatterContextObject,
    axis: LabelPosition
  ): string {
    if (chart.relative) {
      return formatField(
        point.y ?? null,
        chart.settingsProperty.tooltipRelativeValueFormatFieldKey || DefaultFormat.Percent
      );
    } else {
      return formatField(
        { amount: point.y ?? null, currencyCode: chart.currencyCode(axis) || '' },
        chart.priceFormat(axis)
      );
    }
  }

  protected formatLabelDate(chart: AdChartBase, value: number): string {
    const format = chart.selectedPeriod?.xAxisLabelFormatFieldKey || DefaultFormat.Date;
    return formatField(utcDate(value), format);
  }

  protected formatLabelValue(chart: AdChartBase, value: number): string {
    if (chart.relative) {
      return formatField(value, chart.settingsProperty.yAxisRelativeLabelFormatFieldKey || DefaultFormat.Percent);
    } else {
      return formatField(value, chart.settingsProperty.yAxisLabelFormatFieldKey || DefaultFormat.Decimal);
    }
  }

  get chartHeight(): number {
    let height: number;
    if (this.$screen.lg) height = this.maxHeightLg || 460;
    else if (this.$screen.md) height = this.maxHeightMd || 300;
    else height = this.maxHeight || 245;
    return height;
  }

  chartMarginLeft = 0;
  chartMarginRight = 0;
  updateChartMargin(chart: AdChartBase, side: LabelPosition, value: number) {
    if (value != 0) {
      const avgDigitWidth = chart.$screen.sm && !chart.teaserLayout ? 11 : 9;
      value = value * avgDigitWidth + 10;
    }
    switch (side) {
      case LabelPosition.Left:
        chart.chartMarginLeft = value;
        break;
      case LabelPosition.Right:
        chart.chartMarginRight = value;
        break;
    }
  }

  get periods(): ChartPeriod[] {
    return this.isInSinglePeriodMode ? [this.settingsProperty.periods[0]] : this.settingsProperty.periods;
  }

  get isInSinglePeriodMode() {
    return (
      this.singlePeriod &&
      this.settingsProperty?.periods?.length > 0 &&
      !this.chartConfigurationProperty.forceDropDownForChartPeriods &&
      !this.chartConfigurationProperty.forceRadioButtonsForChartPeriods
    );
  }

  get chartPeriodsMarginStyle(): object | undefined {
    if (this.isInSinglePeriodMode)
      return { marginLeft: this.chartMarginLeft + 'px', marginRight: this.chartMarginRight + 'px' };
  }
  get chartLegendMarginStyle(): object | undefined {
    if (this.$screen.sm) return { marginLeft: this.chartMarginLeft + 'px' };
    else return { marginLeft: 24 + 'px' };
  }
  get chartButtonsMarginStyle(): object | undefined {
    if (this.$screen.sm) return { marginLeft: this.chartMarginLeft + 'px', marginRight: this.chartMarginRight + 'px' };
  }

  get chartLegendFont() {
    return !this.$screen.md && this.teaserLayout ? 'fs-12' : 'fs-14';
  }

  data: DataSeries[] = [];

  @Watch('selectedPeriodKey')
  @Watch('relative')
  protected updateData(): void {
    this.loadChartData();
  }

  protected async requestChartData(
    action: string,
    symbol: string | undefined,
    timezone: InstrumentOriginTimeZone,
    series: ChartSeries[] = []
  ): Promise<{ [key: string]: ChartPoint[] } | null> {
    if (!symbol || !this.selectedPeriod?.key) return null;
    try {
      const response = await httpClient.get<{ [key: string]: ChartPoint[] }>(getTheQApiUrl(Controller.Charts, action), {
        params: new ChartsApiFilterItem(
          this.selectedPeriod.key,
          series,
          symbol,
          timezone,
          this.selectedPeriod.pointInterval,
          this.settingsProperty.tradingTimeStart,
          this.settingsProperty.tradingTimeEnd
        ),
      });
      return response.data;
    } catch (ex) {
      this.$log.error(ex);
      return null;
    }
  }

  protected async requestInitialData(
    action: string,
    symbol: string | undefined,
    timezone: InstrumentOriginTimeZone,
    series: ChartSeries[] = []
  ): Promise<{ [key: string]: number }> {
    if (!symbol || !this.selectedPeriod?.key) {
      return {};
    }
    try {
      const response = await httpClient.get<{ [key: string]: number }>(getTheQApiUrl(Controller.Charts, action), {
        params: new ChartsApiFilterItem(
          this.selectedPeriod.key,
          series,
          symbol,
          timezone,
          this.selectedPeriod.pointInterval,
          this.settingsProperty.tradingTimeStart,
          this.settingsProperty.tradingTimeEnd
        ),
      });
      return response.data;
    } catch (ex) {
      this.$log.error(ex);
      return {};
    }
  }

  private trySetInitialValue(chartSerie: ChartSeries, value: number) {
    if (this.relative && !(chartSerie in this.initialValueForRelativeData) && value > 0) {
      this.initialValueForRelativeData[chartSerie] = value;
    }
  }

  protected getChartPushValue(chartSerie: ChartSeries, pushValue: number | null): number | null {
    if (!this.relative) {
      return pushValue;
    }
    if (pushValue === null) {
      return null;
    }

    this.trySetInitialValue(chartSerie, pushValue);

    return this.initialValueForRelativeData[chartSerie]
      ? pushValue / this.initialValueForRelativeData[chartSerie]
      : null;
  }
}
