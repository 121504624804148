<template>
  <div v-if="isVisible" class="pb-6 px-0 pt-2 pl-md-2 pr-md-1 pt-md-0 px-lg-5 pt-lg-1">
    <b-row>
      <b-col>
        <ad-product-header :productModel="product" :small-header="true" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ad-product-column-list>
          <template #isin-label>ISIN</template>
          <template #nsin-label><slot name="nsin-label"></slot></template>
          <template #nsin-field><slot name="nsin-field"></slot></template>
        </ad-product-column-list>
      </b-col>
    </b-row>
    <b-row class="no-gutters">
      <b-col class="pr-xl-5" xl="6" cols="12">
        <b-row>
          <b-col><slot name="product-price-data"></slot></b-col>
        </b-row>
        <b-row align-h="end" :class="['py-3', 'py-xl-0']">
          <b-col cols="12" xl="4"><slot name="underlying-price-data"></slot></b-col>
        </b-row>
      </b-col>
      <b-col xl="6" cols="12" :class="['pt-5', 'pt-xl-0', { hr: !$screen.xl }]">
        <slot name="chart"></slot>
      </b-col>
    </b-row>
    <b-row :class="['mt-0', 'mt-xl-n6']">
      <b-col>
        <ad-product-link :product-item="product" class="w-sm-100">
          <slot name="teaser-view-details-text">View details</slot>
        </ad-product-link>
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import { ProductModel } from '@/src/types/the-q-api';
import { ProductDetailBlockSetting } from '@/src/types/episerver-api';
import { Component, Inject, InjectReactive, Vue } from 'vue-property-decorator';
import AdProductHeader from '@/src/components/organisms/ad-product-details/ad-product-header.vue';

@Component({
  components: {
    AdProductHeader,
  },
})
export default class AdProductDetailBlockTeaser extends Vue {
  @Inject()
  settingsProperty?: ProductDetailBlockSetting;
  @InjectReactive()
  product!: ProductModel | null;

  get isVisible() {
    return this.product && this.settingsProperty?.productType == this.product.productType;
  }
}
</script>
