<template>
  <!-- First tile -->
  <div class="row mb-0 mb-md-5" v-if="index === 0">
    <div class="col-12 col-md-7 mb-3 mb-md-0">
      <a :href="settingsProperty.url" v-if="$slots['picture']" class="w-100 d-block aspect-ratio-16-9">
        <slot name="picture"></slot>
      </a>
      <a :href="settingsProperty.url" v-if="$slots['video']" class="w-100">
        <slot name="video"></slot>
      </a>
    </div>
    <div class="d-flex flex-column justify-content-md-center col-12 col-md-4 mb-5 mb-md-0"
      ><div class="w-100 ce-news-teaser-markets__small-header"><slot name="page-type"></slot></div>
      <div class="w-100">
        <h3>
          <a :href="settingsProperty.url"><slot name="headline"></slot></a>
        </h3>
      </div>
      <div class="w-100 mb-3">
        <slot name="preview-text"></slot>
      </div>
      <div class="w-100 ce-news-teaser-markets__date">
        {{ settingsProperty.publishedDate | formatField(dateFormatKey) }}
        <ad-like-count :content-id="settingsProperty.contentId" />
      </div>
    </div>
  </div>
  <!-- Second tile -->
  <div class="d-flex col-12 col-md-6 flex-column-reverse flex-md-column" v-else-if="index === 1">
    <div class="row p-0 d-flex justify-content-md-center mt-3 mt-md-0">
      <div class="d-flex col flex-column justify-content-md-center col-12 col-md-8"
        ><div class="w-100 ce-news-teaser-markets__small-header"><slot name="page-type"></slot></div>
        <div class="w-100">
          <h3>
            <a :href="settingsProperty.url"><slot name="headline"></slot></a>
          </h3>
        </div>
        <div class="w-100 ce-news-teaser-markets__date">
          {{ settingsProperty.publishedDate | formatField(dateFormatKey) }}
          <ad-like-count :content-id="settingsProperty.contentId" />
        </div>
      </div>
    </div>
    <div class="col-12 p-0 mt-0 mt-md-5">
      <a :href="settingsProperty.url" v-if="$slots['picture']" class="w-100 d-block aspect-ratio-16-9">
        <slot name="picture"></slot>
      </a>
      <a :href="settingsProperty.url" v-if="$slots['video']" class="w-100">
        <slot name="video"></slot>
      </a>
    </div>
  </div>
  <!-- Third tile -->
  <div class="col-12 col-md-5 mt-5 mt-md-0 d-flex flex-column justify-content-start" v-else-if="index === 2">
    <div class="row">
      <div class="col-12 mb-0 mb-md-0">
        <a :href="settingsProperty.url" v-if="$slots['picture']" class="w-100 d-block aspect-ratio-16-9">
          <slot name="picture"></slot>
        </a>
        <a :href="settingsProperty.url" v-if="$slots['video']" class="w-100">
          <slot name="video"></slot>
        </a>
      </div>
      <div class="d-flex flex-column col-12 col-md-8 mt-3 mt-md-5"
        ><div class="w-100 ce-news-teaser-markets__small-header"><slot name="page-type"></slot></div>
        <div class="w-100">
          <h3>
            <a :href="settingsProperty.url"><slot name="headline"></slot></a>
          </h3>
        </div>
        <div class="w-100 ce-news-teaser-markets__date">
          {{ settingsProperty.publishedDate | formatField(dateFormatKey) }}
          <ad-like-count :content-id="settingsProperty.contentId" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Inject, Vue } from 'vue-property-decorator';
import { BlogPageSettings } from '@/src/types/episerver-api';
import { DefaultFormat } from '@/src/types/enumerations';

@Component
export default class CeAdNewsTeaserMarketsItem extends Vue {
  @Inject() index!: number;
  @Inject() settingsProperty!: BlogPageSettings;

  get dateFormatKey(): string {
    return DefaultFormat.BlogDate;
  }
}
</script>
