import Vue from 'vue';
import { NAVBAR_HEIGHT } from '@src/utils/constants';

export function scrollToElement($element: HTMLElement | null, withScroll?: boolean, extraHeight?: number): void {
  if (!$element) return;

  // Vue.nextTick to allow application render, to increase chance the scrollTo works
  Vue.nextTick(() => {
    const $navbar = document.querySelector('.ce-header__navbar-menu');

    // top of element + current scroll offset - navbarheight + offset so spyscroll shows correct entry
    const y =
      $element.getBoundingClientRect().top +
      window.scrollY -
      ($navbar?.clientHeight ?? NAVBAR_HEIGHT) +
      (extraHeight ?? 5);

    window.scrollTo({
      top: y,
      behavior: withScroll ? 'smooth' : 'auto',
    });
  });
}

export function jumpToAnchor(): void {
  const anchor = location.hash.substring(1);
  if (anchor) {
    const $element = document.getElementById(anchor);
    scrollToElement($element, false);
  }
}
