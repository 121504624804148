import { enUS, enGB, enAU, nl, de, fr, fi, it, es, pt, sv, zhCN } from 'date-fns/locale';

export const NAVBAR_HEIGHT = 52;

export const CULTURE_TO_LOCALE_MAP = new Map()
  .set('de-DE', de)
  .set('en-NL', enUS)
  .set('nl-NL', nl)
  .set('en-DE', enUS)
  .set('fr-FR', fr)
  .set('pt-PT', pt)
  .set('fi-FI', fi)
  .set('en-FI', enUS)
  .set('sv-SE', sv)
  .set('en-SE', enUS)
  .set('en-HK', enUS)
  .set('zh-HK', zhCN)
  .set('en-AU', enAU)
  .set('en-GB', enGB)
  .set('it-IT', it)
  .set('en-ch', enUS)
  .set('en-US', enUS)
  .set('es-US', es);
