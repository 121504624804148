import { formatValue } from '@/src/utils/value-formatter/formatting-service';
import numeral from 'numeral';

export class TextToNumericConvertHelper {
  private displayFormat: string;
  private percentFormatPlaceholder = '%';

  constructor(dispformat: string) {
    this.displayFormat = dispformat;
  }
  public toText(val: number | null | undefined): string {
    return val === null || val === undefined ? '' : formatValue(val, this.displayFormat, null);
  }

  /**
   * Convert a formated value into it's respresented number
   * @param stringVal local format value to convert into number, like 10%, 0,5, 1.000,30
   * @returns Number of formated string
   */
  public toNumber(stringVal: string | null | undefined): number {
    if (stringVal === null || stringVal === undefined) {
      return NaN;
    }

    stringVal = stringVal.trim();

    if (
      this.displayFormat.indexOf(this.percentFormatPlaceholder) !== -1 &&
      stringVal.indexOf(this.percentFormatPlaceholder) === -1
    ) {
      stringVal += this.percentFormatPlaceholder; // fix to format percent value correct
    }

    const numeralVal = numeral(stringVal);

    return numeralVal === null ? NaN : numeralVal.value();
  }
  public toPrecisionNumber(val: number | null): number {
    return this.toNumber(this.toText(val));
  }
}
