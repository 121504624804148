<script lang="ts">
import { VNode } from 'vue';
import { Component, Provide, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AdJsonSettingsProperty extends Vue {
  @Prop() private settingsJson;
  @Prop() private settings;

  /**
   * since whitespaces are encoded as '+', they are replaced here with ' ',
   * reason: decodeURIComponent doesn't decode '+' to whitespace
   */
  changePlusToWhitespace(encodedStr: string): string {
    const plusRegex = new RegExp(/\+/, 'g');
    return encodedStr.replace(plusRegex, ' ');
  }

  @Provide()
  get settingsProperty(): unknown {
    if (this.settings) {
      return this.settings;
    }

    try {
      if (this.settingsJson) {
        const encodedStr = this.changePlusToWhitespace(this.settingsJson);
        const json = decodeURIComponent(encodedStr);
        return JSON.parse(json);
      }
    } catch (error) {
      this.$log.error(error);
    }

    return null;
  }

  render(): VNode[] | VNode | undefined {
    return this.$scopedSlots.default?.({});
  }
}
</script>
