<template>
  <ad-product-teaser-property :teaserSettingsProperty="teaserSettings" v-if="loading || bestMatchSearchResultModel">
    <div class="best-match-search-table">
      <ad-instrument-table
        :sort-orders="sortOrders"
        v-bind="$attrs"
        :instruments="products"
        :settings="settingsProperty"
        :loading="loading"
        class="mb-4"
        ref="table"
        selectable
        :no-select-on-click="true"
        select-mode="multi"
        selected-variant="primary"
      >
        <template #top-row="{ fieldCount }">
          <b-td :colspan="fieldCount || 1"
            ><b-button class="my-3" @click="showMoreAbove" :disabled="isButtonTopDisabled"
              ><slot name="table-show-more-item-text">[show 10 more table placeholder]</slot></b-button
            ></b-td
          >
        </template>
        <template #row-details="{ data }">
          <ad-product-data-property :product="data.item.instrument.product">
            <!-- wrap in div to prevent multiple root nodes returned from render function error  -->
            <div class="wrap">
              <slot name="product-detail-page-teaser" :data="data">{{ data.item.instrument }}</slot>
            </div>
          </ad-product-data-property>
        </template>
        <template #row-instrument="{ setting, instrument }">
          <div class="best-match-badge" v-if="setting.key === 'bestMatch'">
            <b-badge variant="light" v-if="instrument.isBestMatch">{{ setting.tanslation }}</b-badge>
          </div>
          <ad-product-data-value
            v-else
            :is-link-to-detail-page="setting.isLinkToDetailPage"
            :product-item="instrument.product"
            :field-key="setting.formatId"
          />
        </template>
        <template #row-watch-list="{ instrument }">
          <ad-product-watchlist-toggle :product-item="instrument.product" />
        </template>
      </ad-instrument-table>
      <div class="text-center">
        <b-button @click="showMoreBelow" :disabled="isButtonBottomDisabled"
          ><slot name="table-show-more-item-text">[show 10 more table placeholder]</slot></b-button
        >
      </div>
    </div>
  </ad-product-teaser-property>
</template>

<script lang="ts">
import { PageRoutes, BestMatchSearchBlockSetting } from '@t/episerver-api';
import { BestMatchSearchResultModel, SortOrder } from '@t/the-q-api';
import { Component, Inject, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { scrollToElement } from '@src/utils/scroll-helpers';

import { BestMatchSearchRequestFilter, ProductTeaserSettings } from '@t/vue-api';
import { BRow, BTable } from 'bootstrap-vue';
import { getProductTeaserSettings } from '@src/utils/table-columns-helper';

@Component({
  inheritAttrs: false,
})
export default class AdBestMatchSearchTable extends Vue {
  @Inject() settingsProperty!: BestMatchSearchBlockSetting;
  @Inject() pageRoutes!: PageRoutes;
  @Prop({ required: true }) filter!: BestMatchSearchRequestFilter;
  @Prop({ required: true }) bestMatchSearchResultModel!: BestMatchSearchResultModel;
  @Prop({ required: true }) sortOrders!: Array<SortOrder>;
  @Prop({ required: true }) refreshTrigger!: number;
  @Prop({ required: true }) loading!: boolean;

  private currentOpenItem;
  private aboveItemsCount = 0;
  private belowItemsCount = 0;

  @Ref() table;

  created() {
    this.resetItemCount();
  }

  get products() {
    if (this.bestMatchSearchResultModel) {
      const aboveItems = this.bestMatchSearchResultModel.aboveMatches.slice(
        this.bestMatchSearchResultModel.aboveMatches.length - this.aboveItemsCount,
        this.bestMatchSearchResultModel.aboveMatches.length
      );
      const belowItems = this.bestMatchSearchResultModel.belowMatches.slice(0, this.belowItemsCount);
      return [...aboveItems, ...this.bestMatchSearchResultModel.bestMatches, ...belowItems];
    }

    return null;
  }

  get isButtonTopDisabled() {
    return this.aboveItemsCount >= this.bestMatchSearchResultModel?.aboveMatches.length;
  }

  get isButtonBottomDisabled() {
    return this.belowItemsCount >= this.bestMatchSearchResultModel?.belowMatches.length;
  }

  get teaserSettings(): ProductTeaserSettings {
    return getProductTeaserSettings(this.settingsProperty);
  }

  @Watch('refreshTrigger')
  refresh() {
    this.resetItemCount();
    this.$nextTick(() => this.highlightBestMatches());
    this.scrollBestMatchIntoView();
  }

  showMoreBelow() {
    this.belowItemsCount += this.settingsProperty.showMoreItem;
    this.$nextTick(this.highlightBestMatches); // workaround to select items
  }

  showMoreAbove() {
    this.increaseAboveItemCount();
    this.$nextTick(this.highlightBestMatches); // workaround to select items
  }

  highlightBestMatches() {
    for (let i = 0; i < this.bestMatchSearchResultModel?.bestMatches.length; i++) {
      (this.table.$refs.table as BTable).selectRow(this.aboveItemsCount + i);
    }
  }

  resetItemCount() {
    this.aboveItemsCount = 0;
    this.increaseAboveItemCount();
    this.belowItemsCount = this.settingsProperty.showMoreItem;
  }

  increaseAboveItemCount() {
    this.aboveItemsCount += this.settingsProperty.showMoreItem;

    if (this.aboveItemsCount > this.bestMatchSearchResultModel?.aboveMatches.length)
      this.aboveItemsCount = this.bestMatchSearchResultModel.aboveMatches.length;
  }

  toggleDetails(e: Event, row: BRow) {
    row.toggleDetails(e);

    if (this.currentOpenItem && this.currentOpenItem !== row.item) {
      this.currentOpenItem._showDetails = false;
    }
    this.currentOpenItem = row.item;
  }

  scrollBestMatchIntoView(): void {
    const $bestMatchTableRef = this.table.$refs.table;
    if (!$bestMatchTableRef) return;

    const $firstHighlightedRow = $bestMatchTableRef.$el as HTMLElement;
    scrollToElement($firstHighlightedRow);
  }
}
</script>

<style lang="scss">
.best-match-search-table {
  .table {
    .b-table-top-row {
      text-align: center;
    }
    /* stylelint-disable-next-line */
    th {
      border-bottom: 1px solid $cool-grey;
    }

    tr:nth-of-type(2n + 1) {
      background: transparent;
    }

    tr:nth-of-type(2n + 2) {
      background: $table-accent-bg;
    }

    .td-col-detail {
      background-color: #d8e1e9;
    }

    /* stylelint-disable-next-line */
    tr:nth-of-type(2n + 1) .td-col-detail {
      background-color: #e4ebf1;
    }

    @include media-breakpoint-down('md') {
      .best-match-badge {
        display: inline-block;
        margin-top: rem(15);
      }
    }
  }
}
</style>
