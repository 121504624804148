<template>
  <div class="content-container container-fluid mb-7 mb-md-8" v-if="isVisible">
    <h1 class="mb-md-3 mb-2">{{ headline }}</h1>
    <h2><span v-html="errorMessage"></span></h2>
  </div>
  <div v-else><slot></slot></div>
</template>
<script lang="ts">
import { HttpStatusCodes } from '@/src/types/enumerations';
import { CustomErrorMessage } from '@/src/types/episerver-api';
import { mapErrorCodes } from '@/src/utils/enum-value-helper';
import { Inject, Component, Prop, Watch } from 'vue-property-decorator';
import { Vue } from 'vue-property-decorator';

@Component({})
export default class AdError extends Vue {
  @Inject({ default: [] }) errorMessages!: CustomErrorMessage[];
  @Prop({ default: HttpStatusCodes.OK }) httpStatusCode!: string | HttpStatusCodes;

  isVisible = false;
  headline = '';
  errorMessage = '';

  get errorCode(): HttpStatusCodes {
    if (typeof this.httpStatusCode === 'string') {
      return mapErrorCodes(Number.parseInt(this.httpStatusCode));
    }
    return this.httpStatusCode;
  }

  @Watch('httpStatusCode', { immediate: true })
  throwError(): void {
    this.isVisible = false;

    if (this.errorCode != HttpStatusCodes.OK) {
      this.populateError();
      this.isVisible = true;
    }
  }

  populateError(): void {
    const currentError = this.errorMessages.find((e) => e.statusCodes?.indexOf(this.errorCode) != -1);

    if (currentError) {
      this.headline = currentError.headline ? currentError.headline : '';
      this.errorMessage = currentError.errorMessage ? currentError.errorMessage : '';
      return;
    }

    const defaultError = this.errorMessages.find((e) => e.isDefault);

    if (defaultError) {
      this.headline = defaultError.headline ? defaultError.headline : '';
      this.errorMessage = defaultError.errorMessage ? defaultError.errorMessage : '';
    }
  }
}
</script>
