<template>
  <b-form id="general-search" novalidate>
    <div class="row">
      <slot name="filters" :filter-state="filterState" :set-filter="setFilter">Filter Placeholder</slot>
    </div>
    <div class="row">
      <slot name="content" :filter-state="filterState">Data Placeholder</slot>
    </div>
  </b-form>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class AdGeneralSearch extends Vue {
  filterState: { [key: string]: string | number | null } = {};
  setFilter(key: string, value: string | null) {
    this.$set(this.filterState, key, value);
  }
}
</script>
