<template>
  <b-button
    v-if="isVisible"
    class="watchlist p-2 border-0 miw-auto rounded-circle d-inline-block"
    :variant="isProductInWatchlist && cookieComfortAccepted ? 'watchlist-remove' : 'watchlist-add'"
    @click="toggleItem"
  >
    <ad-icon-watchlist-remove v-if="isProductInWatchlist" :height="iconSize" :width="iconSize" />
    <ad-icon-watchlist-add v-else :height="iconSize" :width="iconSize" />
  </b-button>
</template>

<script lang="ts">
import { InjectReactive, Component, Vue, Inject, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ProductModel, PriceModel } from '@/src/types/the-q-api';
import { WatchedProduct } from '@/src/types/vue-api';
import { PageRoutes } from '@/src/types/episerver-api';
import CookieManager from '@/src/utils/cookie';
import { MissingCookieModalId } from '@/src/types/enumerations';

@Component({})
export default class AdProductWatchlistToggle extends Vue {
  @Getter('watchedProducts', { namespace: 'watchlist' })
  watchedProducts!: Array<WatchedProduct>;

  @Action('addProduct', { namespace: 'watchlist' })
  addProduct!: (product: { code: string; price: PriceModel }) => Promise<void>;

  @Action('removeProduct', { namespace: 'watchlist' })
  removeProduct!: (code: string) => Promise<void>;

  @Inject() pageRoutes!: PageRoutes;

  /**
   * Product can be obtained with three different approaches in this component.
   * 1. Pass "product" as Prop
   * 2. Inject "product" from parent
   */

  // 1. Pass as Prop
  @Prop() productItem!: ProductModel | null;

  // 2. Inject from Parent
  @InjectReactive({ from: 'product', default: null }) productInjected!: ProductModel | null;

  private cookieManager!: CookieManager;
  private iconSize = '2.5em';

  get currentProduct(): ProductModel | null {
    return this.productItem || this.productInjected;
  }

  get isVisible() {
    return this.currentProduct?.securityCode && this.pageRoutes.watchlistUrl;
  }

  get isProductInWatchlist(): boolean {
    if (this.currentProduct === null) return false;
    return this.watchedProducts.find((p) => p.securityCode === this.currentProduct?.securityCode) !== undefined;
  }

  get cookieComfortAccepted(): boolean {
    return this.cookieManager?.comfort || false;
  }

  async created(): Promise<void> {
    this.cookieManager = new CookieManager(this.$cookies);
  }

  toggleItem() {
    if (this.cookieComfortAccepted) {
      if (this.currentProduct && this.currentProduct.securityCode) {
        if (this.isProductInWatchlist) this.removeProduct(this.currentProduct.securityCode);
        else this.addProduct({ code: this.currentProduct.securityCode, price: this.currentProduct.price.bid });
      }
    } else {
      this.$bvModal.show(MissingCookieModalId.MissingWatchlistCookieModal);
    }
  }
}
</script>
