<template>
  <footer class="ce-footer">
    <div class="back-to-top">
      <ce-back-to-top-button />
    </div>
    <div class="d-flex flex-lg-row flex-column align-items-lg-center">
      <div class="ce-footer__logo">
        <a :href="logoLinkHref" :title="logoLinkTitle"
          ><img class="logo" src="~@assets/citi_logo_footer.svg" alt="Citi Logo" width="90"
        /></a>
      </div>
      <b-navbar type="dark" class="flex-fill">
        <b-navbar-nav class="ce-footer__service border-bottom">
          <slot></slot>
        </b-navbar-nav>
        <b-navbar-nav class="ce-footer__legal">
          <slot name="legal"></slot>
          <b-nav-text class="ce-footer__copyright">© {{ ' ' + brandName }}</b-nav-text>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </footer>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CeFooter extends Vue {
  @Prop({ default: 'Citigroup Inc.' }) private brandName!: string;
  @Prop({ default: '/' }) private logoLinkHref!: string;
  @Prop({ default: 'Back to Start' }) private logoLinkTitle: string | undefined;
}
</script>

<style lang="scss">
$ce-footer-spacing-y-xs: calc(#{$grid-gutter-width * 2} - #{$nav-link-padding-y});
$ce-footer-spacing-y-lg: calc(#{$grid-gutter-width} - #{$nav-link-padding-y});

.ce-footer {
  background: $footer-color;
  line-height: 1.1112;

  .back-to-top {
    display: flex;
    position: absolute;
    top: -6.25rem;
    justify-content: center;
    z-index: 2;
    width: 100%;
    height: 0;
  }

  &__logo {
    padding: 0 0 0 $grid-gutter-width;

    @include media-breakpoint-down('md') {
      padding: $grid-gutter-width 0 0 0;
      text-align: center;
    }
  }

  .navbar {
    flex-flow: column;
    padding: 0 $grid-gutter-width;

    .navbar-nav {
      padding: $ce-footer-spacing-y-xs 0;
      width: 100%;

      @include media-breakpoint-down('md') {
        flex-direction: column;
      }

      @include media-breakpoint-up('lg') {
        padding: $ce-footer-spacing-y-lg 0;
      }
    }

    .nav-link {
      margin-right: $nav-link-padding-x * 2;
      padding: $nav-link-padding-y 0;
      &:hover,
      &:focus {
        text-decoration: underline;
        color: $white;
      }
    }

    @include media-breakpoint-up('lg') {
      padding: $grid-gutter-width;
    }
  }

  &__service {
    border-color: rgba($white, 0.4);
  }

  &__copyright {
    @include media-breakpoint-down('md') {
      margin-top: $ce-footer-spacing-y-xs;
    }

    @include media-breakpoint-up('lg') {
      flex-grow: 1;
      text-align: right;
    }
  }
}
</style>
