<template>
  <b-button class="w-100 mb-2 px-2 d-flex fs-16" variant="light" v-bind="$attrs" :disabled="disabled" @click="click">
    <span class="flex-grow-1 text-left text-nowrap">
      {{ type.displayName }}
    </span>
    <span>
      <ad-icon-chevron
        v-if="!disabled"
        class="display-inline-block"
        aria-hidden="true"
        size="s"
        direction="right"
      ></ad-icon-chevron>
    </span>
  </b-button>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { ProductTypeModel } from '@src/types/episerver-api';

@Component
export default class AdProductTypeButton extends Vue {
  @Prop()
  private type: ProductTypeModel | undefined;

  @Prop({ type: Boolean, default: true })
  private disabled;

  @Emit('click')
  private click(e) {
    return e;
  }
}
</script>
