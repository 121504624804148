<template>
  <li class="ce-sub-nav-item" v-if="isVisible()">
    <a v-bind:href="href" v-bind:title="title" @click="onClick">{{ text }}</a>
    <slot></slot>
  </li>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { OptionGroup, ProductTypeGroupOptionsFilter } from '@src/types/the-q-api';
import { Action, Getter } from 'vuex-class';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';

@Component
export default class CeSubNavItem extends Vue {
  @Prop() private href!: string;
  @Prop() private text!: string;
  @Prop() private title: string | undefined;
  @Prop({ default: false, type: Boolean }) public isProductItem!: boolean;

  /**
   * Set a product type here if this sub menu item should be displayed
   * only if there are active products for this product type.
   * Set <c>null</c> here if this sub menu item should always be displayed.
   */
  @Prop() private typeOfRequiredProducts: string | undefined;

  @Getter('productTypeGroupOptions', { namespace: 'product' })
  productTypeGroupOptions!: (filter: ProductTypeGroupOptionsFilter) => OptionGroup[];

  @Action('getProductTypeGroupOptionsAsync', { namespace: 'product' })
  getProductTypeGroupOptionsAsync!: (filter: ProductTypeGroupOptionsFilter) => Promise<void>;

  private filter = {} as ProductTypeGroupOptionsFilter;

  private async created() {
    this.filter = { onlyLeverage: false } as ProductTypeGroupOptionsFilter;
    try {
      await this.getProductTypeGroupOptionsAsync(this.filter);
    } catch (e) {
      this.$log.error('Error occured loading data in sub menu item', e);
    }
  }

  /**
   * Returns whether the sub menu item has to be displayed.
   */
  private isVisible(): boolean {
    if (!this.typeOfRequiredProducts) {
      // no type of required products specified -> show the sub menu item
      return true;
    }

    const groupOptions = this.productTypeGroupOptions(this.filter);

    if (!groupOptions || groupOptions.length == 0) {
      // no information about active products available -> show the sub menu item
      return true;
    }

    for (const group of groupOptions) {
      for (const option of group.options) {
        if (option.value == this.typeOfRequiredProducts && option.count && option.count > 0) {
          // active products of the specified type exist -> show the sub menu item
          return true;
        }
      }
    }

    //otherwise hide the sub menu item
    return false;
  }

  onClick(): void {
    this.trackNavItem();
  }

  public trackNavItem(): void {
    if (!this.isProductItem) {
      return;
    }
    trackEvent(GaEvent.SelectItem, {
      item_list_id: 'product_navigation',
      items: [{ item_id: this.text }],
    });
  }
}
</script>

<style lang="scss">
.ce-sub-nav-item {
  border-bottom: 1px solid $cool-grey;

  a {
    display: block;
    border-bottom: 4px solid transparent;
    padding: rem(18) calc($grid-gutter-width / 2) rem(14);
    text-decoration: none;
    line-height: 1;
    color: $carbon;
  }

  a:hover,
  a:focus {
    border-color: $header-highlight-color;
    padding-bottom: rem(14) !important;
    text-decoration: none;
    color: $header-highlight-color;
  }

  @include media-breakpoint-up('lg') {
    &:last-child {
      border-bottom: 0;
    }
    // stylelint-disable
    & a:focus + &-info,
    & a:hover + &-info {
      display: block;
    }
  }
}
</style>
