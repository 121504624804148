<template>
  <ad-skeleton-text :loading="currentUnderlying == null">
    <ad-underlying-link v-if="isLinkToDetailPage" :underlyingItem="currentUnderlying" variant="link">
      <ad-instrument-data-value
        v-bind="$attrs"
        :value="value"
        :fieldKey="fieldKey"
        :instrument="currentUnderlying"
        @subscribe-to-push="subscribePushValue"
      ></ad-instrument-data-value>
    </ad-underlying-link>
    <ad-instrument-data-value
      v-else
      v-bind="$attrs"
      :value="value"
      :fieldKey="fieldKey"
      :instrument="currentUnderlying"
      @subscribe-to-push="subscribePushValue"
    ></ad-instrument-data-value>
  </ad-skeleton-text>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Inject, InjectReactive } from 'vue-property-decorator';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { ApiTypeValue } from '@/src/types/vue-api';
import { PageRoutes } from '@/src/types/episerver-api';
import { Action, Getter } from 'vuex-class';
import evaluateExpressionByKey from '@/src/utils/value-formatter/format-helper';

@Component({
  inheritAttrs: false,
})
export default class AdUnderlyingDataValue extends Vue {
  @Prop({ required: true }) fieldKey!: string;
  @Prop({ default: false, type: Boolean }) isLinkToDetailPage!: boolean;

  /**
   * Underlyings can be obtained with three different approaches in this component.
   * 1. Bind "underlying" argument to an UnderlyingModel
   * 2. Inject "underlying" from parent
   * 3. Fallback to reading from state. When reading from state, make sure that
   *    `loadUnderlyingAysnc` (see state/modules/underlying.ts) was called before,
   *     otherwise it will not be available.
   */

  // 1. Pass as Prop
  @Prop() underlyingItem!: UnderlyingModel | null;

  // 2. Inject from Parent
  @InjectReactive({ from: 'underlying' }) underlyingInjected!: UnderlyingModel | null;

  // 3. Read from State
  @Getter('underlying', { namespace: 'underlying' })
  private underlying!: UnderlyingModel | null;

  //@Inject({ default: null }) underlying!: UnderlyingModel | null;
  @Inject({ default: null }) pageRoutes!: PageRoutes | null;

  @Action('subscribePushValue', { namespace: 'underlying' })
  subscribePushValue!: (fieldKey: string) => Promise<void>;

  get value(): ApiTypeValue {
    return evaluateExpressionByKey(this.fieldKey, {
      // setting underlying to get value from underlyingItem and set fieldkey, e.g. underlying.last
      underlying: this.currentUnderlying,
    });
  }

  get currentUnderlying(): UnderlyingModel | null {
    return this.underlyingItem || this.underlyingInjected || this.underlying;
  }

  public get hasValue(): boolean {
    return this.value !== null && this.value !== undefined;
  }

  @Watch('hasValue', { immediate: true })
  onValueChange(): void {
    this.$emit('has-value', this.hasValue);
  }
}
</script>
