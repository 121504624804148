<template>
  <button @click="scroll" class="ce-to-top-button btn btn-secondary rounded-circle px-0 py-0 border-0">
    <ad-icon-chevron size="l" direction="up"></ad-icon-chevron>
  </button>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CeBackToTopButton extends Vue {
  scroll(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
</script>

<style lang="scss">
/* four times to set specificity higher than ce-buttons */
.ce-to-top-button.ce-to-top-button.ce-to-top-button.ce-to-top-button {
  transform-origin: center;
  width: rem(64);
  min-width: rem(64);
  height: rem(64);
}
</style>
