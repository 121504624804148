<template>
  <div class="position-relative">
    <ce-ad-marker>Advertisement</ce-ad-marker>
    <a href="#" class="overflow-hidden d-block">
      <picture class="d-none d-md-block">
        <source media="(min-width:650px)" srcset="https://picsum.photos/seed/2/1300/300" />
        <source media="(min-width:465px)" srcset="https://picsum.photos/seed/2/1300/300" />
        <img src="https://picsum.photos/seed/2/1300/300" class="w-100" />
      </picture>

      <picture class="d-block d-md-none">
        <source media="(min-width:650px)" srcset="https://picsum.photos/seed/7/1600/700" />
        <source media="(min-width:465px)" srcset="https://picsum.photos/seed/7/1600/700" />
        <img src="https://picsum.photos/seed/7/1600/700" class="w-100" />
      </picture>
    </a>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CeBannerAd extends Vue {}
</script>
