import { VueCookies } from 'vue-cookies';
import { CookieCreateState, CookieType } from '@/src/types/enumerations';
import { getCurrentWebsiteKey } from './culture-helper';

export class CookieSettings {
  public version: string;
  public technical: boolean;
  public marketing: boolean;
  public comfort: boolean;
  public thirdPartyMedia: boolean;

  constructor(version: string, technical: boolean, marketing: boolean, comfort: boolean, third: boolean) {
    this.version = version;
    this.technical = technical;
    this.marketing = marketing;
    this.comfort = comfort;
    this.thirdPartyMedia = third;
  }
}

export class CookieWebSelection {
  public cookieName: string;
  public cookieValue: string | null;

  constructor(cookieName: string, cookieValue: string | null) {
    this.cookieName = cookieName;
    this.cookieValue = cookieValue;
  }
}

export default class CookieManager {
  private cookies: VueCookies;
  private consentSetting: { [key: string]: CookieType } | undefined;
  private consentCookieKey = 'cookie-consent_' + getCurrentWebsiteKey();
  private contentLikeCookieKey = 'content-likes';
  private adBlockerDetectionKey = 'adb-viewed';

  constructor(cookies: VueCookies) {
    this.consentSetting = this.parseSettings();
    this.cookies = cookies;
  }

  public get technical(): boolean {
    return !!this.getCurrentSettings();
  }
  public get marketing(): boolean {
    return this.getCurrentSettings()?.marketing || false;
  }
  public get comfort(): boolean {
    return this.getCurrentSettings()?.comfort || false;
  }
  public get thirdPartyMedia(): boolean {
    return this.getCurrentSettings()?.thirdPartyMedia || false;
  }

  public saveSettings(settings: CookieSettings, remember: boolean) {
    const expire = remember ? Infinity : 0;
    this.setCookieToRoot(this.consentCookieKey, JSON.stringify(settings), expire);
  }

  public saveSelectedWebsite(cookieWebSelection: CookieWebSelection, remember: boolean) {
    if (!cookieWebSelection.cookieValue) {
      this.removeRootCookie(cookieWebSelection.cookieName);
    }

    const expire = remember ? Infinity : 0;
    this.setCookieToRoot(cookieWebSelection.cookieName, cookieWebSelection.cookieValue, expire);
  }

  public wasLiked(contentId: number): boolean {
    const cookie: string = this.cookies.get(this.contentLikeCookieKey);
    return cookie ? cookie.indexOf(this.formatContentKey(contentId)) >= 0 : false;
  }

  public saveLike(contentId: number): CookieCreateState {
    if (!this.canBeCreated(this.contentLikeCookieKey)) return CookieCreateState.NotAccepted;
    if (this.wasLiked(contentId)) return CookieCreateState.Exists;
    const cookie: string = (this.cookies.get(this.contentLikeCookieKey) || '') + this.formatContentKey(contentId);
    this.setCookieToRoot(this.contentLikeCookieKey, cookie, Infinity);
    return CookieCreateState.Created;
  }

  public wasAdBlockerHintViewed(): boolean {
    const cookie: string = this.cookies.get(this.adBlockerDetectionKey);
    return cookie !== null;
  }

  public saveAdBlockerHintViewed(): CookieCreateState {
    //Even though this cookie is technical, it must be set without user consenting cookies. Because
    //adblocker might even block cookie consenting.
    if (this.wasAdBlockerHintViewed()) return CookieCreateState.Exists;
    const cookieValue = 'true';
    this.setCookieToRoot(this.adBlockerDetectionKey, cookieValue, Infinity);
    return CookieCreateState.Created;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setCookieToRoot(keyName: string, value: any, expireTimes?: string | number | Date) {
    this.cookies.set(keyName, value, expireTimes, undefined, undefined, true);
  }

  private removeRootCookie(keyName: string) {
    this.cookies.remove(keyName);
  }

  private getCurrentSettings(): CookieSettings | undefined {
    const saved = this.cookies.get(this.consentCookieKey);
    if (saved) {
      return new CookieSettings(saved.version, saved.technical, saved.marketing, saved.comfort, saved.thirdPartyMedia);
    }
  }

  canBeCreated(key: string): boolean {
    if (!this.consentSetting) return false;
    const type: CookieType | undefined = this.consentSetting[key];
    if (!type) return false;
    switch (type) {
      case CookieType.Technical:
        return this.technical;
      case CookieType.Comfort:
        return this.comfort;
      case CookieType.Marketing:
        return this.marketing;
      case CookieType.ThirdPartyMedia:
        return this.thirdPartyMedia;
      default:
        return false;
    }
  }

  private formatContentKey(contentId: number): string {
    return `[${contentId}]`;
  }

  private parseSettings(): { [key: string]: CookieType } | undefined {
    const json = document.getElementById('app')?.getAttribute('data-cookie-consent-settings');
    if (!json) return undefined;
    return JSON.parse(json);
  }
}
