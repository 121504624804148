<template>
  <div class="suggest-search">
    <vue-autosuggest
      ref="autocomplete"
      v-model="query"
      :inputProps="inputProps"
      @input="fetchSuggestions"
      @selected="selectSuggestion"
      @keypress.enter="rapidSearch"
      :sectionConfigs="suggestionsGroups"
      :suggestions="suggestions"
      :getSuggestionValue="getCurrentQuery"
      :componentAttrClassAutosuggestResults="suggestionsContainerClass"
    >
      <template #before-suggestions>
        <div class="pt-3 pt-md-0 pb-3 w-100">{{ query }}</div>
      </template>
      <template #after-suggestions>
        <hr class="mt-0" />
      </template>
    </vue-autosuggest>
    <b-popover
      v-if="isErrorMessagePopoverEnabled"
      :target="$refs.autocomplete"
      triggers="focus hover"
      :show.sync="showPopover"
      variant="danger"
      placement="bottom"
    >
      <div class="text-danger"><ad-icon-warnings class="mr-2 align-top" /><slot name="error-message"></slot></div>
    </b-popover>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Inject, Watch, Emit } from 'vue-property-decorator';
import { VueAutosuggest } from 'vue-autosuggest';
import { GlobalSearchBlockSettings, InstrumentFieldKeys, PageRoutes } from '@/src/types/episerver-api';
import { SearchSuggestion } from '@/src/types/vue-api';
//using pDebounce because lodash does not support async
import pDebounce from 'p-debounce';
import { AdGlobalSearchBase } from './ad-global-search-base';

@Component({
  components: {
    VueAutosuggest,
  },
})
export default class AdGlobalSearch extends AdGlobalSearchBase {
  @Inject() settingsProperty!: GlobalSearchBlockSettings;
  @Inject() pageRoutes!: PageRoutes;
  @Inject() nsinKeys!: InstrumentFieldKeys | null;
  @Prop({ type: Boolean, default: false }) modalView!: boolean;
  @Watch('isErrorMessagePopoverEnabled')
  errorMessageState(newState: boolean, oldState: boolean) {
    this.changeValidationState(!newState);
    if (newState) {
      this.flashRapidSearchNotFoundMessage();
    }
  }

  @Emit('changeValidationState')
  changeValidationState(isValid: boolean) {
    return isValid;
  }

  protected resetValidationState(): void {
    super.resetValidationState();
    this.hasAnyRapidSearchResults = true;
  }

  private query = '';
  private hasAnyRapidSearchResults = true;
  private showPopover = false;
  private flashMessageTimeoutId = -1;

  private get inputProps() {
    return {
      class: this.inputClass,
      placeholder: this.settingsProperty.searchInputPlaceholder,
    };
  }

  private get inputClass() {
    return ['ce-header-quicksearch__input', 'w-100'];
  }

  getCurrentQuery(): string {
    return this.query;
  }

  get isTwoColumnView(): boolean {
    return this.$screen.md;
  }

  get suggestionsContainerClass(): string {
    let classes = 'suggest-search-results-container';
    if (this.$screen.xl) {
      classes += ' suggest-search-results-modal';
    }
    if (this.searchResult?.hasBothItemsGroups) {
      classes += ' two-column';
    }
    return classes;
  }

  get isErrorMessagePopoverEnabled(): boolean {
    return (
      this.settingsProperty.suggestSearchSettings.isErrorMessageEnabled &&
      !this.hasAnySuggestSearchResult &&
      !this.hasAnyRapidSearchResults
    );
  }

  private flashRapidSearchNotFoundMessage() {
    this.showPopover = true;
    const self = this;

    if (this.flashMessageTimeoutId > 0) {
      window.clearTimeout(this.flashMessageTimeoutId);
      this.flashMessageTimeoutId = -1;
    }
    this.flashMessageTimeoutId = window.setTimeout(() => {
      var timeoutId = self.flashMessageTimeoutId;
      self.hideRapidSearchNotFoundMessage(timeoutId);
    }, 5000);
  }

  private hideRapidSearchNotFoundMessage(timeoutId: number) {
    this.showPopover = false;
    if (timeoutId === this.flashMessageTimeoutId) {
      this.flashMessageTimeoutId = -1;
    }
  }

  get suggestionsGroups() {
    return {
      default: {
        label: 'dropdown',
        type: 'ad-suggest-search-dropdown',
      },
    };
  }

  get suggestions() {
    return this.searchResult ? [{ name: 'default', data: this.searchResult }] : [];
  }

  private selectSuggestion(selected: { item: SearchSuggestion } | undefined) {
    const url = selected?.item?.url;
    const anchorUrl = this.settingsProperty?.anchorUrl || '';
    if (url) {
      this.abortController?.abort(); // Abort all pending requests before loading another page
      const urlWithFragment = url + anchorUrl;
      document.location.href = urlWithFragment;
      return;
    }

    if (this.searchResult?.allItems.length == 1) {
      const singleSuggestionUrl = this.searchResult?.allItems[0].url;
      if (singleSuggestionUrl) {
        this.abortController?.abort(); // Abort all pending requests before loading another page

        const urlWithFragment = singleSuggestionUrl + anchorUrl;
        document.location.href = urlWithFragment;
        return;
      }
    }

    this.rapidSearch();
  }

  public async rapidSearch() {
    const anchorUrl = this.settingsProperty?.anchorUrl || '';
    if (!this.pageRoutes) return;
    this.hasAnyRapidSearchResults = true;
    try {
      const result = await this.rapidSearchBase(this.query, this.settingsProperty, this.pageRoutes);
      if (result) {
        this.abortController?.abort(); // Abort all pending requests before loading another page
        const urlWithFragment = result + anchorUrl;
        document.location.href = urlWithFragment;
      } else {
        this.hasAnyRapidSearchResults = false;
      }
    } catch (ex) {
      this.$log.error(ex);
    }
  }

  debounceDelay = 500;
  private debounceRequestSuggestions = pDebounce(this.requestSuggestions, this.debounceDelay);

  private async fetchSuggestions() {
    if (!this.pageRoutes) return;
    try {
      this.abortController = new AbortController();
      await this.debounceRequestSuggestions(this.query, this.settingsProperty, this.pageRoutes, this.nsinKeys);
    } catch (ex) {
      this.$log.error(ex);
    }
  }
}
</script>
<style lang="scss">
.suggest-search {
  width: 100%;
  & > div {
    width: 100%;
  }
}
.suggest-search-results-container {
  position: absolute;
  left: -30px;
  width: 100%;
  overflow-y: auto;

  .text-danger {
    color: $deep-red;
  }

  &.suggest-search-results-modal {
    top: 45px;
    right: 0;
    left: auto;

    border-radius: 10px;
    box-shadow: 0 1px 6px 0 rgba(165, 103, 103, 0.3);
    background-color: $white;
    padding: rem(20) rem(30) rem(20);
    width: 30vw;
    min-height: 150px;
    max-height: 70vh;

    &.two-column {
      width: 50vw;
    }
  }

  @include media-breakpoint-down('lg') {
    top: 44px;
    left: 0;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0 1px 6px 0 rgba(165, 103, 103, 0.3);
    background-color: $light-grey;
    padding: rem(10) rem(20);
  }
}
</style>
