import axios from 'axios';
import qs from 'qs';

const httpClient = axios.create({
  timeout: 45000,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat', encode: false });
  },
});

export default httpClient;
