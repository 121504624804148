import httpClient from './http-service';
import { VueCookies } from 'vue-cookies';
import CookieManager from '@src/utils/cookie';
import { getTheQApiUrl } from '@src/utils/url-helper';
import { Controller, CookieCreateState } from '@src/types/enumerations';
import { AxiosResponse } from 'axios';

export default class ContentLikeService {
  private _cookieManager: CookieManager;

  constructor(cookies: VueCookies) {
    this._cookieManager = new CookieManager(cookies);
  }

  public wasLiked(contentId: number): boolean {
    return this._cookieManager.wasLiked(contentId);
  }

  public async getLikeCount(contentId: number): Promise<number> {
    const response = await httpClient.get<number>(getTheQApiUrl(Controller.ContentLikes, 'Count'), {
      params: { contentId: contentId },
    });
    return response.data;
  }

  private async addLike(contentId: number): Promise<number> {
    const response = await httpClient.post<null, AxiosResponse<number>>(
      getTheQApiUrl(Controller.ContentLikes, 'Add'),
      null,
      {
        params: {
          contentId: contentId,
        },
      }
    );

    return response.data;
  }

  public async likeContent(
    contentId: number
  ): Promise<{ cookieState: CookieCreateState; likeCount: number | undefined }> {
    const cookieState: CookieCreateState = this._cookieManager.saveLike(contentId);
    let likeCount;
    switch (cookieState) {
      case CookieCreateState.Exists:
        likeCount = await this.getLikeCount(contentId);
        break;
      case CookieCreateState.Created:
        likeCount = await this.addLike(contentId);
        break;
    }
    return { cookieState, likeCount };
  }
}
