<template>
  <div>
    <div class="px-md-0 py-4 ce-ad-short-news-item__news-tile">
      <div class="ce-ad-short-news-item__date fs-18">
        {{ publicationDate | formatField(dateFormat) }}
      </div>
      <h3>
        <a href="#" :id="this.shortNewsId"><slot name="headline"></slot></a>
      </h3>
      <div class="ce-ad-short-news-item__text">
        <slot name="text"></slot>
      </div>
      <div class="ce-ad-short-news-item__likes d-flex align-items-center pt-2">
        <ad-like-count :content-id="parsedId" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DefaultFormat } from '@/src/types/enumerations';
import { jumpToAnchor } from '@/src/utils/scroll-helpers';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CeAdShortNewsItem extends Vue {
  @Prop({ type: String }) shortNewsId!: string;
  @Prop({ type: String }) publicationDateTimestamp!: string;

  dateFormat = DefaultFormat.BlogDate;

  get parsedId(): number {
    return this.shortNewsId !== undefined ? Number.parseInt(this.shortNewsId) : 0;
  }

  get publicationDate(): Date {
    return new Date(Number.parseInt(this.publicationDateTimestamp));
  }

  created() {
    this.$nextTick(jumpToAnchor);
  }
}
</script>

<style lang="scss">
.ce-ad-short-news-item {
  &__news-tile {
    border-top: 1px dashed $peacock-blue;
  }

  &__date {
    color: $peacock-blue;
  }

  &__header {
    color: $marine-blue;
  }

  &__text {
    color: $gunmetal;
  }

  &__likes {
    color: $peacock-blue;
    svg {
      color: $clarinet;
    }
  }
}
</style>
