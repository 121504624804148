<template>
  <div>
    <div class="content-container container-fluid mb-0">
      <div class="position-relative">
        <!-- a-d is separated by the dash to not trigger a-d-blockers -->
        <div class="a-d-marker fs-12"><slot name="advertisment"></slot></div>
        <slot name="header-image"></slot>
      </div>
    </div>
    <b-container fluid class="blog-container mt-6 mt-md-7 mt-xl-8 mb-7">
      <h2><slot name="headline"></slot></h2>
      <p class="mb-4 fs-26 marine-blue" v-if="$slots['preview-text']">
        <slot name="preview-text"></slot>
      </p>
      <div>
        <ce-author-small>
          <template #author-name><slot name="author-name"></slot></template>
          <template #author-headline><slot name="author-headline"></slot></template>
          <template #social-like><slot name="social-like"></slot></template>
          <template #social-share-twitter><slot name="social-share-twitter"></slot></template>
          <template #social-share-facebook><slot name="social-share-facebook"></slot></template>
        </ce-author-small>
      </div>
    </b-container>
    <div
      v-if="$slots['content']"
      :class="[
        'mt-7',
        { 'mb-7 mb-md-8 ': !displayContainer && !settingsProperty.showNewsletterSubscription && !$slots['teasers'] },
      ]"
    >
      <ad-json-settings-property :settings="settingsProperty.accordionAnchorSettings">
        <slot name="content"></slot>
      </ad-json-settings-property>
    </div>
    <b-container fluid class="blog-container mb-7 mb-md-8" v-if="displayContainer">
      <div class="mt-7" v-if="settingsProperty.showProspectusDisclaimer">
        <slot name="disclaimer-prospectus"></slot>
      </div>
      <div class="mt-7" v-if="settingsProperty.showLegalDisclaimer">
        <slot name="disclaimer-legal"></slot>
      </div>
      <div class="mt-7" v-if="settingsProperty.showAuthor">
        <slot name="author-block"></slot>
      </div>
      <div class="mt-6" v-if="settingsProperty.showBlogNavigation">
        <slot name="blog-navigation-block"></slot>
      </div>
    </b-container>
    <div class="mt-7 mb-7" v-if="settingsProperty.showNewsletterSubscription">
      <slot name="newsletter-subscription-block"></slot>
    </div>
    <slot name="teasers"></slot>
  </div>
</template>
<script lang="ts">
import { BlogPageSettings } from '@/src/types/episerver-api';
import { Component, Inject, Vue } from 'vue-property-decorator';

@Component
export default class AdBlogPageBase extends Vue {
  @Inject() settingsProperty?: BlogPageSettings;

  get displayContainer(): boolean | undefined {
    return (
      this.settingsProperty?.showAuthor ||
      this.settingsProperty?.showBlogNavigation ||
      this.settingsProperty?.showProspectusDisclaimer ||
      this.settingsProperty?.showLegalDisclaimer
    );
  }
}
</script>
