import createMutationsSharer from 'vuex-shared-mutations';
import { isShareMutation } from '../../modules/watchlist';

const sharedMutation = createMutationsSharer({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  predicate: (mutation, state) => {
    return isShareMutation(mutation.type);
  },
});
export default sharedMutation;
