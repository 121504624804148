<template>
  <ad-icon-base iconName="xls" v-bind="$attrs" viewBox="10 10 25 25">
    <path
      d="M25.84,22H19a.5.5,0,0,0,0,1h6.84a.5.5,0,1,0,0-1ZM16,12H15a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V13A1,1,0,0,0,16,12Zm0,2H15V13h1Zm0,6H15a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V21A1,1,0,0,0,16,20Zm0,2H15V21h1Zm0-6H15a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V17A1,1,0,0,0,16,16Zm0,2H15V17h1Zm9.84,0H19a.5.5,0,0,0,0,1h6.84a.5.5,0,1,0,0-1Zm0-4H19a.5.5,0,0,0,0,1h6.84a.5.5,0,1,0,0-1Z"
    /><path
      d="M12.5,8A1.5,1.5,0,0,0,11,9.5v21A1.5,1.5,0,0,0,12.5,32h6.93a1.59,1.59,0,0,0,.61-.13l8.08-3.64A1.52,1.52,0,0,0,29,26.86V9.5A1.5,1.5,0,0,0,27.5,8ZM20,30.79V28.5a.5.5,0,0,1,.5-.5h5.69ZM28,9.5V26.86A.74.74,0,0,1,28,27H20.5A1.5,1.5,0,0,0,19,28.5V31H12.5a.5.5,0,0,1-.5-.5V9.5a.5.5,0,0,1,.5-.5h15A.5.5,0,0,1,28,9.5Z"
    />
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconXls extends Vue {}
</script>
