<template>
  <div class="w-100 position-relative ce-header-image">
    <div class="aspect-ratio-1600-540 w-100 ce-header-image__image-container">
      <slot name="header-image" />
    </div>
    <div v-if="$slots['left-block'] || $slots['right-block']" class="ce-header-image__overlay pt-3 pt-lg-0">
      <div
        :class="[
          'content-container',
          'content-container-x',
          'container-fluid',
          'd-flex',
          'flex-column',
          'flex-lg-row',
          'align-items-center',
          'h-100',
          'my-0',
          { 'w-100': $screen.lg },
        ]"
      >
        <div class="row justify-content-between w-100">
          <div class="col-12 col-lg-7 d-flex align-items-center">
            <div
              v-if="$slots['left-block']"
              :class="['ce-header-image__block', 'p-lg-3', 'p-xl-6', 'w-100', { 'bg-transparent': hideLightBox }]"
            >
              <div class="ce-header-image__inner-block">
                <slot name="left-block" />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex align-items-center">
            <div class="ce-header-image__block my-5 my-lg-0 w-100">
              <div class="ce-header-image__inner-block">
                <slot name="right-block" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CeHeaderImage extends Vue {
  @Prop({ default: false, type: Boolean }) hideLightBox!: boolean;
}
</script>

<style lang="scss">
.ce-header-image {
  & &__image-container {
    @include media-breakpoint-up('lg') {
      padding-top: 0;
      height: 650px;
    }
  }

  &__overlay {
    position: relative;
    background-color: $silver;
    @include media-breakpoint-up('lg') {
      position: absolute;
      top: 0;
      z-index: 2;
      background-color: transparent;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  &__slots {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__block {
    border-radius: 12px;
    background-color: $silver;
    @include media-breakpoint-up('lg') {
      background-color: rgba($white, 0.6);
    }
  }

  &__inner-block {
    pointer-events: all;
  }
}
</style>
