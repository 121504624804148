<template>
  <div>
    <div id="play-html">
      <div>
        <slot name="play-html"></slot>
      </div>
    </div>
    <b-modal
      v-model="anyBlocked"
      size="xl"
      id="ad-adblocker-detection-modal"
      hide-header
      no-close-on-backdrop
      ok-only
      body-class=""
    >
      <div class="mt-4">
        <b-container class="px-3 px-md-4 pb-4">
          <b-row>
            <b-col>
              <div class="inner pr-0 pr-md-3">
                <slot name="hint"></slot>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <template #modal-footer>
        <b-container>
          <b-row class="mt-lg-5">
            <b-col lg="4" offset-lg="8" class="pb-3 d-lg-block">
              <b-button block text-wrap variant="secondary" @click="setHintViewed">
                <slot name="ok-button"></slot>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from 'vue-property-decorator';
import { AdBlockerDetectionBlockSettings } from '@/src/types/episerver-api';
import CookieManager from '@/src/utils/cookie';

@Component({})
export default class AdAdblockerDetection extends Vue {
  @Inject() settingsProperty!: AdBlockerDetectionBlockSettings;
  private cookieManager!: CookieManager;
  public anyBlocked = false;

  created() {
    this.cookieManager = new CookieManager(this.$cookies);
  }

  mounted() {
    if (!this.isDetectionEnabled || this.wasAdBlockerHintViewed || !this.isAdBlockerDetected) {
      this.hideHintAndShowDisclaimer();
      return;
    }
    this.hideDisclaimerAndShowHint();
  }

  setHintViewed() {
    this.saveCookie();
    this.hideHintAndShowDisclaimer();
  }

  get isDetectionEnabled(): boolean {
    return this.settingsProperty.cssSelectors.length > 0;
  }

  get wasAdBlockerHintViewed(): boolean {
    return this.cookieManager.wasAdBlockerHintViewed();
  }

  get isAdBlockerDetected(): boolean {
    const elements = document.querySelectorAll(this.settingsProperty.cssSelectors);
    const blockedElements = Array.from(elements).filter((element) => {
      const displayValue = window.getComputedStyle(element, null).display;
      return displayValue === 'none';
    });

    return blockedElements.length > 0;
  }

  private hideHintAndShowDisclaimer() {
    this.hideHint();
    this.showDisclaimer();
  }

  private hideDisclaimerAndShowHint() {
    this.showHint();
    this.hideDisclaimer();
  }

  private showDisclaimer() {
    this.$nextTick(() => {
      this.$bvModal.show('ad-disclaimer-modal');
    });
  }

  private hideHint() {
    this.anyBlocked = false;
  }

  private showHint() {
    this.anyBlocked = true;
  }

  private hideDisclaimer() {
    this.$nextTick(() => {
      this.$bvModal.hide('ad-disclaimer-modal');
    });
  }

  private saveCookie(): void {
    this.cookieManager.saveAdBlockerHintViewed();
  }
}
</script>
<style lang="scss">
.ce-content.hidden-by-position {
  position: absolute;
  top: -999px;
  left: -999px;
  min-width: 0;
}
</style>
