import { ProductModel, ProductPricePush, UnderlyingModel, UnderlyingPricePush } from '../../../types/the-q-api';
import { MutationPushPayloadHolder } from '../../../types/vue-api';
import Vue from 'vue';
import evaluateExpressionByKey, {
  hasExpressionField,
  setExpressionValue,
} from '../../../utils/value-formatter/format-helper';

/**
 * Vuex module namespaces
 */

export const underlyingNamespace = 'underlying/';
export const productNamespace = 'product/';
export const realtimeNamespace = 'realtime/';
export const watchlistNamespace = 'watchlist/';
export const bestMatchNamespace = 'bestMatch/';

/**
 * PUSHING PRODUCT
 */

/**
 * push puffer number for cashing items
 * @returns number for puffer
 */
export const pushPufferNumber = parseInt(process.env.VUE_APP_LIGHTSTREAMER_UPDATE_PUSH_PUFFER_ITEMS) * 2;

/**
 * Frequenz to set trigger in rows when get visible. E.g. every 10 rows a visible trigger is set.
 * @returns frequenz for tigger
 */
export const pushTriggerFrequenz = parseInt(process.env.VUE_APP_LIGHTSTREAMER_UPDATE_PUSH_PUFFER_ITEMS);

/**
 * Pushing subscription mutations for product
 */
export const PUSH_SUBSCRIPTION_PRODUCT_GETTER = 'PUSH_SUBSCRIPTION_PRODUCT_GETTER';

/**
 * Pushing update mutations for product
 */
export const PUSH_UPDATE_PRODUCT = 'PUSH_UPDATE_PRODUCT';

/**
 * Contains all namespaces for modules which have registerd a PUSH_UPDATE_UNDERLYING mutation
 */
export const PUSH_PRODUCT_NAMESPACES = [productNamespace, watchlistNamespace, bestMatchNamespace];

/**
 * Pushing mutations for product
 */
export enum LightStreamerProductSubscriptionMutationTypes {
  CACHE_PRODUCT = 'CACHE_PRODUCT',
  CACHE_PRODUCTS = 'CACHE_PRODUCTS',
  CACHE_PRODUCTS_CONCAT = 'CACHE_PRODUCTS_CONCAT',
  CACHE_PRODUCTS_SEARCH = 'CACHE_PRODUCTS_SEARCH',
  CACHE_PRODUCTS_BEST_MATCHES = 'CACHE_PRODUCTS_BEST_MATCHES',
  CACHE_TOP_LEVERAGE_TABLE_SEARCH_PRODUCTS = 'CACHE_TOP_LEVERAGE_TABLE_SEARCH_PRODUCTS',
  CACHE_WATCHLIST_PRODUCT = 'CACHE_WATCHLIST_PRODUCT',
}

/**
 * Tries to update underlying push item in underlying
 * @param product
 * @param productPush
 * @param productPushValues
 */
export function tryUpdateProductValue(
  product: ProductModel | null,
  productPush: ProductPricePush,
  productPushValues: { [key: string]: boolean }
) {
  if (product && productPush && product.isin === productPush.isin) {
    Object.keys(productPushValues).forEach((pushValueProperty) => {
      if (
        !hasExpressionField(pushValueProperty, {
          product: productPush,
        })
      ) {
        Vue.$log.debug('pushValue not set ', pushValueProperty);
        return;
      }

      const pushValue = evaluateExpressionByKey(pushValueProperty, {
        product: productPush,
      });
      setExpressionValue(pushValueProperty, pushValue, {
        product,
      });
    });
  }
}

/**
 * PUSHING UNDERLYING
 */

/**
 * Pushing subscription mutations for underlying
 */
export const PUSH_SUBSCRIPTION_UNDERLYING_GETTER = 'PUSH_SUBSCRIPTION_UNDERLYING_GETTER';

/**
 * Pushing update mutations for underlying
 */
export const PUSH_UPDATE_UNDERLYING = 'PUSH_UPDATE_UNDERLYING';

/**
 * Contains all namespaces for modules which have registerd for pushing
 */
export const PUSH_UNDERLYING_NAMESPACES = [underlyingNamespace, realtimeNamespace, watchlistNamespace];

/**
 * Pushing mutations for underlying
 */
export enum LightStreamerUnderlyingSubscriptionMutationTypes {
  CACHE_UNDERLYING = 'CACHE_UNDERLYING',
  CACHE_UNDERLYINGS_BY_KEY = 'CACHE_UNDERLYINGS_BY_KEY',
  CACHE_TEASER_UNDERLYINGS = 'CACHE_TEASER_UNDERLYINGS',
  CACHE_PRODUCT_UNDERLYING = 'CACHE_PRODUCT_UNDERLYING',
  CACHE_TOP_FLOP_TABLE_SEARCH_PRODUCTS = 'CACHE_TOP_FLOP_TABLE_SEARCH_PRODUCTS',
  CACHE_UNDERLYINGS = 'CACHE_UNDERLYINGS',
  CACHE_WATCHLIST_UNDERLYING = 'CACHE_WATCHLIST_UNDERLYING',
}

// Helper function which helps in checking the types
export function createPushPayload<T, Q>(
  holder: T,
  getSubscriptionPushItems: (holder: T) => Array<Q>
): MutationPushPayloadHolder<T, Q> {
  return {
    holder,
    getSubscriptionPushItems,
  } as MutationPushPayloadHolder<T, Q>;
}

/**
 * Tries to update underlying push item in underlying
 * @param underlying
 * @param underlingPush
 * @param underlyingPushValues
 */
export function tryUpdateUnderlyingValue(
  underlying: UnderlyingModel | UnderlyingModel | null,
  underlingPush: UnderlyingPricePush,
  underlyingPushValues: { [key: string]: boolean }
) {
  if (underlying && underlingPush && underlying.isin === underlingPush.isin) {
    const currentTimeStamp = new Date(underlying.timeStamp ?? 0);
    const pushTimeStamp = new Date(underlingPush.timeStamp ?? 0);

    if (pushTimeStamp > currentTimeStamp) {
      Object.keys(underlyingPushValues).forEach((pushValueProperty) => {
        if (
          !hasExpressionField(pushValueProperty, {
            underlying: underlingPush,
          })
        ) {
          Vue.$log.debug('pushValue not set ', pushValueProperty);
          return;
        }

        const pushValue = evaluateExpressionByKey(pushValueProperty, {
          underlying: underlingPush,
        });

        setExpressionValue(pushValueProperty, pushValue, {
          underlying: underlying,
        });
      });
    }
  }
}
