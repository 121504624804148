import { getCurrentCulture } from '@src/utils/culture-helper';
import { CULTURE_TO_LOCALE_MAP } from '@src/utils/constants';

export const millisecondsInMinute = 60 * 1000;
export const millisecondsInAnHour = 60 * millisecondsInMinute;
export const secondsInDay = 24 * 3600;
export const millisecondsInDay = secondsInDay * 1000;

/*
Returns the unix timestamp for today's date in UTC timezone
*/
export function utcToday(): number {
  const now = new Date();
  return Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
}

/*
Creates a date from a unix timestamp in UTC timezone
*/
export function utcTimestamp(timeStamp: number): number {
  return timeStamp - new Date().getTimezoneOffset() * millisecondsInMinute;
}

/*
Creates a date from a unix timestamp in UTC timezone
*/
export function utcDate(timeStamp: number): Date {
  return new Date(timeStamp + new Date().getTimezoneOffset() * millisecondsInMinute);
}
/*
Parses a date from a string and adjusts it to UTC timezone
 */
export function utcParse(value: string) {
  return Date.parse(value) - new Date().getTimezoneOffset() * millisecondsInMinute;
}

/**
 * maps the culture to the locale
 * @returns locale for the current culture
 */
export function getCurrentLocale() {
  const culture = getCurrentCulture();
  return CULTURE_TO_LOCALE_MAP.get(culture);
}
