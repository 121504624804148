<template>
  <div class="mb-5 mb-xl-0">
    <b-row>
      <b-col class="py-1 pr-3">
        <b-button
          variant="light"
          :class="['px-1', 'pt-1', 'ml-2', 'float-right', 'btn-chart-type', { active: !candlestick }]"
          @click="setChartType(chartPatterns.Line)"
        >
          <ad-icon-chart-line height="2em" width="2em" :iconName="settingsProperty.lineChartName" />
        </b-button>
        <b-button
          variant="light"
          :class="['px-1', 'pt-1', 'float-right', 'btn-chart-type', { active: candlestick }]"
          @click="setChartType(chartPatterns.Candlestick)"
        >
          <ad-icon-chart-candlestick height="2em" width="2em" :iconName="settingsProperty.candlestickChartName" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="no-gutters">
      <b-col>
        <ad-stock-chart
          :series="data"
          :pointInterval="pointInterval"
          :labelDateFormatter="formatLabelDate"
          :labelValueFormatter="formatLabelValue"
          :tooltipDateFormatter="formatTooltipDate"
          :tooltipValueFormatter="formatTooltipValue"
          :xAxisTickInterval="xAxisTickInterval"
          :xAxisMax="xAxisMax"
          :xAxisMin="xAxisMin"
          :marginRight="5"
          :navigator="true"
          :scrollbar="showScrollbar"
          :type="chartType"
          ref="chart"
          class="realtime-chart"
        ></ad-stock-chart></b-col
    ></b-row>
  </div>
</template>
<script lang="ts">
import { getTheQApiUrl } from '@src/utils/url-helper';
import httpClient from '@/src/utils/http-service';
import { Component, Inject, Ref, Vue } from 'vue-property-decorator';
import AdStockChart from '@components/molecules/charts/ad-stock-chart.vue';
import { ChartPoint, OhlcChartPoint, PriceModel, UnderlyingModel } from '@src/types/the-q-api';
import { DataSeries, OhlcDataSeries, ChartsApiFilterItem } from '@src/types/vue-api';
import { Getter, Action } from 'vuex-class';
import {
  Controller,
  InstrumentOriginTimeZone,
  ChartPeriods,
  ChartPatterns,
  ChartSeries,
  ChartColors,
  DefaultFormat,
} from '@src/types/enumerations';
import { formatField } from '@/src/utils/value-formatter/formatting-service';
import { utcDate, utcParse, utcToday } from '@/src/utils/date-helper';
import { ChartsApiFilter, RealtimeChartSettings } from '@/src/types/episerver-api';
import { InstrumentDataFieldBase } from '@/src/types/episerver-api-instrument';
import { getFormatDataField } from '@/src/utils/value-formatter/format-helper';

export class RealtimeChartsApiFilterItem implements ChartsApiFilter {
  timeZone: InstrumentOriginTimeZone;
  symbol: string;
  pointInterval: number | null;
  timeFrom: number | null;
  timeTo: number | null;
  period: ChartPeriods;
  series: string | null;
  constructor(
    symbol: string,
    timeZone: InstrumentOriginTimeZone,
    pointInterval: number | null,
    timeFrom: number | null,
    timeTo: number | null
  ) {
    this.symbol = symbol;
    this.timeZone = timeZone;
    this.pointInterval = null;
    this.timeFrom = null;
    this.timeTo = null;
    this.pointInterval = pointInterval;
    this.timeFrom = timeFrom;
    this.timeTo = timeTo;
    this.period = ChartPeriods.Intraday;
    this.series = null;
  }
}

@Component({
  components: {
    'ad-stock-chart': AdStockChart,
  },
})
export default class AdRealtimeChart extends Vue {
  @Ref() readonly chart!: AdStockChart;
  @Inject() settingsProperty!: RealtimeChartSettings;

  @Getter('underlying', { namespace: 'underlying' })
  private underlying!: UnderlyingModel | null;

  @Action('subscribePushValue', { namespace: 'underlying' })
  subscribePushValue!: (fieldKey: string) => Promise<void>;

  protected data: OhlcDataSeries[] | DataSeries[] = [];
  protected candlestick = true;
  protected chartPatterns = ChartPatterns;
  get priceFormat(): string {
    return 'underlying.last';
  }
  currencyCode(): string {
    return this.underlying?.currencyCode || '';
  }

  get chartType() {
    return this.candlestick ? ChartPatterns.Candlestick : ChartPatterns.Line;
  }

  setChartType(type: string) {
    switch (type) {
      case ChartPatterns.Candlestick:
        this.candlestick = true;
        break;
      case ChartPatterns.Line:
        this.candlestick = false;
        break;
      default:
        break;
    }
    this.loadChartData();
  }

  private get pointInterval(): number | null {
    return this.candlestick ? this.settingsProperty.candlestickPointInterval : this.settingsProperty.linePointInterval;
  }
  protected get xAxisTickInterval(): number | null {
    return this.settingsProperty?.axisTickInterval || null;
  }
  protected get xAxisMin(): number | undefined {
    return this.settingsProperty.tradingTimeStart
      ? utcToday() + this.settingsProperty.tradingTimeStart * 1000 * 0.99 //additional padding to prevent label cut off
      : undefined;
  }
  protected get xAxisMax(): number | undefined {
    return this.settingsProperty.tradingTimeEnd
      ? utcToday() + this.settingsProperty.tradingTimeEnd * 1000 * 1.01 //additional padding to prevent label cut off
      : undefined;
  }

  get showScrollbar() {
    return !this.$screen.md;
  }

  protected formatTooltipDate(chart: AdRealtimeChart, value: number): string {
    return formatField(utcDate(value), chart.settingsProperty.tooltipDateFormatFieldKey || DefaultFormat.DateTime);
  }

  protected formatTooltipValue(chart: AdRealtimeChart, value: Highcharts.TooltipFormatterContextObject): string {
    const point = value.point as unknown as OhlcChartPoint;
    const format = chart.settingsProperty.tooltipValueFormatFieldKey || DefaultFormat.Decimal;
    if (this.chartType === ChartPatterns.Candlestick) {
      const close = formatField({ amount: point.close, currencyCode: chart.currencyCode() }, format);
      const open = formatField({ amount: point.open, currencyCode: chart.currencyCode() }, format);
      const low = formatField({ amount: point.low, currencyCode: chart.currencyCode() }, format);
      const high = formatField({ amount: point.high, currencyCode: chart.currencyCode() }, format);
      return `${chart.settingsProperty.labelOpen}: ${open}<br/>
      ${chart.settingsProperty.labelHigh}: ${high}<br/>
      ${chart.settingsProperty.labelLow}: ${low}<br/>
      ${chart.settingsProperty.labelClose}: ${close}`;
    } else if (value.point.y) {
      return `${formatField({ amount: value.point.y, currencyCode: chart.currencyCode() }, format)}`;
    } else {
      return `${value.point.y}`;
    }
  }

  protected formatLabelDate(chart: AdRealtimeChart, value: number): string {
    return formatField(utcDate(value), chart.settingsProperty.xAxisLabelFormatFieldKey || DefaultFormat.Time);
  }

  protected formatLabelValue(chart: AdRealtimeChart, value: number): string {
    return formatField(value, chart.settingsProperty.yAxisLabelFormatFieldKey || DefaultFormat.Decimal);
  }

  get chartHeight(): number {
    let height: number;
    if (this.$screen.lg) height = 425;
    else if (this.$screen.md) height = 335;
    else height = 240;
    height += 75;
    return height;
  }

  protected async loadChartData() {
    if (!this.underlying) return;
    const action = 'GetUnderlying';
    if (this.chartType === ChartPatterns.Candlestick) {
      const data = await this.requestCandlestickChartData(
        action,
        this.underlying.origin.timeZone == InstrumentOriginTimeZone.AET
          ? this.underlying.nsins.wkn ?? this.underlying.nsins.isin
          : this.underlying.nsins.isin,
        this.underlying.origin.timeZone
      );
      if (data) {
        this.data = [new OhlcDataSeries(data)];
      }
    } else if (this.chartType === ChartPatterns.Line) {
      const data = await this.requestLineChartData(action, this.underlying.nsins.isin, this.underlying.origin.timeZone);
      if (data) {
        this.data = [new DataSeries(ChartSeries.realtime, data, ChartColors.underlying)];
      }
    }
  }
  /**
   * Requests realtime chart data for Candlestick pattern
   */
  protected async requestCandlestickChartData(
    action: string,
    isin: string | undefined,
    timezone: InstrumentOriginTimeZone
  ): Promise<OhlcChartPoint[] | null> {
    if (!isin) return null;
    try {
      const response = await httpClient.get<OhlcChartPoint[]>(getTheQApiUrl(Controller.RealtimeCharts, action), {
        params: new RealtimeChartsApiFilterItem(
          isin,
          timezone,
          this.pointInterval,
          this.settingsProperty.tradingTimeStart,
          this.settingsProperty.tradingTimeEnd
        ),
      });
      return response.data;
    } catch (ex) {
      this.$log.error(ex);
      return null;
    }
  }

  /**
   * Requests realtime chart data for Line pattern
   */
  protected async requestLineChartData(
    action: string,
    isin: string | undefined,
    timezone: InstrumentOriginTimeZone
  ): Promise<ChartPoint[] | null> {
    if (!isin) return null;
    try {
      const response = await httpClient.get<{ [key: string]: ChartPoint[] }>(getTheQApiUrl(Controller.Charts, action), {
        params: new ChartsApiFilterItem(
          ChartPeriods.Intraday,
          [ChartSeries.price],
          isin,
          timezone,
          this.pointInterval,
          this.settingsProperty.tradingTimeStart,
          this.settingsProperty.tradingTimeEnd
        ),
      });
      return response.data[ChartSeries.price];
    } catch (ex) {
      this.$log.error(ex);
      return null;
    }
  }

  pushValueFieldKey = 'underlying.last';
  get pushValue(): PriceModel | null | undefined {
    return this.underlying?.last;
  }

  get pushTimestamp(): number | undefined {
    return this.underlying?.timeStamp ? utcParse(this.underlying.timeStamp) : undefined;
  }

  pushChart() {
    if (
      this.chart &&
      this.pointInterval &&
      this.pushValue &&
      this.pushValue.amount != null &&
      //if there is a min-max limitation for the xAxis, timestamp has to fit into that range
      this.pushTimestamp &&
      (!this.xAxisMin || this.pushTimestamp >= this.xAxisMin) &&
      (!this.xAxisMax || this.pushTimestamp <= this.xAxisMax)
    ) {
      this.chart.pushValue(0, this.pushTimestamp, this.pushValue.amount, this.pointInterval * 1000, true);
    }
  }

  created() {
    this.loadChartData();
    if ((getFormatDataField(this.pushValueFieldKey) as InstrumentDataFieldBase)?.push) {
      this.subscribePushValue(this.pushValueFieldKey);
      this.$watch('pushValue', this.pushChart);
    }
  }
}
</script>
<style lang="scss">
$chart-real-font-size: $fs-10;
$chart-real-tooltip-value-font-size: $fs-14;
$chart-real-tooltip-value-font-size-sm: $fs-10;

.highcharts-point-down {
  fill: $mulberry;
}
.highcharts-point-up {
  fill: $kelley-green;
}
.btn-chart-type {
  background-color: $light-blue-grey;
  width: rem(46);
  min-width: rem(46);
  height: rem(46);
  color: $peacock-blue;
}
.btn-chart-type.active {
  background-color: $peacock-blue;
  color: $light-blue-grey;
}

.realtime-chart {
  .highcharts-background {
    fill: $silver;
  }
  .highcharts-plot-background {
    fill: $white;
  }
  .highcharts-tooltip {
    .timestamp {
      font-size: $chart-real-font-size !important;
    }

    .value {
      font-size: $chart-real-tooltip-value-font-size !important;

      @include media-breakpoint-down('sm') {
        font-size: $chart-real-tooltip-value-font-size-sm !important;
      }
    }
  }
}
</style>
