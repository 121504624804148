<template>
  <div class="ce-glossary-mobile-section-navigation-button d-block d-md-none" :class="{ open }">
    <button class="ce-glossary-mobile-section-navigation-button--open-button" @click="open = true">
      <slot></slot>
    </button>
    <div class="ce-glossary-mobile-section-navigation-button--letter-selection">
      <div class="ce-glossary-mobile-section-navigation-button--letter-selection-header">
        <slot></slot>
      </div>
      <div class="ce-glossary-mobile-section-navigation-button--letter-container">
        <button
          v-for="letter in letters"
          :key="letter"
          @click="scrollToLetter(letter)"
          :disabled="!entriesByLetter[letter] || entriesByLetter[letter].length === 0"
          class="ce-glossary-mobile-section-navigation-button--letter"
        >
          {{ letter.toUpperCase() }}
        </button>
      </div>
      <div class="ce-glossary-mobile-section-navigation-button--letter-selection-footer">
        <button @click="open = false"><ad-icon-close></ad-icon-close></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { GlossaryEntry } from '@/src/types/ce-component-types';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class CeGlossaryMobileSectionNavigationButton extends Vue {
  @Prop({ default: {} }) entriesByLetter!: Record<string, GlossaryEntry[]>;
  @Prop() letters!: string[];

  open = false;

  @Emit('scrollToLetter')
  scrollToLetter(letter: string) {
    this.open = false;
    return letter;
  }
}
</script>

<style lang="scss">
.ce-glossary-mobile-section-navigation-button {
  position: fixed;
  right: 15px;
  bottom: calc(50vh - #{rem(100)});
  z-index: 5;

  &--open-button {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.16s;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
    background: $bright-sky-blue;
    width: rem(64);
    height: rem(64);
    color: $white;
    font-size: $fs-22;

    &:focus {
      // Only visible on mobile, so outline: none is okay
      outline: 0;
    }
    &:hover {
      background-color: $teal-blue;
    }
  }

  &--letter-selection {
    position: fixed;
    right: 15px;
    bottom: calc(50vh - #{rem(100)});
    left: 15px;
    transition: opacity 0.2s;
    opacity: 0;
    border-bottom-right-radius: 1rem;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    background: $white;
    color: $marine-blue;
    font-size: $fs-26;
    pointer-events: none;
  }

  &.open &--letter-selection {
    opacity: 1;
    pointer-events: all;
  }

  &--letter-selection-header {
    padding: map-get($spacers, 1);
  }

  &--letter-container {
    display: flex;
    flex-wrap: wrap;
  }

  &--letter {
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    margin-bottom: 1px;
    border: 0;
    background: $light-grey;
    width: calc(#{(100% / 7)} - 1px);
    height: rem(56);
    color: $peacock-blue;

    &:disabled {
      color: $cool-grey;
    }

    &:hover:not(:disabled) {
      background: $peacock-blue;
      color: $white;
    }
  }

  &--letter-selection-footer {
    display: flex;
    justify-content: flex-end;

    button {
      border: 0;
      background: transparent;
      padding: 1rem;
      color: $marine-blue;
      font-size: 1rem;
    }
  }
}
</style>
