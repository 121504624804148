import {
  ExcelExportField,
  ProductHistoryExcelExportFilter,
  ProductModel,
  RollingHistoryModel,
  StrikeHistoryModel,
} from '@/src/types/the-q-api';
import { Inject, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { formatField } from '@/src/utils/value-formatter/formatting-service';
import { ProductHistoryBlockSettings } from '@/src/types/episerver-api';
import { InstrumentDataFieldBase, ProductHistoryDataField } from '@/src/types/episerver-api-instrument';
import evaluateExpressionByKey, { getFormatDataField } from '@/src/utils/value-formatter/format-helper';

export default abstract class AdProductHistoryBase extends Vue {
  @InjectReactive() product!: ProductModel | null;
  @Inject() settingsProperty!: ProductHistoryBlockSettings;
  @Prop({ default: false }) showCollapsed!: boolean;

  abstract getData(): RollingHistoryModel[] | StrikeHistoryModel[] | null | undefined;
  abstract excelExport(): void;

  get headers(): string[] {
    return this.settingsProperty.columns.map((c) => c.header);
  }
  get rows(): string[][] | boolean {
    const data = this.getData();
    if (!data || data.length == 0) return false;

    const formattedValues: string[][] = [];
    for (const row of data) {
      const formattedRow: string[] = [];
      for (const column of this.settingsProperty.columns) {
        const expression = (getFormatDataField(column.fieldKey) as InstrumentDataFieldBase | ProductHistoryDataField)
          ?.valueExpression;
        if (expression === undefined) {
          formattedRow.push('');
        } else {
          const value = evaluateExpressionByKey(expression, row);
          const formattedValue = formatField(value, column.fieldKey);
          formattedRow.push(formattedValue);
        }
      }
      formattedValues.push(formattedRow);
    }
    return formattedValues;
  }

  protected buildExcelRequest(): ProductHistoryExcelExportFilter {
    return {
      isin: this.product?.isin,
      headerValues: this.evaluateExportHeaders(),
      tableFields: this.mapExportColumns(),
    } as ProductHistoryExcelExportFilter;
  }

  protected evaluateExportHeaders() {
    const headers: { [key: string]: string } = {};
    for (const h of this.settingsProperty.exportHeaders) {
      let value;
      if (h.value) value = h.value;
      else if (h.valueExpression) {
        const expression = (getFormatDataField(h.valueExpression) as InstrumentDataFieldBase | ProductHistoryDataField)
          ?.valueExpression;
        if (!expression) continue;
        value = evaluateExpressionByKey(expression, this.product);
      } else continue;
      headers[h.headline] = value;
    }
    return headers;
  }

  protected mapExportColumns(): ExcelExportField[] {
    return this.settingsProperty.columns.map((c) => {
      const expression = (getFormatDataField(c.fieldKey) as InstrumentDataFieldBase | ProductHistoryDataField)
        ?.valueExpression;
      return {
        header: c.header,
        valueExpression: expression,
      } as ExcelExportField;
    });
  }
}
