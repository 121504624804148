<script lang="ts">
import { VNode } from 'vue';
import { Component, Prop, Vue, ProvideReactive } from 'vue-property-decorator';
import { UnderlyingModel } from '@/src/types/the-q-api';

@Component({})
export default class AdUnderlyingDataProperty extends Vue {
  @ProvideReactive()
  @Prop({ required: true })
  underlying!: UnderlyingModel | null;

  render(): VNode[] | VNode | undefined {
    return this.$scopedSlots.default?.({});
  }
}
</script>
