<template>
  <ad-error :http-status-code="statusCode">
    <div>
      <slot v-if="!isArchiveDetail" :product="product" name="product-detail-page"></slot>
      <slot v-if="isArchiveDetail" :product="product" name="archive-detail-page"></slot>
    </div>
  </ad-error>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import AdProductDetails from '@/src/components/organisms/ad-product-details/index.vue';
import { ProductModel } from '@/src/types/the-q-api';
import { isAxiosError } from '@/src/types/type-guards';
import { HttpStatusCodes } from '@/src/types/enumerations';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    AdProductDetails,
  },
})
export default class AdProductDetailsLoadGuard extends Vue {
  @Prop()
  isinOrNsin!: string;

  @Action('loadProductAsync', { namespace: 'product' })
  loadProductAsync!: (isinOrNsin: string) => Promise<void>;

  @Action('loadFirstProductAsync', { namespace: 'product' })
  loadFirstProductAsync!: (leverageType: string) => Promise<void>;

  @Getter('product', { namespace: 'product' })
  product!: ProductModel | null;

  statusCode: number | HttpStatusCodes = HttpStatusCodes.OK;

  get isArchiveDetail() {
    if (this.product) {
      return this.product.lifetimeCycle != null;
    }

    return false;
  }

  async created(): Promise<void> {
    try {
      await this.loadProductAsync(this.isinOrNsin);
    } catch (error: unknown) {
      this.$log.error('Loading Error', error);

      if (isAxiosError(error)) {
        this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
      } else {
        this.statusCode = HttpStatusCodes.UnknownError;
      }
    }
  }
}
</script>
