import numeral from 'numeral';
import Vue from 'vue';

export const CustomOrdinalFormatting = 'customOrdinalFormatting';
export const CulturesWithCustomOrdinal = { enDE: 'en-DE', deDE: 'de-DE' };

/**
 * Custom currency formatter
 */
numeral.register('format', 'CustomOrdinalFormatting', {
  regexps: {
    format: /(\s?O{2})/,
  },
  format: function (value: number) {
    const locale = numeral.locales[numeral.options.currentLocale];

    if (typeof locale.customOrdinal === 'function') {
      return locale.customOrdinal(value);
    } else {
      Vue.$log.fatal('No custom ordinal set for this culture', locale);
    }
  },
});
