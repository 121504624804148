<template>
  <li class="nav-item d-flex ce-nav-item-dropdown">
    <a v-if="href" class="nav-link" v-bind:href="href" v-bind:title="title">{{ text }}</a>
    <a v-else class="nav-link" :aria-expanded="isExpanded" aria-haspopup="true" v-on:click.stop="toggleMenu">
      {{ text }}
    </a>
    <button
      type="button"
      class="ce-nav-item-dropdown-open d-xl-none"
      :aria-expanded="isExpanded"
      aria-haspopup="true"
      v-on:click="toggleMenu"
    >
      <b-icon icon="chevron-right"></b-icon>
    </button>
    <ul tabindex="-1" class="ce-nav-item-dropdown-menu ce-header-nav-flyout no-bullets" :class="expandedClass">
      <li class="ce-sub-nav-item d-xl-none">
        <button type="button" class="ce-nav-item-dropdown-close" v-on:click="toggleMenu">
          <span class="ce-nav-item-dropdown-close__icon"><b-icon icon="chevron-left"></b-icon></span
          ><span class="ce-nav-item-dropdown-close__text">{{ text }}</span>
        </button>
      </li>
      <slot></slot>
    </ul>
  </li>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CeNavItemDropdown extends Vue {
  /**
   * If no href is provided, this item is simply a wrapper for child menu items.
   */
  @Prop() private href: string | undefined;
  @Prop() private text!: string;
  @Prop() private title: string | undefined;

  isExpanded = false;

  get expandedClass(): string {
    return this.isExpanded ? 'show' : '';
  }

  toggleMenu(): void {
    this.isExpanded = !this.isExpanded;
  }
}
</script>

<style lang="scss">
.ce-nav-item-dropdown {
  > .nav-link {
    flex: 1;
  }

  button {
    border: 0;
    background: transparent;
    color: $clarinet;
  }

  & &-open {
    width: rem(56);
  }

  & &-close {
    display: flex;
    border-bottom: 4px solid $header-highlight-color;
    padding: rem(14) calc($grid-gutter-width / 2) rem(10) 0;
    width: 100%;
    text-align: left;

    &__icon {
      display: block;
      width: rem(56);
      text-align: center;
    }

    &__text {
      color: $header-highlight-color;
    }
  }

  @include media-breakpoint-up('xl') {
    position: relative;
  }
}

.ce-nav-item-dropdown-menu {
  padding: 0;
  min-width: 300px;
  list-style: none;
  font-size: $header-font-size;

  @include media-breakpoint-down('lg') {
    position: absolute !important;
    top: 0;
    right: -100vw;
    transition: right 0.2s ease-in-out;
    z-index: 10;
    width: $header-flyout-width;
    height: 100%;

    &.show {
      right: 0;
    }
  }

  @include media-breakpoint-up('xl') {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: $header-sub-menu-box-shadow;
    background: $white;
    padding-right: $grid-gutter-width;

    .ce-nav-item-dropdown:hover & {
      display: block;
    }
  }
}
</style>
