<template>
  <b-col v-bind="$attrs">
    <b-row v-for="(file, index) in files" :key="index" class="mb-2">
      <b-col>
        <ce-grouped-download-button
          v-if="isDownloadGroup(file)"
          :key="index"
          :downloadGroups="file.files"
          :loadMoreCount="settingsProperty.loadMoreCount"
          @downloadError="propagateError"
          :download-click="downloadClick"
        >
          <template #summary-and-supplements>{{ file.childListName }}</template>
          <template #hide-older>{{ settingsProperty.downloadBlockTranslations.listHide }}</template>
          <template #show-older>{{ settingsProperty.downloadBlockTranslations.listShow }}</template>
        </ce-grouped-download-button>
        <ce-download
          v-else-if="isDownload(file)"
          :url="file.link"
          :key="index"
          @downloadError="propagateError"
          :download-click="() => downloadClick(file.link, file.title, file.fileFormat)"
        >
          <template #text>{{ file.title }}</template>
          <template #fileFormat>{{ getDownloadFileFormat(file) }}</template>
          <template #image v-if="file.previewImageLink">
            <picture>
              <img class="aspect-ratio-filler" :src="file.previewImageLink" />
            </picture>
          </template>
        </ce-download>
        <ce-download
          v-else-if="isSingleDownload(file)"
          :url="file.files[0].link"
          :key="index"
          @downloadError="propagateError"
          :download-click="() => downloadClick(file.files[0].link, file.files[0].title, file.files[0].format)"
        >
          <template #text>{{ file.files[0].title }}</template>
          <template #fileFormat>{{ getDownloadGroupFileFormat(file) }}</template>
        </ce-download>
        <b-button
          v-else-if="isDownloadLink(file)"
          class="p-3 ce-download"
          :href="file.link"
          variant="light"
          :key="index"
          @click="downloadClick(file.link, file.title)"
        >
          <b-row class="no-gutters">
            <b-col cols="2"><ad-icon-link-four-five /></b-col>
            <b-col cols="10" class="text-left">{{ file.title }}</b-col>
          </b-row>
        </b-button>
      </b-col>
    </b-row>
  </b-col>
</template>
<script lang="ts">
import { DownloadLink, Download, DownloadsBlockPayloadSettings } from '@/src/types/episerver-api';
import { DownloadGroupItem } from '@/src/types/vue-api';
import {
  downloadClick,
  isDownloadLink,
  isDownload,
  isDownloadGroup,
  isSingleDownload,
  getDownloadFileFormat,
} from '@/src/utils/document-service';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';

@Component
export default class AdDownloadsItems extends Vue {
  @Prop({ required: true }) files!: (DownloadLink | Download | DownloadGroupItem)[];
  @Inject() settingsProperty!: DownloadsBlockPayloadSettings;

  propagateError(statusCode: number): void {
    this.$emit('downloadError', statusCode);
  }

  isDownload(document: DownloadLink | Download | DownloadGroupItem): document is Download {
    return isDownload(document);
  }

  getDownloadFileFormat(file: Download) {
    return getDownloadFileFormat(file);
  }

  isDownloadLink(document: DownloadLink | Download | DownloadGroupItem): document is DownloadLink {
    return isDownloadLink(document);
  }

  isDownloadGroup(document: DownloadLink | Download | DownloadGroupItem): document is DownloadGroupItem {
    return isDownloadGroup(document);
  }

  isSingleDownload(document: DownloadLink | Download | DownloadGroupItem): document is DownloadGroupItem {
    return isSingleDownload(document);
  }

  getDownloadGroupFileFormat(file: DownloadGroupItem): string | undefined {
    return file.files.length !== 0 ? file.files[0].format?.toUpperCase() : undefined;
  }

  downloadClick(url: string, title: string, format?: string | null | undefined): void {
    downloadClick(url, title, format);
  }
}
</script>
<style lang="scss">
.ad-download-column {
  width: rem(410);
  max-width: 100%;
}
</style>
