<template>
  <ad-collapse-on-mobile v-bind="$attrs" v-if="isVisible">
    <template #header>
      <slot name="block-header"></slot>
    </template>
    <slot name="block-description"></slot>
    <div>
      <slot name="block-button"></slot>
    </div>
    <div v-if="hasNotes" class="mt-6 mt-md-5 mt-lg-6">
      <slot name="notes"></slot>
    </div>
  </ad-collapse-on-mobile>
</template>
<script lang="ts">
import { ProductModel } from '@/src/types/the-q-api';
import { Component, InjectReactive, Prop } from 'vue-property-decorator';
import { Vue } from 'vue-property-decorator';
import { AnyCheckOptions, ProductSubTypeAny, ProductSubTypeBo } from '@/src/types/enumerations';

@Component({
  inheritAttrs: false,
})
export default class AdProductDescription extends Vue {
  @Prop({ required: true }) quantoCheckOption!: AnyCheckOptions;
  @Prop({ required: true }) barrierBreachedOption!: AnyCheckOptions;
  @Prop({ required: true }) europeanUnderlyingOption!: AnyCheckOptions;
  @Prop({ required: true }) ProductSubTypeOption!: ProductSubTypeAny;
  @Prop({ default: false, type: Boolean }) isPageInEditMode!: boolean;
  @InjectReactive() product!: ProductModel | null;

  get isVisible() {
    if (this.product) {
      return (
        ((this.ProductSubTypeOption === ProductSubTypeAny.Any ||
          this.product.subType === ProductSubTypeBo[this.ProductSubTypeOption]) &&
          this.checkOptions(this.quantoCheckOption, this.product.isQuanto) &&
          this.checkOptions(this.barrierBreachedOption, this.product.barrierBreached) &&
          this.checkOptions(this.europeanUnderlyingOption, this.product.isEuropean)) ||
        this.isPageInEditMode
      );
    }

    return this.isPageInEditMode;
  }

  get hasNotes() {
    //Check if there is a note slot defined and it has content in order to adjust the bottom margin
    return this.$scopedSlots.notes && !!this.$scopedSlots.notes({});
  }

  checkOptions(checkOption: AnyCheckOptions, value: boolean | null) {
    switch (checkOption) {
      case AnyCheckOptions.No:
        return !value;
      case AnyCheckOptions.Yes:
        return !!value;
      default:
        return true;
    }
  }
}
</script>
