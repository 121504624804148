<template>
  <b-table-simple striped class="fs-18">
    <b-thead><th>Supports</th><th class="text-right">Resistances</th></b-thead>
    <b-tbody>
      <b-tr><b-td>13,255</b-td><b-td class="text-right">13,425</b-td></b-tr>
      <b-tr><b-td>13,425</b-td><b-td class="text-right">13,425</b-td></b-tr>
      <b-tr><b-td>13,425</b-td><b-td class="text-right">13,425</b-td></b-tr>
      <b-tr><b-td>13,425</b-td><b-td class="text-right">13,425</b-td></b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CeSupportsAndResitancesTable extends Vue {}
</script>
