<template>
  <b-row v-bind="$attrs" class="ade-underlying-teaser-price-data b-skeleton-m">
    <b-col cols="12" class="mr-lg-4 d-flex">
      <h3 class="mb-0">
        <ad-underlying-preheadline-text
          class="fs-12"
          :udp-preheadlines="settingsProperty.udpPreHeadlines"
          :underlying="underlying"
        ></ad-underlying-preheadline-text>
        <slot name="teaser-title"></slot>
      </h3>
      <span class="pl-1 blue"><slot name="info-icon-block"></slot></span>
    </b-col>
    <b-col cols="12" lg="6" class="fs-26 gun-metal"
      ><div>
        <ad-underlying-data-value fieldKey="underlying.last" class="text-nowrap" />
      </div>
    </b-col>
    <b-col sm="6" lg="6" class="mt-2 mt-sm-3 mt-lg-1" v-if="!hideChange">
      <b-row>
        <b-col cols="6" sm="12" lg="12" class="">
          <div class="fs-14 font-weight-bold blue"><slot name="price-change-label"></slot></div>
        </b-col>
        <b-col cols="6" sm="12" lg="12" class="pl-lg-3 pr-sm-0">
          <ad-underlying-data-value fieldKey="underlying.changeAbsolute" />
          / <ad-underlying-data-value fieldKey="underlying.changePercentage" />
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      :sm="hideChange ? 12 : 6"
      :lg="hideChange ? 12 : 6"
      class="mt-2 mt-sm-3 mt-lg-0 ml-lg-0 fs-18 font-weight-light"
    >
      <slot class="mb-0" name="price-indications-label"></slot>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { UnderlyingDetailPageSetting } from '@/src/types/episerver-api';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { Component, Vue, Prop, Inject, InjectReactive } from 'vue-property-decorator';

@Component({})
export default class AdUnderlyingTeaserPriceData extends Vue {
  @Inject()
  settingsProperty?: UnderlyingDetailPageSetting;
  @InjectReactive() underlying!: UnderlyingModel | null;
  @Prop({ default: false, type: Boolean }) hideChange!: boolean;
}
</script>
