<template>
  <keep-alive>
    <b-tr v-if="isValueVisible">
      <b-th class="w-50">
        <slot name="headline"></slot>
      </b-th>
      <b-td class="text-right align-middle w-50">
        <slot
          name="value"
          :setVisible="setVisible"
          :fieldKey="settingsProperty.fieldKey"
          :is-link-to-detail-page="settingsProperty.isLinkToDetailPage"
        ></slot>
      </b-td>
    </b-tr>
  </keep-alive>
</template>
<script lang="ts">
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { InstrumentDataRow } from '@src/types/episerver-api-instrument';
@Component
export default class AdInstrumentDataRow extends Vue {
  @Inject() settingsProperty: InstrumentDataRow | undefined;
  @Prop({ default: false }) showOnEmpty!: boolean;
  private isValueVisible = true;

  public setVisible(hasValue: boolean): void {
    this.isValueVisible = this.settingsProperty?.showOnEmpty || hasValue;
  }
}
</script>
