<template>
  <a class="link btn btn-default ce-author-like-share__share-button flex-grow-1" :href="this.fullUrl" target="_blank">
    <slot name="label">Share Twitter</slot>
  </a>
</template>

<script lang="ts">
import { getTwitterShareIntentURL } from '@/src/utils/url-helper';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CeTwitterButton extends Vue {
  @Prop({ default: '' }) private tweetText!: string;
  @Prop({ default: '' }) private shareURL!: string;
  get fullUrl(): string {
    return getTwitterShareIntentURL({ urlToShare: this.shareURL, textToShare: this.tweetText });
  }
}
</script>
