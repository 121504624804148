<template>
  <ad-skeleton-input :loading="loading">
    <v-select
      class="ad-drop-down-list"
      :components="{ Deselect }"
      v-bind="$attrs"
      transition=""
      :getOptionLabel="getFilterText"
      :clearable="clearable"
      :selectable="isItemSelectable"
      @open="open"
      @close="close"
      :value="value"
      @input="setValue"
      :reduce="(dropDownItem) => dropDownItem.value"
      :multiple="multiple"
      :options="dropdownItems"
      :placeholder="placeholder"
      :required="required"
    >
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          v-form-element-state="formElementStateId"
          v-bind="attributes"
          v-on="events"
          :id="inputId"
          :required="isRequired"
        />
      </template>
      <template #open-indicator="{ attributes }">
        <ad-icon-chevron v-bind="attributes" icon-color="#002d72" size="s" direction="down" />
      </template>
      <template #option="DropDownItem">
        <h6 v-if="isHeader(DropDownItem)" class="m-0">{{ getText(DropDownItem) }}</h6>
        <hr v-else-if="isSplitter(DropDownItem)" class="m-0 hr" />
        <span v-else-if="multiple">
          <b-form-checkbox
            inline
            size="lg"
            :disabled="!(isItemSelectable(DropDownItem) || value == null || value.includes(DropDownItem.value))"
            :checked="value != null && value.includes(DropDownItem.value)"
          >
            {{ getText(DropDownItem) }}
          </b-form-checkbox>
        </span>
        <span v-else> {{ getText(DropDownItem) }}</span>
      </template>
    </v-select>
  </ad-skeleton-input>
</template>

<script lang="ts">
import { DropDownItemType } from '@src/types/enumerations';
import { VNode, CreateElement } from 'vue/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DropDownItem } from '@/src/types/vue-api';
import { createAllOption } from '@/src/utils/dropdown-option-provider';
import { uniqBy } from 'lodash';
import { nameOf } from '@src/utils/table-columns-helper';

@Component({
  inheritAttrs: false,
  data: function (this: Vue) {
    return {
      Deselect: {
        render: (createElement: CreateElement): VNode =>
          createElement('ad-icon-close', {
            attrs: {
              height: '0.625em',
              width: '0.625em',
            },
          }),
      },
    };
  },
})
export default class AdDropDownList extends Vue {
  @Prop() formElementStateId!: string;
  @Prop() inputId!: string;
  @Prop({ default: false }) clearable!: boolean;
  @Prop({ default: false, type: Boolean }) required!: boolean;
  @Prop({ default: null }) value!: unknown | unknown[] | null;
  @Prop({ default: () => true, type: Function }) isSelectable!: (option: DropDownItem) => true;
  @Prop({ default: false, type: Boolean }) multiple!: boolean;
  @Prop({ required: true }) options!: Array<DropDownItem>;
  @Prop({ type: Number }) maxSelectableItems: number | undefined | null;
  @Prop({ default: false, type: Boolean }) loading!: boolean;

  private currentOptions: Array<DropDownItem> | null = null;
  private formGroupElement: HTMLElement | undefined | null;
  private isOpen = false;

  get dropdownItems(): DropDownItem[] {
    const allLabel = this.getAlItemsLabelText();
    this.currentOptions = [];
    this.currentOptions = [...this.options];

    if (allLabel && !this.multiple) {
      createAllOption(this.currentOptions, allLabel);
    }

    return uniqBy(this.currentOptions, nameOf<DropDownItem>('value'));
  }

  get isRequired() {
    return this.required && (this.value === null || (this.value as unknown[]).length === 0);
  }

  get placeholder(): string | null | undefined {
    const slot = this.$slots['multiple-placeholder'];
    return slot && slot.length && this.multiple ? slot[0].text?.trim() : null;
  }

  private getAlItemsLabelText(): string | null | undefined {
    const slot = this.$slots['all-items-label'];
    return slot && slot.length ? slot[0].text : null;
  }

  setValue(newValue: string | null): void {
    this.$emit('input', newValue);
    this.$emit('selected-value-changed');
  }

  @Watch('mutiple')
  @Watch('value')
  validateItem() {
    this.formGroupElement = document.getElementById(this.formElementStateId);
    if (this.formGroupElement && !this.isOpen) {
      if (this.isRequired) {
        this.formGroupElement.classList.add('error');
      } else {
        this.formGroupElement.classList.remove('error');
      }
    }
  }

  isHeader(item: DropDownItem): boolean {
    return item.type == DropDownItemType.header;
  }

  isSplitter(item: DropDownItem): boolean {
    return item.type == DropDownItemType.splitter;
  }

  isItem(item: DropDownItem): boolean {
    return item.type == DropDownItemType.item;
  }

  isItemSelectable(item: DropDownItem): boolean {
    return this.isItem(item) && this.isMaxSelectableItemIsNotReached() && this.isSelectable(item);
  }

  isMaxSelectableItemIsNotReached() {
    return this.maxSelectableItems ? ((this.value as unknown[])?.length ?? 0) < this.maxSelectableItems : true;
  }

  open() {
    this.isOpen = true;
    if (this.formGroupElement) {
      this.formGroupElement.classList.remove('error');
    }
  }

  close() {
    this.isOpen = false;
    this.validateItem();
  }

  /**
   * Returns text to be rendered on option for items and headers
   * @param {DropDownItem} option
   */
  getText(option: DropDownItem): string | number | undefined {
    if (option?.type !== DropDownItemType.splitter) return option?.text || option?.value || '';
    else return undefined;
  }

  /**
   * Returns text for filtering. Only selectable items should be returned
   * @param {DropDownItem} option
   */
  getFilterText(option: DropDownItem): string | null | undefined {
    if (option?.type === DropDownItemType.item) return option.text;
    else return undefined;
  }
}
</script>

<style lang="scss">
$select-space: rem(10);

.ad-drop-down-list {
  margin-right: -$select-space;
  margin-left: -$select-space;
  padding-right: $select-space;

  /*
    Input styling
  */
  /* stylelint-disable-next-line */
  .vs__dropdown-toggle {
    border-style: none;
    padding: 0;

    /* stylelint-disable-next-line */
    .vs__selected-options {
      margin-top: -$select-space;
      font-size: $fs-14;

      /* stylelint-disable-next-line */
      input {
        margin-top: $select-space;
        margin-left: rem(7);
        padding: 0;
        font-size: $input-font-size;
      }

      /* stylelint-disable-next-line */
      > .vs__selected {
        opacity: 1;
        z-index: 2;
        margin-top: 0;
        margin-top: $select-space;
        margin-left: $select-space;
        border-style: none;
        padding: 0;
        padding-right: $select-space * 3;
        padding-left: $select-space;
        max-width: calc(100% - 20px); // fix to prevent input field shifting to next line
        color: $white;
        font-size: $input-font-size;
      }
    }
  }

  &.vs--multiple {
    /* stylelint-disable-next-line */
    .vs__selected {
      display: block;
      position: relative;
      height: 23px;
    }
  }

  &.vs--single {
    /* stylelint-disable-next-line */
    .vs__selected-options {
      flex-wrap: nowrap;
      overflow: hidden;
      white-space: nowrap;
      font-size: $input-font-size;

      /* stylelint-disable-next-line */
      > .vs__selected {
        padding-left: 0;
        color: $input-color;
      }
    }
  }

  &.vs--open {
    /* stylelint-disable-next-line */
    .vs__search {
      &,
      &:focus {
        position: initial;
      }
    }
  }

  /* stylelint-disable-next-line */
  .vs__deselect {
    position: absolute;
    top: calc(50% - #{$select-space / 2});
    right: $select-space;
    color: $white;
  }

  /* stylelint-disable-next-line */
  .vs__clear {
    position: relative;
    top: -$select-space;
    height: $select-space;
  }

  /* stylelint-disable-next-line */
  .vs__actions {
    cursor: pointer;
    padding-bottom: 5px;
  }
  /*
    Dropdown styling
  */
  /* stylelint-disable-next-line */
  > .vs__dropdown-menu {
    margin-top: -4px;
    border: solid 2px $peacock-blue;
    border-top-style: none;
    border-radius: 0 0 0.4rem 0.4rem;
    background-color: $white;
    padding-bottom: 15px;
    overflow-x: hidden;

    li:first-child {
      border-top: 1px $gunmetal dashed;
      padding-top: 20px;
    }

    h6 {
      color: $marine-blue;
      font-weight: bold;
    }
  }

  /* stylelint-disable-next-line */
  .vs__dropdown-option {
    padding: 5px 10px;
    white-space: normal;
  }
  /* stylelint-disable-next-line */
  .vs__dropdown-option--selected {
    color: $peacock-blue;
  }
  /* stylelint-disable-next-line */
  .vs__dropdown-option--highlight {
    font-weight: bold;
  }

  /* stylelint-disable-next-line */
  .vs__dropdown-option--disabled {
    color: $light-blue-grey;
  }

  /* stylelint-disable-next-line */
  .vs__open-indicator {
    color: $marine-blue;
    font-size: small;
  }
}

.focus {
  .ad-drop-down-list {
    /* stylelint-disable-next-line */
    .vs__selected {
      opacity: 1;
      color: $marine-blue;
      font-size: $input-font-size;
    }
  }
}
.form-group {
  .ad-drop-down-list {
    /* stylelint-disable-next-line */
    .vs__actions {
      position: relative;
      top: rem(-8);
    }
  }
}
</style>
