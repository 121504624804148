import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import {
  checkForAllElementsIfAppearanceShouldTrigger,
  startCheckingAppearance,
  stopCheckingAppearance,
} from './helper/viewportChecker';

/**
 * Use this directive to attach callbacks to the point in time, where an element enters the viewport
 * for the first time or on consecutive times.
 * "Enters the viewport" means "at least 50% of the elements height is currently on the viewport"
 *
 * Example:
 *
 * <div v-on-viewport-enter="{ firstAppearanceHandler: onFirstEnter, appearanceHandler: onEveryEnter }">
 *   Do something when I enter the viewport
 * </div>
 */

Vue.directive('on-viewport-enter', {
  inserted: (el: HTMLElement, binding: DirectiveBinding) => {
    startCheckingAppearance({
      el,
      appearanceHandler: binding.value.appearanceHandler,
      firstAppearanceHandler: binding.value.firstAppearanceHandler,
    });

    // First check should be delayed so that the initial mount is also animated when using CSS animations.
    setTimeout(() => {
      checkForAllElementsIfAppearanceShouldTrigger();
    }, 0);
  },

  unbind: (el: HTMLElement) => {
    stopCheckingAppearance(el);
  },
});
