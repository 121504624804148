<template>
  <ad-icon-base viewBox="0 0 40 40" v-bind="$attrs">
    <path
      d="M23.3,17.3c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8v15.2c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8  V17.3z M15,17.3c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v15.2c0,0.5-0.4,0.8-0.8,0.8S15,33,15,32.5V17.3z M36.7,10H3.3V8.3  c0-0.9,0.7-1.7,1.7-1.7h30c0.9,0,1.7,0.7,1.7,1.7V10z M17.1,1.7h5.8c1.8,0,3.3,1.5,3.3,3.3H13.8C13.8,3.2,15.2,1.7,17.1,1.7z M35,5  h-7.1c0-2.8-2.2-5-5-5h-5.8c-2.8,0-5,2.2-5,5H5C3.2,5,1.7,6.5,1.7,8.3v2.5c0,0.5,0.4,0.8,0.8,0.8h2.8l2.4,26.8  C7.8,39.3,8.5,40,9.4,40h21.9c0.9,0,1.6-0.7,1.7-1.5l2.4-26.8h2.2c0.5,0,0.8-0.4,0.8-0.8V8.3C38.3,6.5,36.8,5,35,5z M31.3,38.3H9.4  L7,11.7h26.7L31.3,38.3z"
    />
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class AdIconDelete extends Vue {}
</script>
