<template>
  <ad-error :http-status-code="statusCode" v-if="isVisible" :key="settingsProperty.productType">
    <b-form id="product-search-page" novalidate>
      <b-container class="content-container mb-8 content-container-t">
        <b-row>
          <b-col>
            <h1><slot name="headline"></slot></h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" lg="5">
            <ad-best-match-search-filter-container
              class="alert mb-6 alert-light"
              v-model="filter"
              :best-match-metadata-result-model="bestMatchMetadataResultModelItem"
              @best-match-button-click="onBestMatchValueClick"
              @product-type-changed="productFilterChanged"
              @underlying-changed="loadUnderlyingsAsync"
            >
              <template #filter-headline>
                <slot name="filter-headline">Placeholder filter headline</slot>
              </template>
              <template #filter-product-type-dropdown-label-text>
                <slot name="filter-product-type-dropdown-label-text">Placeholder product type label</slot>
              </template>

              <template #filter-subtype-label-both>
                <slot name="filter-subtype-label-both"></slot>
              </template>

              <template #filter-underlying-dropdown-label-text>
                <slot name="filter-underlying-dropdown-label-text">Dropdown label</slot>
              </template>

              <template #filter-show-best-match-button-text>
                <slot name="filter-show-best-match-button-text">[filter show best match button text placeholder]</slot>
              </template>

              <template #maturity-date-filter="{ onFilterValueChanged, availableValues }">
                <slot
                  name="maturity-date-filter"
                  :availableValues="availableValues"
                  :currentSearchFilter="filter"
                  :onFilterValueChanged="onFilterValueChanged"
                ></slot>
              </template>

              <template
                #additional-numeric-range-filter-elements="{
                  onFilterValueChanged,
                  isNumericFilterVisible,
                  availableValues,
                  isLoading,
                }"
              >
                <slot
                  name="additional-numeric-range-filter-elements"
                  :availableValues="availableValues"
                  :currentSearchFilter="filter"
                  :onFilterValueChanged="onFilterValueChanged"
                  :required="true"
                  :isVisibleFunc="isNumericFilterVisible"
                  :isLoading="isLoading"
                ></slot>
              </template>

              <template #go-to-full-seach-button-text>
                <slot name="go-to-full-seach-button-text">[go to full seach button text]</slot>
              </template>
            </ad-best-match-search-filter-container>
          </b-col>
          <b-col md="6" lg="4">
            <slot name="underlying-teaser"></slot>
            <div class="d-none d-md-block d-lg-none">
              <slot name="explanatory-text"> </slot>
            </div>
          </b-col>
          <b-col class="d-md-none d-lg-block"><slot name="explanatory-text"> </slot></b-col>
        </b-row>
      </b-container>
      <div
        v-if="settingsProperty.isInEditMode || ($screen.sm && bestMatchSearchResultModel)"
        class="float-right content-container-y"
      >
        <b-button variant="light" v-if="settingsProperty.isInEditMode || $screen.xl" @click="excelExport">
          <ad-icon-xls /><slot name="button-excel-export-text">Excel Export</slot>
        </b-button>
      </div>
      <b-container
        v-if="bestMatchSearchResultModel"
        class="content-container content-container-x my-0"
        id="table-search-top"
      >
        <h3> <slot name="search-headline">[Your Best Match Results]</slot></h3>
      </b-container>
      <b-container fluid class="mb-7 mb-md-8 mt-5">
        <b-row>
          <b-col>
            <ad-error :http-status-code="statusCodeTable">
              <ad-best-match-search-table
                :loading="isTableLoding"
                :sort-orders="currentSorting"
                :best-match-search-result-model="bestMatchSearchResultModel"
                :filter="filter"
                :refresh-trigger="refreshTrigger"
                ref="table"
              >
                <template #product-detail-page-teaser>
                  <slot name="product-detail-page-teaser" />
                </template>
                <template #table-show-more-item-text>
                  <slot name="table-show-more-item-text">[show 10 more placeholder]</slot>
                </template>
              </ad-best-match-search-table>
            </ad-error>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </ad-error>
</template>
<script lang="ts">
import {
  BestMatchSearchRequestModel,
  BestMatchSearchResultModel,
  BestMatchMetadataResultModel,
  BestMatchExcelRequestModel,
  SortOrder,
  UnderlyingModel,
} from '@/src/types/the-q-api';
import { BestMatchSearchBlockSetting, PageRoutes } from '@/src/types/episerver-api';
import { Component, Inject, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import AdBestMatchSearchTable from './ad-best-match-search-table.vue';
import { getTheQApiUrl, tryCreateBestMatchSearchLink } from '@/src/utils/url-helper';
import { Controller, HttpStatusCodes, ProductKey, ProductTypeBo, SortingDirection } from '@/src/types/enumerations';
import { downloadFilePost } from '@/src/utils/download-file';
import { parseQueryObject } from '@/src/utils/query-param-helper';
import qs from 'qs';
import { BestMatchSearchRequestFilter } from '@/src/types/vue-api';
import { Action, Getter } from 'vuex-class';
import { isAxiosError } from '@/src/types/type-guards';
import { setBestMatchSearchFilterForRequest } from '@/src/utils/instrument-search-helper';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';

@Component({
  components: {
    AdBestMatchSearchTable,
  },
})
export default class AdBestMatchSearch extends Vue {
  @Inject() settingsProperty!: BestMatchSearchBlockSetting;
  @Inject() pageRoutes!: PageRoutes;
  @Ref() table;
  @Prop({ required: true }) productType!: ProductTypeBo;
  @Prop({ required: true }) pageLink!: string;

  @Getter('bestMatchSearchResultModel', { namespace: 'bestMatch' })
  bestMatchSearchResultModel!: BestMatchSearchResultModel | null;

  @Getter('bestMatchMetadataResultModel', { namespace: 'bestMatch' })
  bestMatchMetadataResultModel!: (filter: BestMatchSearchRequestModel) => BestMatchMetadataResultModel | null;

  @Getter('teaserUnderlyings', { namespace: 'underlying' })
  teaserUnderlyings!: Array<UnderlyingModel> | undefined;
  @Action('loadBestMatchMetadataResultModelAsync', { namespace: 'bestMatch' })
  loadBestMatchMetadataResultModelAsync!: (filter: BestMatchSearchRequestModel) => Promise<void>;

  @Action('loadBestMatchSearchResultModelAsync', { namespace: 'bestMatch' })
  loadBestMatchSearchResultModelAsync!: (filter: BestMatchSearchRequestModel) => Promise<void>;

  @Action('loadTeaserUnderlyingsAsync', { namespace: 'underlying' })
  loadTeaserUnderlyingsAsync!: (isin: string[] | string) => Promise<void>;

  @Action('clearTeaserUnderlyings', { namespace: 'underlying' })
  clearTeaserUnderlyings!: () => void;

  private filter = new BestMatchSearchRequestFilter();
  private currentSorting: Array<SortOrder> = [];
  private refreshTrigger = 0;
  private isTableLoading = false;
  statusCode: number | HttpStatusCodes = HttpStatusCodes.OK;
  statusCodeTable: number | HttpStatusCodes = HttpStatusCodes.OK;

  get isVisible() {
    return this.productType == this.settingsProperty.productType;
  }

  get bestMatchMetadataResultModelItem() {
    return this.bestMatchMetadataResultModel(this.filter);
  }

  async onBestMatchValueClick(): Promise<void> {
    await this.loadBestMatchResultAsync();
  }

  async created(): Promise<void> {
    this.setFilterDefaults();
    this.setFilterFromUrl();
    await this.loadBestMatchMetaWithErrorHandlingAsync();

    if (this.filter.underlyingIsin) {
      await this.loadUnderlyingsAsync();
    }
    if (this.filter.keyDataField && this.filter.keyDataValue) await this.loadBestMatchResultAsync();
  }

  async loadBestMatchResultAsync() {
    await this.loadBestMatchResultWithErrorHandlingAsync();
    this.updateSortOrder();
    this.refreshTrigger++;
  }

  async loadBestMatchMetaWithErrorHandlingAsync() {
    try {
      await this.loadBestMatchMetadataResultModelAsync(this.filter);
    } catch (error: unknown) {
      this.$log.error('Loading Error', error);

      if (isAxiosError(error)) {
        this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
      }
    }
  }

  async loadBestMatchResultWithErrorHandlingAsync() {
    this.isTableLoading = true;
    this.statusCodeTable = HttpStatusCodes.OK;

    try {
      await this.loadBestMatchSearchResultModelAsync(this.filter);
    } catch (error: unknown) {
      this.$log.error('Loading Error', error);

      if (isAxiosError(error)) {
        this.statusCodeTable = error?.response?.status ?? HttpStatusCodes.UnknownError;
      }
    }

    this.isTableLoading = false;
  }

  updateSortOrder() {
    this.currentSorting = [
      {
        key: ProductKey.UnderlyingName,
        direction: SortingDirection.Ascending,
      } as SortOrder,
    ];

    if (this.filter.maturityDate)
      this.currentSorting.push({
        key: ProductKey.MaturityDate,
        direction: SortingDirection.Ascending,
      } as SortOrder);

    this.currentSorting.push({
      key: this.filter.keyDataField ?? '',
      direction: SortingDirection.Ascending,
    });
  }

  async loadUnderlyingsAsync() {
    if (this.filter.underlyingIsin !== null) {
      await this.loadTeaserUnderlyingsAsync(this.filter.underlyingIsin);
    } else {
      this.clearTeaserUnderlyings();
    }
  }

  async onUnderlyingChangedAsync() {
    await this.loadUnderlyingsAsync();
  }

  excelExport() {
    const url = getTheQApiUrl(Controller.BestMatch, 'Excel');
    this.trackExport(url);
    downloadFilePost(url, this.buildExcelRequest(), 'BestMatchSearch.xlsx');
  }

  trackExport(url: string) {
    trackEvent(GaEvent.ClickDownload, {
      displayName: 'BestMatchSearch.xlsx',
      extension: 'xlsx',
      url: url,
    });
  }

  buildExcelRequest(): BestMatchExcelRequestModel {
    setBestMatchSearchFilterForRequest(this.filter);

    const excelFilter = {
      searchRequest: this.filter,
      columnList: this.settingsProperty.excelExportColumnSettings,
    } as BestMatchExcelRequestModel;

    return excelFilter;
  }

  productFilterChanged(value: ProductTypeBo): void {
    this.$emit('update-product-type', value);
  }

  @Watch('productType')
  async productTypeChanged() {
    if (this.isVisible) {
      this.validateUnderlyingAndUpdateUrl();
      this.updateUrl();
      this.updateTeaserItemIfChanged();
    }
  }

  @Watch('filter', { deep: true })
  async filterChanged() {
    if (this.isVisible) {
      this.validateUnderlyingAndUpdateUrl();
      this.updateUrl();
    }

    this.updateTeaserItemIfChanged();
  }

  async validateUnderlyingAndUpdateUrl() {
    // check if underlying is still set, otherwise it is cleared by available values in ad-underlying-list
    if (
      this.bestMatchMetadataResultModelItem?.availableValues.underlyingNames &&
      (this.filter.underlyingIsin === null ||
        !this.bestMatchMetadataResultModelItem?.availableValues.underlyingNames[this.filter.underlyingIsin])
    ) {
      if (
        this.settingsProperty.underlyingDefaultIsin &&
        this.bestMatchMetadataResultModelItem?.availableValues.underlyingNames[
          this.settingsProperty.underlyingDefaultIsin
        ]
      ) {
        this.filter.underlyingIsin = this.settingsProperty.underlyingDefaultIsin;
      } else {
        this.filter.underlyingIsin =
          Object.keys(this.bestMatchMetadataResultModelItem?.availableValues.underlyingNames)[0] ?? null;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await this.loadTeaserUnderlyingsAsync(this.filter.underlyingIsin!);
    }
  }

  async updateTeaserItemIfChanged() {
    // check if displayed teaser underlying is still as underlying in dropdown, when product type and set visible window has changed
    if (
      this.teaserUnderlyings?.length === 1 &&
      this.filter.underlyingIsin != null &&
      this.filter.underlyingIsin !== this.teaserUnderlyings[0].isin
    ) {
      await this.loadTeaserUnderlyingsAsync(this.filter.underlyingIsin);
    }
  }

  updateUrl() {
    this.$nextTick(() =>
      window.history.pushState({}, window.document.title, tryCreateBestMatchSearchLink(this.pageLink, this.filter))
    );
  }

  private setFilterDefaults(): void {
    this.filter.productType = this.settingsProperty.productType;
    this.filter.filterProperty = this.settingsProperty.numericFilters[0].filterProperty;
    this.filter.numberOfItems = this.settingsProperty.loadMaxItems;

    if (this.settingsProperty.underlyingDefaultIsin) {
      this.filter.underlyingIsin = this.settingsProperty.underlyingDefaultIsin;
    }
  }

  private setFilterFromUrl(): void {
    const filter = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (filter) {
      const parsedFilter = parseQueryObject(filter) as BestMatchSearchRequestFilter;

      if (parsedFilter.productType == this.settingsProperty.productType) {
        this.$emit('update-product-type', parsedFilter.productType);
        parsedFilter.productType = this.settingsProperty.productType;

        this.filter = Object.assign(this.filter, parsedFilter);
      }
    }
  }
}
</script>
