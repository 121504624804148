<script lang="ts">
import { VNode } from 'vue';
import { Component, Prop, Vue, ProvideReactive } from 'vue-property-decorator';
import { UnderlyingType } from '@/src/types/enumerations';
import { ProductModel, UnderlyingModel } from '@/src/types/the-q-api';
import { Getter } from 'vuex-class';

@Component({})
export default class AdUnderlyingProductDataProperty extends Vue {
  @ProvideReactive()
  get underlying(): UnderlyingModel {
    return this.product?.underlyings?.find((u) => u.underlyingType === this.underlyingType) || ({} as UnderlyingModel);
  }

  @Prop({ default: UnderlyingType.Default }) underlyingType!: UnderlyingType;

  @Getter('product', { namespace: 'product' })
  product!: ProductModel | null;

  render(): VNode[] | VNode | undefined {
    return this.$scopedSlots.default?.({});
  }
}
</script>
