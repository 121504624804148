<template>
  <div class="ce-grouped-download-button">
    <b-collapse
      v-for="(chunk, indexOfChunk) in groupChunks"
      :key="indexOfChunk"
      :visible="expandedChunks >= indexOfChunk"
    >
      <div
        v-for="(group, index) in chunk"
        :key="index"
        class="ce-grouped-download-button--group"
        :class="{ expanded: openGroupIndices.has(getGlobalIndex(index, indexOfChunk)) }"
      >
        <a
          @click.prevent="handleDownloadClick(group)"
          :href="group.link"
          class="ce-grouped-download-button--group-link"
          :class="{ '--has-children': !!group.childDownloads }"
        >
          <span class="ce-grouped-download-button--group-icon">
            <ad-icon-download></ad-icon-download>
          </span>
          <span class="ce-grouped-download-button--group-text-container">
            <span class="ce-grouped-download-button--group-title">{{ group.title }}</span>
            <span class="ce-grouped-download-button--group-file-type fs-14">{{ group.format }}</span>
          </span>
        </a>
        <button
          v-if="group.childDownloads"
          class="ce-grouped-download-button--group-button"
          @click="toggle(getGlobalIndex(index, indexOfChunk))"
        >
          <ad-icon-chevron width="12"></ad-icon-chevron>
          <slot name="summary-and-supplements">Summary and supplements</slot>
        </button>
        <b-collapse
          class="ce-grouped-download-button--child-groups"
          :visible="openGroupIndices.has(getGlobalIndex(index, indexOfChunk))"
        >
          <template v-for="(childGroup, index) in group.childDownloads">
            <a
              :key="index"
              @click.prevent="handleDownloadClick(childGroup)"
              :href="childGroup.link"
              class="ce-grouped-download-button--group-link level-1"
              :class="{ '--has-children': !!childGroup.childDownloads }"
            >
              <span class="ce-grouped-download-button--group-icon">
                <ad-icon-download></ad-icon-download>
              </span>
              <span class="ce-grouped-download-button--group-text-container">
                <span class="ce-grouped-download-button--group-title">{{ childGroup.title }}</span>
                <span class="ce-grouped-download-button--group-file-type fs-14">{{ childGroup.format }}</span>
              </span>
            </a>
            <a
              v-for="(group, childIndex) in childGroup.childDownloads"
              :key="childIndex + '' + index"
              @click.prevent="handleDownloadClick(group)"
              :href="group.link"
              class="ce-grouped-download-button--group-link level-2"
            >
              <span class="ce-grouped-download-button--group-icon">
                <ad-icon-download></ad-icon-download>
              </span>
              <span class="ce-grouped-download-button--group-text-container">
                <span class="ce-grouped-download-button--group-title">{{ group.title }}</span>
                <span class="ce-grouped-download-button--group-file-type fs-14">{{ group.format }}</span>
              </span>
            </a>
          </template>
        </b-collapse>
      </div>
    </b-collapse>

    <button
      v-if="groupChunks.length > 1"
      @click="showOlder()"
      class="ce-grouped-download-button--show-more-button"
      :class="{ 'shown-all': hasShownAll }"
    >
      <span v-if="hasShownAll"><slot name="hide-older">Hide older</slot></span>
      <span v-else><slot name="show-older">Show older</slot></span>
      <ad-icon-chevron width="12"></ad-icon-chevron>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import chunk from 'lodash/chunk';
import { GroupedDownloadGroupItem } from '@/src/types/vue-api';
import { downloadFileGetOrOpenInNewWindowForExternalUrl } from '@src/utils/download-file';
import { HttpStatusCodes } from '@src/types/enumerations';

@Component
export default class CeGroupedDownloadButton extends Vue {
  @Prop({ required: true }) downloadGroups!: GroupedDownloadGroupItem[];
  @Prop({ type: Number }) loadMoreCount!: number;
  /* eslint-disable @typescript-eslint/no-empty-function */
  @Prop({
    default: () => {},
    type: Function,
  })
  downloadClick!: (url: string, title: string, format: string) => void;
  /* eslint-enable @typescript-eslint/no-empty-function */

  // First chunk (the main download group) is always visible
  expandedChunks = 0;

  openGroupIndices = new Set<number>();

  get groupChunks() {
    // Sort newest to oldest
    const sorted = [...this.downloadGroups].sort((a, b) => (a.date < b.date ? 1 : -1));
    const [first, ...remaining] = sorted;

    const remainingChunks = chunk(remaining, this.loadMoreCount);
    return [[first], ...remainingChunks];
  }

  get hasShownAll() {
    return this.expandedChunks >= this.groupChunks.length - 1;
  }

  getGlobalIndex(indexInChunk: number, indexOfChunk: number) {
    if (indexOfChunk === 0) return indexInChunk;
    return 1 + indexInChunk + 3 * (indexOfChunk - 1);
  }

  toggle(globalIndex: number) {
    if (this.openGroupIndices.has(globalIndex)) {
      this.openGroupIndices.delete(globalIndex);
    } else {
      this.openGroupIndices.add(globalIndex);
    }
    // Set operations need force update
    this.$forceUpdate();
  }

  showOlder() {
    if (this.hasShownAll) this.expandedChunks = 0;
    else this.expandedChunks++;
  }

  async handleDownloadClick(groupItem: GroupedDownloadGroupItem) {
    this.downloadClick(groupItem.link, groupItem.title, groupItem.format);

    const result = await downloadFileGetOrOpenInNewWindowForExternalUrl(groupItem.link);

    if (result !== HttpStatusCodes.OK) {
      this.$emit('downloadError', result);
    }
  }
}
</script>

<style lang="scss">
.ce-grouped-download-button {
  display: inline-flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background: $silver;
  width: rem(410);
  max-width: 100%;
  overflow: hidden;

  &--group {
    display: flex;
    flex-direction: column;
    transition: background 0.15s;
    border-bottom: 1px solid $peacock-blue;

    &.expanded {
      background: $light-grey;
    }
  }

  &--group-link {
    display: flex;
    align-items: center;
    padding: map-get($spacers, 3) map-get($spacers, 3);
    color: $peacock-blue;

    &:hover {
      background: $peacock-blue;
      color: $white;

      svg {
        color: $white;
      }
    }

    &.--has-children {
      border-bottom: 1px dashed $peacock-blue;
    }

    &.level-1:first-of-type {
      padding-top: map-get($spacers, 2);
    }

    &.level-2 {
      border-bottom: 1px dashed $peacock-blue;
      padding-left: map-get($spacers, 6);
    }
  }

  &--group-icon {
    display: flex;

    flex: 0 0 auto;
    width: 2.5rem;
    color: $clarinet;
  }

  &--group-text-container {
    display: flex;
    flex-direction: column;
  }

  &--group-button {
    border: 0;
    background: inherit;
    padding: map-get($spacers, 3) map-get($spacers, 3);
    text-align: left;
    color: $peacock-blue;

    &:focus {
      outline: none;
    }

    &:hover {
      background: $peacock-blue;
      color: $white;

      svg {
        color: $white;
      }
    }

    svg {
      transition: transform 0.15s;
      margin-right: map-get($spacers, 1);
      color: $clarinet;

      .expanded & {
        transform: rotate(180deg);
      }
    }
  }

  &--show-more-button {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    border: 0;
    background: inherit;
    padding: map-get($spacers, 5);
    color: $bright-sky-blue;

    &:focus {
      outline: none;
    }

    &:hover {
      color: $teal;
    }

    svg {
      position: absolute;
      top: 55%;
      transition: transform 0.15s;
    }
  }

  &--show-more-button.shown-all svg {
    transform: rotate(180deg);
  }
}
</style>
