<template>
  <div class="accordion">
    <b-card class="accordion__header important py-4" no-body v-for="(h, i) in headers" v-bind:key="i">
      <b-card-header class="bg-transparent border-0 p-0">
        <div class="outline-none d-flex align-items-center" v-b-toggle="'acc-' + i">
          <ad-icon-chevron class="mr-3 fs-12 accordion-collapse-indicator" direction="down" />
          <div class="accordion__header__big-text fs-26"><ce-node-renderer :node="h" /></div>
        </div>
      </b-card-header>
      <b-collapse
        :id="'acc-' + i"
        accordion="ce-acc"
        :visible="visibleAccordionIndex !== null && visibleAccordionIndex === i"
      >
        <b-card-body class="accordion__content px-0 fs-20"><ce-node-renderer :node="contents[i]" /></b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script lang="ts">
import Vue, { VNode } from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { filterVNodes } from '@src/utils/vnodes-filter';

@Component
export default class CeAccordion extends Vue {
  @Prop({ default: null, type: Number })
  visibleAccordionIndex!: number | null;

  get headers(): VNode[] {
    return filterVNodes(this.$slots.header);
  }

  get contents(): VNode[] {
    return filterVNodes(this.$slots.content);
  }
}
</script>

<style lang="scss">
.accordion {
  &__header {
    &.important {
      border: 1px solid $peacock-blue;
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      box-shadow: none;
      background-color: $white;
      color: $peacock-blue;
    }
    &__small-text {
      color: $peacock-blue;
    }

    &__big-text {
      color: $peacock-blue;
    }

    .not-collapsed &__big-text,
    .card-header:hover &__big-text {
      color: $marine-blue;
    }

    .card-header:hover {
      .accordion-collapse-indicator {
        color: $bright-sky-blue;
      }
    }
  }
  .accordion-collapse-indicator {
    transition-duration: 0.5s;
    color: $clarinet;
  }
  .not-collapsed {
    .accordion-collapse-indicator {
      transform: rotate(180deg);
    }
  }
  .outline-none {
    outline: none;
  }
  &__content {
    color: $gunmetal;
  }
}
</style>
