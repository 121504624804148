<template>
  <ad-skeleton-table :loading="loading || items == null" :columns="columns" :rows="rows">
    <b-table
      striped
      borderless
      no-provider-paging
      no-provider-filtering
      no-local-sorting
      v-bind="$attrs"
      :hover="false"
      :items="items"
      :fields="fields"
      class="sticky-nav-table instrument-search-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @sort-changed="sortingChanged"
      @row-clicked="rowClicked"
      :selectable="selectable"
      no-select-on-click
      ref="table"
    >
      <template #empty>
        <slot name="empty"></slot>
      </template>
      <template #emptyfiltered>
        <slot name="empty"></slot>
      </template>
      <template #top-row v-if="$scopedSlots['top-row']">
        <slot name="top-row" :field-count="fields.length"></slot>
      </template>
      <template v-slot:head()="data">
        <template v-if="data.label">
          <span class="d-inline-block" v-bind:class="{ active: data.field.sortNumber > 0 }"
            ><span class="text-normal" v-html="data.label"></span>
          </span>
          <div class="sorting"></div>
          <sup v-if="data.field.sortNumber > 0">{{ data.field.sortNumber }}</sup>
        </template>
        <template v-else-if="data.field.mergeColumnSettings">
          <span v-for="(setting, index) in data.field.mergeColumnSettings" :key="index">
            <template v-if="setting.separator == separator.NewLine">
              <br />
            </template>
            <template v-else-if="setting.separator == separator.Slash"> / </template>
            <template v-else><span v-html="setting.headline"></span></template>
          </span>
        </template>
        <template v-else><slot name="row-custom-header" :data="data"> &nbsp; </slot></template>
      </template>
      <template v-slot:cell()="data">
        <template v-if="data.field.mergeColumnSettings">
          <ad-column-visible-wrapper
            :class="['overflow-hidden', { 'text-truncate': data.field.isEllipsis }]"
            :index="data.index"
            :fields="fields"
            :field="data.field"
            @visibleChange="visibleChange"
          >
            <span v-for="(setting, index) in data.field.mergeColumnSettings" :key="index">
              <template v-if="setting.separator == separator.NewLine">
                <br />
              </template>
              <template v-else-if="setting.separator == separator.Slash"> / </template>
              <template v-else>
                <slot name="row-instrument" :setting="setting" :instrument="data.item.instrument">
                  {{ setting.key }}
                </slot>
              </template>
            </span>
          </ad-column-visible-wrapper>
        </template>
        <template v-else-if="data.field.setting">
          <ad-column-visible-wrapper
            :index="data.index"
            :fields="fields"
            :field="data.field"
            :class="['overflow-hidden', { 'text-truncate': data.field.isEllipsis }]"
            @visibleChange="visibleChange"
          >
            <slot name="row-instrument" :setting="data.field.setting" :instrument="data.item.instrument">
              {{ data.field.key }}
            </slot>
          </ad-column-visible-wrapper>
        </template>
        <template v-else-if="data.field.key === watchListKey">
          <slot name="row-watch-list" :field="data.field" :instrument="data.item.instrument">
            {{ data.field }}
          </slot>
        </template>
        <template v-else>
          <ad-column-visible-wrapper
            :index="data.index"
            :fields="fields"
            :field="data.field"
            :class="['overflow-hidden', { 'text-truncate': data.field.isEllipsis }]"
            @visibleChange="visibleChange"
          >
            <slot name="row-custom-instrument" :field="data.field" :instrument="data.item.instrument">
              {{ data.field }}
            </slot>
          </ad-column-visible-wrapper>
        </template>
      </template>
      <template #cell(showDetails)="row" v-if="isDetailRow">
        <b-button squared size="sm" variant="l" @click="toggleDetails(this, row)">
          <ad-icon-chevron :direction="row.detailsShowing ? direction.up : direction.down" size="m" class="clarinet" />
        </b-button>
      </template>
      <template #row-details="data" v-if="isDetailRow">
        <slot name="row-details" :data="data">Table details</slot>
      </template>
    </b-table>
  </ad-skeleton-table>
</template>

<script lang="ts">
import { Direction, Separator, SortingDirection, TextAlign } from '@t/enumerations';
import {
  InstrumentMergeColumnSetting,
  InstrumentTableColumnSetting,
  PageRoutes,
  TableColumnSettingBase,
} from '@t/episerver-api';
import { ProductModel, SortOrder, UnderlyingModel } from '@t/the-q-api';
import { Component, Inject, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { InstrumentTableSettingData, TableField } from '@t/vue-api';
import { BRow, BTable, BvTableCtxObject } from 'bootstrap-vue';
import { isMergeColumnSetting } from '@/src/types/type-guards';
import { mapTextAlign } from '@/src/utils/enum-value-helper';
import AdColumnVisibleWrapper from './column-visible-wrapper.vue';
import { pushPufferNumber } from '@/src/state/plugins/light-streamer/pushing-helper';

@Component({
  inheritAttrs: false,
  components: {
    AdColumnVisibleWrapper,
  },
})
export default class AdInstrumentTable extends Vue {
  @Prop() settings!: InstrumentTableSettingData;
  @Prop({ default: () => [] }) sortOrders!: Array<SortOrder>;
  @Prop({ required: true }) instruments!: Array<ProductModel | UnderlyingModel>;
  @Prop()
  isShowColumn!: (column: InstrumentTableColumnSetting | InstrumentMergeColumnSetting) => boolean;
  @Prop({ default: false, type: Boolean })
  sorting!: boolean;
  @Prop({ default: false, type: Boolean })
  selectable!: boolean;
  @Prop({ default: false, type: Boolean })
  noSelectOnClick!: boolean;
  @Prop({ default: 5, type: Number })
  rows!: boolean;
  @Prop({ default: 5, type: Number })
  cols!: boolean;
  @Prop({ default: false, type: Boolean })
  loading!: boolean;
  @Inject() pageRoutes!: PageRoutes;

  @Ref() readonly table!: BTable;

  private watchListKey = 'watchListKey';
  private fields: Array<TableField> = [];
  private isBlockSetTableColumnDefinitionSwitch = false;
  // to use enum in template
  private separator = Separator;
  private direction = Direction;

  private currentOpenItem;
  private sortBy: string | null = null;
  private sortDesc = false;
  private visibleItems: Array<number> = [];
  private maxPufferItems = 0;
  private minPufferItems = 0;

  get items() {
    return this.instruments?.map((i) => {
      const item = {
        instrument: i,
        _showDetails: false,
      };

      return item;
    });
  }

  get isDetailRow() {
    return !!this.$scopedSlots['row-details'];
  }

  get isWatchListRow() {
    return !!this.$scopedSlots['row-watch-list'];
  }

  get columns() {
    return this.fields.length > 0 ? this.fields.length : 5;
  }

  created() {
    this.$refs.table = this.table;
  }

  mounted(): void {
    this.setTableColumnDefinition();
  }

  @Watch('loading')
  clearVisibleItems() {
    this.visibleItems = [];
  }

  visibleChange(isVisible: boolean, index: number) {
    const currentMax = this.maxPufferItems;
    const currentMin = this.minPufferItems;

    if (isVisible) {
      if (this.visibleItems.findIndex((n) => n === index) === -1) this.visibleItems.push(index);
    } else {
      const i = this.visibleItems.findIndex((n) => n === index);
      if (i >= 0) this.visibleItems.splice(i, 1);
    }

    if (this.visibleItems.length > 0) {
      const min = Math.min(...this.visibleItems) - pushPufferNumber;
      const max = Math.max(...this.visibleItems) + pushPufferNumber;
      this.minPufferItems = min < 0 ? 0 : min;
      this.maxPufferItems = max > this.items.length ? this.items.length : max;
    }

    if (currentMax != this.maxPufferItems || currentMin != this.minPufferItems) {
      this.$emit('pushing-items', this.minPufferItems, this.maxPufferItems);
    }
  }

  @Watch('sortOrders')
  @Watch('$screen.breakpoint')
  setTableColumnDefinition(): void {
    if (this.isBlockSetTableColumnDefinitionSwitch) {
      this.isBlockSetTableColumnDefinitionSwitch = false;
    } else {
      this.sortBy = null;
      this.sortDesc = false;
      this.fields = [];

      if (this.isDetailRow && !this.settings.hideDetailColumn)
        this.fields.push({
          key: 'showDetails',
          label: '',
          tdClass: 'td-col-detail',
          class: 'col-detail',
        });

      if (this.isWatchListRow && this.pageRoutes.watchlistUrl)
        this.fields.push({
          label: '',
          tdClass: 'td-watchlist',
          class: 'td-watchlist',
          key: this.watchListKey,
        });

      const setClass = (textAlign: TextAlign, columnWidth: number | null) => {
        return [mapTextAlign(textAlign), columnWidth ? `w-${columnWidth}` : 'w-auto'];
      };

      const addMergeColumn = (
        tableColumnsSetting: Array<InstrumentMergeColumnSetting | TableColumnSettingBase>,
        fields: TableField[]
      ) => {
        tableColumnsSetting.forEach((column, index) => {
          if (isMergeColumnSetting(column)) {
            if (this.getIsShowColumnSetting(column)) {
              fields.push({
                label: column.headline,
                key: index.toString(),
                sortable: false,
                class: setClass(column.textAlign, column.columnWidth),
                mergeColumnSettings: column.columnMergeSetting,
                isEllipsis: column.isEllipsis,
              });
            }
          } else {
            fields.push({
              label: column.headline,
              key: index.toString(),
              sortable: false,
              class: setClass(column.textAlign, column.columnWidth),
              isEllipsis: column.isEllipsis,
            });
          }
        });
      };

      if (this.$screen.xl) {
        let sortIndex = 0;
        this.settings.desktopTableColumnsSetting.forEach((column, index) => {
          if (isMergeColumnSetting(column)) {
            if (this.getIsShowColumnSetting(column)) {
              let sortColumn: SortOrder | undefined;
              let key = index.toString();
              let isSorting = false;

              if (column.columnMergeSetting.length == 1) {
                key = (column.columnMergeSetting[0] as InstrumentTableColumnSetting).key;
                sortColumn = this.sortOrders?.find((so) => so.key === key);
                isSorting = true;
              }

              if (sortColumn) sortIndex = this.sortOrders.indexOf(sortColumn) + 1;

              this.fields.push({
                key: key,
                label: column.headline,
                sortable: this.sorting && isSorting,
                class: setClass(column.textAlign, column.columnWidth),
                thClass: [sortColumn ? SortingDirection[sortColumn.direction].toLowerCase() : 'no-sort'],
                sortNumber: sortColumn ? sortIndex : 0,
                mergeColumnSettings: column.columnMergeSetting,
                isEllipsis: column.isEllipsis,
              });
            }
          } else {
            this.fields.push({
              label: column.headline,
              key: index.toString(),
              sortable: false,
              class: setClass(column.textAlign, column.columnWidth),
              isEllipsis: column.isEllipsis,
            });
          }
        });
      } else if (this.$screen.md) {
        addMergeColumn(this.settings.tabletTableColumnsSetting, this.fields);
      } else {
        addMergeColumn(this.settings.mobileTableColumnsSetting, this.fields);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowClicked(item: any, index: number, event: Event) {
    if (!(this.noSelectOnClick || this.table.isRowSelected(index))) {
      this.table.selectRow(index);
    }

    this.$emit('row-clicked', item.instrument, index, event);
  }

  sortingChanged(ctx: BvTableCtxObject): void {
    this.fields.forEach((f) => {
      f.thClass = '';
      f.sortNumber = 0;
    });
    this.isBlockSetTableColumnDefinitionSwitch = true;
    this.sortBy = ctx.sortBy;
    this.sortDesc = ctx.sortDesc;

    this.$emit('sorting-value-changed', [
      {
        key: ctx.sortBy,
        direction: ctx.sortDesc ? SortingDirection.Descending : SortingDirection.Ascending,
      },
    ] as Array<SortOrder>);
  }

  getIsShowColumnSetting(column: InstrumentMergeColumnSetting) {
    if (typeof this.isShowColumn === 'function') {
      return this.isShowColumn(column);
    }

    return true;
  }

  toggleDetails(e: Event, row: BRow) {
    row.toggleDetails(e);

    if (this.currentOpenItem && this.currentOpenItem !== row.item) {
      this.currentOpenItem._showDetails = false;
    }
    this.currentOpenItem = row.item;
  }
}
</script>

<style lang="scss">
.instrument-search-table {
  table-layout: fixed;

  tr:nth-of-type(2n + 1) {
    .td-col-detail {
      background-color: #d8e1e9;
    }
  }

  .sorting {
    display: none;
  }

  [aria-sort='ascending'],
  [aria-sort='descending'] {
    padding-right: 1em !important;
  }

  .ascending,
  .descending {
    padding-right: 2em !important;
  }

  [aria-sort='ascending'],
  .ascending,
  [aria-sort='descending'],
  .descending {
    .sorting {
      display: inline-block;
      height: 1em;
    }
  }

  td,
  th {
    overflow: hidden;
    white-space: nowrap;
  }

  .b-table-details > td {
    padding-bottom: 0;
    white-space: normal;
  }

  .col-detail {
    width: 39px;
  }

  .td-watchlist {
    padding-right: 0;
    padding-left: rem(4);
    width: rem(39);
    vertical-align: middle;
    > .btn {
      padding: 0 4px !important;
    }
    svg {
      width: 1.3em;
      height: 1.3em;
    }
  }

  .td-col-detail {
    background-color: #e4ebf1;
    padding: 0;

    vertical-align: middle;
    > .btn {
      border-style: none;
      background-color: transparent;
      padding-right: 10px;
      padding-left: 10px;
      min-width: 39px;
      height: 100%;

      &:focus {
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
      }
    }
  }

  .b-table-has-details {
    .td-col-detail {
      background-color: transparent;
    }
  }
}
</style>
