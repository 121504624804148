<template>
  <ad-icon-base iconName="hamburger" v-bind="$attrs" stroke-width="0.5">
    <path
      d="M39.2,30.8H0.8C0.3,30.8,0,30.5,0,30s0.3-0.8,0.8-0.8h38.3c0.5,0,0.8,0.3,0.8,0.8S39.7,30.8,39.2,30.8z
    M39.2,20.8H0.8C0.3,20.8,0,20.5,0,20s0.3-0.8,0.8-0.8h38.3c0.5,0,0.8,0.3,0.8,0.8S39.7,20.8,39.2,20.8z M39.2,10.8H0.8
    C0.3,10.8,0,10.5,0,10s0.3-0.8,0.8-0.8h38.3c0.5,0,0.8,0.3,0.8,0.8S39.7,10.8,39.2,10.8z"
    />
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconHamburger extends Vue {}
</script>
