var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'd-inline-block',
    'w-sm-100',
    'text-nowrap',
    'text-left',
    'mr-1',
    'mb-2',
    'px-3',
    'px-md-2',
    'py-2',
    'rounded',
    _vm.stateClass,
  ]},[_c('b-form-checkbox',{attrs:{"checked":_vm.value,"disabled":_vm.disabled,"name":"switch-button","switch":""},on:{"change":_vm.toggle}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }