<template>
  <ad-collapse-on-mobile :show-collapsed="showCollapsed" v-bind="$attrs">
    <template #header>{{ staticHeadline || headline }}</template>
    <template #default><slot name="default"></slot></template>
  </ad-collapse-on-mobile>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { HeadlineBlock } from '@/src/types/vue-api';
import { isHeadlineBlock } from '@src/types/type-guards';

@Component({
  inheritAttrs: false,
})
export default class AdCollapseOnMobileGroup extends Vue {
  @Prop() staticHeadline: string | undefined;
  @Prop({ type: Boolean, default: false }) showCollapsed!: boolean;
  headline: string | null = null;
  items: HeadlineBlock[] = [];

  mounted() {
    if (!this.staticHeadline) {
      this.items = this.findChildrenWithHeadlines(this.$children);
      this.propagateFirstHeadline();
    }
  }

  propagateFirstHeadline() {
    const first = this.items.find((b) => b.isVisible);
    if (first && first.blockHeadline) {
      this.headline = first.blockHeadline;
      first.setHeadlineVisibility(false);
    }
  }

  findChildrenWithHeadlines(blockChildren: Vue[]): HeadlineBlock[] {
    let items: HeadlineBlock[] = [];
    for (let index = 0; index < blockChildren.length; index++) {
      const block = blockChildren[index];
      if (isHeadlineBlock(block)) {
        items.push(block);
      } else if (block.$children.length != 0) {
        const subItems = this.findChildrenWithHeadlines(block.$children);
        if (subItems.length != 0) items = items.concat(subItems);
      }
    }
    return items;
  }
}
</script>
