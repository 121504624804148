<template>
  <ad-error :http-status-code="statusCode">
    <b-form id="product-search-page" novalidate>
      <b-container class="content-container content-container-t">
        <b-row>
          <b-col>
            <h1><slot name="headline"></slot></h1>
            <slot name="risk-disclaimer"></slot>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="7">
            <ad-product-search-filter-container
              class="alert form-card-helper mb-6 alert-light"
              v-model="filter"
              :productSearchDetailResult="productSearchDetailResult"
              @selected-value-changed="onFilterValueChangedWithResetSecondaryFilters"
              @selected-product-type-changed="onProductTypeChange"
              @selected-product-sub-type-changed="onProductSubTypeChange"
              @selected-underlying-changed="onUnderlyingChangedAsync"
              @reset-filter="onUnderlyingChangedAsync"
              :loading="isLoading"
            >
              <template #filter-headline>
                <slot name="filter-headline">Placeholder filter headline</slot>
              </template>
              <template #filter-product-type-dropdown-label-text>
                <slot name="filter-product-type-dropdown-label-text">Placeholder product type label</slot>
              </template>
              <template #filter-product-type-dropdown-allitems-text>
                <slot name="filter-product-type-dropdown-allitems-text">Placeholder product type all label</slot>
              </template>
              <template #filter-subtype-label-text>
                <slot name="filter-subtype-label-text">Select sub type</slot>
              </template>
              <template #filter-subtype-label-put>
                <slot name="filter-subtype-label-put">Put label</slot>
              </template>

              <template #filter-subtype-label-call>
                <slot name="filter-subtype-label-call">Call label</slot>
              </template>

              <template #filter-subtype-label-both>
                <slot name="filter-subtype-label-both"></slot>
              </template>

              <template #filter-category-dropdown-label-text>
                <slot name="filter-category-dropdown-label-text">Select category</slot>
              </template>

              <template #filter-category-dropdown-allitems-text>
                <slot name="filter-category-dropdown-allitems-text">All categories</slot>
              </template>

              <template #filter-country-dropdown-label-text>
                <slot name="filter-country-dropdown-label-text"></slot>
              </template>
              <template #filter-country-dropdown-allitems-text>
                <slot name="filter-country-dropdown-allitems-text"></slot>
              </template>
              <template #filter-sector-dropdown-label-text>
                <slot name="filter-sector-dropdown-label-text"></slot>
              </template>
              <template #filter-sector-dropdown-allitems-text>
                <slot name="filter-sector-dropdown-allitems-text"></slot>
              </template>

              <template #sorting-apply-button-text>
                <slot name="sorting-apply-button-text">Apply</slot>
              </template>

              <template #filter-underlying-radio-single-label-text>
                <slot name="filter-underlying-radio-single-label-text">Single underlying placholder</slot>
              </template>

              <template #filter-underlying-radio-multi-text>
                <slot name="filter-underlying-radio-multi-text">Multi underlying placholder</slot>
              </template>

              <template #filter-underlying-dropdown-label-text>
                <slot name="filter-underlying-dropdown-label-text">Dropdown label</slot>
              </template>

              <template #filter-underlying-dropdown-allitems-text>
                <slot name="filter-underlying-dropdown-allitems-text">Filter all label</slot>
              </template>
              <template #filter-underlying-dropdown-multiple-placeholder>
                <slot name="filter-underlying-dropdown-multiple-placeholder">Select items label</slot>
              </template>

              <template #filter-clear-button-text>
                <slot name="filter-clear-button-text">Clear filter placeholder</slot>
              </template>

              <template #additional-date-range-filter-elements="{ availableValues, isLoading }">
                <slot
                  name="additional-date-range-filter-elements"
                  :availableValues="availableValues"
                  :currentSearchFilter="filter"
                  :isLoading="isLoading"
                  :onFilterValueChanged="onFilterValueChanged"
                ></slot>
              </template>
              <template #additional-numeric-range-filter-elements="{ availableValues, isLoading }">
                <slot
                  name="additional-numeric-range-filter-elements"
                  :availableValues="availableValues"
                  :currentSearchFilter="filter"
                  :onFilterValueChanged="onFilterValueChanged"
                  :isLoading="isLoading"
                ></slot>
              </template>
            </ad-product-search-filter-container>
          </b-col>
          <b-col lg="5">
            <slot name="underlying-teaser"></slot>
          </b-col>
        </b-row>
      </b-container>
      <div v-if="settingsProperty.isInEditMode || $screen.sm" class="float-right content-container-y">
        <b-button
          variant="light"
          :disabled="isLoading"
          v-if="settingsProperty.isInEditMode || $screen.xl"
          @click="excelExport"
        >
          <ad-icon-xls /><slot name="button-excel-export-text">Excel Export</slot>
        </b-button>
        <ad-product-search-sorting
          :disabled="isLoading"
          wide
          :filter="filter"
          class="ml-3 d-inline-block"
          @sorting-value-changed="onFilterValueChanged"
        >
          <template #button-sorting-text>
            <slot name="button-sorting-text">Sorting</slot>
          </template>
          <template #sorting-headline-text>
            <slot name="sorting-headline-text">Sort By</slot>
          </template>
          <template #sorting-column-headline-text>
            <slot name="sorting-column-headline-text">Columns</slot>
          </template>
          <template #sorting-direction-headline-text>
            <slot name="sorting-direction-headline-text">Ascending/Descending</slot>
          </template>
          <template #sorting-add-columns-text>
            <slot name="sorting-add-columns-text">Add columns (optional)</slot>
          </template>
          <template #sorting-distribution-fee-text>
            <slot name="sorting-distribution-fee-text">Distribution Fee</slot>
          </template>
          <template #sorting-reset-button-text>
            <slot name="sorting-reset-button-text">Reset</slot>
          </template>
          <template #sorting-apply-button-text>
            <slot name="sorting-apply-button-text">Apply</slot>
          </template>
        </ad-product-search-sorting>
      </div>
      <b-container class="content-container content-container-x my-0" id="table-search-top">
        <ad-skeleton-text :loading="isLoading">
          <h3>
            <slot
              name="search-headline-no-result"
              v-if="productSearchDetailResult.totalElementsCount === 0 || settingsProperty.isInEditMode"
              >No Result</slot
            >
            <slot
              name="search-headline-one-result"
              v-if="productSearchDetailResult.totalElementsCount === 1 || settingsProperty.isInEditMode"
              >One Result</slot
            >
            <slot
              name="search-headline"
              :count="productSearchDetailResult.totalElementsCount | formatField(formatNumber)"
              v-if="productSearchDetailResult.totalElementsCount > 1 || settingsProperty.isInEditMode"
              >Many Result {{ productSearchDetailResult.totalElementsCount }}</slot
            ></h3
          >
        </ad-skeleton-text>
        <ad-product-search-sorting
          wide
          class="mt-2"
          :filter="filter"
          v-if="!$screen.sm"
          :disabled="isLoading"
          @sorting-value-changed="onFilterValueChanged"
          :key="updateKey"
        >
          <template #button-sorting-text>
            <slot name="button-sorting-text">Sorting</slot>
          </template>
          <template #sorting-headline-text>
            <slot name="sorting-headline-text">Sort By</slot>
          </template>
          <template #sorting-column-headline-text>
            <slot name="sorting-column-headline-text">Columns</slot>
          </template>
          <template #sorting-direction-headline-text>
            <slot name="sorting-direction-headline-text">Ascending/Descending</slot>
          </template>
          <template #sorting-add-columns-text>
            <slot name="sorting-add-columns-text">Add columns (optional)</slot>
          </template>
          <template #sorting-distribution-fee-text>
            <slot name="sorting-distribution-fee-text">Distribution Fee</slot>
          </template>
          <template #sorting-reset-button-text>
            <slot name="sorting-reset-button-text">Reset</slot>
          </template>
          <template #sorting-apply-button-text>
            <slot name="sorting-apply-button-text">Apply</slot>
          </template>
        </ad-product-search-sorting>
      </b-container>
      <b-container fluid class="mb-7 mb-md-8 mt-5">
        <b-row>
          <b-col>
            <ad-product-search-table
              :products="productSearchDetailResult.items"
              :filter="filter"
              @sorting-value-changed="onFilterValueChanged"
              :loading="isLoading"
              @pushing-items="pushingItems"
            >
              <template #product-detail-page-teaser>
                <slot name="product-detail-page-teaser" />
              </template>
            </ad-product-search-table>
          </b-col>
        </b-row>
        <ad-pagination
          :views-per-page-selection-text="settingsProperty.viewsPerPageSelectionText"
          @paging-change="updatePagination"
          :take="filter.pageSize"
          :pageNumber="filter.pageNumber"
          :rows="productSearchDetailResult.totalElementsCount"
        >
          <template #views-per-page-text>
            <slot name="views-per-page-text" />
          </template>
          <template v-slot:page-of-text="{ totalPages }">
            <slot name="page-of-text" v-bind:totalPages="totalPages"></slot>
          </template>
          <template #pervious-page-text>
            <slot name="pervious-page-text" />
          </template>
          <template #next-page-text>
            <slot name="next-page-text" />
          </template>
        </ad-pagination>
      </b-container>
    </b-form>
  </ad-error>
</template>
<script lang="ts">
import {
  ProductSearchDetailedResultModel,
  ProductSearchParametersModel,
  SortOrder,
  ExcelExportSearchParameterModel,
} from '@/src/types/the-q-api';
import { PageRoutes, ProductSearchPageSetting, SortingColumnSetting } from '@/src/types/episerver-api';
import { Component, Inject, Vue } from 'vue-property-decorator';
import AdProductSearchTable from './ad-product-search-table.vue';
import AdProductSearchSorting from './ad-product-search-sorting.vue';
import AdProductSearchFilterContainer from './filter-components/ad-product-search-filter-container.vue';
import { redirectToSearchProducts, tryCreateProductSearchLink } from '@/src/utils/url-helper';
import { getTheQApiUrl, getParamFromUrl } from '@/src/utils/url-helper';
import {
  Controller,
  DefaultFormat,
  HttpStatusCodes,
  ProductSubTypeAnyMap,
  ProductSubTypeAny,
} from '@/src/types/enumerations';
import { downloadFilePost } from '@/src/utils/download-file';
import { parseQueryObject } from '@/src/utils/query-param-helper';
import qs from 'qs';
import { ProductSearchParametersFilter } from '@/src/types/vue-api';
import { isAxiosError } from '@/src/types/type-guards';
import { Action, Getter } from 'vuex-class';
import { pushPufferNumber } from '@/src/state/plugins/light-streamer/pushing-helper';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';

@Component({
  components: {
    AdProductSearchTable,
    AdProductSearchSorting,
    AdProductSearchFilterContainer,
  },
})
export default class AdProductSearchPage extends Vue {
  @Inject() settingsProperty!: ProductSearchPageSetting;
  @Inject() pageRoutes!: PageRoutes;

  @Getter('productSearchDetailResult', { namespace: 'product' })
  productSearchDetailResult!: ProductSearchDetailedResultModel | null;

  @Action('loadProductSearchDetailedResultAsync', { namespace: 'product' })
  loadProductSearchDetailedResultAsync!: (params: {
    filter: ProductSearchParametersModel;
    minPushItem: number;
    maxPushItem: number;
  }) => Promise<void>;

  @Action('updateProductSearchDetailedResultPushItems', { namespace: 'product' })
  updateProductSearchDetailedResultPushItems!: (params: { minPushItem: number; maxPushItem: number }) => void;

  @Action('loadTeaserUnderlyingsAsync', { namespace: 'underlying' })
  loadTeaserUnderlyingsAsync!: (isin: string[] | string) => Promise<void>;

  @Action('clearTeaserUnderlyings', { namespace: 'underlying' })
  clearTeaserUnderlyings!: () => void;

  statusCode: number | HttpStatusCodes = HttpStatusCodes.OK;

  private filter = new ProductSearchParametersFilter();
  private firstRequestSwitch = true;
  private formatNumber = DefaultFormat.Number;
  private isLoading = true;
  private updateKey = 0;

  async onFilterValueChangedWithResetSecondaryFilters(): Promise<void> {
    this.filter = this.filter.getResetSecondaryFilter();
    await this.onFilterValueChanged();
  }

  async onFilterValueChanged(): Promise<void> {
    this.filter.pageNumber = 1;
    await this.loadProductsAsync();
  }

  async created(): Promise<void> {
    this.setFilterDefaults();
    this.setSortOrders();
    this.setFilterFromUrl();
    this.filter.isRequestWithoutUnderlyingWhenNoResult = true;

    await this.loadProductsAsync();
    //check if item is available and if not request had loaded all items,
    //so therefore reset default underlying, because it is not in the request
    const underlyingIdToCheck = this.preSelectedUnderlyingId;

    if (underlyingIdToCheck && this.productSearchDetailResult?.availableValues?.underlyingNames[underlyingIdToCheck]) {
      await this.loadUnderlyingsAsync();
    } else {
      this.filter.underlyingIsinOrIsins = null;
      this.filter.defaultUnderlying = null;
    }

    this.filter.isRequestWithoutUnderlyingWhenNoResult = false;
  }

  async loadProductsAsync() {
    if (!this.firstRequestSwitch) {
      const url = tryCreateProductSearchLink(
        this.pageRoutes,
        this.filter.productType,
        this.filter,
        this.shouldItemBeSerializedInQuery
      );

      window.history.pushState({}, window.document.title, url);
    }

    this.firstRequestSwitch = false;
    await this.loadProductsWithErrorHandling();
    this.updateKey++;
  }

  private pushingItems(min: number, max: number) {
    this.updateProductSearchDetailedResultPushItems({
      minPushItem: min,
      maxPushItem: max,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shouldItemBeSerializedInQuery(prefix: string, value: any) {
    switch (prefix) {
      case 'sortOrders':
        if (JSON.stringify(value) === JSON.stringify(this.filter.resetSortOrders)) value = undefined;
        return value;
      case 'underlyingIsinOrIsins':
        if (value === this.settingsProperty.defaultUnderlyingIsin) value = undefined;
        return value;
      default:
        return value;
    }
  }

  private get preSelectedUnderlyingId(): string | null {
    if (typeof this.filter.underlyingIsinOrIsins === 'string') {
      return this.filter.underlyingIsinOrIsins;
    } else if (Array.isArray(this.filter.underlyingIsinOrIsins)) {
      return this.filter.underlyingIsinOrIsins[0];
    }
    return this.filter.defaultUnderlying;
  }

  async updatePagination(take: number, pageNumber: number) {
    this.filter.pageSize = take;
    this.filter.pageNumber = pageNumber;
    document.getElementById('table-search-top')?.scrollIntoView({
      behavior: 'smooth',
    });

    await this.loadProductsWithErrorHandling();
  }

  async loadProductsWithErrorHandling() {
    this.isLoading = true;

    try {
      await this.loadProductSearchDetailedResultAsync({
        filter: this.filter,
        minPushItem: 0,
        maxPushItem: pushPufferNumber,
      });
    } catch (error: unknown) {
      this.$log.error('Loading Error', error);

      if (isAxiosError(error)) {
        this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
      } else {
        this.statusCode = HttpStatusCodes.UnknownError;
      }
    }

    this.isLoading = false;
  }

  async loadUnderlyingsAsync() {
    if (this.filter.underlyingIsinOrIsins !== null) {
      await this.loadTeaserUnderlyingsAsync(this.filter.underlyingIsinOrIsins);
    } else {
      this.clearTeaserUnderlyings();
    }
  }

  async onUnderlyingChangedAsync() {
    await Promise.all([this.onFilterValueChangedWithResetSecondaryFilters(), this.loadUnderlyingsAsync()]);
  }

  onProductTypeChange() {
    redirectToSearchProducts(null, this.pageRoutes, false, this.filter.productType);
  }

  async onProductSubTypeChange() {
    const sortOrdersFromUrl = getParamFromUrl<ProductSearchParametersFilter>('sortOrders');
    if (sortOrdersFromUrl === null) {
      this.setSortOrders();
    }
    await this.onFilterValueChanged();
  }

  resolveSearchPageLink(): string | null {
    if (this.filter.productType != null) {
      return this.pageRoutes.productSearchTypeUrlSegments[this.filter.productType];
    }

    const emptyProductKey = '';
    if (emptyProductKey in this.pageRoutes) {
      return this.pageRoutes.productSearchTypeUrlSegments[emptyProductKey];
    }

    return null;
  }

  excelExport() {
    const url = getTheQApiUrl(Controller.ProductSearch, 'Excel');
    this.trackExport(url);
    downloadFilePost(url, this.buildExcelRequest(), 'ProductSearch.xlsx');
  }

  trackExport(url: string) {
    trackEvent(GaEvent.ClickDownload, {
      displayName: 'BestMatchSearch.xlsx',
      extension: 'xlsx',
      url: url,
    });
  }

  buildExcelRequest(): ExcelExportSearchParameterModel {
    const excelFilter = this.filter as unknown as ExcelExportSearchParameterModel;
    excelFilter.columnList = this.settingsProperty.excelExportColumnSettings;
    return excelFilter;
  }

  private setFilterDefaults(): void {
    this.filter.productType = this.settingsProperty.productType;

    if (this.settingsProperty.defaultUnderlyingIsin) {
      this.filter.defaultUnderlying = this.settingsProperty.defaultUnderlyingIsin;
      this.filter.underlyingIsinOrIsins = this.filter.defaultUnderlying;
    }

    this.filter.subType = getParamFromUrl<ProductSearchParametersFilter>('subType');
  }

  private setFilterFromUrl(): void {
    const filter = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    this.filter = Object.assign(this.filter, parseQueryObject(filter));
  }

  /**
   * Some of the sorting orders can be sub-type specific (not all products have sub types)
   * Sorting Orders with sub type set to 'Any' can be used for any product and any subtype.
   * Sorting Orders with sub type other than 'Any' can only be used with products with sub types
   * E.g. sorting with sub type by call will only be set if filter subtyp is set to call
   */
  private setSortOrders(): void {
    let sortingSetting = new Array<SortingColumnSetting>();
    if (this.settingsProperty.hasSubTypeFilter && this.filter.subType !== null) {
      sortingSetting = this.settingsProperty.tableColumnSorting?.filter(
        (item) =>
          ProductSubTypeAnyMap.get(item.productSubTypeAny) === this.filter.subType ||
          item.productSubTypeAny === ProductSubTypeAny.Any
      );
    } else {
      sortingSetting = this.settingsProperty.tableColumnSorting.filter(
        (item) => item.productSubTypeAny === ProductSubTypeAny.Any
      );
    }

    const sortOrders = sortingSetting.map((item) => ({ key: item.key, direction: item.sortingDirection }) as SortOrder);

    this.filter.sortOrders = sortOrders;
    this.filter.resetSortOrders = sortOrders;
  }
}
</script>
