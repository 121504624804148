<template>
  <b-popover
    :popoverId="popoverId"
    :target="targetId"
    triggers="focus hover"
    :show.sync="isPopoverShow"
    :placement="placement"
    :fallback-placement="[]"
    custom-class="brokers-overlay"
    boundary="viewport"
    boundary-padding="5"
  >
    <b-row class="no-gutters">
      <b-col cols="10" class="mt-3 pl-md-2">
        <h3><slot name="overlay-headline"></slot></h3>
      </b-col>
      <b-col cols="2" class="mt-3 pr-md-3 text-right blue">
        <b-link @click="onClose" class="blue">
          <ad-icon-close />
        </b-link>
      </b-col>
      <b-col cols="12" class="mt-2 mb-4 pr-md-3 ml-md-2">
        <slot name="leaving-disclaimer"></slot>
      </b-col>
      <b-col cols="12" class="px-md-1">
        <b-row class="no-gutters mb-2">
          <slot name="brokers" :on-broker-click="onBrokerClick"></slot>
        </b-row>
      </b-col>
    </b-row>
  </b-popover>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';

@Component({})
export default class AdBrokersOverlay extends Vue {
  @Prop({ required: true, type: String }) targetId!: string;
  @Prop({ required: true, type: String }) popoverId!: string;
  placement = 'rightbottom';
  isPopoverShow = false;

  onClose(): void {
    this.isPopoverShow = false;
  }

  created(): void {
    this.setOverlayPlacement();
    this.$watch('$screen.breakpoint', this.setOverlayPlacement);
  }
  setOverlayPlacement(): void {
    if (this.$screen.lg) {
      this.placement = 'rightbottom';
    } else {
      this.placement = 'bottomleft';
    }
  }

  onBrokerClick(brokerName: string) {
    trackEvent(GaEvent.SelectItem, {
      item_list_id: 'broker',
      items: [
        {
          item_id: brokerName,
        },
      ],
    });
  }
}
</script>

<style scoped lang="scss">
.brokers-overlay {
  @include media-breakpoint-up('md') {
    margin-left: 15px !important;
    min-width: rem(575) !important;
  }

  @include media-breakpoint-down('sm') {
    margin-top: 15px !important;
    min-width: 95% !important;
  }
}
</style>
