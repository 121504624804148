<template>
  <div class="ce-newsletter-registration">
    <div class="ce-newsletter-registration--checkmark-container">
      <ad-icon-check></ad-icon-check>
    </div>
    <div class="blog-container container-fluid">
      <h2>Your registration for our newsletters has been successful.</h2>
    </div>
    <a href="/" class="btn btn-secondary">Visit home page</a>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CeNewsletterRegistration extends Vue {}
</script>

<style lang="scss">
.ce-newsletter-registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $marine-blue;

  &--checkmark-container {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, -50%);
    border-radius: 50%;
    background: $turquoise-blue;
    width: 100px;
    height: 100px;
    color: $white;
    font-size: 2rem;
  }

  h2 {
    margin-bottom: map-get($spacers, 6);
    text-align: center;
    color: $white;
  }

  .btn {
    margin-bottom: map-get($spacers, 8);
  }
}
</style>
