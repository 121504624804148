<template>
  <b-container fluid class="ml-0 px-0 position-relative chart-legend fs-14 b-skeleton-m" v-bind="$attrs">
    <div class="dot rounded position-absolute" :style="{ backgroundColor: this.color }"></div>
    <b-row>
      <b-col class="marine-blue font-weight-bold"><slot name="header"></slot></b-col>
    </b-row>

    <b-row class="gun-metal text-nowrap">
      <b-col cols="6" :sm="smCols">
        <div class="gun-metal font-weight-bold"><slot name="first-header"></slot></div>
        <div class="values"><slot name="first-values"></slot></div>
      </b-col>
      <b-col cols="6" :sm="smCols">
        <div class="gun-metal font-weight-bold"><slot name="second-header"></slot></div>
        <div class="values"><slot name="second-values"></slot></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { ChartColors } from '@/src/types/enumerations';
import { ChartConfiguration } from '@/src/types/vue-api';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AdChartLegend extends Vue {
  @Prop() color?: ChartColors;
  @Inject({ default: new ChartConfiguration() }) chartConfigurationProperty!: ChartConfiguration;

  get smCols() {
    return this.chartConfigurationProperty.legendInTwoLineModeAtTablet ? 12 : 6;
  }
}
</script>
<style lang="scss">
.chart-legend {
  .dot {
    top: rem(5);
    left: rem(-17);
    width: rem(10);
    height: rem(10);
  }

  /* stylelint-disable selector-max-compound-selectors */
  .values span + span::before {
    content: '/';
  }
  /* stylelint-enable selector-max-compound-selectors */
}
</style>
