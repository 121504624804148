<template>
  <div class="underlying-top-flop-tabel">
    <ad-instrument-table :settings="settingsProperty" v-bind="$attrs" :instruments="items">
      <template #row-instrument="{ setting, instrument }">
        <ad-underlying-data-value
          :isLinkToDetailPage="setting.isLinkToDetailPage"
          :underlying-item="instrument"
          :field-key="setting.key"
        />
      </template>
    </ad-instrument-table>
  </div>
</template>
<script lang="ts">
import { UnderlyingTopFlopTableBlockSetting } from '@/src/types/episerver-api';
import { Getter, Action } from 'vuex-class';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { TopFlopTableSearchFilter, TopFlopTableSearchPayload, UnderlyingModel } from '@/src/types/the-q-api';

@Component({})
export default class AdUnderlyingTopFlopTable extends Vue {
  @Inject() settingsProperty!: UnderlyingTopFlopTableBlockSetting;

  @Getter('topFlopTableSearchResult', { namespace: 'underlying' })
  topFlopTableSearchResult!: (contentId: number) => Array<UnderlyingModel>;

  @Action('loadTopFlopTableSearchAsync', { namespace: 'underlying' })
  loadTopFlopTableSearchAsync!: (filter: TopFlopTableSearchPayload) => Promise<void>;

  get items() {
    return this.topFlopTableSearchResult(this.settingsProperty.contentId);
  }

  async created(): Promise<void> {
    await this.loadTopFlopTableSearchAsync({
      contentId: this.settingsProperty.contentId,
      filter: {
        isins: this.settingsProperty.underlyingIsins,
        topFlop: this.settingsProperty.topFlop,
        pageSize: this.settingsProperty.pageSize,
      } as TopFlopTableSearchFilter,
    } as TopFlopTableSearchPayload);
  }
}
</script>
