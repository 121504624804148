/**
 * Declare specification of window variables;
 */
declare global {
  interface Window {
    gtag: Function;
  }
}

/**
 * A facade of gtag. Only act when gtag available.
 * @param args
 */
export function trackEvent(...args: unknown[]) {
  if (typeof window.gtag === 'function') {
    window.gtag(...['event', ...args]);
  }
}

export enum GaEvent {
  JoinGroup = 'join_group',
  SelectItem = 'select_item',
  SelectContent = 'select_content',
  ClickDownload = 'ClickDownload',
}
