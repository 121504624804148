<template>
  <b-form-group
    :id="'folder-group-filter-group' + _uid"
    :label-for="'folder-group-filter-dropdownlist' + _uid"
    class="focus-white"
  >
    <template #label>
      <slot name="label-text" />
    </template>
    <ad-drop-down-list
      :input-id="'folder-group-filter-dropdownlist' + _uid"
      :form-element-state-id="'folder-group-filter-group' + _uid"
      :options="items"
      v-model="currentSelection"
      :value="value"
      @input="setValue"
      v-bind="$attrs"
    >
      <template #all-items-label>
        <slot name="all-items-label">All</slot>
      </template>
    </ad-drop-down-list>
  </b-form-group>
</template>

<script lang="ts">
import { FolderDownloadsGroupNameFilterBlockPayloadSettings } from '@/src/types/episerver-api';
import { DropDownItem } from '@/src/types/vue-api';
import { convertOptions } from '@src/utils/dropdown-option-provider';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class AdFolderDownloadsGroupFilter extends Vue {
  @Inject()
  settingsProperty!: FolderDownloadsGroupNameFilterBlockPayloadSettings;
  @Prop()
  value!: string | null;
  currentSelection: string | null = '';

  mounted() {
    this.setDefaultSelection();
  }

  setDefaultSelection() {
    const hash = this.tryGetSelectionFromHash();
    if (hash && this.inGroups(hash)) {
      this.currentSelection = hash;
      this.removeHash();
    } else if (this.inGroups(this.settingsProperty.folderGroups.selectedValue)) {
      this.currentSelection = this.settingsProperty.folderGroups.selectedValue;
    }

    if (this.currentSelection) {
      this.setValue(this.currentSelection);
    }
  }

  inGroups(group: string | null): boolean {
    return this.settingsProperty.folderGroups.items.some((_) => _.value == group);
  }

  removeHash() {
    history.replaceState('', document.title, window.location.pathname);
  }

  get items(): DropDownItem[] {
    return convertOptions(this.settingsProperty.folderGroups.items, null);
  }

  private tryGetSelectionFromHash(): string | null | undefined {
    return location.hash.substring(1);
  }

  setValue(newValue) {
    this.$emit('input', newValue);
    this.$emit('set-filter', this.settingsProperty.name, newValue);
  }
}
</script>
