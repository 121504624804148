<template>
  <div :class="{ 'mb-0 mb-md-4': !lastChunk }">
    <div class="d-flex flex-column flex-md-row align-items-end mb-0 mb-md-4">
      <a :href="first.link" :class="firstClasses">
        <div :class="'ce-tile__gradient-' + first.color" class="w-100 ce-tile h-100 p-3 p-md-5 d-flex flex-nowrap">
          <div class="col-11 p-0">
            <h3>{{ first.title }}</h3>
            <p v-if="!tiny">
              {{ first.text }}
            </p>
          </div>
          <div class="col-1 fs-18"><ad-icon-chevron rotate="270" /></div>
        </div>
      </a>
      <template v-if="tiles.length > 1">
        <a :href="second.link" :class="secondClasses">
          <div :class="'ce-tile__gradient-' + second.color" class="w-100 ce-tile h-100 p-3 p-md-5 d-flex flex-nowrap">
            <div class="col-11 p-0">
              <h3>{{ second.title }}</h3>
              <p v-if="!tiny">{{ second.text }}</p></div
            >
            <div class="col-1 fs-18"><ad-icon-chevron rotate="270" /></div>
          </div>
        </a>
      </template>
    </div>

    <div v-if="tiles.length > 2" class="d-flex flex-column flex-md-row">
      <template v-if="tiles.length > 2">
        <a :href="third.link" :class="thirdClasses">
          <div :class="'ce-tile__gradient-' + third.color" class="w-100 ce-tile h-100 p-3 p-md-5 d-flex flex-nowrap">
            <div class="col-11 p-0">
              <h3>{{ third.title }}</h3>
              <p v-if="!tiny">{{ third.text }}</p>
            </div>
            <div class="col-1 fs-18"><ad-icon-chevron rotate="270" /></div>
          </div>
        </a>
      </template>
      <template v-if="tiles.length > 3">
        <a :href="fourth.link" :class="fourthClasses">
          <div :class="'ce-tile__gradient-' + fourth.color" class="w-100 ce-tile h-100 p-3 p-md-5 d-flex flex-nowrap">
            <div class="col-11 p-0">
              <h3>{{ fourth.title }}</h3>
              <p v-if="!tiny">{{ fourth.text }}</p>
            </div>
            <div class="col-1 fs-18"><ad-icon-chevron rotate="270" /></div>
          </div>
        </a>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { TileContent } from '@/src/types/ce-component-types';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class CeTileChunk extends Vue {
  @Prop({ required: true }) tiles!: TileContent[];
  @Prop({ required: true }) lastChunk!: boolean;
  @Prop({ default: false }) tiny!: boolean;

  get first() {
    return this.tiles[0];
  }
  get second() {
    return this.tiles[1];
  }
  get third() {
    return this.tiles[2];
  }
  get fourth() {
    return this.tiles[3];
  }
  get firstClasses() {
    return `ce-tile__${this.tiny ? 'tiny' : 'size1'} mb-5 mb-md-0 mr-md-4`;
  }
  get secondClasses() {
    return `ce-tile__${this.tiny ? 'tiny' : this.tiles.length === 2 ? 'size1' : 'size2'} mb-5 mb-md-0`;
  }
  get thirdClasses() {
    return `ce-tile__${this.tiny ? 'tiny' : 'size3'} mb-5 mb-md-0 mr-md-4`;
  }
  get fourthClasses() {
    return `ce-tile__${this.tiny ? 'tiny' : 'size4'} mb-5 mb-md-0`;
  }
}
</script>

<style lang="scss">
.ce-tile {
  position: relative;
  transition: background-position-x 2s ease;
  border: 0;
  background-position: 0 0;
  background-size: 200%, 200%;
  color: $white;

  &:not(.no-hover) {
    cursor: pointer;

    &:hover {
      background-position: 100% 0;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    color: $white;
  }
  &__gradient-mulberry {
    background-image: linear-gradient(113deg, $mulberry, $magenta, $mulberry);

    &:not(.no-hover):active {
      background: $magenta;
    }
  }
  &__gradient-grape {
    background-image: linear-gradient(115deg, $grape, $lavender, $grape);

    &:not(.no-hover):active {
      background: $lavender;
    }
  }
  &__gradient-deep-turquoise {
    background-image: linear-gradient(109deg, $deep-turquoise, #0098a0, $deep-turquoise);

    &:not(.no-hover):active {
      background: #0098a0;
    }
  }
  &__gradient-peacock-blue {
    background-image: linear-gradient(135deg, $peacock-blue, #3089cc, $peacock-blue);

    &:not(.no-hover):active {
      background: #3089cc;
    }
  }

  &__size1 {
    width: 100%;
    height: 100%;
    @include media-breakpoint-up('md') {
      max-width: 50%;
      height: rem(397);
    }
  }

  &__size2 {
    width: 100%;
    height: 100%;
    @include media-breakpoint-up('md') {
      max-width: 50%;
      height: rem(300);
    }
  }
  &__size3 {
    width: 100%;
    height: 100%;
    @include media-breakpoint-up('md') {
      max-width: 66%;
      height: rem(300);
    }
  }
  &__size4 {
    width: 100%;
    height: 100%;
    @include media-breakpoint-up('md') {
      max-width: 34%;
      height: rem(410);
    }
  }
  &__tiny {
    width: 100%;
    height: rem(88);

    @include media-breakpoint-up('md') {
      width: calc(50% - #{map-get($spacers, 4) / 2});
      height: rem(145);
    }
  }
}
</style>
