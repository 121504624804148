<template>
  <div v-if="underlying">
    <h6 class="teaser-title fs-14 mb-0 font-weight-bold">
      <ad-underlying-preheadline-text
        class="fs-10"
        :udp-preheadlines="settingsProperty.udpPreHeadlines"
        :underlying="underlying"
      ></ad-underlying-preheadline-text>
      <slot name="teaser-title"></slot>
    </h6>
    <h5 class="marine-blue">{{ underlying.name }}</h5>
    <slot name="teaser-chart"></slot>
  </div>
</template>

<script lang="ts">
import { UnderlyingDetailPageSetting } from '@/src/types/episerver-api';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { Component, Vue, Inject, InjectReactive } from 'vue-property-decorator';

@Component
export default class AdUnderlyingTeaserChart extends Vue {
  @InjectReactive() underlying!: UnderlyingModel | null;
  @Inject()
  settingsProperty?: UnderlyingDetailPageSetting;
}
</script>
