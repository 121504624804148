<template>
  <div class="ade-paging">
    <b-row>
      <b-col cols="4" v-if="$screen.lg">
        <div class="pagination">
          <span class="pt-2"><slot name="views-per-page-text">Views</slot></span>
          <b-button
            class="btn-views ml-3"
            @click="pagingChange(true, 25, 1)"
            :pressed="currentTake == 25"
            :disabled="loading"
            variant="light"
            >25</b-button
          >
          <b-button
            class="btn-views ml-1"
            @click="pagingChange(true, 50, 1)"
            :pressed="currentTake == 50"
            :disabled="loading"
            variant="light"
            >50</b-button
          >
          <b-button
            class="btn-views ml-1"
            @click="pagingChange(true, 100, 1)"
            :pressed="currentTake == 100"
            :disabled="loading"
            variant="light"
            >100</b-button
          >
        </div>
      </b-col>
      <b-col cols="12" v-if="!$screen.md">
        <div class="pagination justify-content-center justify-content-lg-start mb-3">
          <b-form-input
            @keyup.enter.prevent="pagingChange($event.target.validity.valid, currentTake, currentPageNumber)"
            class="btn-page mx-4 px-4 default"
            min="1"
            :max="totalPages"
            :disabled="totalPages == 1 || loading"
            step="1"
            type="number"
            v-model="currentPageNumber"
          ></b-form-input>
          <span class="pt-2">
            <ad-skeleton-text class="b-skeleton-m" :loading="loading">
              <slot name="page-of-text" :totalPages="totalPages"> of {{ totalPages }}</slot>
            </ad-skeleton-text>
          </span>
        </div>
      </b-col>
      <b-col lg="8" cols="12">
        <div class="pagination justify-content-center justify-content-lg-start">
          <b-button
            class="btn-step text-left"
            @click="pagingChange(true, currentTake, currentPageNumber, -1)"
            :disabled="currentPageNumber === 1 || loading"
            variant="light"
          >
            <ad-icon-chevron :direction="direction.left" class="clarinet" :size="size.m" />
            <span class="d-inline-block float-right"> <slot name="pervious-page-text">Previous</slot></span>
          </b-button>

          <b-form-input
            v-if="$screen.md"
            @keyup.enter.prevent="pagingChange($event.target.validity.valid, currentTake, currentPageNumber)"
            class="btn-page mx-4 px-4 default"
            min="1"
            :max="totalPages"
            :disabled="totalPages === 1 || loading"
            step="1"
            type="number"
            v-model="currentPageNumber"
          ></b-form-input>
          <span class="pt-2" v-if="$screen.md">
            <ad-skeleton-text class="b-skeleton-m" :loading="loading">
              <slot name="page-of-text" :totalPages="totalPages"> of {{ totalPages }}</slot>
            </ad-skeleton-text>
          </span>
          <div class="w-10" v-if="!$screen.md" />
          <b-button
            class="ml-0 ml-sm-6 btn-step text-right"
            @click="pagingChange(true, currentTake, currentPageNumber, 1)"
            :disabled="currentPageNumber * currentTake >= rows || loading"
            variant="light"
          >
            <span class="d-inline-block float-left"><slot name="next-page-text">Next</slot></span>
            <ad-icon-chevron :direction="direction.right" class="clarinet" :size="size.m"
          /></b-button>
        </div>
      </b-col>

      <b-col cols="12" v-if="!$screen.lg">
        <div class="text-center">
          <b-form-group
            id="input-group-1"
            class="focus-white mt-5 text-left input-step d-sm-inline-block"
            label-for="input-1"
          >
            <template #label>
              <slot name="views-per-page-text">Views</slot>
            </template>
            <ad-drop-down-list
              :options="viewItems"
              :disabled="loading"
              v-model="currentTake"
              @input="pagingChange(true, currentTake, pageNumber)"
              form-element-state-id="input-group-1"
              id="input-1"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { Direction, DropDownItemType, Size } from '@/src/types/enumerations';
import { DropDownItem } from '@/src/types/vue-api';

@Component({
  inheritAttrs: false,
})
export default class AdPagination extends Vue {
  @Prop({ required: true, type: Number })
  take!: number;
  @Prop({ required: true, type: Number })
  pageNumber!: number;
  @Prop({ required: true })
  rows!: number;
  @Prop({ required: true })
  viewsPerPageSelectionText!: string;
  private direction = Direction;
  private size = Size;
  private currentTake = 0;
  private currentPageNumber = 1;
  private viewItems!: Array<DropDownItem>;

  created() {
    this.viewItems = [
      {
        value: 25,
        text: this.replaceViewText(25),
        type: DropDownItemType.item,
      },
      {
        value: 50,
        text: this.replaceViewText(50),
        type: DropDownItemType.item,
      },
      {
        value: 100,
        text: this.replaceViewText(100),
        type: DropDownItemType.item,
      },
    ];
    this.currentTake = this.take;
    this.currentPageNumber = this.pageNumber;
  }

  @Watch('pageNumber')
  updateCurrentPageNumber(): void {
    this.currentPageNumber = this.pageNumber;
  }

  get totalPages() {
    return Math.ceil(this.rows / this.currentTake);
  }

  get loading() {
    return this.rows == null;
  }

  pagingChange(isValid: boolean, take: number, pageNumber: number | string, increase = 0) {
    pageNumber = this.currentPageNumber = parseInt(pageNumber.toString());
    pageNumber = pageNumber + increase;
    this.currentTake = take;

    if (!isValid) {
      this.currentPageNumber = pageNumber < 0 ? 1 : this.totalPages;
    } else {
      this.currentPageNumber = pageNumber;
    }

    this.$emit('paging-change', take, this.currentPageNumber);
  }

  private replaceViewText(views: number) {
    return this.viewsPerPageSelectionText.replace('{views}', views.toString());
  }
}
</script>

<style lang="scss">
.ade-paging {
  .btn-page {
    width: 100px;
    text-align: center;
  }
  .btn-step {
    width: 45%;
  }
  .btn-views {
    padding-right: 0;
    padding-left: 0;
    min-width: 50px;
  }
  .btn-step,
  .input-step {
    @include media-breakpoint-up('sm') {
      width: 160px;
    }
  }
}
</style>
