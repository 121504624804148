var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-100 position-relative ce-header-image"},[_c('div',{staticClass:"aspect-ratio-1600-540 w-100 ce-header-image__image-container"},[_vm._t("header-image")],2),(_vm.$slots['left-block'] || _vm.$slots['right-block'])?_c('div',{staticClass:"ce-header-image__overlay pt-3 pt-lg-0"},[_c('div',{class:[
        'content-container',
        'content-container-x',
        'container-fluid',
        'd-flex',
        'flex-column',
        'flex-lg-row',
        'align-items-center',
        'h-100',
        'my-0',
        { 'w-100': _vm.$screen.lg },
      ]},[_c('div',{staticClass:"row justify-content-between w-100"},[_c('div',{staticClass:"col-12 col-lg-7 d-flex align-items-center"},[(_vm.$slots['left-block'])?_c('div',{class:['ce-header-image__block', 'p-lg-3', 'p-xl-6', 'w-100', { 'bg-transparent': _vm.hideLightBox }]},[_c('div',{staticClass:"ce-header-image__inner-block"},[_vm._t("left-block")],2)]):_vm._e()]),_c('div',{staticClass:"col-12 col-lg-4 d-flex align-items-center"},[_c('div',{staticClass:"ce-header-image__block my-5 my-lg-0 w-100"},[_c('div',{staticClass:"ce-header-image__inner-block"},[_vm._t("right-block")],2)])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }