<template>
  <div
    v-bind="$attrs"
    v-bind:class="[
      { 'pt-4': mobileView },
      { 'pb-1': mobileView },
      mobileView ? 'collapse-mobileview' : 'collapse-fullview',
    ]"
  >
    <a v-b-toggle v-if="mobileView" :href="collapseTarget" @click.prevent class="header">
      <h3
        ><ad-icon-chevron size="m" :rotate="iconRotation" class="mr-2"></ad-icon-chevron>
        <slot name="header"></slot>
      </h3>
    </a>
    <slot name="header-desktop" v-if="!mobileView && showDesktopHeader">
      <h2 :class="['mb-2', 'mb-lg-5', { 'fs-26': !$screen.xl }]"><slot name="header"></slot></h2>
    </slot>
    <b-collapse :id="collapseId" :visible="visible || !mobileView" @input="toogleCollapse" :ref="collapseId">
      <b-card-body class="px-0 py-sm-0">
        <slot name="default"></slot>
      </b-card-body>
    </b-collapse>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BCollapse } from 'bootstrap-vue';
@Component({
  inheritAttrs: false,
})
export default class AdCollapseOnMobile extends Vue {
  private static uuId = 0;
  private currentUuId = AdCollapseOnMobile.uuId++;

  @Prop({ type: Boolean, default: false }) showCollapsed!: boolean;
  @Prop({ default: 'sm' }) breakpoint!: string;
  @Prop({ default: true, type: Boolean }) showDesktopHeader!: boolean;
  private visible = false;

  private get collapseTarget() {
    return `#${this.collapseId}`;
  }
  private get collapseId(): string {
    return `typeTable-${this.currentUuId}`;
  }

  private get mobileView(): boolean {
    return !this.$screen[this.breakpoint];
  }

  private get iconRotation(): number {
    return this.visible ? 180 : 0;
  }

  created() {
    this.visible = !this.showCollapsed;
  }

  toogleCollapse(state: boolean) {
    this.visible = state;
  }

  @Watch('$screen.sm')
  forceOpen() {
    if (!this.mobileView) {
      (this.$refs[this.collapseId] as BCollapse).show = true;
    }
  }
}
</script>
<style lang="scss">
.collapse-mobileview {
  margin-top: -1px;
  margin-right: calc($grid-gutter-width / -2);
  margin-left: calc($grid-gutter-width / -2);
  border-top: 1px solid $peacock-blue;
  border-bottom: 1px solid $peacock-blue;
  padding: 0 $container-spacing;
}
</style>
