import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import {
  checkForAllElementsIfAppearanceShouldTrigger,
  isElementInViewport,
  startCheckingAppearance,
  stopCheckingAppearance,
} from './helper/viewportChecker';

/**
 * Use this directive to mark a container that contains appearing elements OR elements that appear themselves.
 *
 * Example:
 *
 * <div v-appear-trigger v-appear-delay="1">
 *   I appear when scrolled into view.
 * </div>
 *
 * or:
 *
 * <ul v-appear-trigger>
 *   <li v-appear-delay="0">First</li>
 *   <li v-appear-delay="1">Second</li>
 *   <li v-appear-delay="2">Third</li>
 * </ul>
 */
Vue.directive('appear-trigger', {
  inserted: (el: HTMLElement, binding: DirectiveBinding) => {
    if (isElementInViewport(el)) {
      // If element is already in viewport
      el.classList.add(`appear-without-animation`);
      return;
    }
    el.classList.add(`show-animation-delay-${binding.value}`);

    startCheckingAppearance({
      el,
      firstAppearanceHandler: () => el.classList.add('animation-triggered'),
    });

    checkForAllElementsIfAppearanceShouldTrigger();
  },

  unbind: (el: HTMLElement) => {
    stopCheckingAppearance(el);
  },
});
