<template>
  <div>
    <div class="row ce-author-small align-items-center justify-content-between mx-0 px-0">
      <div class="ce-author-small__author col-12 col-md-auto px-0">
        <img :src="authorImageUrl" class="ce-author-small__image" v-if="authorImageUrl" />
        <div class="ce-author-small__text fs-16 d-flex flex-column">
          <span><slot name="author-name"></slot></span>
          <span>{{ settingsProperty.publishedDate | formatField(dateFormatKey) }}</span>
        </div>
      </div>
      <div class="col-12 col-md-auto ce-author-like-share d-flex justify-content-md-end px-0">
        <div class="row align-self-stretch mx-0 align-items-center flex-grow-1">
          <div
            v-if="settingsProperty.contentId"
            class="d-flex justify-content-start justify-content-md-end col-12 col-md-auto fs-14 ce-author-like__container px-0 my-3 mr-md-2"
          >
            <ad-like-count :content-id="settingsProperty.contentId" :show-count-on-left="true"></ad-like-count>
            <span class="pl-1"><slot name="social-like"></slot></span>
          </div>
          <div class="col-12 col-md-auto ce-author-like-share__buttons d-flex px-0">
            <ce-facebook-button :shareURL="shareUrl" :facebookAppId="settingsProperty.facebookAppId">
              <template #label><slot name="social-share-facebook"></slot></template>
            </ce-facebook-button>
            <div class="spacer pr-4 pr-md-3 py-1"></div>
            <ce-twitter-button :shareURL="shareUrl" :tweetText="settingsProperty.twitterTeaserText">
              <template #label><slot name="social-share-twitter"></slot></template>
            </ce-twitter-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DefaultFormat } from '@/src/types/enumerations';
import { BlogPageSettings } from '@/src/types/episerver-api';
import { Component, Inject, Vue } from 'vue-property-decorator';

@Component
export default class CeAuthorSmall extends Vue {
  @Inject()
  settingsProperty!: BlogPageSettings;
  get authorImageUrl(): string {
    return this.settingsProperty.authorImageUrl ?? '';
  }

  get shareUrl(): string {
    return document.location.href;
  }

  get dateFormatKey(): string {
    return DefaultFormat.BlogDate;
  }
}
</script>

<style lang="scss">
.ce-author-small {
  border-top: 1px dashed $gunmetal;
  border-bottom: 1px dashed $gunmetal;
  padding: map-get($spacers, 3) map-get($spacers, 2);

  &__author {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__text {
    padding-left: rem(8);
  }
  &__image {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding: map-get($spacers, 2) map-get($spacers, 2);
  }
}

.ce-author-like-share {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &__share-button {
    flex-shrink: 0;
  }

  &__buttons {
    flex-direction: column;

    @media (min-width: 400px) {
      flex-direction: row;
    }
  }
}
</style>
