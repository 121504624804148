/**
 * Controler end points for theq api
 */
export enum Controller {
  Underlying,
  Product,
  ProductSearch,
  BestMatch,
  FieldFormats,
  Charts,
  RealtimeCharts,
  TriggerProbabilityCharts,
  RollingHistory,
  StrikeHistory,
  ContentLikes,
  GlobalSearch,
  NewsletterSubscription,
  DataSource,
  Document,
  Watchlist,
  Priip,
}

export enum TextImageTeaserTypes {
  Image = 'image',
  Video = 'video',
}

/**
 * Bootstrap vue items https://bootstrap-vue.org
 */

export enum PopoverPosition {
  Auto = 'auto',
  Top = 'top',
  TopLeft = 'topleft',
  TopRight = 'topright',
  Right = 'right',
  RightTop = 'righttop',
  RightBottom = 'rightbottom',
  Bottom = 'bottom',
  BottomLeft = 'bottomleft',
  BottomRight = 'bottomright',
  Left = 'left',
  LeftTop = 'lefttop',
  LeftBottom = 'leftbottom ',
}

export enum PopoverFallback {
  Flip = 'flip',
  Clockwise = 'clockwise',
  Counterclockwise = 'counterclockwise ',
}

export enum TextAlign {
  Left = 'Left',
  Right = 'Right',
  Center = 'Center',
}
export enum SortingDirection {
  Descending = 'Descending',
  Ascending = 'Ascending',
}
export enum Separator {
  NewLine = 'NewLine',
  Slash = 'Slash',
}

/**
 * Lightstreamer
 */
export enum PriceProvider {
  Citi = 0,
  TtmZeroUnderlying = 1,
  TtmZeroProduct = 2,
  CitiAu = 3,
}
export enum InstrumentOriginTimeZone {
  CET,
  EET,
  GMT,
  AET,
}

/**
 * Data default formatings
 * Note: Default format settings are starting with an upper letter
 */
export enum DefaultFormat {
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  Number = 'Number',
  Decimal = 'Decimal',
  Percent = 'Percent',
  Ordinal = 'Ordinal',
  DocumentDate = 'DocumentDate',
  BlogDate = 'BlogDate',
}

/**
 * episerver Settings
 */
export enum AnyCheckOptions {
  Yes = 'Yes',
  No = 'No',
  Any = 'Any',
}

export enum ProductSubTypeAny {
  Call = 'Call',
  Put = 'Put',
  Any = 'Any',
}

/**
 * Product
 */

export enum ProductSubTypeBo {
  Call = 'Call',
  Put = 'Put',
}

export const ProductSubTypeAnyMap = new Map<ProductSubTypeAny, ProductSubTypeBo | null>()
  .set(ProductSubTypeAny.Call, ProductSubTypeBo.Call)
  .set(ProductSubTypeAny.Put, ProductSubTypeBo.Put)
  .set(ProductSubTypeAny.Any, null);

export enum ProductTypeBo {
  Warrant = 'Warrant',
  SpreadWarrant = 'SpreadWarrant',
  Turbo = 'Turbo',
  OpenEndTurbo = 'OpenEndTurbo',
  MiniFuture = 'MiniFuture',
  Factor = 'Factor',
  Discount = 'Discount',
  Bonus = 'Bonus',
  ReverseBonus = 'ReverseBonus',
  CappedReverseBonus = 'CappedReverseBonus',
  CappedBonus = 'CappedBonus',
  IndexTracker = 'IndexTracker',
  Outperformance = 'Outperformance',
  Sprint = 'Sprint',
  GslMini = 'GslMini',
  Instalment = 'Instalment',
  InstalmentMini = 'InstalmentMini',
  Instreet = 'Instreet',
  MasonStevens = 'MasonStevens',
  SelfFundingInstalment = 'SelfFundingInstalment',
  SelfFundingInstalmentMini = 'SelfFundingInstalmentMini',
  Obbligazioni = 'Obbligazioni',
  Certificati = 'Certificati',
}

/**
 * Product
 */

export enum ProductHeadlineLines {
  OneLine = 'OneLine',
  TwoLines = 'TwoLines',
}

export enum ExchangeBo {
  EUWX,
  XAMS,
  XNDX,
  XPAR,
  XLIS,
  NMTF,
}

export enum ExerciseStyleBo {
  European,
  American,
}

export enum SettlementTypeBo {
  Cash,
  Physical,
}

export enum Irs871 {
  I,
  D,
  G,
}

export enum TopLeverageTableFilter {
  TopLeverageAexNl,
  TopLeverageIndicesNl,
  TopLeverageShareNl,
  TopLeverageGoldEuroUsdNl,
}

export enum DigitalAssetDocumentType {
  None,
  BaseProspectus,
  Summary,
  Supplement,
  SecuritiesNote,
}

/**
 * Underlying
 */
export enum UnderlyingType {
  Default = 'Default',
  LeveragedUnderlying = 'LeveragedUnderlying',
  RollingFutureUnderlying = 'RollingFutureUnderlying',
  DefaultCacBase = 'DefaultCacBase',
}

/**
 * Routings for episerver
 */

export enum RoutingParameters {
  Isin = 'Isin',
  IsinOrNsin = 'IsinOrNsin',
  Width = 'Width',
  Height = 'Height',
  UnderlyingName = 'UnderlyingName',
}

/**
 * Colors
 */
export enum Colors {
  black = '#0f1632',
  cyanBlue = '#73c2fc',
  turquoiseBlue = '#00af68',
  marineBlue = '#002d72',
  deepRed = '#ff3c28',
}

/**
 * Chart types
 */

export enum ChartColors {
  underlying = Colors.black,
  productBid = Colors.cyanBlue,
  productAsk = Colors.deepRed,
  triggerProbability = Colors.turquoiseBlue,
}

export enum ChartPeriods {
  Intraday = 'intraday',
  Week = 'week',
  Month = 'month',
  Year = 'year',
  Year3 = 'year3',
  Year5 = 'year5',
}

export enum ChartSeries {
  price = 'Price',
  bid = 'Bid',
  ask = 'Ask',
  realtime = 'Realtime',
}

export enum Size {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
}

export enum Direction {
  down = 0,
  up = 180,
  right = 270,
  left = 90,
}

export enum ComparisonOperators {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  Less = 'Less',
  Greater = 'Greater',
  LessOrEqual = 'LessOrEqual',
  GreaterOrEqual = 'GreaterOrEqual',
}

export enum ChartPatterns {
  Candlestick = 'candlestick',
  Line = 'line',
}

/**
 * Vue Component Enums
 */

export enum DropDownItemType {
  item = 'Item',
  header = 'Header',
  splitter = 'Splitter',
}

export enum NewTeaserType {
  ImageTop = 'ImageTop',
  ImageBottom = 'ImageBottom',
  ImageRight = 'ImageRight',
}

/**
 * Global Objects
 */
export enum EntityType {
  Product = 'Product',
  Underlying = 'Underlying',
}

export enum MissingCookieViewMode {
  //Modal overlay view
  Popup = 0,
  //Video content replacement
  Content = 1,
  //Single line text
  Text = 2,
}

export enum MissingCookieModalId {
  MissingLikeCookieModal = 'MissingLikeCookieModal',
  MissingWatchlistCookieModal = 'MissingWatchlistCookieModal',
}

export enum TextImageTeaserBackground {
  White = 'white',
  Grey = 'grey-background',
  WithSeparatingLine = 'with-separating-line',
}

export enum HttpStatusCodes {
  OK = 200,
  Created = 201,
  NoContent = 204,
  BadRequest = 400,
  NotFound = 404,
  InternalServerError = 500,
  UnknownError = 0,
}

export enum CookieType {
  Technical = 'Technical',
  Marketing = 'Marketing',
  Comfort = 'Comfort',
  ThirdPartyMedia = 'ThirdPartyMedia',
}

export enum CookieCreateState {
  Created = 1,
  Exists = 2,
  NotAccepted = 3,
}

export enum ArchiveReason {
  Delisting = 0,
  Termination = 1,
  KnockedOut = 2,
  Matured = 3,
}

/**
 * Product key mapping
 */
export enum ProductKey {
  Agio = 'product.agio',
  BonusYield = 'product.',
  BonusLevel = 'product.bonusLevel.amount',
  BonusBarrier = 'product.bonusBarrier.amount',
  CapBonusLevel = 'product.capBonusLevel.amount',
  ChangePercent = 'product.price.changePercent',
  CapBonus = 'product.capBonusCap.amount',
  CapBonusBarrier = 'product.capBonusBarrier.amount',
  DeltaOverRatio = 'product.ttmKeyData.blackScholesQuantities.deltaOverRatio',
  DistanceToStopLoss = 'product.distanceToStopLoss',
  Discount = 'product.ttmKeyData.spotAndCertPrice.discount',
  Factor = 'product.factor',
  IssueDate = 'product.issueDate',
  KoBarrier = 'product.koBarrier.amount',
  Leverage = 'product.ttmKeyData.spotAndCertPrice.leverage',
  MaturityDate = 'product.maturityDate',
  MaximumAmount = 'product.ttmKeyData.independent.maximumAmount',
  MaximumYield = 'ttmKeyData.certPrice.maximumYield',
  Omega = 'product.ttmKeyData.blackScholesQuantities.omega',
  Participation = 'product.participationRate',
  RevBonusBarrier = 'product.revBonusBarrier.amount',
  RevCapBonusBarrier = 'product.revCapBonusBarrier.amount',
  RevBonusLevel = 'product.revBonusLevel.amount',
  RevCapBonusLevel = 'product.revCapBonusLevel.amount',
  RiskBuffer = 'product.riskBuffer',
  Strike = 'product.strike.amount',
  SpreadWarrantCapFloor = 'product.spreadWarrantCapFloor',
  SprintStartValue = 'product.sprintStartValue.amount',
  SprintStopValue = 'product.sprintStopValue.amount',
  UnderlyingName = 'product.underlyings.0.name',
}

export enum DocumentType {
  OtherDigitalAsset = 'OtherDigitalAsset',
  FinalTerms = 'FinalTerms',
  BaseProspectus = 'BaseProspectus',
  Supplement = 'Supplement',
  Factsheet = 'Factsheet',
  KeyInformationDocument = 'KeyInformationDocument',
  ReductionInYield = 'ReductionInYield',
  SecuritiesNote = 'SecuritiesNote',
}

export enum TopFlop {
  Top = 'Top',
  Flop = 'Flop',
}

export enum KnockoutTimeIndicativeInfo {
  KoTimeInCetIsIndicative = 'KoTimeInCetIsIndicative',
  ClosingAuctionMayAlsoLeadToKo = 'ClosingAuctionMayAlsoLeadToKo',
}

export enum SearchState {
  Initial,
  Failed,
  NotFound,
}
