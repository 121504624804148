<template>
  <div class="aspect-ratio-16-9 w-100">
    <video v-if="videoPath" class="aspect-ratio-filler" :controls="!noControls" ref="htmlVideo">
      <source :src="videoPath" />
      Your browser does not support this type of video.
    </video>
    <iframe
      v-else-if="youtubeId"
      class="aspect-ratio-filler border-0"
      :src="youtubeUrl"
      allowfullscreen
      allow="autoplay"
      ref="youtubeFrame"
      :style="{ objectFit: 'initial' }"
    ></iframe>
    <div v-else-if="!videoPath && !youtubeId"
      >Can't display video component because neither a youtube URL nor a video path was provided</div
    >
  </div>
</template>
<script lang="ts">
import { getYoutubeUrl } from '@utils/url-helper';
import { Prop, Vue, Component } from 'vue-property-decorator';

@Component
export default class CeVideo extends Vue {
  //youtubeId - code/id of the youtube video
  @Prop({ default: '' }) youtubeId!: string;
  @Prop({ default: '' }) videoPath!: string;
  @Prop({ default: false }) noControls!: boolean;
  @Prop({ default: false }) autoPlay!: boolean;

  get youtubeUrl(): string {
    return getYoutubeUrl(this.youtubeId, { enableJsAPI: true, autoPlay: this.autoPlay });
  }

  mounted() {
    if (this.autoPlay) this.play();
  }

  play() {
    if (this.videoPath) {
      const video = this.$refs.htmlVideo as HTMLVideoElement;
      video.play();
    }
  }
}
</script>
