<template>
  <div class="underlying-indications-block">
    <b-row class="pb-3">
      <b-col>
        <div>
          <h2 class="mb-n2 d-inline"><slot name="headline"></slot> </h2>
          <span><slot name="info-icon"></slot></span>
        </div>
        <span class="marine-blue fs-14"><slot name="subline"></slot></span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="6" class="px-1 pr-lg-0 pr-lg-2">
        <ad-error :http-status-code="statusCode">
          <ad-instrument-table
            :loading="isLoading"
            ref="table"
            :settings="settings"
            v-bind="$attrs"
            :instruments="teaserUnderlyings"
            selectable
            select-mode="single"
            selected-variant="secondary"
            @row-clicked="selectUnderlying"
          >
            <template #row-instrument="{ setting, instrument }">
              <ad-underlying-data-value
                :isLinkToDetailPage="setting.isLinkToDetailPage"
                :underlying-item="instrument"
                :field-key="setting.formatId"
              />
            </template>
            <template #row-custom-instrument="{}">
              <div class="chart-icon">
                <ad-icon-chart-line height="1em" width="1em" iconName="" />
              </div>
            </template>
          </ad-instrument-table>
        </ad-error>
      </b-col>
      <b-col cols="12" xl="6">
        <ad-underlying-data-property :underlying="selectedUnderlying" :key="teaserKey">
          <slot name="indication-teaser-chart"></slot>
        </ad-underlying-data-property>
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import { Action, Getter } from 'vuex-class';
import { Direction, HttpStatusCodes, Separator, TextAlign } from '@/src/types/enumerations';
import { UnderlyingIndicationTableBlockSetting, TableColumnSettingBase } from '@/src/types/episerver-api';

import { InstrumentTableSettingData, TableField } from '@/src/types/vue-api';
import { Component, Inject, Ref, Vue } from 'vue-property-decorator';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { BTable } from 'bootstrap-vue';
import { isAxiosError } from '@/src/types/type-guards';

@Component
export default class AdUnderlyingIndicationsTable extends Vue {
  @Inject() settingsProperty!: UnderlyingIndicationTableBlockSetting;

  @Action('loadTeaserUnderlyingsAsync', { namespace: 'underlying' })
  loadTeaserUnderlyingsAsync!: (isin: string[] | string) => Promise<void>;

  @Getter('teaserUnderlyings', { namespace: 'underlying' })
  teaserUnderlyings!: Array<UnderlyingModel> | null;

  private fields: Array<TableField> = [];

  private separator = Separator;
  private direction = Direction;
  private settings!: InstrumentTableSettingData;
  private statusCode: number | HttpStatusCodes = HttpStatusCodes.OK;
  private isLoading = true;
  @Ref()
  private table;

  selectedUnderlying: UnderlyingModel | null = null;

  selectUnderlying(item: UnderlyingModel) {
    this.selectedUnderlying = item;
  }

  async created(): Promise<void> {
    this.settings = {
      desktopTableColumnsSetting: [
        ...this.settingsProperty.desktopTableColumnsSetting,
        { headline: '', textAlign: TextAlign.Right, isEllipsis: false, columnWidth: null } as TableColumnSettingBase,
      ],
      tabletTableColumnsSetting: [
        ...this.settingsProperty.tabletTableColumnsSetting,
        { headline: '', textAlign: TextAlign.Right, isEllipsis: false, columnWidth: null } as TableColumnSettingBase,
      ],
      mobileTableColumnsSetting: [
        ...this.settingsProperty.mobileTableColumnsSetting,
        { headline: '', textAlign: TextAlign.Right, isEllipsis: false, columnWidth: null } as TableColumnSettingBase,
      ],
      tableColumnSorting: [],
      hideDetailColumn: this.settingsProperty.hideDetailColumn,
    } as InstrumentTableSettingData;

    await this.loadUnderlyingsWithErrorHandling();

    if (this.teaserUnderlyings && this.teaserUnderlyings?.length != 0) {
      this.selectUnderlying(this.teaserUnderlyings[0]);
      (this.table.$refs.table as BTable).selectRow(0);
    }
  }

  async loadUnderlyingsWithErrorHandling() {
    this.isLoading = true;

    try {
      await this.loadTeaserUnderlyingsAsync(this.settingsProperty.underlyingIsins);
    } catch (error: unknown) {
      this.$log.error('Loading Error', error);

      if (isAxiosError(error)) {
        this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
        this.$log.error('Status of error', error?.response?.status);
      } else {
        this.statusCode = HttpStatusCodes.UnknownError;
      }
    }

    this.isLoading = false;
  }

  get teaserKey() {
    return this.selectedUnderlying?.isin || null;
  }
}
</script>

<style lang="scss">
$chart-value-font-size: $fs-12;
$chart-date-font-size: $fs-10;

.underlying-indications-block {
  .table th {
    padding-top: 0 !important; //has to be more important than table th

    &:last-child {
      // fixes chart-icon being cut off
      width: 52px !important;
    }
  }

  td:last-child {
    .ade-icon {
      background-color: $light-blue-grey;
      width: 28px;
      min-width: 28px;
      height: 28px;
    }
  }

  .highcharts-yaxis-labels text {
    font-size: $chart-value-font-size !important;
  }
  .highcharts-xaxis-labels text {
    font-size: $chart-date-font-size !important;
  }

  .icon-size {
    .ade-icon {
      width: rem(20);
      height: rem(20);
    }
  }
}
</style>
