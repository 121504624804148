import { Controller } from '@/src/types/enumerations';
import { AvailableUnderlyingTranslationsModel } from '@/src/types/the-q-api';
import { getTheQApiUrl } from '@/src/utils/url-helper';
import httpClient from '@/src/utils/http-service';
import { Commit } from 'vuex';

export const state = {
  countries: [] as Array<string>,
  categories: [] as Array<string>,
  sectors: [] as Array<string>,
  realtimeCategories: [] as Array<string>,
};

type State = typeof state;

export const getters = {
  countries: (state: State) => {
    return state.countries;
  },
  categories: (state: State) => {
    return state.categories;
  },
  sectors: (state: State) => {
    return state.sectors;
  },
  realtimeCategories: (state: State) => {
    return state.realtimeCategories;
  },
};

enum MutationTypes {
  CACHE_CATEGORIES = 'CACHE_CATEGORIES',
  CACHE_COUNTRIES = 'CACHE_COUNTRIES',
  CACHE_SECTORS = 'CACHE_SECTORS',
  CACHE_REALTIMECATEGORIES = 'CACHE_REALTIMECATEGORIES',
}

export const mutations = {
  [MutationTypes.CACHE_CATEGORIES](state: State, categories: Array<string>): void {
    state.categories = categories;
  },
  [MutationTypes.CACHE_COUNTRIES](state: State, countries: Array<string>): void {
    state.countries = countries;
  },
  [MutationTypes.CACHE_SECTORS](state: State, sectors: Array<string>): void {
    state.sectors = sectors;
  },
  [MutationTypes.CACHE_REALTIMECATEGORIES](state: State, realtimeCategories: Array<string>): void {
    state.realtimeCategories = realtimeCategories;
  },
};

export const actions = {
  async loadTranslationsAsync({ commit, state }: { commit: Commit; state: State }): Promise<void> {
    // Return cached translations if already fetched
    if (state.categories.length) {
      return Promise.resolve();
    }

    // Fetch and save in Cache
    const response = await httpClient.get<AvailableUnderlyingTranslationsModel>(
      getTheQApiUrl(Controller.Underlying, 'GetAvailableTranslations')
    );

    commit(MutationTypes.CACHE_CATEGORIES, response.data.categories);
    commit(MutationTypes.CACHE_COUNTRIES, response.data.countries);
    commit(MutationTypes.CACHE_SECTORS, response.data.sectors);
    commit(MutationTypes.CACHE_REALTIMECATEGORIES, response.data.realtimeCategories);
  },
};
