<template>
  <b-row>
    <b-col lg="8" order="2">
      <template v-if="!isProductHeaderReduced">
        <template v-if="!smallHeader">
          <h1 :class="twoLinesVisible ? 'm-40-20' : 'm-60-30'">
            <ad-product-data-value
              field-key="product.productNameTranslation.productNameFirstLine"
            ></ad-product-data-value>
          </h1>
          <h2 class="m-60-30" v-if="twoLinesVisible">
            <ad-product-data-value
              field-key="product.productNameTranslation.productNameSecondLine"
            ></ad-product-data-value>
          </h2>
        </template>
        <template v-else>
          <h3>
            <ad-product-data-value
              field-key="product.productNameTranslation.productNameFirstLine"
            ></ad-product-data-value
          ></h3>
          <h3 v-if="twoLinesVisible">
            <ad-product-data-value
              field-key="product.productNameTranslation.productNameSecondLine"
            ></ad-product-data-value>
          </h3>
        </template>
      </template>
    </b-col>
    <b-col lg="3" class="text-right" order="3">
      <b-row>
        <slot name="status-icons"></slot>
      </b-row>
    </b-col>
    <b-col lg="1" class="text-right pt-lg-3 pb-3 pb-lg-0" order="1" order-lg="4">
      <ad-product-watchlist-toggle />
    </b-col>
  </b-row>
</template>
<script lang="ts">
import { ProductDetailBlockSetting } from '@/src/types/episerver-api';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { ProductHeadlineLines } from '@/src/types/enumerations';
@Component({
  inheritAttrs: false,
})
export default class AdProductHeader extends Vue {
  @Inject({ default: { reduceProductHeader: true } })
  settingsProperty?: ProductDetailBlockSetting;
  @Prop() headerMode?: ProductHeadlineLines;
  @Prop({ default: false }) smallHeader!: boolean;

  get twoLinesVisible(): boolean {
    return this.settingsProperty?.headlineMode === ProductHeadlineLines.TwoLines;
  }

  // true if reduceProductHeader is true
  // false if reduceProductHeader is false or null
  get isProductHeaderReduced(): boolean {
    return this.settingsProperty?.reduceProductHeader == true;
  }
}
</script>
