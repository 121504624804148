<template>
  <ad-product-data-property :product="product">
    <ad-underlying-data-property :underlying="underlying">
      <ad-chart-configuration-property :chart-configuration-property="chartConfiguration">
        <ad-anchor-layout :show-navigation="$screen.xl">
          <template #layout-navigation>
            <slot name="anchor-navigation"></slot>
          </template>
          <template #layout-content>
            <ad-product-page-title
              :productModel="product"
              :hidePrices="isProductHeaderReduced"
              v-if="isProductHeaderVisible"
            />
            <ad-product-header :productModel="product" v-if="isProductHeaderVisible">
              <template #status-icons>
                <slot name="status-icons" v-if="product"></slot>
              </template>
            </ad-product-header>
            <slot name="important-notices" v-if="product && isProductHeaderVisible && !isProductHeaderReduced"></slot>
            <slot name="product-main-information" v-if="isProductHeaderVisible"></slot>
            <template v-if="isProductHeaderVisible && !isProductHeaderReduced">
              <b-row v-if="!isArchiveDetails">
                <b-col cols="12" lg="7" class="pt-3 pb-3 hr">
                  <slot name="product-price-data"></slot>
                </b-col>
                <b-col cols="12" lg="5" class="pt-3 pb-5 pb-lg-3 hr bl-lg">
                  <slot name="underlying-price-data"></slot>
                </b-col>
              </b-row>
              <b-row>
                <div v-if="$slots['risk-disclaimer'] === undefined" class="col-12 pt-lg-7 pt-4 hr d-none d-sm-block">
                </div>
                <div v-if="$slots['risk-disclaimer']" class="col-12 hr pt-lg-6 mb-lg-3 pt-4">
                  <slot name="risk-disclaimer"></slot>
                </div>
              </b-row>
            </template>
            <b-row>
              <slot name="items"></slot>
            </b-row>
          </template>
        </ad-anchor-layout>
      </ad-chart-configuration-property>
    </ad-underlying-data-property>
  </ad-product-data-property>
</template>
<script lang="ts">
import { ProductModel, UnderlyingModel } from '@/src/types/the-q-api';
import { ProductDetailBlockSetting } from '@/src/types/episerver-api';
import { Component, Inject, Vue, Prop } from 'vue-property-decorator';
import AdProductHeader from '@/src/components/organisms/ad-product-details/ad-product-header.vue';
import AdProductPageTitle from '@/src/components/organisms/ad-product-details/ad-product-page-title.vue';
import AdAnchorLayout from '@components/molecules/ad-anchor-layout/index.vue';
import { ChartConfiguration } from '@/src/types/vue-api';
import { ProductHeadlineLines, UnderlyingType } from '@/src/types/enumerations';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    AdProductHeader,
    AdProductPageTitle,
    AdAnchorLayout,
  },
})
export default class AdProductDetails extends Vue {
  @Inject({ default: { hideProductHeader: false, reduceProductHeader: true } })
  settingsProperty?: ProductDetailBlockSetting;
  @Prop()
  productHeaderMode!: ProductHeadlineLines;

  @Getter('product', { namespace: 'product' })
  product!: ProductModel | null;

  @Action('registerProductUnderlyingForPush', { namespace: 'underlying' })
  registerProductUnderlyingForPush!: (underlying: UnderlyingModel) => void;

  get isArchiveDetails(): boolean | null {
    if (!this.product) return null;
    return this.product?.lifetimeCycle != null;
  }

  // true if hideProductHeader is false or null
  // false if hideProductHeader is true
  get isProductHeaderVisible(): boolean {
    return !this.settingsProperty?.hideProductHeader;
  }

  // true if reduceProductHeader is true
  // false if reduceProductHeader is false or null
  // will be ignored if product header is hidden
  get isProductHeaderReduced(): boolean {
    return this.settingsProperty?.reduceProductHeader == true;
  }

  get underlying() {
    if (this.product && this.product.underlyings) {
      const underlyingDefault = this.product.underlyings.find((u) => u.underlyingType === UnderlyingType.Default);
      if (underlyingDefault !== undefined) {
        if (!this.isProductHeaderReduced) {
          this.registerProductUnderlyingForPush(underlyingDefault);
        }
        return underlyingDefault;
      }
      // return empty object so ad-underlying-data-value don't show skeleton loading item
      return {};
    }

    return null;
  }

  chartConfiguration = new ChartConfiguration();

  created(): void {
    this.chartConfiguration.legendInTwoLineModeAtTablet = true;
  }
}
</script>
