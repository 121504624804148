<template>
  <ad-icon-base iconName="warnings" v-bind="$attrs">
    <path
      d="M20,13.3c-0.5,0-0.9,0.4-0.9,0.9v8.7l0.3,5.5c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.5-0.3,0.6-0.6l0.3-5.5v-8.7
		C20.9,13.7,20.5,13.3,20,13.3z M20,31.2c-0.3,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.4,0.6-0.4,0.9c0,0.4,0.1,0.7,0.4,0.9
		c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4c0.3-0.3,0.4-0.6,0.4-0.9c0-0.3-0.1-0.7-0.4-0.9C20.6,31.3,20.3,31.1,20,31.2
		L20,31.2z"
    />
    <path
      d="M20,0c-1,0-1.8,0.6-2.2,1.4C17.6,1.7,0.5,35.7,0.1,36.7c-0.4,1.3,0.3,2.7,1.6,3.2C2,40,2.2,40,2.5,40h35
		c1.4,0,2.5-1.1,2.5-2.5c0-0.3-0.1-0.7-0.2-1C39.4,35.6,22.4,1.7,22.2,1.4C21.8,0.5,20.9,0,20,0z M38.3,37.5c0,0.5-0.4,0.8-0.8,0.8
		l0,0h-35c-0.5,0-0.8-0.4-0.8-0.8c0-0.1,0-0.1,0-0.2C2.3,35.8,18.3,4,19.3,2.1c0.2-0.4,0.7-0.6,1.1-0.4c0.2,0.1,0.3,0.2,0.4,0.4
		c0.8,1.6,17,33.9,17.5,35.1C38.3,37.3,38.3,37.4,38.3,37.5z"
    />
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconWarnings extends Vue {}
</script>
