<template>
  <ad-error :http-status-code="statusCode">
    <b-container fluid class="content-container mt-6 mt-md-7 mt-xl-8">
      <b-row>
        <b-col class="mb-5">
          <h1 class="d-inline">
            <slot name="headline"></slot>
            <span class="icon-size align-text-top"><slot name="info-icon"></slot></span>
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" xl="8" class="pb-5 pb-xl-0">
          <h3><slot name="categories-headline"></slot></h3>
          <span v-if="categoriesRadioView" class="fs-14"><slot name="categories-dropdown-headline"></slot></span>
          <ad-skeleton-input :loading="fetchingCategories">
            <div>
              <b-form-radio-group
                v-if="categoriesRadioView"
                id="btn-radios-categories"
                name="radios-btn-categories"
                size="sm"
                button-variant="default"
                :checked="selectedCategory"
                :options="categories"
                @input="selectCategory"
                buttons
                class="mt-2 flex-wrap btn-group-multiline"
              ></b-form-radio-group>

              <b-form-group
                id="input-group-categories"
                label-for="categories-input"
                class="focus-white mb-0"
                v-if="!categoriesRadioView"
              >
                <template #label><slot name="categories-dropdown-headline"></slot></template>
                <ad-drop-down-list
                  :options="dropdownCategories"
                  :value="selectedCategory"
                  @input="selectCategory"
                  form-element-state-id="input-group-categories"
                />
              </b-form-group>
            </div>
          </ad-skeleton-input>
        </b-col>
        <b-col cols="12" md="6" xl="4" v-if="$slots['description']">
          <h3><slot name="description-headline"></slot></h3>
          <p><slot name="description"></slot></p>
          <a
            v-if="settingsProperty.watchlistButtonUrl"
            :href="settingsProperty.watchlistButtonUrl"
            class="btn btn-secondary w-sm-100"
          >
            <slot name="watchlist-button-text"></slot>
          </a>
        </b-col>
      </b-row>
    </b-container>
    <div class="pt-6 pb-7">
      <slot name="realtime-block" :selectedUnderlying="selectedUnderlyingIsin"></slot>
    </div>
    <b-container fluid class="content-container mt-0">
      <b-row>
        <b-col>
          <h2 class="mb-0"><slot name="indication-headline"></slot></h2>
          <span class="fs-14 marine-blue"><slot name="indication-subline"></slot></span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pt-4">
          <slot v-if="cookiesAccepted" name="indication-description"></slot>
          <slot v-else name="missing-cookie" :view-mode="missingCookieBlockViewMode"></slot>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="mb-6">
      <b-row>
        <b-col class="pt-3">
          <ad-instrument-table
            ref="table"
            :settings="tableSettings"
            v-bind="$attrs"
            :instruments="underlyings"
            selectable
            sorting
            @sorting-value-changed="sortingChanged"
            v-sortable-table="getSortingOption"
            select-mode="single"
            selected-variant="secondary"
            @row-clicked="selectUnderlying"
            :loading="fetchingUnderlyings"
            :key="sortKey"
            @pushing-items="pushingItems"
          >
            <template #row-instrument="{ setting, instrument }">
              <ad-underlying-data-value
                :isLinkToDetailPage="setting.isLinkToDetailPage"
                :underlying-item="instrument"
                :field-key="setting.key"
              />
            </template>
            <template #row-custom-instrument="{}">
              <ad-icon-chart-line height="1.8em" width="1.8em" iconName="" class="bg-light-blue-grey rounded" />
            </template>
            <template #row-watch-list="{ instrument }">
              <ad-underlying-watchlist-toggle :underlying-item="instrument" />
            </template>
          </ad-instrument-table>
        </b-col>
      </b-row>
    </b-container>
  </ad-error>
</template>
<script lang="ts">
import { Action, Getter } from 'vuex-class';
import { Component, Inject, Ref, Watch, Vue } from 'vue-property-decorator';
import { RealtimePageSettingPayload, TableColumnSettingBase } from '@/src/types/episerver-api';
import { SortOrder, UnderlyingModel, UnderlyingSearchFilterPayload } from '@/src/types/the-q-api';
import { DropDownItem, InstrumentTableSettingData } from '@/src/types/vue-api';
import { DropDownItemType, HttpStatusCodes, MissingCookieViewMode, TextAlign } from '@/src/types/enumerations';
import CookieManager from '@/src/utils/cookie';
import AdInstrumentTable from '@/src/components/atoms/instruments/ad-instrument-table/index.vue';
import { isAxiosError } from '@/src/types/type-guards';
import { pushPufferNumber } from '@/src/state/plugins/light-streamer/pushing-helper';

@Component
export default class AdRealtimePage extends Vue {
  @Inject() settingsProperty!: RealtimePageSettingPayload;

  @Action('searchUnderlyingsAsync', { namespace: 'realtime' })
  searchUnderlyingsAsync!: ({
    filter,
    sortOrder,
    category,
    minPushItem,
    maxPushItem,
  }: {
    filter: UnderlyingSearchFilterPayload;
    sortOrder: SortOrder | null;
    category: string | null;
    minPushItem: number;
    maxPushItem: number;
  }) => Promise<void>;

  @Getter('underlyings', { namespace: 'realtime' })
  underlyings!: Array<UnderlyingModel> | null;

  @Action('saveRealtimeCategoryAndUpdateSorting', { namespace: 'realtime' })
  saveRealtimeCategoryAndUpdateSorting!: ({
    category,
    oldIndex,
    newIndex,
  }: {
    category: string;
    oldIndex: number;
    newIndex: number;
  }) => void;

  @Action('resortUnderlyings', { namespace: 'realtime' })
  resortUnderlyings!: ({
    sortOrder,
    category,
    minPushItem,
    maxPushItem,
  }: {
    sortOrder: SortOrder | null;
    category: string | null;
    minPushItem: number;
    maxPushItem: number;
  }) => void;

  @Action('updateUnderlyingsPushItems', { namespace: 'realtime' })
  updateUnderlyingsPushItems!: ({ minPushItem, maxPushItem }: { minPushItem: number; maxPushItem: number }) => void;

  @Action('loadTranslationsAsync', { namespace: 'translations' })
  loadTranslationsAsync!: () => Promise<void>;

  @Getter('realtimeCategories', { namespace: 'translations' })
  categories!: Array<string>;

  @Ref()
  table: AdInstrumentTable | undefined;

  private tableSettings!: InstrumentTableSettingData;
  private statusCode: number | HttpStatusCodes = 200;
  private cookieManager!: CookieManager;
  private missingCookieBlockViewMode = MissingCookieViewMode.Text;
  private selectedUnderlyingIsin: string | null = null;
  private selectedCategory: string | null = '';

  private fetchingCategories = true;
  private fetchingUnderlyings = false;
  sortKey = 0;
  sortOrder: SortOrder | null = null;

  async created(): Promise<void> {
    this.cookieManager = new CookieManager(this.$cookies);
    this.mapTableColumns();
    await this.fetchCategories();

    if (
      this.settingsProperty.defaultCategoriesOption &&
      this.categories.includes(this.settingsProperty.defaultCategoriesOption)
    ) {
      this.selectedCategory = this.settingsProperty.defaultCategoriesOption;
    } else if (this.categories.length != 0) {
      this.selectedCategory = this.categories[0];
    }
  }

  private mapTableColumns() {
    const actionsColumn = {
      headline: '',
      textAlign: TextAlign.Center,
      isEllipsis: false,
      columnWidth: null,
    } as TableColumnSettingBase;
    this.tableSettings = {
      desktopTableColumnsSetting: [...this.settingsProperty.tableColumns.desktopTableColumnsSetting, actionsColumn],
      tabletTableColumnsSetting: [...this.settingsProperty.tableColumns.tabletTableColumnsSetting, actionsColumn],
      mobileTableColumnsSetting: [...this.settingsProperty.tableColumns.mobileTableColumnsSetting, actionsColumn],
      tableColumnSorting: [],
      hideDetailColumn: this.settingsProperty.tableColumns.hideDetailColumn,
    } as InstrumentTableSettingData;
  }

  sortingChanged(sortOrders: Array<SortOrder>) {
    if (Array.isArray(sortOrders) && sortOrders.length === 1) this.sortOrder = sortOrders[0];
    else this.sortOrder = null;
    this.resortUnderlyings({
      minPushItem: 0,
      maxPushItem: pushPufferNumber,
      sortOrder: this.sortOrder,
      category: this.selectedCategory,
    });
  }

  private updateCustomSorting(oldIndex: number, newIndex: number) {
    if (oldIndex === newIndex) return;
    if (!this.underlyings || !this.selectedCategory) return;

    this.sortOrder = null;

    if (this.cookiesAccepted)
      this.saveRealtimeCategoryAndUpdateSorting({ category: this.selectedCategory, oldIndex, newIndex });

    this.sortKey += 1;
  }

  private getSortingOption() {
    const onEnd = (oldIndex, newIndex) => {
      this.updateCustomSorting(oldIndex, newIndex);
    };
    return {
      onEnd: (evt) => onEnd(evt.oldIndex, evt.newIndex),
    };
  }

  private pushingItems(min: number, max: number) {
    this.updateUnderlyingsPushItems({
      minPushItem: min,
      maxPushItem: max,
    });
  }

  private get cookiesAccepted(): boolean {
    return this.cookieManager?.comfort || false;
  }

  private async fetchCategories(): Promise<void> {
    this.fetchingCategories = true;
    try {
      await this.loadTranslationsAsync();
      this.fetchingCategories = false;
    } catch (error: unknown) {
      this.$log.error('Loading Error', error);
      this.fetchingCategories = false;
      if (isAxiosError(error)) {
        this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
      }
    }
  }

  private selectCategory(value: string) {
    this.selectedCategory = value;
  }

  @Watch('selectedCategory')
  async fetchCategoryUnderlyings() {
    if (this.selectedCategory) {
      const value =
        this.settingsProperty.allCategoriesOption && this.selectedCategory === this.settingsProperty.allCategoriesOption
          ? null
          : this.selectedCategory;
      this.fetchingUnderlyings = true;
      this.selectedUnderlyingIsin = null;
      await this.searchUnderlyingsAsync({
        filter: { realtimeCategory: value },
        sortOrder: this.sortOrder,
        category: this.selectedCategory,
        minPushItem: 0,
        maxPushItem: pushPufferNumber,
      });

      this.fetchingUnderlyings = false;
      if (this.underlyings && this.underlyings.length !== 0) {
        this.selectedUnderlyingIsin = this.underlyings[0].isin;
      }
    }
  }

  private async selectUnderlying(item: UnderlyingModel) {
    if (this.selectedUnderlyingIsin === item.isin) {
      this.selectedUnderlyingIsin = null;
      await this.$nextTick();
    }
    this.selectedUnderlyingIsin = item.isin;
  }

  private get categoriesRadioView(): boolean {
    return this.$screen.xl;
  }

  get dropdownCategories(): DropDownItem[] {
    return (
      this.categories?.map((option) => {
        return {
          type: DropDownItemType.item,
          text: option,
          value: option,
        } as DropDownItem;
      }) || []
    );
  }
}
</script>
<style lang="scss">
.icon-size {
  .ade-icon {
    width: rem(20);
    height: rem(20);
  }
}

.btn-group-multiline .btn {
  flex-grow: 0;
  margin-bottom: rem(10);
}
</style>
