<template>
  <div id="product-search-filter-container" class="product-search-sorting-container">
    <p class="mb-3 filter-headline"><slot name="filter-headline">Placeholder product type</slot></p>
    <b-card class="mb-3" body-class="pl-0">
      <b-row no-gutters>
        <b-col cols="12" md="6">
          <b-form-group id="product-type-filter-group" class="focus-white" label-for="product-type-filter-dropdownlist">
            <template #label>
              <slot name="filter-product-type-dropdown-label-text">Dropdown label</slot>
            </template>
            <ad-product-type-list
              form-element-state-id="product-type-filter-group"
              id="product-type-filter-dropdownlist"
              v-model="value.productType"
              @selected-value-changed="onProductTypeChange"
            >
              <template #all-items-label>
                <slot name="filter-product-type-dropdown-allitems-text">Filter all label</slot>
              </template>
            </ad-product-type-list>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <ad-product-search-filter-subtype
            :loading="loading"
            v-model="value.subType"
            v-if="settingsProperty.hasSubTypeFilter"
            @selected-value-changed="onProductSubTypeChange"
          >
            <template #filter-label-text>
              <slot name="filter-subtype-label-text">...</slot>
            </template>

            <template #subtype-label-put>
              <slot name="filter-subtype-label-put">Put label</slot>
            </template>

            <template #subtype-label-call>
              <slot name="filter-subtype-label-call">Call label</slot>
            </template>

            <template #subtype-label-both>
              <slot name="filter-subtype-label-both">Both label</slot>
            </template>
          </ad-product-search-filter-subtype>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <ad-product-search-filter-category
            :filter="value"
            v-if="settingsProperty.hasCategoryFilter"
            :availableItems="categoryNames"
            :translations="categories"
            v-model="value.categoryName"
            @selected-value-changed="onFilterValueChanged"
            :loading="loading"
          >
            <template #category-label-text>
              <slot name="filter-category-dropdown-label-text">Select category</slot>
            </template>

            <template #all-items-label-text>
              <slot name="filter-category-dropdown-allitems-text">All categories</slot>
            </template>
          </ad-product-search-filter-category>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col cols="12" md="6">
          <ad-product-search-filter-dropdown
            v-if="settingsProperty.hasCountryFilter"
            :availableItems="countryNames"
            :translations="countries"
            v-model="value.countryName"
            @selected-value-changed="onFilterValueChanged"
            :loading="loading"
          >
            <template #dropdown-label-text>
              <slot name="filter-country-dropdown-label-text">Select country</slot>
            </template>

            <template #all-items-label-text>
              <slot name="filter-country-dropdown-allitems-text">All countries</slot>
            </template>
          </ad-product-search-filter-dropdown>
        </b-col>
        <b-col cols="12" md="6">
          <ad-product-search-filter-dropdown
            v-if="settingsProperty.hasSectorFilter"
            :availableItems="sectorNames"
            :translations="sectors"
            v-model="value.sectorName"
            @selected-value-changed="onFilterValueChanged"
            :loading="loading"
            hide-disabled-items
          >
            <template #dropdown-label-text>
              <slot name="filter-sector-dropdown-label-text">Select sector</slot>
            </template>

            <template #all-items-label-text>
              <slot name="filter-sector-dropdown-allitems-text">All sectors</slot>
            </template>
          </ad-product-search-filter-dropdown>
        </b-col>
      </b-row>
    </b-card>
    <div class="arrow"></div>
    <b-row>
      <b-form-group class="bg-transparent mt-4 border-0">
        <b-form-radio
          :inline="$screen.md"
          :class="{ 'mb-3': !$screen.md }"
          :value="false"
          v-model="isMultiUnderlying"
          @change="onIsMultiUnderlyingChange"
          name="underlying-filter-multi-radios"
          ><slot name="filter-underlying-radio-single-label-text">Single underlying placholder</slot></b-form-radio
        >
        <b-form-radio
          :inline="$screen.md"
          :class="{ 'mb-2': !$screen.md }"
          :value="true"
          v-model="isMultiUnderlying"
          @change="onIsMultiUnderlyingChange"
          name="underlying-filter-multi-radios"
          ><slot name="filter-underlying-radio-multi-text">Multi underlying placholder</slot></b-form-radio
        >
      </b-form-group>
    </b-row>
    <b-row no-gutters>
      <b-col cols="12" md="6">
        <b-form-group id="underlying-filter-group" class="focus-white" label-for="underlying-filter-dropdownlist">
          <template #label>
            <slot name="filter-underlying-dropdown-label-text">Dropdown label</slot>
          </template>
          <ad-underlying-list
            form-element-state-id="underlying-filter-group"
            id="underlying-filter-dropdownlist"
            v-model="value.underlyingIsinOrIsins"
            @selected-value-changed="onUnderlyingChange"
            :class="{ 'd-none': isMultiUnderlying }"
            :available-items="underlyingNames"
            group-items
            :loading="loading"
          >
            <template #all-items-label>
              <slot name="filter-underlying-dropdown-allitems-text">Filter all label</slot>
            </template>
            <template #multiple-placeholder>
              <slot name="filter-underlying-dropdown-multiple-placeholder">Select items label</slot>
            </template>
          </ad-underlying-list>
          <ad-underlying-list
            form-element-state-id="underlying-filter-group"
            id="underlying-filter-dropdownlist"
            v-model="value.underlyingIsinOrIsins"
            @selected-value-changed="onUnderlyingChange"
            :class="{ 'd-none': !isMultiUnderlying }"
            multiple
            :required="isMultiUnderlying"
            :max-selecteable-items="settingsProperty.multiUnderlyingMaxItems"
            :available-items="underlyingNames"
            group-items
            :loading="loading"
          >
            <template #all-items-label>
              <slot name="filter-underlying-dropdown-allitems-text">Filter all label</slot>
            </template>
            <template #multiple-placeholder>
              <slot name="filter-underlying-dropdown-multiple-placeholder">Select items label</slot>
            </template>
          </ad-underlying-list>
        </b-form-group>
        <ad-icon-warnings class="error-icon" />
      </b-col>
    </b-row>

    <b-row no-gutters>
      <slot name="additional-date-range-filter-elements" :availableValues="availableValues" :isLoading="loading"></slot>
    </b-row>
    <b-row no-gutters>
      <slot
        name="additional-numeric-range-filter-elements"
        :availableValues="availableValues"
        :isLoading="loading"
      ></slot>
    </b-row>

    <div class="text-right mt-3">
      <b-button @click="resetFilter()" :disabled="loading" class="w-100 w-md-auto" variant="light" size="s"
        ><slot name="filter-clear-button-text">Clear filter placeholder</slot></b-button
      >
    </div>
  </div>
</template>
<script lang="ts">
import { ProductSearchPageSetting } from '@/src/types/episerver-api';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { ProductSearchParametersFilter } from '@/src/types/vue-api';
import AdProductSearchFilterDropdown from './ad-product-search-filter-dropdown.vue';
import AdProductSearchFilterCategory from './ad-product-search-filter-category.vue';
import { GeneralAvailableValuesModel, ProductSearchDetailedResultModel } from '@/src/types/the-q-api';
import { Action, Getter } from 'vuex-class';

@Component({
  inheritAttrs: false,
  components: {
    AdProductSearchFilterDropdown,
    AdProductSearchFilterCategory,
  },
})
export default class AdProductSearchFilterContainer extends Vue {
  @Inject() settingsProperty!: ProductSearchPageSetting;
  @Prop({ default: false, type: Boolean }) wide!: boolean;
  @Prop({ required: true })
  value!: ProductSearchParametersFilter;
  @Prop({ required: true })
  productSearchDetailResult!: ProductSearchDetailedResultModel | null;
  @Prop({ required: true, type: Boolean }) loading!: boolean;

  @Action('loadTranslationsAsync', { namespace: 'translations' })
  loadTranslationsAsync!: () => Promise<void>;

  @Getter('countries', { namespace: 'translations' })
  countries!: Array<string>;
  @Getter('categories', { namespace: 'translations' })
  categories!: Array<string>;
  @Getter('sectors', { namespace: 'translations' })
  sectors!: Array<string>;

  isMultiUnderlying = false;

  async created(): Promise<void> {
    this.isMultiUnderlying = Array.isArray(this.value.underlyingIsinOrIsins);
    try {
      await this.loadTranslationsAsync();
    } catch (e) {
      this.$log.fatal('translations can not be loaded', e);
    }
  }

  get isFilterValid() {
    return !(
      this.isMultiUnderlying &&
      (this.value.underlyingIsinOrIsins === null || this.value.underlyingIsinOrIsins.length === 0)
    );
  }

  get availableValues(): GeneralAvailableValuesModel {
    return this.productSearchDetailResult?.availableValues ?? ({} as GeneralAvailableValuesModel);
  }

  get sectorNames(): string[] | undefined {
    return this.availableValues.sectorNames;
  }

  get countryNames(): string[] | undefined {
    return this.availableValues.countryNames;
  }

  get categoryNames(): string[] | undefined {
    return this.availableValues.categoryNames;
  }

  get underlyingNames(): { [key: string]: string } | undefined {
    return this.availableValues.underlyingNames;
  }

  onFilterValueChanged(): void {
    if (this.isFilterValid) this.$emit('selected-value-changed');
  }

  onProductSubTypeChange(): void {
    this.$emit('selected-product-sub-type-changed');
  }

  onProductTypeChange(): void {
    this.$emit('selected-product-type-changed');
  }

  onUnderlyingChange(): void {
    if (this.isFilterValid) this.$emit('selected-underlying-changed');
  }

  onIsMultiUnderlyingChange(isMultiUnderlying: boolean) {
    if (!(this.value.underlyingIsinOrIsins === null || this.value.underlyingIsinOrIsins.length === 0)) {
      const emitEvent = this.value.underlyingIsinOrIsins.length > 0;
      this.value.underlyingIsinOrIsins = isMultiUnderlying
        ? [this.value.underlyingIsinOrIsins as string]
        : this.value.underlyingIsinOrIsins[0];

      if (emitEvent) this.$emit('selected-underlying-changed');
    } else {
      this.value.underlyingIsinOrIsins = isMultiUnderlying ? [] : null; // set to null to select all item or set to multi selection
    }
  }

  resetFilter() {
    const filter = new ProductSearchParametersFilter();
    filter.underlyingIsinOrIsins =
      this.isMultiUnderlying && this.value.defaultUnderlying
        ? [this.value.defaultUnderlying]
        : this.value.defaultUnderlying;
    filter.sortOrders = this.value.resetSortOrders;
    filter.resetSortOrders = this.value.resetSortOrders;
    filter.isAddColumnDistributionFee = this.value.isAddColumnDistributionFee;
    filter.productType = this.settingsProperty.productType;
    filter.pageNumber = this.value.pageNumber;
    filter.pageSize = this.value.pageSize;
    filter.defaultUnderlying = this.value.defaultUnderlying;

    this.$emit('input', filter);
    this.$emit('reset-filter');
  }
}
</script>

<style lang="scss">
.product-search-sorting-container {
  $arrow-width: rem(15);
  $arrow-height: rem(20);

  .arrow {
    position: relative;
    left: calc(50% - #{$arrow-width});

    // prevents rendering issue making the page larger than viewport
    width: 0;

    &::after,
    &::before {
      display: block;
      position: absolute;
      border-style: solid;
      border-color: transparent;
      content: '';
    }

    &::before {
      bottom: 0;
      border-width: $arrow-height $arrow-width 0;
      border-top-color: #eaebeb;
    }

    &::after {
      bottom: 2px;
      border-width: $arrow-height $arrow-width 0;
      border-top-color: #fff;
    }
  }

  @include media-breakpoint-down('sm') {
    margin-right: -$grid-gutter-spacer;
    margin-left: -$grid-gutter-spacer;
    border-radius: 0;

    .card {
      margin-right: -$alert-padding-x;
      margin-left: -$alert-padding-x;
      border-radius: 0;
    }
  }
}
</style>
