<template>
  <div id="product-search-sorting-container" class="product-search-sorting">
    <b-button
      variant="light"
      v-bind="$attrs"
      id="popover-sorting-reactive"
      v-bind:class="{ 'w-100': wide }"
      ref="button"
    >
      <ad-icon-sort />
      <slot name="button-sorting-text">Sorting</slot>
    </b-button>

    <!-- Our popover title and content render container -->
    <!-- We use placement 'auto' so popover fits in the best spot on viewport -->
    <!-- We specify the same container as the trigger button, so that popover is close to button -->
    <b-popover
      target="popover-sorting-reactive"
      triggers="click"
      :show.sync="isPopoverShow"
      placement="bottom"
      :fallback-placement="[]"
      container="product-search-sorting-container"
      ref="popover"
    >
      <b-row>
        <b-col
          ><h3>
            <slot name="sorting-headline-text">Sort By</slot>
          </h3></b-col
        >
        <b-col>
          <div class="text-right">
            <b-button @click="close" variant="link" aria-label="Close">
              <ad-icon-close />
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="no-gutters mb-2">
        <b-col cols="7">
          <slot name="sorting-column-headline-text"> Columns </slot>
        </b-col>
        <b-col cols="5">
          <div class="ml-3">
            <slot name="sorting-direction-headline-text"> Ascending/Descending </slot>
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters v-for="(sort, index) in sortings" :key="index">
        <b-col cols="7">
          <b-row no-gutters>
            <b-col cols="1" class="text-nowrap pt-3"> {{ index + 1 }}. </b-col>
            <b-col cols="11">
              <b-form-group :id="'input-group-' + index" class="focus-white form-group-xl-spacing">
                <ad-drop-down-list
                  :options="items"
                  v-model="sort.key"
                  :form-element-state-id="'input-group-' + index"
                  :id="'input-' + index"
                  :is-selectable="isSelectable"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="5">
          <div class="ml-3 mt-2">
            <b-button
              variant="light"
              @click="sortingDirectionChanged(sort, sortingDirection.Ascending)"
              :pressed="sort.direction == sortingDirection.Ascending"
              class="miw-auto sorting right"
            >
              <ad-icon-chevron :direction="direction.up" :size="size.s" />
            </b-button>
            <b-button
              variant="light"
              @click="sortingDirectionChanged(sort, sortingDirection.Descending)"
              :pressed="sort.direction == sortingDirection.Descending"
              class="miw-auto sorting left"
            >
              <ad-icon-chevron :size="size.s" />
            </b-button>
          </div>
        </b-col>
      </b-row>

      <div class="mt-3" v-if="isDistributionFeeVisible">
        <slot name="sorting-add-columns-text">Add columns (optional)</slot>
        <b-form-checkbox name="switch-button" v-model="filter.isAddColumnDistributionFee" switch>
          <slot name="sorting-distribution-fee-text">Distribution Fee</slot>
        </b-form-checkbox>
      </div>

      <div class="text-center mt-5">
        <b-button @click="resetFilter" class="mb-2 mb-md-0" variant="light" size="s"
          ><slot name="sorting-reset-button-text">Reset</slot></b-button
        >
        <b-button @click="sortingChanged" class="ml-2" size="s"
          ><slot name="sorting-apply-button-text">Apply</slot></b-button
        >
      </div>
    </b-popover>
  </div>
</template>

<script lang="ts">
import {
  InstrumentTableColumnSetting,
  ProductSearchPageSetting,
  ProductSearchTableSetting,
} from '@/src/types/episerver-api';
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { Direction, DropDownItemType, Size, SortingDirection } from '@/src/types/enumerations';
import { DropDownItem, ProductSearchParametersFilter } from '@/src/types/vue-api';
import { SortOrder } from '@/src/types/the-q-api';
import { convertOptions } from '@/src/utils/dropdown-option-provider';
import { flatMapMergeColumns } from '@src/utils/table-columns-helper';

interface SortItem extends Omit<SortOrder, 'key'> {
  key: string | null;
}

@Component({
  inheritAttrs: false,
})
export default class AdProductSearchSorting extends Vue {
  @Inject() settingsProperty!: ProductSearchPageSetting;
  @Prop({ default: false, type: Boolean }) wide!: boolean;
  @Prop({ required: true })
  filter!: ProductSearchParametersFilter;

  // needed to use in enum in template
  direction = Direction;
  size = Size;
  sortingDirection = SortingDirection;

  items!: Array<DropDownItem>;
  isPopoverShow = false;
  sortings: Array<SortItem> = [];
  formItems = 4;
  restFilter: Array<SortItem> = [];

  get isDistributionFeeVisible(): boolean {
    const hiddenCols = this.settingsProperty.searchResultHiddenValuesSetting;

    let cols: InstrumentTableColumnSetting[];
    if (this.$screen.xl) {
      cols = flatMapMergeColumns(this.settingsProperty.desktopTableColumnsSetting);
    } else if (this.$screen.lg || this.$screen.md) {
      cols = flatMapMergeColumns(this.settingsProperty.tabletTableColumnsSetting);
    } else {
      cols = flatMapMergeColumns(this.settingsProperty.mobileTableColumnsSetting);
    }

    return [...cols, ...hiddenCols].some((c) => (c as ProductSearchTableSetting).isDistributionSwitchColumn);
  }

  created(): void {
    this.items = convertOptions(() => {
      return flatMapMergeColumns(this.settingsProperty.desktopTableColumnsSetting).map(
        (setting) =>
          ({
            value: setting.key,
            text: setting.headline,
            type: DropDownItemType.item,
          }) as DropDownItem
      );
    }, this.settingsProperty.sortingDropDownClearSelectionText);

    this.bindFilter();
  }

  sortingDirectionChanged(sort: SortOrder, direction: SortingDirection): void {
    sort.direction = direction;
  }

  resetFilter() {
    this.sortings = [];
    this.filter.resetSortOrders.forEach((s) => this.sortings.push(this.copySortOrderItem(s)));
    this.filter.isAddColumnDistributionFee = false;

    this.sortingChanged();
  }

  copySortOrderItem(s: SortItem | SortOrder): SortItem {
    return {
      key: s.key,
      direction: s.direction,
    };
  }

  sortingChanged(): void {
    const filter = this.sortings
      .filter((s) => s.key)
      .map((s) => {
        return {
          key: s.key,
          direction: s.direction,
        } as SortOrder;
      });

    this.filter.sortOrders = filter;

    this.$emit('sorting-value-changed');

    this.close();
  }

  setSortingArray(sortOrder: Array<SortOrder>, sortings: Array<SortItem>) {
    for (let i = 0; i < this.formItems; i++) {
      if (sortOrder && i < sortOrder.length) {
        sortings.push({
          key: this.filter.sortOrders[i].key,
          direction: this.filter.sortOrders[i].direction,
        });
      } else {
        sortings.push({
          key: null,
          direction: SortingDirection.Ascending,
        });
      }
    }
  }

  @Watch('filter.sortOrders')
  bindFilter(): void {
    this.sortings = [];
    this.setSortingArray(this.filter.sortOrders, this.sortings);
  }

  isSelectable(option: DropDownItem): boolean {
    return !(option.value && this.sortings.some((s) => s.key == option.value));
  }

  close(): void {
    this.isPopoverShow = false;
  }
}
</script>

<style lang="scss">
.product-search-sorting {
  position: relative;

  .arrow {
    display: none;
  }
  .popover {
    top: auto !important;
    right: 0 !important;
    left: auto !important;
    transform: none !important;
    margin-top: rem(10);
    border-width: 0;
    box-shadow: $box-shadow-grey;
    padding: rem(30);
    padding-bottom: rem(60);
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up('sm') {
      width: rem(520);
      max-width: rem(520);
    }
  }

  .popover-body {
    padding: 0;
  }

  .sorting {
    border-width: 0;
    padding-top: rem(5);
    padding-right: 0;
    padding-bottom: rem(5);
    padding-left: 0;
    width: 50%;

    &.right {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.left {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
