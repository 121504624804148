<template>
  <div class="ce-header-nav">
    <b-navbar-toggle class="ce-header-nav__toggler ce-hamburger" target="nav-collapse-main">
      <span class="sr-only">Menu</span>
      <span class="ce-hamburger__box" aria-hidden="true">
        <span class="ce-hamburger__bars"></span>
      </span>
    </b-navbar-toggle>

    <b-collapse accordion="header" id="nav-collapse-main" class="ce-header-flyout ce-header-nav-flyout" is-nav>
      <b-navbar-nav class="ce-header-nav-main">
        <slot name="main-menu"></slot>
      </b-navbar-nav>

      <b-navbar-nav class="ce-header-nav-meta d-xl-none">
        <b-nav-item-dropdown no-caret>
          <template v-slot:button-content>
            <span class="ce-header-nav-meta__selected" :data-label="countryLabel">
              {{ selectedCountry }}
            </span>
            <b-icon icon="caret-down" />
          </template>
          <slot name="countries"></slot>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown no-caret>
          <template v-slot:button-content>
            <span class="ce-header-nav-meta__selected" :data-label="languageLabel">
              {{ selectedLanguage }}
            </span>
            <b-icon icon="caret-down" />
          </template>
          <slot name="languages"></slot>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CeHeaderNav extends Vue {
  @Prop() private selectedCountry!: string;
  @Prop() private countryDropdownLabel: string | undefined;
  @Prop() private selectedLanguage!: string;
  @Prop() private languageDropdownLabel: string | undefined;

  get countryLabel(): string {
    return this.countryDropdownLabel ?? 'Choose Country';
  }
  get languageLabel(): string {
    return this.languageDropdownLabel ?? 'Choose Language';
  }
}
</script>

<style lang="scss">
.ce-header-nav {
  padding: inherit 0;
  & &-flyout {
    @include media-breakpoint-down('lg') {
      background: $light-grey;
      padding: map-get($spacers, 3) (map-get($spacers, 4) / 2);
    }
  }

  @include media-breakpoint-up('xl') {
    flex-grow: 1;
  }
}
.sticky .ce-hamburger {
  &__bars,
  &__bars::before,
  &__bars::after {
    @include media-breakpoint-down('lg') {
      background-color: $black;
    }
  }
}

.ce-hamburger.not-collapsed.ce-hamburger.not-collapsed {
  .ce-hamburger {
    &__bars,
    &__bars::after,
    &__bars::before {
      background-color: $black;
    }
  }
}

.ce-hamburger {
  &.navbar-toggler {
    border-color: transparent !important;
    background: transparent;
  }

  &__box {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 16px;
  }

  &__bars {
    display: block;
    top: 2px;
    margin-top: -2px;

    &::before,
    &::after {
      display: block;
      content: '';
    }

    &::before {
      top: 7px;
      transition-property: transform, opacity;
    }

    &::after {
      top: 14px;
    }

    &,
    &::before,
    &::after {
      position: absolute;
      transition-duration: 0.15s;
      transition-property: transform;
      transition-timing-function: ease;
      border-radius: 1px;
      background-color: $black;
      width: 24px;
      height: 2px;
    }
  }

  &.not-collapsed &__bars {
    transform: translate3d(0, 10px, 0) rotate(45deg);
  }

  &.not-collapsed &__bars::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
  }

  &.not-collapsed &__bars::after {
    transform: translate3d(0, -15px, 0) rotate(-90deg);
  }
}

.ce-header-nav-main {
  .nav-item {
    a {
      padding-top: 14px;
      padding-bottom: 14px;
    }
    a:active,
    a:hover,
    a:focus,
    &.show > a {
      border-bottom: 4px solid $header-highlight-color;
      padding-bottom: 10px;
    }

    @include media-breakpoint-down('lg') {
      border-bottom: 1px solid $cool-grey;

      a {
        color: $header-highlight-color !important;
      }
    }
  }
}

.ce-header-nav-meta {
  margin-top: map-get($spacers, 4);

  .dropdown {
    margin-top: map-get($spacers, 4);
    border-radius: $border-radius;
    background: $silver;
    padding: 10px;

    &.show {
      background: $white;
    }
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    padding: rem(2) 0 !important;
  }

  .dropdown-menu {
    border: 0;

    a {
      border-bottom: 4px solid transparent;
      padding: map-get($spacers, 2);
      color: $gunmetal;

      &:hover,
      &:focus {
        border-bottom-color: $header-highlight-color;
        background: transparent;
        color: $header-highlight-color;
      }
    }

    li {
      border-bottom: 1px solid $gunmetal;
    }
  }

  &__selected {
    display: inline-block;
    flex: 1;
    line-height: 1;

    &::before {
      display: block;
      font-size: 12px;
      content: attr(data-label) '';
    }
  }
}
</style>
