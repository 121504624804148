import httpClient from '@/src/utils/http-service';
import { getTheQApiUrl } from '@/src/utils/url-helper';
import { Controller } from '@/src/types/enumerations';
import { Commit } from 'vuex';
import { PriipModel } from '@/src/types/the-q-api';

export const state = {
  product: null as PriipModel | null,
};

enum MutationTypes {
  CACHE_PRIIP = 'CACHE_PRIIP',
  CACHE_PRIIP_PRICING_SUPPLEMENT = 'CACHE_PRIIP_PRICING_SUPPLEMENT',
}

type State = typeof state;

export const mutations = {
  [MutationTypes.CACHE_PRIIP](state: State, product: PriipModel) {
    state.product = product;
  },
};

export const getters = {
  product: (state: State) => {
    return state.product;
  },
};

export const actions = {
  async loadProductAsync({ commit, state }: { commit: Commit; state: State }, isin: string): Promise<void> {
    if (state.product?.isin.toUpperCase() == isin.toUpperCase()) {
      return Promise.resolve();
    }

    return httpClient.get<PriipModel>(getTheQApiUrl(Controller.Priip, `Index/${isin}`)).then((response) => {
      commit(MutationTypes.CACHE_PRIIP, response.data);
    });
  },
};
