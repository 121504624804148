<template>
  <ce-text-image-teaser
    :teasers="teasers"
    :layout-mode="layoutMode"
    :showMoreButtonLink="settingsProperty.showMoreButtonLink"
    :maxItemCount="settingsProperty.maxItemsPerRow"
  >
    <template v-if="!!settingsProperty.showMoreButtonText" #show-more>
      {{ settingsProperty.showMoreButtonText }}
    </template>
  </ce-text-image-teaser>
</template>
<script lang="ts">
import {
  TextImageTeaserBlock,
  TextImageTeaserImageBlock,
  TextImageTeaserVideoBlock,
} from '@/src/types/ce-component-types';
import { TextImageTeaserBackground, TextImageTeaserTypes } from '@/src/types/enumerations';
import { TextImageTeaserSettings } from '@/src/types/episerver-api';
import { isTeaserImageSetting, isTeaserVideoSetting } from '@/src/types/type-guards';
import { getImageResizerUrl } from '@/src/utils/url-helper';
import { Component, Inject, Vue } from 'vue-property-decorator';

@Component
export default class AdTextImageTeasers extends Vue {
  @Inject() settingsProperty!: TextImageTeaserSettings;

  get layoutMode(): TextImageTeaserBackground {
    return this.settingsProperty.background ? TextImageTeaserBackground[this.settingsProperty.background] : null;
  }

  get teasers(): Array<TextImageTeaserBlock> {
    return this.settingsProperty.teasers.map((teaser) => {
      teaser.type = TextImageTeaserTypes[teaser.type];

      if (isTeaserImageSetting(teaser)) {
        return {
          type: teaser.type,
          link: teaser.link,
          headlineText: teaser.headlineText,
          preHeadlineText: teaser.preHeadlineText,
          copyText: teaser.copyText,
          callToActionText: teaser.callToActionText,
          image: {
            src: getImageResizerUrl(teaser.imageData.src, 316, 185),
            sources: [
              { Media: '(min-width:650px)', SrcSet: getImageResizerUrl(teaser.imageData.src, 1600, 540) },
              { Media: '(min-width:465py)', SrcSet: getImageResizerUrl(teaser.imageData.src, 500, 500) },
            ],
          },
        } as TextImageTeaserImageBlock;
      } else if (isTeaserVideoSetting(teaser)) {
        const video = {
          youtubeId: teaser.youtubeId,
          type: teaser.type,
        } as TextImageTeaserVideoBlock;

        if (teaser.previewImageSrc) {
          video.previewImageSrc = getImageResizerUrl(teaser.previewImageSrc, 316, 185);
        }

        return video;
      }

      return teaser as TextImageTeaserImageBlock;
    });
  }
}
</script>
