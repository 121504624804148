<template>
  <ad-icon-base v-bind="$attrs">
    <path
      d="M23.5,23.6c2-2,2-5.1,0-7.1c-2-2-5.1-2-7.1,0c-2,2-2,5.1,0,7.1c0.9,0.9,2.2,1.5,3.5,1.5
		C21.3,25,22.6,24.5,23.5,23.6z M20,13.3c3.7,0,6.7,3,6.7,6.7c0,3.7-3,6.7-6.7,6.7c-3.7,0-6.7-3-6.7-6.7c0-1.8,0.7-3.5,2-4.7
		C16.5,14,18.2,13.3,20,13.3L20,13.3z M20.1,31.7c6,0,11.8-3.7,18.2-11.8C31.8,12,26.1,8.3,20.1,8.3C14,8.3,8.2,12,1.7,19.9
		C8.2,27.9,14.1,31.7,20.1,31.7L20.1,31.7z M39.7,19c0.4,0.5,0.4,1.3,0,1.9C33,29.2,26.8,33.3,20.1,33.3s-13-4.2-19.7-12.5
		c-0.4-0.5-0.4-1.3,0-1.9c6.7-8.2,13-12.3,19.7-12.3S33,10.8,39.7,19L39.7,19z"
    />
    <template #title>
      <slot name="watchist-tooltip">Entries on watchlist</slot>
    </template>
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconWatchlist extends Vue {}
</script>
