<template>
  <ad-error :http-status-code="statusCode">
    <div class="content-container container-fluid mb-0 mt-7" v-if="product">
      <div class="mb-5 mb-lg-7">
        <h1 class="mb-1"> {{ product.name | uppercase }} </h1>
        <div class="fs-26 gun-metal mb-5">
          <span>{{ product.isin | uppercase }}</span>
          <span
            class="mt-0 blue icon-copy"
            v-if="product"
            v-clipboard="product.isin"
            v-clipboard:success="clipboardSuccessHandler"
            @click="onCloseCopyPopover"
            v-b-popover.top.click="copyConfirmationText"
            role="button"
            ><ad-icon-copy id="icon-copy" height="0.8em" />
          </span>
        </div>
      </div>
      <h2 class="mb-2 mb-lg-5"><slot name="downloads-label"></slot></h2>
      <div>
        <ce-download :url="product.pricingSupplementPath" :download-click="downloadClick">
          <template #text>{{ pricingSupplementLabel }}</template>
        </ce-download>
      </div>
    </div>
  </ad-error>
</template>
<script lang="ts">
import { HttpStatusCodes } from '@/src/types/enumerations';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { PriipModel } from '@/src/types/the-q-api';
import { PriipPageSettings } from '@/src/types/episerver-api';
import { isAxiosError } from '@/src/types/type-guards';
import { Action, Getter } from 'vuex-class';
import { trackEvent, GaEvent } from '@/src/utils/web-tracking';
import { downloadClick } from '@/src/utils/document-service';

@Component({
  filters: {
    uppercase: function (value: string) {
      return !value ? '' : value.toUpperCase();
    },
  },
})
export default class AdPriipPage extends Vue {
  @Prop() isin!: string | undefined;
  @Prop({ default: 'Copied' }) isinCopiedText!: string;
  @Inject() settingsProperty?: PriipPageSettings;

  @Getter('product', { namespace: 'priip' })
  product!: PriipModel | null;

  @Action('loadProductAsync', { namespace: 'priip' })
  loadProductAsync!: (isin: string) => Promise<void>;

  private statusCode: number | HttpStatusCodes = HttpStatusCodes.OK;

  private get pricingSupplementLabelWithPlaceholder(): string | undefined {
    return this.product?.isFinal
      ? this.settingsProperty?.finalPricingSupplementLabel
      : this.settingsProperty?.preliminaryPricingSupplementLabel;
  }

  private get pricingSupplementLabel(): string | null | undefined {
    return this.product?.isin == null
      ? null
      : this.pricingSupplementLabelWithPlaceholder?.replace('{{ISIN}}', this.product.isin.toUpperCase());
  }

  public async created() {
    if (this.isin) {
      try {
        await this.loadProductAsync(this.isin);
        return;
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
          return;
        }
      }
    }

    this.statusCode = HttpStatusCodes.NotFound;
  }

  private get copyConfirmationText(): string {
    return this.settingsProperty?.isinCopiedText ?? 'Copied';
  }

  private onCloseCopyPopover(): void {
    setTimeout(() => {
      this.$root.$emit('bv::hide::popover');
    }, 3000);
  }

  private clipboardSuccessHandler({ value }) {
    trackEvent(GaEvent.SelectItem, {
      item_list_id: 'product_identifier',
      items: [{ item_id: value }],
    });
  }

  private downloadClick() {
    if (!this.product || !this.pricingSupplementLabel) {
      return;
    }
    downloadClick(this.product.pricingSupplementPath, this.pricingSupplementLabel, 'pdf');
  }
}
</script>
