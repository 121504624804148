import Vue from 'vue';

/*
 *  Import Plugins
 */
import '@plugins';

/*
 *  Import Css
 */
import '@design';

/*
 *  Import Vuex
 */
import store from '@state/store';

/*
 *  Import Filters
 */
import '@filters';
/*
 *  Import global custom directives
 */
import '@directives';

/*
 * Globally register all components
 */
import '@components/_globals';

new Vue({ store }).$mount('#app');
