<script lang="ts">
import { BaseSettings, InstrumentFieldKeys, PageRoutes, CustomErrorMessage } from '@/src/types/episerver-api';
import { VNode } from 'vue';
import { Component, Provide, Prop, Vue } from 'vue-property-decorator';
import {} from '@/src/types/episerver-api';

@Component({})
export default class AdBaseSettingsProperties extends Vue {
  @Prop({ default: null }) settingsJson!: string | null;
  @Prop({ default: null }) settingsErrorMessages!: string | null;

  @Provide()
  get pageRoutes(): PageRoutes | null {
    return this.settings?.routes || null;
  }

  @Provide()
  get nsinKeys(): InstrumentFieldKeys | null {
    return this.settings?.nsinKeys || null;
  }

  @Provide()
  get errorMessages(): CustomErrorMessage[] | null {
    if (!this.settingsErrorMessages) return null;
    return this.tryParseJson<CustomErrorMessage[]>(this.settingsErrorMessages);
  }

  render(): VNode[] | VNode | undefined {
    return this.$scopedSlots.default?.({});
  }

  get settings(): BaseSettings | null {
    if (!this.settingsJson) return null;
    return this.tryParseJson<BaseSettings>(this.settingsJson);
  }

  private tryParseJson<T>(jsonString: string): T | null {
    try {
      if (jsonString) {
        const json = decodeURIComponent(jsonString);
        return JSON.parse(json) as T;
      }
    } catch (error) {
      this.$log.error(jsonString, error);
    }

    return null;
  }
}
</script>
