<template>
  <ad-skeleton-table :loading="types.length === 0" :columns="2">
    <b-table-simple striped borderless>
      <colgroup>
        <col class="w-75" />
        <col class="w-25 text-right" />
      </colgroup>
      <b-thead>
        <b-tr>
          <b-th><slot name="header-name"></slot></b-th>
          <b-th class="text-right"><slot name="header-count"></slot></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(link, i) in searchLinks" :key="link">
          <b-td>
            <a :href="link">{{ types[i].text }}</a>
          </b-td>
          <b-td class="text-right">
            {{ types[i].count }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </ad-skeleton-table>
</template>
<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { Option } from '@src/types/the-q-api';
import { PageRoutes } from '@/src/types/episerver-api';
import { tryCreateProductSearchLink } from '@/src/utils/url-helper';
import { ProductTypeBo } from '@/src/types/enumerations';
import { ProductSearchParametersFilter } from '@/src/types/vue-api';

@Component({})
export default class AdProductTypeCountTable extends Vue {
  @Prop({ required: true }) types!: Option[];
  @Prop({ required: true }) selectedUnderlying!: string;
  @Prop({ default: false, type: Boolean }) collapsible!: boolean;

  @Inject() pageRoutes!: PageRoutes;

  get searchLinks(): Array<string | null> {
    if (this.selectedUnderlying) {
      const searchFilters = new ProductSearchParametersFilter();
      searchFilters.underlyingIsinOrIsins = this.selectedUnderlying;
      return this.types.map((type) =>
        tryCreateProductSearchLink(this.pageRoutes, ProductTypeBo[type.value], searchFilters)
      );
    }

    return Array(this.types.length).fill(null);
  }
}
</script>
