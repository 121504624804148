<template>
  <ad-error :http-status-code="statusCode">
    <div class="content-container container-fluid mb-0 mt-7">
      <h2> <slot name="header"></slot>&nbsp;{{ isin | uppercase }} </h2>
      <div class="mt-6">
        <h3>
          <slot name="final-terms-link-description"></slot>&nbsp;
          <b-button class="d-inline" :href="link">
            <slot name="final-terms-link-text"></slot>
          </b-button>
        </h3>
      </div>
      <div class="mt-6">
        <h3>
          <slot name="legal-documents-page-link-content"></slot>&nbsp;
          <b-button class="d-inline" :href="legalDocumentsPageUrl">
            <slot name="legal-documents-page-link-text"></slot>
          </b-button>
        </h3>
      </div>
    </div>
  </ad-error>
</template>
<script lang="ts">
import { HttpStatusCodes } from '@/src/types/enumerations';
import { getAllDocumentsForProduct } from '@/src/utils/document-service';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { isAxiosError } from '@/src/types/type-guards';

@Component({
  filters: {
    uppercase: function (value: string) {
      return !value ? '' : value.toUpperCase();
    },
  },
})
export default class AdStructuredProductsPage extends Vue {
  @Prop() isin!: string | undefined;
  @Prop() legalDocumentsPageUrl!: string | undefined;

  statusCode: number | HttpStatusCodes = HttpStatusCodes.OK;

  /**
   * A redirect needs to be created for this Url in the Redirect-Manager Module of EPiServer.
   * This Url should be redirected to the FinalTerms Endpoint in the Document-Controller, so that
   * the document can be downloaded for the product with the specified Isin.
   */
  get link(): string | undefined {
    const isin = this.isin;
    return isin ? location.pathname.replace(isin, `final-terms/FT_${isin}`) : undefined;
  }

  async created() {
    if (this.isin) {
      try {
        const documents = await getAllDocumentsForProduct(this.isin);
        if (documents.length !== 0) {
          return;
        }
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          this.statusCode = error?.response?.status ?? HttpStatusCodes.UnknownError;
          return;
        }
      }
    }

    this.statusCode = HttpStatusCodes.NotFound;
  }
}
</script>
