<template>
  <div>
    <slot name="missing-cookie" :viewMode="viewMode.Popup" :modalId="modalId"></slot>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MissingCookieModalId, MissingCookieViewMode } from '@/src/types/enumerations';
@Component
export default class AdMissingCookieModal extends Vue {
  @Prop({ required: true }) modalId!: MissingCookieModalId;
  viewMode = MissingCookieViewMode;
}
</script>
