<template>
  <ad-icon-base iconName="like" v-bind="$attrs" stroke-width="0.5">
    <path
      d="M26.4,3.4C26.3,1.5,24.7,0,22.8,0h-0.6c-1.6,0-3,1.1-3.5,2.6c-0.7,2.5-1.6,4.6-2.5,6.3
	c-1.3,2.9-3.4,4.7-6.4,5.5H4.5c-1.5,0-2.7,1.2-2.7,2.7v18.2c0,1.5,1.2,2.7,2.7,2.7h5.3l3.9,1.2c1.6,0.5,3.2,0.7,4.9,0.7h11.2
	c1.5,0,2.9-0.8,3.8-2c2.2-3.3,3.4-6.2,3.8-8.8c0.6-3,0.9-6.4,0.8-10.3c-0.1-2.5-2.1-4.4-4.5-4.4H23.5c1.5-2.2,2.4-4.3,2.7-6.3
	C26.4,6.5,26.5,4.9,26.4,3.4z M24.4,7.9c-0.3,2-1.4,4.3-3.4,6.9c-0.3,0.4-0.2,1,0.1,1.3c0.2,0.1,0.4,0.2,0.6,0.2h11.9
	c1.5,0,2.7,1.2,2.7,2.7c0.1,3.7-0.1,7-0.7,9.9c-0.4,2.3-1.5,5-3.6,8.1c-0.5,0.8-1.4,1.2-2.3,1.2H18.6c-1.5,0-2.9-0.2-4.4-0.6
	l-4.1-1.2l-0.3,0H4.5c-0.5,0-0.9-0.4-0.9-0.9V17.2c0-0.5,0.4-0.9,0.9-0.9H10l0.2,0c3.6-1,6.1-3.2,7.7-6.5c1-1.8,1.9-4,2.6-6.6
	c0.2-0.8,0.9-1.3,1.7-1.3h0.6c1,0,1.7,0.7,1.8,1.7C24.7,4.9,24.6,6.3,24.4,7.9L24.4,7.9z"
    />
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconLike extends Vue {}
</script>
