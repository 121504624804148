<template>
  <div class="ad-load-more-teaser-json">
    <slot name="headline"></slot>
    <div>
      <slot name="component" :items="contentSettings"></slot>
    </div>
    <div class="row">
      <div v-if="canLoadMore" class="col-12 d-flex justify-content-center">
        <button class="btn btn-secondary w-100 w-md-auto" @click="loadMore()">
          <slot name="show-more"></slot>
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Inject } from 'vue-property-decorator';
import { LoadMoreTeaserSettingsJson, RenderedPagedJsonContent, SerializedObject } from '@/src/types/episerver-api';
import { Controller } from '@src/types/enumerations';
import httpClient from '@/src/utils/http-service';
import { getEpiserverApiUrl } from '@/src/utils/url-helper';

@Component({})
export default class AdLoadMoreTeasersJson extends Vue {
  @Inject() settingsProperty!: LoadMoreTeaserSettingsJson<SerializedObject>;

  isLoading = false;
  canLoadMore = false;
  contentItemsCount = 0;
  loadedItems: Array<SerializedObject> = [];

  created() {
    this.isLoading = false;
    this.canLoadMore = this.settingsProperty.hasMore;
    this.contentItemsCount = this.settingsProperty.initialItemsCount;
  }

  get contentSettings() {
    return [...this.settingsProperty.settings, ...this.loadedItems];
  }

  async loadMore() {
    try {
      this.isLoading = true;
      const response = await httpClient.get<RenderedPagedJsonContent>(
        getEpiserverApiUrl(Controller.DataSource, 'GetJson'),
        {
          params: {
            blockId: this.settingsProperty.blockId,
            skip: this.contentItemsCount,
            take: this.settingsProperty.loadMoreCount,
          },
        }
      );
      const result = response.data;
      this.contentItemsCount += result.contentItemsCount;
      this.canLoadMore = result.hasMore;
      this.loadedItems = [...this.loadedItems, ...result.contentSettings];
    } catch (ex) {
      this.$log.error(ex);
    }
    this.isLoading = false;
  }
}
</script>
