<template>
  <div>
    <template v-if="cookiesAccepted">
      <ce-video-preview v-if="cookiesAccepted" :youtube-id="settingsProperty.youtubeId">
        <template #preview-image>
          <slot name="preview-image"></slot>
        </template>
      </ce-video-preview>
      <ce-video-info :youtube-id="settingsProperty.youtubeId">
        <template #default="{ title, duration }">
          <span class="d-block video-title" v-if="settingsProperty.showTitle">{{ title }}</span>
          <span class="d-block">{{ duration }}</span>
        </template>
      </ce-video-info>
    </template>
    <template v-else>
      <slot name="missing-cookie" :view-mode="missingCookieBlockViewMode"></slot>
    </template>
  </div>
</template>

<script lang="ts">
import { MissingCookieViewMode } from '@/src/types/enumerations';
import { VideoBlockSettings } from '@/src/types/episerver-api';
import CookieManager from '@/src/utils/cookie';
import { Component, Inject, Vue } from 'vue-property-decorator';

@Component
export default class AdVideo extends Vue {
  @Inject() settingsProperty!: VideoBlockSettings;
  private cookieManager!: CookieManager;

  missingCookieBlockViewMode = MissingCookieViewMode.Content;

  get cookiesAccepted(): boolean {
    return this.cookieManager?.thirdPartyMedia;
  }

  private created() {
    this.cookieManager = new CookieManager(this.$cookies);
  }
}
</script>

<style lang="scss">
.video-title {
  color: $marine-blue;
}
</style>
