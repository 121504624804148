<template>
  <div v-if="isVisible">
    <b-table-simple striped borderless>
      <b-tbody>
        <b-tr>
          <b-th class="w-33"> </b-th>
          <b-th class="w-33 text-center align-middle"><slot name="first-product-header"></slot></b-th>
          <b-th class="w-33 text-center align-middle"><slot name="second-product-header"></slot></b-th>
        </b-tr>
        <slot name="data-rows" :firstProduct="firstProduct" :secondProduct="secondProduct"></slot>
      </b-tbody>
    </b-table-simple>
    <div v-if="$slots['disclaimer-prospectus']">
      <slot name="disclaimer-prospectus"></slot>
    </div>
  </div>
  <b-alert variant="warning" v-else> Product table is hidden: {{ isHiddenReason }} </b-alert>
</template>
<script lang="ts">
import { ProductInfoTableSettings } from '@/src/types/episerver-api';
import { ProductModel } from '@/src/types/the-q-api';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({})
export default class AdProductInfoTable extends Vue {
  @Inject() settingsProperty!: ProductInfoTableSettings;

  @Getter('productByIsin', { namespace: 'product' })
  productByIsin!: (isin: string) => ProductModel | null;
  @Action('loadConcatProductsAsync', { namespace: 'product' })
  loadConcatProductsAsync!: (isins: string[]) => Promise<void>;

  async created() {
    const isins = [this.settingsProperty.firstProductIsin, this.settingsProperty.secondProductIsin].filter(
      (s) => s !== null
    ) as string[];
    await this.loadConcatProductsAsync(isins);
  }

  get isVisible(): boolean {
    return this.isHiddenReason === null;
  }

  get isHiddenReason(): string | null {
    if (!this.firstProduct && !this.secondProduct) {
      return 'Both products do not exist';
    }
    if (this.firstProduct && this.secondProduct && this.firstProduct.productType !== this.secondProduct.productType) {
      return `Product types do not match: ${this.firstProduct.productType} and ${this.secondProduct.productType}`;
    }
    if (
      this.firstProduct?.productType &&
      !this.settingsProperty.allowedTypes.find((t) => this.firstProduct?.productType === t)
    ) {
      return `First product type is not allowed: ${this.firstProduct.productType}`;
    }
    if (
      this.secondProduct?.productType &&
      !this.settingsProperty.allowedTypes.find((t) => this.secondProduct?.productType === t)
    ) {
      return `Second product type is not allowed: ${this.secondProduct.productType}`;
    }
    return null;
  }

  get firstProduct(): ProductModel | null {
    return this.settingsProperty.firstProductIsin ? this.productByIsin(this.settingsProperty.firstProductIsin) : null;
  }

  get secondProduct(): ProductModel | null {
    return this.settingsProperty.secondProductIsin ? this.productByIsin(this.settingsProperty.secondProductIsin) : null;
  }
}
</script>
