<template>
  <ad-icon-base v-bind="$attrs">
    <path
      d="M33.3,27.5h-4.2v-4.2c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8v4.2h-4.2c-0.5,0-0.8,0.4-0.8,0.8
      c0,0.5,0.4,0.8,0.8,0.8h4.2v4.2c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8v-4.2h4.2c0.5,0,0.8-0.4,0.8-0.8
      C34.1,27.9,33.8,27.5,33.3,27.5z M35.2,19C35.2,19,35.2,19,35.2,19c1.5-1.4,2.9-3.1,4.4-4.9c0.4-0.5,0.4-1.3,0-1.9l0,0
      C33,4.1,26.8,0,20.1,0S7.1,4.1,0.3,12.2c-0.5,0.5-0.5,1.3,0,1.9c5.7,7,11,11,16.5,12.1c-0.1,0.7-0.2,1.4-0.2,2.1
      c0,6.4,5.2,11.6,11.7,11.6C34.8,40,40,34.8,40,28.4C40,24.5,38.1,21.2,35.2,19z M1.7,13.2C8.2,5.3,14,1.7,20.1,1.7
      c6,0,11.8,3.7,18.2,11.5c-1.5,1.9-3,3.5-4.5,4.9c0,0,0,0,0,0c-1.6-0.9-3.5-1.4-5.5-1.4c-1,0-2,0.1-2.9,0.4c0,0,0,0,0,0
      c0.8-1.1,1.2-2.4,1.2-3.8c0-3.7-3-6.6-6.7-6.6l0,0c-1.8,0-3.5,0.7-4.7,1.9c-1.3,1.2-2,2.9-2,4.7c0,3.7,3,6.6,6.7,6.6
      c0.1,0,0.2,0,0.3,0c-1.4,1.3-2.4,2.9-3.1,4.7C12.3,23.6,7.2,19.9,1.7,13.2z M23.5,16.8c-0.9,0.9-2.2,1.5-3.5,1.5
      c-1.3,0-2.6-0.5-3.5-1.5c-2-2-1.9-5.1,0-7.1c2-1.9,5.1-1.9,7.1,0C25.5,11.7,25.5,14.9,23.5,16.8z M28.3,38.3c-5.5,0-10-4.5-10-10
      s4.5-10,10-10c5.5,0,10,4.5,10,10S33.8,38.3,28.3,38.3z"
    />
    <template #title>
      <slot name="watchlist-icon-tooltip">Watchlist</slot>
    </template>
  </ad-icon-base>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class AdIconWatchlistAdd extends Vue {}
</script>
