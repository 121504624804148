<template>
  <ad-drop-down-list
    :options="availableUnderlyings"
    :value="value"
    @input="setValue"
    @selected-value-changed="selectedValueChanged"
    :multiple="multiple"
    :formElementStateId="formElementStateId"
    v-bind="$attrs"
    :loading="loading || internalLoading"
  >
    <template #all-items-label>
      <slot name="all-items-label"></slot>
    </template>
    <template #multiple-placeholder>
      <slot name="multiple-placeholder"></slot>
    </template>
  </ad-drop-down-list>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Option, OptionGroup } from '@src/types/the-q-api';
import { convertGroups, convertOptions } from '@src/utils/dropdown-option-provider';
import { DropDownItem } from '@/src/types/vue-api';
import { Action, Getter } from 'vuex-class';

@Component({
  inheritAttrs: false,
})
export default class AdUnderlyingList extends Vue {
  @Prop() value!: string | null | string[];
  @Prop() formElementStateId!: string;
  @Prop({ default: false, type: Boolean }) multiple!: boolean;
  @Prop({ default: false, type: Boolean }) groupItems!: boolean;
  @Prop() availableItems!: { [key: string]: string };
  @Prop({ default: false, type: Boolean }) loading!: boolean;

  @Action('getUnderlyingOptions', { namespace: 'underlying' })
  private getUnderlyingOptions!: (isLoadOptionGroup: boolean) => Promise<void>;

  @Getter('underlyingOptions', { namespace: 'underlying' })
  private underlyingOptions!: Option[] | null;
  @Getter('underlyingOptionGroups', { namespace: 'underlying' })
  private underlyingOptionGroups!: OptionGroup[] | null;

  get availableUnderlyings(): DropDownItem[] {
    return this.getUnderlyings(this.underlyings);
  }

  get underlyings() {
    return (this.groupItems ? this.underlyingOptionGroups : this.underlyingOptions) ?? [];
  }

  get internalLoading() {
    return (this.groupItems ? this.underlyingOptionGroups : this.underlyingOptions) == null;
  }

  async created(): Promise<void> {
    try {
      this.getUnderlyingOptions(this.groupItems);
    } catch (ex) {
      console.error(ex);
    }
  }

  private selectedValueChanged(): void {
    this.$emit('selected-value-changed');
  }

  private getUnderlyings(underlyings: Option[] | OptionGroup[]): DropDownItem[] {
    const filterItems = !!this.availableItems;

    if (this.groupItems) {
      const optionGroups = underlyings as OptionGroup[];

      const currentOptionGroup: OptionGroup[] = [];
      optionGroups.forEach((g) => {
        const options = filterItems ? g.options.filter((u) => this.availableItems[u.value]) : g.options;

        if (options.length > 0) {
          currentOptionGroup.push({
            header: g.header?.replace('{optionsCount}', options.length.toString()),
            options: options,
          } as OptionGroup);
        }
      });

      return convertGroups(currentOptionGroup as OptionGroup[], null);
    } else {
      const options = underlyings as Option[];
      return convertOptions(filterItems ? options.filter((u) => this.availableItems[u.value]) : options, null);
    }
  }

  @Watch('availableUnderlyings')
  removeSelectedItemIfNotAvailable() {
    if (this.availableItems && this.value != null) {
      if (typeof this.value === 'string') {
        if (!this.availableItems[this.value]) this.setValue(null);
      } else {
        this.setValue(this.value.filter((v) => this.availableItems[v]));
      }
    }
  }

  setValue(newValue: string | string[] | null): void {
    this.$emit('input', newValue);
  }
}
</script>
