<template>
  <div v-if="isVisible">
    <b-row>
      <b-col
        ><h3 class="mb-2"><slot name="disclaimer-header"></slot></h3
      ></b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert
          variant="secondary"
          class="alert-disclaimer pr-3 px-md-2 mb-0"
          show
          v-bind:class="{ full: disclaimerExpanded }"
        >
          <div class="inner pr-0 pr-md-3">
            <slot name="risk-disclaimer"></slot>
            <slot name="disclaimer-text"></slot>
            <div class="readall" @click="expandDisclaimer">
              <slot name="disclaimer-text-readall"></slot><br />
              <ad-icon-chevron size="s" direction="down" />
            </div>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AdDisclaimerItem extends Vue {
  @Prop({ required: true })
  websiteKey!: string;

  @Prop({ required: true })
  currentWebsite!: string;

  private disclaimerExpanded = false;
  expandDisclaimer(): void {
    this.disclaimerExpanded = true;
  }

  get isVisible(): boolean | null {
    return this.websiteKey === this.currentWebsite;
  }
}
</script>
