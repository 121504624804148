import { AxiosRequestConfig } from 'axios';
import httpClient from './http-service';
import { HttpStatusCodes } from '@src/types/enumerations';

const config: AxiosRequestConfig = {
  headers: { 'Content-Type': 'application/json' },
  responseType: 'blob',
};

function getContentFileName(contentDispositionHeader: string | undefined): string | undefined {
  if (!contentDispositionHeader) return undefined;
  const match = contentDispositionHeader.match(/filename=(?<filename>.*);/);
  return match?.groups?.filename;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function downloadFileFromApi(apiCall: () => Promise<any>, defaultFilename: string): Promise<number> {
  try {
    const response = await apiCall();
    const blob = new Blob([response.data]);
    const blobUrl = URL.createObjectURL(blob);

    // Set link's href to point to the Blob URL
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = getContentFileName(response.headers['content-disposition']) || defaultFilename;
    document.body.appendChild(link);

    // Dispatch click event on the link
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    URL.revokeObjectURL(blobUrl);

    document.body.removeChild(link);
    return HttpStatusCodes.OK;
  } catch (ex) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ((ex as any)?.response?.status as number) || HttpStatusCodes.InternalServerError;
  }
}

export async function downloadFilePost(
  url: string,
  postDataParams: unknown | undefined,
  defaultFilename: string
): Promise<number> {
  return await downloadFileFromApi(() => httpClient.post(url, postDataParams, config), defaultFilename);
}

export async function downloadFileGet(url: string): Promise<number> {
  const lastSlash = url.lastIndexOf('/');
  const fileName = lastSlash > 0 && lastSlash < url.length - 1 ? url.slice(lastSlash + 1) : '';
  const config: AxiosRequestConfig = {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'blob',
  };
  return await downloadFileFromApi(() => httpClient.get(url, config), fileName);
}

/**
 * Check for an url if it is from same origin and download it or open in a new window.
 * @param url to download file
 * @returns HttpStatusCodes of download or if in open in new window HttpStatusCodes.OK
 */
export async function downloadFileGetOrOpenInNewWindowForExternalUrl(url): Promise<HttpStatusCodes> {
  // open download from other urls in new window to avoid cross origin access error
  if (url.startsWith(window.origin) || url.startsWith('/')) {
    return await downloadFileGet(url);
  } else {
    window.open(url, '_blank');
    return Promise.resolve(HttpStatusCodes.OK);
  }
}
