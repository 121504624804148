<template>
  <div v-if="options && options.length == 1">
    <div class="fs-14">{{ options[0].translation }}</div>
  </div>
  <div v-else-if="options" class="mb-2 mb-lg-3">
    <b-form-radio-group
      v-bind="$attrs"
      id="btn-radios-periods"
      name="radios-btn-periods"
      size="sm"
      button-variant="default"
      :checked="value"
      :options="options"
      @input="input"
      buttons
      :class="[
        'd-none',
        { 'd-xl-block': !chartConfigurationProperty.forceDropDownForChartPeriods },
        { 'd-md-block': chartConfigurationProperty.forceRadioButtonsForChartPeriods },
        'text-nowrap',
      ]"
    ></b-form-radio-group>

    <b-form-group
      :id="'input-group-periods' + _uid"
      label-for="periods-input"
      :class="[
        'focus-white',
        { 'd-xl-none': !chartConfigurationProperty.forceDropDownForChartPeriods },
        { 'd-md-none': chartConfigurationProperty.forceRadioButtonsForChartPeriods },
        'mb-0',
      ]"
    >
      <template #label><slot name="header"></slot></template>
      <ad-drop-down-list
        v-bind="$attrs"
        :options="dropdownOptions"
        :value="value"
        @input="input"
        :form-element-state-id="'input-group-periods' + _uid"
      />
    </b-form-group>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { ChartPeriod } from '@/src/types/episerver-api';
import { ChartConfiguration, DropDownItem } from '@/src/types/vue-api';
import { DropDownItemType } from '@/src/types/enumerations';
@Component({})
export default class AdChartPeriods extends Vue {
  @Prop() options: ChartPeriod[] | undefined;
  @Prop() value: string | undefined;
  @Inject({ default: new ChartConfiguration() }) chartConfigurationProperty!: ChartConfiguration;

  input(selected) {
    this.$emit('input', selected);
  }

  get dropdownOptions(): DropDownItem[] {
    return (
      this.options?.map((option) => {
        return {
          type: DropDownItemType.item,
          text: option.translation,
          value: option.key,
        } as DropDownItem;
      }) || []
    );
  }
}
</script>
<style lang="scss">
.btn-group-toggle .btn.btn-default {
  background-color: $light-grey;
  color: $button-color;
  &.active,
  &:hover {
    background-color: $button-color;
    color: $button-text-highlight-color;
  }
}
</style>
