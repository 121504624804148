import Vue from 'vue';
import store from '@/src/state/store';

Vue.directive('register-anchor-slug', {
  inserted(el: HTMLElement) {
    const id = el.getAttribute('id');
    store.dispatch('htmlAnchors/addAnchorId', id);
  },
  unbind(el: HTMLElement) {
    // This if check is required because unbind is called at the beginning, when the element is not even rendered
    if (!el.getAttribute) return;
    const id = el.getAttribute('id');
    store.dispatch('htmlAnchors/removeAnchorId', id);
  },
});
