<template>
  <ad-icon-base iconName="linked-in" v-bind="$attrs">
    <path
      d="M36.4,0H3.6C1.6,0,0,1.6,0,3.6v32.8c0,2,1.6,3.6,3.6,3.6h32.8c2,0,3.6-1.6,3.6-3.6V3.6C40,1.6,38.4,0,36.4,0z
  M11.6,34.3c0,0.6-0.5,1.1-1.1,1.1H6c-0.6,0-1.1-0.5-1.1-1.1V15.5C5,15,5.5,14.5,6,14.5h4.5c0.6,0,1.1,0.5,1.1,1.1V34.3z M8.3,12.7
	C5.9,12.7,4,10.8,4,8.5s1.9-4.3,4.3-4.3s4.3,1.9,4.3,4.3S10.6,12.7,8.3,12.7z M35,34.4c0,0.5-0.4,1-1,1h-4.8c-0.5,0-1-0.4-1-1v-8.8
	c0-1.3,0.4-5.8-3.4-5.8c-3,0-3.6,3-3.7,4.4v10.2c0,0.5-0.4,1-1,1h-4.7c-0.5,0-1-0.4-1-1v-19c0-0.5,0.4-1,1-1h4.7c0.5,0,1,0.4,1,1
	v1.6c1.1-1.6,2.7-2.9,6.2-2.9c7.7,0,7.7,7.2,7.7,11.1L35,34.4L35,34.4z"
    />
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AdIconLinkedIn extends Vue {}
</script>
