<template>
  <b-col v-bind="$attrs" class="ad-downloads-list">
    <div v-if="flattenedFiles.length > 0">
      <b-row v-for="({ depth, file }, index) in flattenedFiles" :key="index" :class="['depth-' + depth]">
        <b-col>
          <div class="wrapper" :class="{ 'pl-3': depth == 2, 'pl-6': depth == 3, 'pl-9': depth == 4 }">
            <ce-download
              v-if="isDownload(file)"
              :url="file.link"
              :key="index"
              @downloadError="propagateError"
              :download-click="() => downloadClick(file.link, file.title, file.fileFormat)"
              class="mb-0"
              is-full-width
              is-icon-at-right
              hide-file-format
            >
              <template #text>{{ file.title }}</template>
              <template #image v-if="file.previewImageLink">
                <picture>
                  <img class="aspect-ratio-filler" :src="file.previewImageLink" />
                </picture>
              </template>
            </ce-download>
            <ce-download
              v-else-if="isGroupedDownloadGroup(file)"
              :url="file.link"
              :key="index"
              @downloadError="propagateError"
              :download-click="() => downloadClick(file.link, file.title, file.format)"
              class="mb-0"
              is-full-width
              is-icon-at-right
              hide-file-format
            >
              <template #text>{{ file.title }}</template>
            </ce-download>
            <b-button
              v-else-if="isDownloadLink(file)"
              class="p-3 ce-download mb-0 full-width"
              :href="file.link"
              variant="light"
              :key="index"
              @click="downloadClick(file.link, file.title)"
            >
              <b-row class="no-gutters">
                <b-col cols="10" class="text-left">{{ file.title }}</b-col>
                <b-col cols="2"><ad-icon-link-four-five /></b-col>
              </b-row>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else><slot name="no-data-found" /> </div>
  </b-col>
</template>
<script lang="ts">
import { DownloadLink, Download, DownloadsBlockPayloadSettings } from '@/src/types/episerver-api';
import { DownloadGroupItem, LayeredDownloadItem, GroupedDownloadGroupItem } from '@/src/types/vue-api';
import { Vue, Component, Prop, Inject, Watch } from 'vue-property-decorator';
import {
  flattenToLayeredDownloadItem,
  isGroupedDownloadGroup,
  isDownloadGroup,
  isDownloadLink,
  isDownload,
  downloadClick,
} from '@src/utils/document-service';
import { GroupedDownloadGroup } from '@/src/types/ce-component-types';
@Component
export default class AdDownloadsList extends Vue {
  @Prop({ required: true }) files!: (DownloadLink | Download | DownloadGroupItem)[];
  @Inject() settingsProperty!: DownloadsBlockPayloadSettings;

  flattenedFiles: LayeredDownloadItem[] = [];

  created() {
    this.prepareFiles();
  }

  @Watch('files')
  prepareFiles() {
    this.flattenedFiles = flattenToLayeredDownloadItem(this.sortedFiles);
  }

  get sortedFiles(): (DownloadLink | Download | DownloadGroupItem)[] {
    this.files
      .filter((f) => isDownloadGroup(f))
      .forEach((g) => {
        let group = g as DownloadGroupItem;
        group.files = this.SortRecursive(group.files as GroupedDownloadGroupItem[], true) as GroupedDownloadGroup[]; //first level newest documents first
      });
    return this.files;
  }

  SortRecursive(
    groups: GroupedDownloadGroupItem[] | undefined,
    isNewestFirst: boolean
  ): GroupedDownloadGroupItem[] | undefined {
    // Sort desc by date at first level
    // Sort asc by rank  then asc by date
    if (!groups) {
      return groups;
    }

    const sorted = groups.sort((a, b) => {
      if (a.rank < b.rank) {
        return 1;
      }

      if (a.rank > b.rank) {
        return -1;
      }

      return isNewestFirst === a.date < b.date ? 1 : -1;
    });

    //from second level, newest documents at last
    sorted.forEach((g) => (g.childDownloads = this.SortRecursive(g.childDownloads, false)));

    return sorted;
  }

  isDownload(document: DownloadLink | Download | DownloadGroupItem): document is Download {
    return isDownload(document);
  }

  isDownloadGroup(document: DownloadLink | Download | DownloadGroupItem): document is DownloadGroupItem {
    return isDownloadGroup(document);
  }
  isGroupedDownloadGroup(document: DownloadGroupItem | GroupedDownloadGroupItem): document is GroupedDownloadGroupItem {
    return isGroupedDownloadGroup(document);
  }

  isDownloadLink(document: DownloadLink | Download | DownloadGroupItem): document is DownloadLink {
    return isDownloadLink(document);
  }

  downloadClick(url: string, title: string, format?: string | null | undefined): void {
    downloadClick(url, title, format);
  }

  getDownloadFileFormat(file: Download): string | undefined {
    return this.getDownloadFileFormat(file);
  }

  propagateError(statusCode: number): void {
    this.$emit('downloadError', statusCode);
  }
}
</script>
<style lang="scss">
.ad-downloads-list {
  color: $gunmetal;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: $white;
  }
  /* stylelint-disable selector-max-compound-selectors */
  .row:nth-child(even) {
    .ce-download {
      &__text {
        color: $gunmetal;
      }
    }
    a,
    a:hover {
      background-color: $white;
      color: $gunmetal;
    }
    a:hover .ce-download {
      &__text {
        background-color: $white;
        color: $gunmetal;
      }
      &__icon,
      .ade-icon {
        color: $cool-grey;
      }
    }
  }

  .row:nth-child(odd) {
    .ce-download {
      &__text {
        color: $gunmetal;
      }
    }
    a,
    a:hover,
    .wrapper {
      background-color: $silver;
      color: $gunmetal;
    }

    a:hover .ce-download {
      &__text {
        background-color: $silver;
        color: $gunmetal;
      }
      &__icon,
      .ade-icon {
        color: $cool-grey;
      }
    }
  }
  /* stylelint-enable selector-max-compound-selectors */

  .ce-download {
    border: 0;
  }
  .depth-1 .ce-download {
    &__text {
      font-weight: bold;
    }
  }
  .row {
    &.depth-2,
    &.depth-3,
    &.depth-4 {
      a::before {
        display: inline-block;
        position: absolute;
        top: 1em;
        margin-left: -1em;
        width: 1em;
        color: $gunmetal;
        content: '\2022';
      }
    }
  }
}
</style>
