<template>
  <b-form-group class="bg-transparent border-0 p-0">
    <ad-skeleton-radio-buttons :label="label" :loading="loading">
      <ce-radio-buttons :label="label" :value="value" @input="setValue">
        <span
          v-for="element in dropdownItems"
          :key="element.value"
          :value="element.value"
          :disabled="element.value && !availableItemsContains(element.text)"
        >
          <slot name="{{element.text}}">{{ element.text }}</slot>
        </span>
      </ce-radio-buttons>
    </ad-skeleton-radio-buttons>
  </b-form-group>
</template>

<script lang="ts">
import { ProductSearchPageSetting } from '@/src/types/episerver-api';
import { Component, Inject, Vue, Watch, Prop } from 'vue-property-decorator';
import { DropDownItem } from '@/src/types/vue-api';
import AdProductSearchFilterDropdown from './ad-product-search-filter-dropdown.vue';
import { convertToDropdownItems } from '@/src/utils/dropdown-option-provider';

@Component({
  inheritAttrs: false,
  components: {
    AdProductSearchFilterDropdown,
  },
})
export default class AdProductSearchFilterCategory extends Vue {
  @Inject() settingsProperty!: ProductSearchPageSetting;
  @Prop({ default: false, type: Boolean }) wide!: boolean;
  @Prop({ required: true })
  translations!: string[];
  @Prop() value!: string;
  @Prop({ required: true })
  availableItems!: string[];
  @Prop({ required: true, type: Boolean }) loading!: boolean;

  selectedRadioButtonValue: string | null = null;

  get dropdownItems(): DropDownItem[] {
    return convertToDropdownItems(this.allItemsLabelText, this.translations);
  }

  get label(): string | undefined {
    return this.$slots['category-label-text'] === undefined ? undefined : this.$slots['category-label-text'][0].text;
  }

  get allItemsLabelText(): string {
    const defaultEmpty = '';
    const key = 'all-items-label-text';
    const slot = this.$slots[key];
    return slot && slot.length ? slot[0].text ?? defaultEmpty : defaultEmpty;
  }

  isSelectable(option: DropDownItem): boolean {
    if (!option.value) {
      return true;
    }

    const strToCompare = option.value?.toString().toUpperCase();

    return this.availableItems?.some((x) => x.toUpperCase() === strToCompare) === true;
  }

  setValue(newValue: string | null): void {
    this.$emit('input', newValue);
    this.$emit('selected-value-changed');
  }

  @Watch('availableItems')
  changeFilter(): void {
    const isPresent = this.value && this.availableItemsContains(this.value);

    if (this.value && !isPresent) {
      this.$emit('input', null);
    }
  }

  availableItemsContains(value: string): boolean {
    const strToCompare = value.toUpperCase();
    return this.availableItems?.some((x) => x.toUpperCase() === strToCompare) === true;
  }
}
</script>
