<template>
  <div
    class="mb-7 mb-md-8 ce-image-text-teaser"
    :class="{
      'bg-light': isGrayLayoudMode,
    }"
  >
    <div v-if="layoutMode === 'with-separating-line'" class="separating-line"></div>
    <div
      :class="{
        'container-fluid': isGrayLayoudMode,
        'content-container': isGrayLayoudMode,
        'py-6': isGrayLayoudMode,
        'content-container-x': isGrayLayoudMode,
      }"
    >
      <slot name="headline"></slot>
      <div class="row">
        <template v-for="(teaser, i) in teasers">
          <div
            v-if="teaser.type === 'image' || teaser.type === 'video'"
            v-bind:key="i"
            v-appear-trigger
            class="col d-flex align-self-stretch col-12 mb-5 mb-md-0"
            :class="[
              teasers.length === 1
                ? 'flex-column flex-md-row align-items-center col-md'
                : `flex-column align-items-start col-md-${teaserShare}`,
            ]"
          >
            <div
              v-appear-delay="0"
              class="w-100 overflow-hidden mb-3"
              :class="[teasers.length === 1 ? 'col-md-7 p-0' : 'mb-md-5']"
            >
              <a
                v-if="teaser.type === 'image'"
                :href="teaser.link"
                class="zoom-animated-image d-block aspect-ratio-16-9"
              >
                <picture>
                  <source
                    v-for="(source, i) in teaser.image.sources"
                    v-bind:key="i"
                    :media="source.Media"
                    :srcset="source.SrcSet"
                  />
                  <img class="aspect-ratio-filler" :src="teaser.image.src" :alt="teaser.image.alt" />
                </picture>
              </a>

              <ce-video-preview
                v-if="teaser.type === 'video'"
                :youtubeId="teaser.youtubeId"
                :videoPath="teaser.videoPath"
              >
              </ce-video-preview>
            </div>
            <div :class="[teasers.length === 1 && 'pl-md-4']">
              <div v-if="teaser.preHeadlineText" class="pre-header fs-16">
                {{ teaser.preHeadlineText }}
              </div>
              <h3 v-appear-delay="1">
                <a :href="teaser.link"><ad-template-renderer :text="teaser.headlineText"></ad-template-renderer></a>
              </h3>
              <p v-if="teaser.copyText" v-appear-delay="2" v-html="teaser.copyText" />
              <p v-appear-delay="3" v-if="teaser.callToActionText && teasers.length !== 1">
                <a :href="teaser.link">{{ teaser.callToActionText }}</a>
              </p>
              <p v-appear-delay="3" v-if="teaser.subText || teaser.likeId != null" class="fs-16">
                <template v-if="teaser.type === 'video' && teaser.youtubeId != null">
                  <ce-video-info :youtube-id="teaser.youtubeId">
                    <template #default="{ duration }">
                      <span class="d-block">{{ teaser.subText }} | {{ duration }}</span>
                    </template>
                  </ce-video-info>
                </template>
                <template v-else>
                  {{ teaser.subText }}
                </template>
                <template v-if="teaser.likeId != null">
                  <ad-like-count :content-id="teaser.likeId" />
                </template>
              </p>
              <div v-if="teasers.length === 1">
                <div class="pt-3">
                  <a :href="teaser.link" class="btn btn-secondary w-100 w-md-auto">
                    <slot name="show-more">{{ teaser.callToActionText }}</slot>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="teaser.type === 'advertisement'"
            v-bind:key="i"
            class="position-relative align-self-stretch col-12 mb-5 mb-md-0"
            :class="`col-md-${teaserShare}`"
          >
            <div class="position-relative">
              <ce-ad-marker><slot name="addvertisement">Advertisement</slot></ce-ad-marker>
              <a :href="teaser.link" class="d-block overflow-hidden">
                <picture>
                  <source
                    v-for="(source, i) in teaser.image.sources"
                    v-bind:key="i"
                    :media="source.Media"
                    :srcset="source.SrcSet"
                  />
                  <img class="w-100" :src="teaser.image.src" :alt="teaser.image.alt" />
                </picture>
              </a>
            </div>
          </div>
        </template>
      </div>
      <div class="row" v-if="showMoreButtonLink && teasers.length !== 1">
        <div class="col pt-4">
          <a :href="showMoreButtonLink" class="btn btn-secondary w-100 w-md-auto">
            <slot name="show-more">Show More</slot>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { TextImageTeaserBlock } from '@/src/types/ce-component-types';

@Component({})
export default class CeTextImageTeaser extends Vue {
  @Prop({ required: true }) teasers!: TextImageTeaserBlock[];
  @Prop() showMoreButtonLink?: string;
  @Prop({ default: 'white' }) layoutMode!: 'grey-background' | 'with-separating-line' | 'white';

  @Prop({ default: 3 }) maxItemCount!: number;

  get teaserShare() {
    return 12 / this.maxItemCount;
  }

  get isGrayLayoudMode() {
    return this.layoutMode === 'grey-background';
  }
}
</script>

<style lang="scss" scoped>
.pre-header {
  color: $peacock-blue;
}

.like-count {
  color: $peacock-blue;
}

.separating-line {
  margin-top: map-get($spacers, 6);
  margin-bottom: map-get($spacers, 6);
  border-top: 1px dashed $bright-sky-blue;
}
</style>
