<template>
  <b-container fluid class="content-container mb-0">
    <slot name="main-video"></slot>
    <div class="row">
      <template v-for="(teaser, i) in teasers">
        <div
          v-bind:key="i"
          v-appear-trigger
          class="col d-flex align-self-stretch col-12 mb-5 mb-md-0"
          :class="[
            teasers.length === 1
              ? 'flex-column flex-md-row align-items-center col-md'
              : `flex-column align-items-start col-md-3`,
          ]"
        >
          <div
            v-appear-delay="0"
            class="w-100 overflow-hidden mb-3"
            :class="[teasers.length === 1 ? 'col-12 col-md-7 p-0' : 'mb-md-5']"
          >
            <ce-video-preview :youtubeId="teaser.youtubeId">
              <template #preview-image v-if="teaser.previewImageSrc">
                <img class="aspect-ratio-filler zoom-animated-image" :src="teaser.previewImageSrc" />
              </template>
            </ce-video-preview>
          </div>
          <div :class="[teasers.length === 1 && 'pl-md-4']">
            <h3 v-appear-delay="1">
              <a :href="teaser.link"><ad-template-renderer :text="teaser.headlineText"></ad-template-renderer></a>
            </h3>
            <p v-if="teaser.previewText" v-appear-delay="2">
              {{ teaser.previewText }}
            </p>
            <p v-appear-delay="3" v-if="teaser.youtubeId != null" class="fs-16">
              <ce-video-info :youtube-id="teaser.youtubeId">
                <template #default="{ duration }">
                  <span class="d-block">{{ teaser.publishedDate | formatField(dateFormatKey) }} | {{ duration }}</span>
                </template>
              </ce-video-info>
            </p>
          </div>
        </div>
      </template>
    </div>
  </b-container>
</template>
<script lang="ts">
import { EducationVideoPageSettings, EducationVideoPageTeaserSettings } from '@/src/types/episerver-api';
import { DefaultFormat } from '@/src/types/enumerations';
import { Component, Inject, Vue } from 'vue-property-decorator';

@Component
export default class AdEducationVideoPage extends Vue {
  @Inject() settingsProperty?: EducationVideoPageSettings;

  get teasers(): Array<EducationVideoPageTeaserSettings> {
    return this.settingsProperty?.teasers ?? [];
  }

  get dateFormatKey(): string {
    return DefaultFormat.BlogDate;
  }
}
</script>
