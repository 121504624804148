function isObject(val) {
  return val.constructor === Object;
}

function isNumber(val) {
  return !isNaN(parseFloat(val)) && isFinite(val);
}

function isBoolean(val) {
  return val === 'false' || val === 'true';
}

function isArray(val) {
  return Array.isArray(val);
}

function parseObject(obj) {
  const result = {};
  let key, val;
  for (key in obj) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    val = parseValue(obj[key]);
    if (val !== null) result[key] = val; // ignore null values
  }
  return result;
}

function parseNumber(val) {
  return Number(val);
}

function parseBoolean(val) {
  return val === 'true';
}

function parseArray(arr) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: Array<any> = [];
  for (let i = 0; i < arr.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    result[i] = parseValue(arr[i]);
  }
  return result;
}

function parseValue(val) {
  if (typeof val == 'undefined' || val == '') {
    return null;
  } else if (isBoolean(val)) {
    return parseBoolean(val);
  } else if (isArray(val)) {
    return parseArray(val);
  } else if (isObject(val)) {
    return parseObject(val);
  } else if (isNumber(val)) {
    return parseNumber(val);
  } else {
    return val;
  }
}

export function parseQueryObject(value: object) {
  return parseValue(value);
}
