<template>
  <div
    class="ad-important-notice"
    v-if="!hasProduct || (!hasUnderlyings && !hasProducts) || matchesUnderlying || matchesProduct"
    clas="w-100"
  >
    <b-alert show variant="danger" class="mb-4">
      <strong><slot name="headline"></slot></strong>
      <div>
        <slot name="description"></slot>
      </div>
    </b-alert>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from 'vue-property-decorator';
import { ImportantNoticeSettings } from '@/src/types/vue-api';
import { ProductModel } from '@/src/types/the-q-api';
import { Getter } from 'vuex-class';

@Component
export default class AdImportantNotice extends Vue {
  @Inject()
  settingsProperty?: ImportantNoticeSettings;

  @Getter('product', { namespace: 'product' })
  private product?: ProductModel | null;

  get hasUnderlyings() {
    const underlyingCount = this.settingsProperty?.underlyingIsins.length ?? 0;
    return underlyingCount > 0;
  }

  get hasProducts() {
    const productCount = this.settingsProperty?.productIsins.length ?? 0;
    return productCount > 0;
  }

  get hasProduct() {
    return this.product != null;
  }

  get matchesUnderlying() {
    if (!this.hasProduct || !this.hasUnderlyings) {
      return false;
    }

    const productUnderlyingIsins = this.product!.underlyings?.map((underlying) => underlying.isin) ?? [];
    const targetUnderlyingIsins = this.settingsProperty!.underlyingIsins;
    const matchingUnderlyingIsins = targetUnderlyingIsins.filter((isin) => productUnderlyingIsins.indexOf(isin) > -1);

    return matchingUnderlyingIsins.length > 0;
  }

  get matchesProduct() {
    if (!this.hasProduct || !this.hasProducts) {
      return false;
    }

    return this.settingsProperty!.productIsins.indexOf(this.product!.isin.toUpperCase()) > -1;
  }
}
</script>
