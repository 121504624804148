<template>
  <div class="d-none d-md-block fs-12 m-2 ce-breadcrumbs align-items-center">
    <template v-for="(h, i) in slots">
      <ad-icon-chevron v-bind:key="i + 'icon'" v-if="i !== 0" rotate="270" size="xxs"></ad-icon-chevron>
      <ce-node-renderer v-bind:key="i + 'node'" :node="h"></ce-node-renderer>
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { filterVNodes } from '@src/utils/vnodes-filter';

/**
 * @example
 *
 * ```html
 * <ce-breadcrumbs>
 *   <a href="/" class="ce-breadcrumb-link">Home</a>
 *   <a href="/" class="ce-breadcrumb-link">Knowledge</a>
 *   <a class="ce-breadcrumb-link">Shorting a stock</a>
 * </ce-breadcrumbs>
 * ```
 */
@Component
export default class CeBreadcrumbs extends Vue {
  get slots() {
    return filterVNodes(this.$slots.default);
  }
}
</script>

<style lang="scss">
/*------------------------------------*\
  #Breadcrumb link
\*------------------------------------*/

.ce-breadcrumb-link {
  margin: 0 rem(10);
  color: inherit;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    font-weight: bold;
  }
}

/*------------------------------------*\
  #Breadcrumb separator
\*------------------------------------*/
.ce-breadcrumbs .ade-icon {
  height: 0.65em;
  stroke-width: 3;
}
</style>
