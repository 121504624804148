<template>
  <span>
    <slot :isin="isin" :instrument-name="name"> ... </slot>
  </span>
</template>

<script lang="ts">
import { ProductModel, UnderlyingModel } from '@/src/types/the-q-api';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';

@Component
export default class AdInstrumentUrlReplacer extends Vue {
  @Getter('underlying', { namespace: 'underlying' })
  private underlying!: UnderlyingModel | null;

  @Getter('product', { namespace: 'product' })
  private product!: ProductModel | null;

  get isin() {
    return this.product?.isin ?? this.underlying?.isin ?? '...';
  }
  get name() {
    return this.product?.productNameTranslation ?? this.underlying?.name ?? '...';
  }
}
</script>
