<template>
  <div>
    <slot
      name="accordion-content"
      :visibleAccordionIndex="visibleAccordionIndex"
      :mutationCallback="onChildListMutation"
      :removeHash="removeHash"
    ></slot>
  </div>
</template>

<script lang="ts">
import { AccordionBlockPayloadSettings } from '@/src/types/episerver-api';
import { jumpToAnchor } from '@/src/utils/scroll-helpers';
import { Vue, Component, Inject } from 'vue-property-decorator';

@Component({})
export default class AdAnchorLinkAccordionContainer extends Vue {
  @Inject() settingsProperty!: AccordionBlockPayloadSettings | undefined;

  private isJumpToAnchorEnabled = true;
  public visibleAccordionIndex: number | null | undefined = 0;

  // Jump on mutation change. Requred due to accordion position change by post-loading content.
  onChildListMutation(mutations: MutationRecord[], observer: MutationObserver): void {
    if (this.isJumpToAnchorEnabled) {
      jumpToAnchor();
    }
  }

  removeHash() {
    history.replaceState('', document.title, window.location.pathname);
  }

  onHashChange(): void {
    const accordionIndex = this.tryGetAccordionIndexFromHash();

    if (typeof accordionIndex !== 'undefined' && accordionIndex !== null && accordionIndex >= 0) {
      this.visibleAccordionIndex = accordionIndex!;
    }
  }

  private tryGetAccordionIndexFromHash(): number | null | undefined {
    const anchor = location.hash.substring(1);
    if (!anchor || typeof this.settingsProperty === 'undefined') {
      return null;
    }
    return this.settingsProperty?.anchorIndexLookup[anchor];
  }

  mounted() {
    this.visibleAccordionIndex = this.settingsProperty?.defaultOpenIndex;
    this.onHashChange();
    window.onhashchange = (event: Event) => {
      event.preventDefault();
      this.onHashChange();
    };
  }

  updated() {
    // Disable jumping after some time, avoiding interfering with user interaction:
    setTimeout(() => (this.isJumpToAnchorEnabled = false), 3000);
  }
}
</script>
