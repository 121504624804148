<template>
  <b-form-group :id="'search-filter-daterange-one' + _uid" class="focus-white" label-for="date-from-list-filter">
    <template #label>
      <slot name="filter-label-one">[DATE FROM]</slot>
      <span class="deep-red" v-if="!$slots['all-items-label']">*</span></template
    >
    <ad-drop-down-list
      :loading="isLoading"
      v-bind="$attrs"
      :options="dropdownItems"
      :value="value"
      @input="onChangeDate"
      :form-element-state-id="'search-filter-daterange-one' + _uid"
      id="date-from-list-filter"
    >
      <template #all-items-label><slot name="all-items-label"></slot></template>
    </ad-drop-down-list>
  </b-form-group>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import { DropDownItem } from '@/src/types/vue-api';
import { convertToDropdownItems } from '@/src/utils/dropdown-option-provider';
import { DateRangeParameterModel } from '@/src/types/the-q-api';
import { formatField } from '@/src/utils/value-formatter/formatting-service';

@Component({
  inheritAttrs: false,
})
export default class AdFilterDaterangeOne extends Vue {
  @Prop() availableValues!: string[];
  @Prop({ required: true }) value!: DateRangeParameterModel | null;

  get isLoading(): boolean {
    return !this.availableValues;
  }

  get dropdownItems(): DropDownItem[] {
    return convertToDropdownItems(null, this.availableValues, (x) => formatField(x, 'date'));
  }

  onChangeDate(newValue: string | undefined): void {
    this.$emit('input', newValue);
    this.$emit('selected-value-changed');
  }
}
</script>
