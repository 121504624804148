<template>
  <div>
    <slot
      name="search"
      :currentProductType="currentProductType"
      :updateProductType="updateProductType"
      :pageLink="settingsProperty.pageLink"
    ></slot>
    <slot name="content-area"></slot>
  </div>
</template>
<script lang="ts">
import { ProductTypeBo } from '@/src/types/enumerations';
import { BestMatchSearchPageSetting } from '@/src/types/episerver-api';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component({})
export default class AdBestMatchSearchPage extends Vue {
  @Inject() settingsProperty!: BestMatchSearchPageSetting;

  @Action('bestMatchTableClear', { namespace: 'bestMatch' })
  bestMatchTableClear!: () => Promise<void>;

  currentProductType: ProductTypeBo = ProductTypeBo.Warrant;

  created() {
    this.currentProductType = this.settingsProperty.productType;
  }

  updateProductType(productType: ProductTypeBo) {
    this.bestMatchTableClear();
    this.currentProductType = productType;
  }
}
</script>
