<template>
  <div v-if="teaserUnderlyings" class="mb-6">
    <h2 class="fs-18 gun-metal mb-2"><slot name="teaser-title">Underlyings</slot></h2>
    <template v-if="teaserUnderlyings.length === 1 && $screen.xl">
      <ad-chart-configuration-property :chart-configuration-property="chartConfiguration">
        <ad-underlying-data-property :underlying="teaserUnderlyings[0]" :key="teaserUnderlyings[0].isin">
          <b-card class="mb-2 mb-3">
            <h3 class="mb-0">
              <ad-underlying-preheadline-text
                class="fs-12"
                :udp-preheadlines="settingsProperty.udpPreHeadlines"
                :underlying="teaserUnderlyings[0]"
              ></ad-underlying-preheadline-text>
              <ad-underlying-data-value fieldKey="underlying.name" />
            </h3>
            <b-row class="mb-3">
              <b-col class="fs-18 font-weight-bold blue">
                <ad-underlying-data-value fieldKey="underlying.last" />
                <span class="mr-lg-5 pl-1"><slot name="indications-info-block"></slot></span>
              </b-col>
              <b-col class="font-weight-bold">
                <ad-underlying-data-value fieldKey="underlying.changeAbsolute" />
                /&nbsp;
                <ad-underlying-data-value fieldKey="underlying.changePercentage" />
              </b-col>
            </b-row>
            <b-row class="fs-14 mb-3">
              <b-col>
                <b-row>
                  <b-col v-if="nsinKey" class="font-weight-bold" cols="4">
                    <slot name="nsin-label">Nsin</slot>
                  </b-col>
                  <b-col v-if="nsinKey" cols="8">
                    <ad-underlying-data-value :fieldKey="nsinKey" />
                  </b-col>
                  <b-col class="font-weight-bold" cols="4">
                    <slot name="isin-label">ISIN</slot>
                  </b-col>
                  <b-col cols="8">
                    <ad-underlying-data-value fieldKey="underlying.isin" />
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <slot name="indications-text"></slot>
              </b-col>
            </b-row>
            <slot name="teaser-chart" />
          </b-card>
        </ad-underlying-data-property>
      </ad-chart-configuration-property>
    </template>
    <template v-else>
      <b-card
        no-body
        class="mb-2 mb-lg-3 b-skeleton-m"
        v-for="teaserUnderlying in teaserUnderlyings"
        :key="teaserUnderlying.id"
      >
        <ad-underlying-data-property :underlying="teaserUnderlying">
          <b-row class="px-2 pl-sm-3 pr-sm-0 px-lg-3 py-3">
            <b-col cols="12" class="fs-26 font-weight-normal blue">
              <ad-underlying-preheadline-text
                class="fs-12"
                :underlying="teaserUnderlying"
                :udp-preheadlines="settingsProperty.udpPreHeadlines"
              ></ad-underlying-preheadline-text>
              <ad-underlying-data-value fieldKey="underlying.name" />
            </b-col>
            <b-col class="fs-18 font-weight-bold blue d-lg-none">
              <ad-underlying-data-value fieldKey="underlying.last" />
              <span class="mr-lg-5 pl-1"><slot name="indications-info-block"></slot></span>
            </b-col>
            <b-col lg="6" class="fs-16 font-weight-bold blue d-none d-lg-inline">
              <ad-underlying-data-value fieldKey="underlying.last" />
              <span class="pl-1 label"><slot name="indications-info-block"></slot></span>
            </b-col>
            <b-col cols="12" class="mt-1 mb-2 fs-16 font-weight-bold gun-metal d-lg-none">
              <div>
                <ad-underlying-data-value fieldKey="underlying.changeAbsolute" />
                /&nbsp;
                <ad-underlying-data-value fieldKey="underlying.changePercentage" />
              </div>
            </b-col>
            <b-col lg="6" class="pb-3 fs-16 font-weight-bold gun-metal d-none d-lg-inline text-right">
              <div>
                <ad-underlying-data-value fieldKey="underlying.changeAbsolute" />
                /&nbsp;
                <ad-underlying-data-value fieldKey="underlying.changePercentage" />
              </div>
            </b-col>
            <b-col cols="6" sm="5" lg="4" class="pt-1 fs-14 font-weight-light d-flex">
              <div class="d-inline">
                <span class="font-weight-bold d-block" v-if="nsinKey"><slot name="nsin-label"></slot></span>
                <span class="font-weight-bold d-block"><slot name="isin-label">ISIN</slot></span>
              </div>
              <div class="d-inline ml-2">
                <span class="d-block" v-if="nsinKey"> <ad-underlying-data-value :fieldKey="nsinKey" /> &nbsp; </span>
                <span class="d-block">
                  <ad-underlying-data-value fieldKey="underlying.isin" />
                </span>
              </div>
            </b-col>
            <b-col cols="5" sm="7" class="pt-1 pr-1 pr-sm-4 fs-14 text-right d-lg-none">
              <div class="font-weight-light">
                <span class="d-inline"><slot name="indications-text"></slot> </span>
              </div>
            </b-col>
            <b-col lg="8" class="pt-1 pr-2 fs-14 text-right d-none d-lg-inline">
              <div class="font-weight-light d-inline-flex">
                <span class="d-inline"><slot name="indications-text"></slot> </span>
              </div>
            </b-col>
          </b-row>
        </ad-underlying-data-property>
      </b-card>
    </template>
  </div>
</template>

<script lang="ts">
import { InstrumentFieldKeys, UnderlyingDetailPageSetting } from '@/src/types/episerver-api';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { ChartConfiguration } from '@/src/types/vue-api';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({})
export default class AdUnderlyingTeaserDefault extends Vue {
  @Getter('teaserUnderlyings', { namespace: 'underlying' })
  teaserUnderlyings!: UnderlyingModel[];
  @Inject({ default: null })
  nsinKeys!: InstrumentFieldKeys | null;
  @Inject()
  settingsProperty?: UnderlyingDetailPageSetting;

  chartConfiguration = new ChartConfiguration();

  get nsinKey(): string | null {
    return this.nsinKeys?.underlyingKey || null;
  }

  created() {
    this.chartConfiguration.forceDropDownForChartPeriods = true;
    this.chartConfiguration.legendInTwoLineModeAtTablet = true;
  }
}
</script>
