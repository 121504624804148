import {
  InstrumentMergeColumnSetting,
  InstrumentTableColumnSetting,
  ProductTableSetting,
} from '@src/types/episerver-api';
import { ProductTeaserSettings } from '../types/vue-api';
import { differenceBy, uniqBy } from 'lodash';

export const nameOf = <T>(name: Extract<keyof T, string>): string => name;

export function flatMapMergeColumns(mergeColumns: InstrumentMergeColumnSetting[]) {
  return mergeColumns.flatMap<InstrumentTableColumnSetting>(
    (c) =>
      c.columnMergeSetting.filter(
        (mc) => (mc as InstrumentTableColumnSetting)?.key !== undefined
      ) as InstrumentTableColumnSetting[]
  );
}

export function getExcludedColumnDefinition(
  columnSetting: InstrumentMergeColumnSetting[],
  settings: ProductTableSetting
): InstrumentTableColumnSetting[] {
  const columns = flatMapMergeColumns(columnSetting);
  const desktopColumns = flatMapMergeColumns(settings.desktopTableColumnsSetting);
  const hiddenValuesColumns = settings.searchResultHiddenValuesSetting;
  const mergedColumns = [
    ...differenceBy(desktopColumns, columns, nameOf<InstrumentTableColumnSetting>('key')),
    ...hiddenValuesColumns,
  ];

  return uniqBy(mergedColumns, nameOf<InstrumentTableColumnSetting>('key'));
}

export function getProductTeaserSettings(settings: ProductTableSetting): ProductTeaserSettings {
  return new ProductTeaserSettings(
    settings.searchResultHiddenValuesSetting,
    getExcludedColumnDefinition(settings.tabletTableColumnsSetting, settings),
    getExcludedColumnDefinition(settings.mobileTableColumnsSetting, settings)
  );
}
