<template>
  <b-form-group
    v-if="isVisibleFunc(valueProperty)"
    class="filter-numeric-input"
    :class="{ error: isError, 'in-valid': required && (value === null || value === undefined) }"
    :label-for="`numeric-range-form-input` + _uid"
  >
    <template #label
      ><slot name="filter-label-text">[From]</slot>
      <span v-if="isValueModified">&nbsp; {{ borderValueAsString }}</span>
      <span class="deep-red" v-if="required"> *</span>
    </template>
    <ad-skeleton-input :loading="loading">
      <b-form-input
        :id="`numeric-range-form-input` + _uid"
        type="text"
        v-model="localCurrentValueAsString"
        v-on:keydown.enter="onValueChange(localCurrentValueAsString)"
        v-on:blur="onValueChange(localCurrentValueAsString)"
        :placeholder="placeholder"
        v-bind="$attrs"
      ></b-form-input>
    </ad-skeleton-input>
  </b-form-group>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { TextToNumericConvertHelper } from './text-to-numeric-convert-helper';

@Component({
  inheritAttrs: false,
})
export default class AdFilterNumericInput extends Vue {
  @Prop({ required: true }) value!: number | null;
  @Prop() valueProperty: string | undefined;
  @Prop() borderValue!: number;
  @Prop({ default: () => true, type: Function }) validateFunc!: (v: number) => boolean;
  @Prop({ default: () => true, type: Function }) isVisibleFunc!: (key: string | undefined) => boolean;
  @Prop({ required: true }) fieldFormat!: string;
  @Prop({ type: Boolean }) required!: boolean;
  @Prop({ default: false, type: Boolean }) loading!: boolean;

  private convertHelper!: TextToNumericConvertHelper;
  private localCurrentValueAsString: string | null = null;
  private isError = false;

  created(): void {
    this.convertHelper = new TextToNumericConvertHelper(this.fieldFormat);
    this.setLocalValueToValueFromModel();
  }

  @Watch('value')
  setLocalValueToValueFromModel(): void {
    this.localCurrentValueAsString = this.value !== null ? this.convertHelper.toText(this.value) : null;
  }

  get isValueModified(): boolean {
    return this.localCurrentValueAsString !== null;
  }

  get placeholder(): string {
    const slot = this.$slots['input-placeholder'];
    return slot && slot.length
      ? slot[0].text?.trim() ?? this.convertHelper.toText(this.borderValue)
      : this.convertHelper.toText(this.borderValue);
  }

  get borderValueAsString(): string {
    return this.convertHelper.toText(this.borderValue);
  }

  onValueChange(textValue: string): void {
    if (textValue) {
      this.isError = false;
      const valAsNumber = this.convertHelper.toNumber(textValue);

      if (this.validateFunc(valAsNumber)) {
        if (this.isDifferentThanCurrentValue(valAsNumber)) {
          this.$emit('input', valAsNumber);
        }
        this.localCurrentValueAsString = this.convertHelper.toText(valAsNumber);
      } else {
        this.$emit('input', null);
        this.localCurrentValueAsString = null;
      }
    } else if (this.localCurrentValueAsString !== null) {
      this.isError = this.required;
      this.$emit('input', null);
      this.localCurrentValueAsString = null;
    }
  }

  isDifferentThanCurrentValue(val: number) {
    return val !== this.convertHelper.toPrecisionNumber(this.value);
  }

  get isValidInput(): boolean {
    if (!this.localCurrentValueAsString?.trim()) {
      return false;
    }
    const nummericVal = this.convertHelper.toNumber(this.localCurrentValueAsString);
    return this.validateFunc(nummericVal);
  }
}
</script>
