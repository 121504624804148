<template>
  <b-row v-bind="$attrs" class="ade-underlying-teaser-realtime-data" v-if="underlying">
    <b-col cols="12" md="9" class="fs-16">
      <b-row>
        <b-col cols="6" md="3" xl="12">
          <b-row>
            <b-col cols="12" xl="4" class="font-weight-bold blue">
              <slot name="price-value-label"></slot>
            </b-col>
            <b-col cols="12" xl="4" class="font-weight-bold blue">
              <slot name="price-change-label"></slot>
            </b-col>
            <b-col cols="12" xl="4" class="font-weight-bold blue">
              <slot name="price-close-label"></slot>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" md="9" xl="12">
          <b-row>
            <b-col cols="12" xl="4">
              <ad-underlying-data-value fieldKey="underlying.last" /><span class="pl-1 blue"
                ><slot name="info-icon-block"></slot
              ></span>
            </b-col>
            <b-col cols="12" xl="4">
              <ad-underlying-data-value fieldKey="underlying.changeAbsolute" />
              / <ad-underlying-data-value fieldKey="underlying.changePercentage" />
            </b-col>
            <b-col cols="12" xl="4">
              <ad-underlying-data-value fieldKey="underlying.close" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" md="3" class="text-md-right fs-12 font-weight-light pt-3 pt-md-0">
      <slot class="mb-0" name="price-indications-label"></slot>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { UnderlyingDetailPageSetting } from '@/src/types/episerver-api';
import { UnderlyingModel } from '@/src/types/the-q-api';
import { Component, Vue, Inject, InjectReactive } from 'vue-property-decorator';

@Component({})
export default class AdUnderlyingTeaserRealtimeData extends Vue {
  @Inject()
  settingsProperty?: UnderlyingDetailPageSetting;
  @InjectReactive() underlying!: UnderlyingModel | null;
}
</script>
