<template>
  <div class="ce-chapter-select">
    <h2><slot name="heading">Content</slot></h2>

    <ol class="ce-chapter-select--chapter-list">
      <li v-for="(chapter, i) in chapters" v-bind:key="chapter.identifier || i">
        <a
          class="ce-chapter-select--chapter"
          :class="{ active: isChapterActive(chapter) }"
          :href="isChapterActive(chapter) ? undefined : chapter.url"
        >
          <div>
            <span class="ce-chapter-select--active-chapter-indicator fs-14">
              <slot name="you-are-here">You are here</slot>
            </span>
            <span class="ce-chapter-select--chapter-title fs-26">
              <span class="ce-chapter-select--chapter-number">{{ i + 1 }}. </span>
              <span class="ce-chapter-select--chapter-title-text"
                ><ad-template-renderer :text="chapter.title"></ad-template-renderer
              ></span>
            </span>
          </div>
          <ad-icon-chevron rotate="270"></ad-icon-chevron>
        </a>
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { ChapterNavigationChapter } from '@/src/types/ce-component-types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CeChapterSelect extends Vue {
  /**
   * All chapters that will be displayed in the UI.
   */
  @Prop({ default: [] }) chapters!: ChapterNavigationChapter[];

  /**
   * Chapter that is marked as active in the UI
   * Can be provided as a string (identifier of the chapter) or as a chapter object.
   * When a chapter object is supplied, the identifier is used to determine if the chapter is active.
   */
  @Prop() activeChapter: ChapterNavigationChapter | string | undefined | null;

  isChapterActive(chapter: ChapterNavigationChapter) {
    if (!this.activeChapter) return false;

    if (typeof this.activeChapter === 'string') {
      return this.activeChapter === chapter.identifier;
    }

    if (this.activeChapter === chapter) return true;

    if (chapter.identifier && this.activeChapter.identifier) {
      return chapter.identifier === this.activeChapter.identifier;
    }

    return false;
  }
}
</script>

<style lang="scss">
.ce-chapter-select {
  &--chapter-list {
    margin-top: map-get($spacers, 4);
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up('md') {
      margin-top: map-get($spacers, 5);
    }
  }

  &--chapter {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin-bottom: map-get($spacers, 2);
    border: 1px dashed $bright-sky-blue;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: map-get($spacers, 3);
    color: $peacock-blue;

    &:hover,
    &:focus {
      background: transparentize($bright-sky-blue, 0.9);
      color: $marine-blue;

      svg {
        color: $bright-sky-blue;
      }
    }

    &:focus {
      border: 2px solid $peacock-blue;
      padding: calc(#{map-get($spacers, 3)} - 1px);
    }

    &.active {
      background: transparentize($bright-sky-blue, 0.9);
      cursor: default;
      padding-top: map-get($spacers, 4);
      color: $gunmetal;

      svg {
        display: none;
      }

      .ce-chapter-select--active-chapter-indicator {
        display: block;
      }
    }
  }

  &--active-chapter-indicator {
    display: none;
    position: absolute;
    top: map-get($spacers, 2);
    color: $peacock-blue;
    font-weight: bold;
  }

  &--chapter-title {
    display: flex;
    padding-right: 1.5rem;
  }

  &--chapter-number {
    margin-right: 0.5rem;
  }
}
</style>
