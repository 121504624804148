<template>
  <div class="ce-article-navigation">
    <a
      v-if="previousUrl"
      class="btn ce-article-navigation--previous justify-content-center d-flex align-items-center"
      :href="previousUrl"
    >
      <ad-icon-chevron rotate="90"></ad-icon-chevron><slot name="previous-button-text">Previous</slot>
    </a>
    <span v-else class="ce-article-navigation--previous"></span>
    <a
      v-if="nextUrl"
      class="btn ce-article-navigation--next justify-content-center d-flex align-items-center"
      :href="nextUrl"
    >
      <slot name="next-button-text">Next</slot><ad-icon-chevron rotate="270"></ad-icon-chevron>
    </a>
    <span v-else class="ce-article-navigation--next"></span>
    <a
      v-if="backUrl"
      class="btn ce-article-navigation--back-to-overview justify-content-center d-flex align-items-center"
      :href="backUrl"
      ><slot name="back-button-text">Back to overview</slot></a
    >
    <span v-else class="ce-article-navigation--back-to-overview"></span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CeArticleNavigation extends Vue {
  @Prop() previousUrl?: string;
  @Prop() backUrl?: string;
  @Prop() nextUrl?: string;
}
</script>

<style lang="scss">
.ce-article-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px dashed $gunmetal;
  border-bottom: 1px dashed $gunmetal;
  padding: map-get($spacers, 3) 0;

  & &--next,
  & &--previous {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: map-get($spacers, 2) map-get($spacers, 3);
    width: calc(50% - #{map-get($spacers, 2)});

    svg {
      font-size: 0.75rem;
    }

    @include media-breakpoint-up('md') {
      width: 12rem;
    }
  }

  &--previous {
    order: 0;
  }

  &--next {
    order: 1;

    @include media-breakpoint-up('md') {
      order: 2;
    }
  }

  &--back-to-overview {
    order: 2;
    margin-top: map-get($spacers, 4);
    width: 100%;

    @include media-breakpoint-up('md') {
      order: 1;
      margin-top: 0;
      width: 12rem;
    }
  }
}
</style>
