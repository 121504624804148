<template>
  <div class="ce-gallery">
    <b-carousel id="carousel-1" :interval="0" v-model="index">
      <b-carousel-slide v-for="(slide, i) in filteredNodes" :key="i">
        <ce-node-renderer :node="slide"></ce-node-renderer>
      </b-carousel-slide>
    </b-carousel>
    <div class="ce-gallery--controls mt-3">
      <button @click="index--"><ad-icon-chevron direction="left"></ad-icon-chevron></button>
      <div class="ce-gallery--indicators">
        <div
          class="ce-gallery--indicator"
          v-for="(slide, i) in filteredNodes"
          :key="i"
          :class="{ active: i === index }"
        >
        </div>
      </div>
      <button @click="index++"><ad-icon-chevron direction="right"></ad-icon-chevron></button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import Vue, { VNode } from 'vue';
import { filterVNodes } from '@/src/utils/vnodes-filter';

@Component
export default class CeImageGallery extends Vue {
  index = 0;

  get filteredNodes(): VNode[] {
    return filterVNodes(this.$slots.default);
  }
}
</script>

<style lang="scss">
.ce-gallery {
  &--controls {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border: 0;
      background: transparent;
      color: $marine-blue;
    }
  }
  &--indicators {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    max-width: rem(800);
  }
  &--indicator {
    margin: 0 0.25rem;
    border-radius: 50%;
    background: $silver;
    width: 0.5rem;
    height: 0.5rem;

    &.active {
      background: $bright-sky-blue;
    }
  }

  .carousel-caption {
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
    margin: 0;
    padding: 0;
    text-align: left;
    color: inherit;
  }

  .carousel-item {
    display: block;
    transition: none;
    visibility: hidden;

    &-left,
    &-right {
      transition: transform 0.6s;
    }

    &-next,
    &-prev,
    &.active {
      visibility: visible;
    }
  }
}

.ce-gallery-image-container {
  display: flex;
  position: relative;
  border: 1px solid $silver;
  width: 100%;
  height: 600px;
  max-height: 50vh;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
