<template>
  <div v-if="$screen.xl">
    <slot name="desktop"></slot>
  </div>
  <div v-else-if="$screen.md"> <slot name="tablet"></slot> </div>
  <div v-else>
    <slot name="mobile"></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({
  inheritAttrs: false,
})
export default class AdResponsiveViewPanel extends Vue {}
</script>
