<template>
  <ad-drop-down-list
    v-bind="$attrs"
    :options="getItems"
    @selected-value-changed="selectedValueChanged"
    :value="value"
    @input="setValue"
    :loading="internalLoading"
  >
    <template #all-items-label>
      <slot name="all-items-label"></slot>
    </template>
    <template #placeholder>
      <slot name="placeholder"></slot>
    </template>
  </ad-drop-down-list>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Option, OptionGroup, ProductTypeGroupOptionsFilter, ProductTypeOptionsFilter } from '@src/types/the-q-api';
import { convertGroups, convertOptions } from '@src/utils/dropdown-option-provider';
import { DropDownItem } from '@/src/types/vue-api';
import { Action, Getter } from 'vuex-class';

@Component({
  inheritAttrs: false,
})
export default class AdProductTypeList extends Vue {
  @Prop() value!: DropDownItem;
  @Prop({ default: false, type: Boolean }) onlyLeverage!: boolean;
  @Prop({ default: null }) availableItems!: Array<string> | null;
  private productTypes: DropDownItem[] | null = [];
  private filter = {} as ProductTypeGroupOptionsFilter;
  private internalLoading = true;

  @Getter('productTypeOptions', { namespace: 'product' })
  productTypeOptions!: (filter: ProductTypeOptionsFilter) => Option[];

  @Getter('productTypeGroupOptions', { namespace: 'product' })
  productTypeGroupOptions!: (filter: ProductTypeGroupOptionsFilter) => OptionGroup[];

  @Action('getProductTypeOptionsAsync', { namespace: 'product' })
  getProductTypeOptionsAsync!: (filter: ProductTypeOptionsFilter) => Promise<void>;

  @Action('getProductTypeGroupOptionsAsync', { namespace: 'product' })
  getProductTypeGroupOptionsAsync!: (filter: ProductTypeGroupOptionsFilter) => Promise<void>;

  get getItems() {
    return this.availableItems !== null ? this.getProductTypes() : this.getProductTypeGroups();
  }

  private selectedValueChanged(): void {
    this.$emit('selected-value-changed');
  }

  get allOptionLabel(): string | null {
    const slots = this.$slots['all-items-label'];
    return slots && slots.length ? slots[0].text ?? null : null;
  }

  private async created() {
    this.filter = { onlyLeverage: this.onlyLeverage } as ProductTypeGroupOptionsFilter;
    try {
      await this.getProductTypeGroupOptionsAsync(this.filter);
      await this.getProductTypeOptionsAsync({} as ProductTypeOptionsFilter);
    } catch (e) {
      this.$log.error('Error occured loading data in product type list', e);
    }
    this.internalLoading = false;
  }

  private getProductTypeGroups(): DropDownItem[] {
    const groupOptions = this.productTypeGroupOptions(this.filter);
    if (groupOptions.length > 1) {
      return convertGroups(groupOptions, this.allOptionLabel);
    } else if (groupOptions.length == 1) {
      return convertOptions(groupOptions[0].options, this.allOptionLabel);
    } else {
      return [];
    }
  }

  private getProductTypes() {
    const filter = {} as ProductTypeOptionsFilter;
    let options = this.productTypeOptions(filter);
    if (this.availableItems) {
      options = options.filter((t) => this.availableItems?.includes(t.value));
    }
    return convertOptions(options, this.allOptionLabel);
  }

  setValue(newValue: string | null): void {
    this.$emit('input', newValue);
  }
}
</script>
