<template>
  <div class="ce-sub-page-tiles">
    <a v-for="(tile, i) in tiles" v-bind:key="i" :href="tile.link" class="ce-sub-page-tiles--tile-container">
      <div :class="'ce-tile__gradient-' + tile.color" class="w-100 ce-tile h-100 p-3 p-md-5 d-flex flex-nowrap">
        <div class="col-11 p-0">
          <h3>{{ tile.title }}</h3>
          <p>
            {{ tile.text }}
          </p>
        </div>
        <div class="col-1 fs-18"><ad-icon-chevron rotate="270" /></div>
      </div>
    </a>
  </div>
</template>

<script lang="ts">
import { TileContent } from '@/src/types/ce-component-types';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class CeSubPageTiles extends Vue {
  @Prop({ required: true }) tiles!: TileContent[];
}
</script>

<style lang="scss">
.ce-sub-page-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &--tile-container {
    margin-bottom: map-get($spacers, 4);
    width: 100%;

    @include media-breakpoint-up('lg') {
      flex: 0 0 auto;
      margin-right: map-get($spacers, 4);
      width: calc(33.333% - #{map-get($spacers, 4) * 2 / 3});

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
