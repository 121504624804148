<template>
  <ad-icon-base iconName="Xing" v-bind="$attrs" :viewBox="viewBox">
    <path
      id="path2"
      sodipodi:nodetypes="ccsscscccccsscccsssccccsscccsssssssccsssssssccsssssssssccscsccsssssscscsccsscssscscc"
      fill="#006567"
      d="
	M41.128,88.578l16.061-30.386c0.868-1.736,1.302-2.604,3.473-2.604h17.363c0.868,0,1.302,0.435,1.736,0.868
	c0,0.435,0.434,0.435,0.434,0.868c0,0.435,0,0.868-0.434,0.868l-25.176,46.012l26.479,48.183c0,0.435,0.434,0.868,0.434,0.868
	c0,0.435,0,0.868-0.434,0.868c-0.434,0.435-0.868,0.868-1.736,0.868H61.964c-2.17,0-2.604-1.302-3.473-2.604l-17.797-32.556
	l-17.797,32.556c-0.868,1.736-1.302,2.604-3.473,2.604H2.062c-0.868,0-1.302-0.434-1.736-0.868c-0.434-0.434-0.434-1.302,0-2.17
	l26.479-48.183L2.496,58.627c-0.434-0.868-0.434-1.303,0-2.171c0.434-0.434,0.868-0.868,1.736-0.868h17.363
	c2.17,0,2.604,1.303,3.473,2.604L41.128,88.578z M175.258,115.49V58.193c0-1.302,0.868-2.604,2.604-2.604h13.89
	c1.302,0,2.604,0.868,2.604,2.604v95.063c0,1.303-0.868,2.604-2.604,2.604h-14.324c-1.736,0-3.039-1.302-3.473-2.604l-29.517-57.298
	v57.298c0,1.303-0.868,2.604-2.604,2.604h-13.891c-1.302,0-2.604-0.868-2.604-2.604V58.193c0-1.302,1.302-2.604,2.604-2.604h14.325
	c2.17,0,2.604,1.303,3.473,2.604L175.258,115.49z M91.047,153.255V58.193c0-1.302,0.868-2.604,2.604-2.604h13.89
	c1.302,0,2.604,0.868,2.604,2.604v95.063c0,1.303-0.868,2.604-2.604,2.604h-13.89C91.916,155.86,91.047,154.558,91.047,153.255z
    M250.787,71.65c-7.379,0-13.457,1.735-18.231,8.247c-4.341,6.077-6.945,13.891-6.945,25.176c0,10.853,0.435,19.534,4.341,25.177
	c3.473,5.643,9.55,9.55,16.929,9.55c8.247,0,14.325-2.171,14.325-2.171v-21.27H246.88c-1.302,0-2.604-1.302-2.604-2.604v-12.153
	c0-1.303,0.869-2.604,2.604-2.604h30.819c1.303,0,2.604,1.302,2.604,2.604v48.182c-0.868,0.435-16.061,7.813-34.292,7.813
	c-15.192,0-23.874-5.643-29.951-13.891c-6.945-9.115-9.549-24.308-9.549-38.198c0-9.983,1.302-19.1,4.774-26.913
	c3.473-8.247,9.55-14.758,15.626-18.23c6.945-3.907,15.193-5.644,23.44-5.644h2.171c12.588,0,19.967,3.039,22.572,4.341
	c2.17,0.868,2.604,1.736,2.604,3.039v11.72c0,0.868-0.434,1.302-0.868,1.736c-0.435,0.434-1.302,0.434-1.736,0.434
	c-4.774-1.736-12.155-4.495-22.138-4.34H250.787z"
    />
    <path
      id="path4"
      fill="#B0D400"
      d="M381.877,0.461c-2.17,0-3.473,1.302-4.341,3.038c0,0-33.423,59.469-34.726,61.205l22.138,40.368
	c0.868,1.303,2.171,3.039,4.341,3.039h15.627c0.868,0,1.736-0.435,2.17-0.868c0.435-0.868,0.435-1.736,0-2.604l-21.703-39.935
	l34.292-60.771c0.434-0.868,0.434-1.736,0-2.604c-0.435-0.435-1.303-0.868-2.171-0.868H381.877z"
    />
    <path
      id="path6"
      fill="#006567"
      d="M316.767,21.731c-0.868,0-1.736,0.434-2.171,0.868c-0.434,0.868-0.434,1.736,0,2.604
	l10.418,18.23l-16.061,29.083c-0.435,0.868-0.435,1.736,0,2.604c0.434,0.435,1.302,0.868,2.17,0.868h15.192
	c2.171,0,3.473-1.736,4.341-3.038c0,0,16.062-28.649,16.93-29.518l-10.853-18.665c-0.868-1.302-2.17-3.038-4.341-3.038H316.767z"
    />
  </ad-icon-base>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class AdIconXing extends Vue {
  @Prop({ default: '0 30 400 157.135', type: String }) viewBox!: string;
}
</script>
