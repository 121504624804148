<template>
  <div>
    <ce-text-image-teaser
      :teasers="teasers"
      :maxItemCount="3"
      :layout-mode="layoutMode"
      :showMoreButtonLink="settingsProperty.showMoreButtonLink"
    >
      <template #headline>
        <h2><slot name="headline"></slot></h2>
      </template>
      <template v-if="!!settingsProperty.showMoreButtonText" #show-more>
        {{ settingsProperty.showMoreButtonText }}
      </template>
    </ce-text-image-teaser>
  </div>
</template>
<script lang="ts">
import { ImageData, TextImageTeaserImageBlock, TextImageTeaserVideoBlock } from '@/src/types/ce-component-types';
import { DefaultFormat, TextImageTeaserBackground, TextImageTeaserTypes } from '@/src/types/enumerations';
import { NewsTeaserContainerSettings } from '@/src/types/episerver-api';
import { getImageResizerUrl } from '@/src/utils/url-helper';
import { formatField } from '@/src/utils/value-formatter/formatting-service';
import { Component, Inject, Vue } from 'vue-property-decorator';

@Component
export default class AdNewsTeasers extends Vue {
  @Inject() settingsProperty!: NewsTeaserContainerSettings;

  get layoutMode(): TextImageTeaserBackground {
    return this.settingsProperty.background ? TextImageTeaserBackground[this.settingsProperty.background] : null;
  }

  get teasers(): Array<TextImageTeaserImageBlock | TextImageTeaserVideoBlock> {
    return this.settingsProperty.teasers.map((teaser) => {
      return teaser.video != null
        ? ({
            type: TextImageTeaserTypes.Video,
            link: teaser.url,
            headlineText: teaser.headline,
            youtubeId: teaser.video.youtubeId,
            previewImageSrc: teaser.video.previewImageSrc,
            copyText: teaser.previewText,
            subText: formatField(teaser.publishedDate, DefaultFormat.BlogDate),
            preHeadlineText: teaser.translatedPageName,
          } as TextImageTeaserVideoBlock)
        : ({
            link: teaser.url,
            headlineText: teaser.headline,
            subText: formatField(teaser.publishedDate, DefaultFormat.BlogDate),
            copyText: teaser.previewText,
            preHeadlineText: teaser.translatedPageName,
            image: {
              src: getImageResizerUrl(teaser.imageUrlWithSizeParameter, 316, 185),
              sources: [
                { Media: '(min-width:650px)', SrcSet: getImageResizerUrl(teaser.imageUrlWithSizeParameter, 1600, 540) },
                { Media: '(min-width:465py)', SrcSet: getImageResizerUrl(teaser.imageUrlWithSizeParameter, 500, 500) },
              ],
            } as ImageData,
            type: TextImageTeaserTypes.Image,
          } as TextImageTeaserImageBlock);
    });
  }
}
</script>
