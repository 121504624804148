<template>
  <div class="pb-3 pb-md-5 pb-lg-4">
    <table>
      <tr>
        <td class="p-0 marine-blue font-weight-bold"><slot name="isin-label"></slot></td>
        <td class="py-0"><ad-product-data-value field-key="product.isin"></ad-product-data-value></td>
      </tr>
      <tr v-if="hasNsinField">
        <td class="p-0 marine-blue font-weight-bold"><slot name="nsin-label"></slot></td>
        <td class="py-0"><slot name="nsin-field"></slot></td>
      </tr>
      <template v-for="(column, index) in columns">
        <tr
          v-if="(isAddColumnDistributionFee && column.isDistributionSwitchColumn) || !column.isDistributionSwitchColumn"
          :key="column.key"
        >
          <td :class="['p-0 marine-blue font-weight-bold', { 'pt-2': index == 0 }]">{{ column.headline }}</td>
          <td :class="['py-0', { 'pt-2': index == 0 }]">
            <ad-product-data-value :field-key="column.formatId"></ad-product-data-value>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>
<script lang="ts">
import { Component, Inject, InjectReactive, Vue } from 'vue-property-decorator';
import { ProductTeaserSettings } from '@/src/types/vue-api';
import { InstrumentTableColumnSetting } from '@/src/types/episerver-api';

@Component({})
export default class AdProductColumnList extends Vue {
  @Inject() teaserSettingsProperty!: ProductTeaserSettings;
  @InjectReactive() isAddColumnDistributionFee!: boolean;

  get hasNsinField() {
    return !!this.$slots['nsin-field'];
  }

  get columns(): InstrumentTableColumnSetting[] {
    if (this.$screen.xl) return this.teaserSettingsProperty.desktopTableColumnsSetting;
    else if (this.$screen.lg || this.$screen.md) return this.teaserSettingsProperty.tabletTableColumnsSetting;
    else return this.teaserSettingsProperty.mobileTableColumnsSetting;
  }
}
</script>
