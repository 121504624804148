<template>
  <div>
    <ce-tile-chunk
      v-for="(chunk, i) in tileChunks"
      v-bind:key="i"
      :tiles="chunk"
      :lastChunk="i + 1 === numberOfChunks"
      :tiny="tiny"
    ></ce-tile-chunk>
  </div>
</template>

<script lang="ts">
import CeTileChunk from './_tile-chunk.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { TileContent } from '@/src/types/ce-component-types';

@Component({
  components: {
    CeTileChunk,
  },
})
export default class CeTile extends Vue {
  @Prop({ required: true }) tiles!: TileContent[];
  @Prop({ default: false }) tiny!: boolean;

  get tileChunks(): TileContent[][] {
    const chunks: TileContent[][] = [];
    const tileList = [...this.tiles];
    let chunk: TileContent[] = [];
    for (let i = 0; i < tileList.length; i++) {
      if (chunk.length === 4) {
        chunks.push(chunk);
        chunk = [];
      }
      chunk.push(tileList[i]);
    }
    chunks.push(chunk);
    return chunks;
  }

  get numberOfChunks() {
    return Math.ceil(this.tiles.length / 4);
  }
}
</script>
