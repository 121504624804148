<template>
  <div>
    <h3>
      <slot name="headline"></slot>
    </h3>
    <div>
      <template v-for="(h, i) in initialContent">
        <ce-node-renderer :node="h" v-bind:key="i + 'initialContent'" />
      </template>
      <template v-for="(item, i) in loadedAdditionalContent">
        <ad-runtime-component :htmlTemplate="item" v-bind:key="i + 'loadedLater'" />
      </template>
    </div>
    <div v-if="canLoadMore">
      <b-button variant="secondary" @click="loadMore" :disabled="isLoading">
        <slot name="show-more"></slot>
      </b-button>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Inject } from 'vue-property-decorator';
import { LoadMoreTeaserSettings, RenderedPagedContent } from '@/src/types/episerver-api';
import { Controller } from '@src/types/enumerations';
import httpClient from '@/src/utils/http-service';
import { getEpiserverApiUrl } from '@/src/utils/url-helper';
import { filterVNodes } from '@src/utils/vnodes-filter';
import AdRuntimeComponent from './ad-runtime-component.vue';

@Component({ components: { AdRuntimeComponent } })
export default class AdLoadMoreTeasers extends Vue {
  @Inject() settingsProperty!: LoadMoreTeaserSettings;

  isLoading = false;
  canLoadMore = false;
  additionalContent: string[] = [];
  contentItemsCount = 0;

  get initialContent() {
    return filterVNodes(this.$slots['initial-content']);
  }

  get loadedAdditionalContent() {
    return this.additionalContent;
  }

  created() {
    this.canLoadMore = this.settingsProperty.hasMore;
    this.contentItemsCount = this.settingsProperty.initialItemsCount;
  }

  async loadMore() {
    try {
      this.isLoading = true;
      const response = await httpClient.get<RenderedPagedContent>(getEpiserverApiUrl(Controller.DataSource, 'Get'), {
        params: {
          blockId: this.settingsProperty.blockId,
          skip: this.contentItemsCount,
          take: this.settingsProperty.loadMoreCount,
        },
      });
      const result = response.data;
      this.contentItemsCount += result.contentItemsCount;
      this.canLoadMore = result.hasMore;
      this.additionalContent = this.additionalContent.concat(result.content);
    } catch (ex) {
      this.$log.error(ex);
    }
    this.isLoading = false;
  }
}
</script>
